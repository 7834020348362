import { LinearProgress } from "@octopusdeploy/design-system-components";
import cn from "classnames";
import type { History } from "history";
import * as React from "react";
import type { Errors } from "~/components/DataBaseComponent/Errors";
import ErrorPanel from "~/components/ErrorPanel/ErrorPanel";
import type { OverflowMenuNavLink } from "~/components/OverflowMenu/OverflowMenu";
import SectionTitle from "~/components/SectionTitle";
import Sticky, { StickyStatus } from "~/components/Sticky/Sticky";
import TransitionAnimation from "~/components/TransitionAnimation/TransitionAnimation";
import PageTitleHelper from "~/utils/PageTitleHelper";
import BusyFromPromise from "../BusyFromPromise/BusyFromPromise";
import styles from "./style.module.less";
type LocationDescriptor = History.LocationDescriptor;
export interface BreadcrumbProps {
    breadcrumbTitle?: string; // If specified, this text will display above your layout's title.
    breadcrumbPath?: LocationDescriptor; // If specified, this will link your breadcrumb title.
    breadcrumbOverflow?: OverflowMenuNavLink[];
    breadcrumbChip?: JSX.Element;
}
export interface PaperLayoutProps extends BreadcrumbProps {
    title?: React.ReactNode;
    titlePath?: LocationDescriptor;
    titleLogo?: JSX.Element;
    sectionControl?: React.ReactNode;
    busy?: Promise<unknown> | boolean;
    enableLessIntrusiveLoadingIndicator?: boolean;
    errors?: Errors;
    callout?: React.ReactNode;
    className?: string;
    innerClassName?: string;
    fullWidth?: boolean;
    flatStyle?: boolean;
    disableAnimations?: boolean;
    disableHeaderAnimations?: boolean;
    disableSettingPageTitle?: boolean;
    hideHelpIcon?: boolean;
    disableScrollToActiveError?: boolean;
    hideErrors?: boolean;
    disableStickyHeader?: boolean;
    statusSection?: React.ReactNode;
}
interface PaperLayoutState {
    stickyState: StickyStatus;
}
export const headerId = "paperlayoutstickyheader";
class PaperLayout extends React.Component<PaperLayoutProps, PaperLayoutState> {
    constructor(props: PaperLayoutProps) {
        super(props);
        if (!props.disableSettingPageTitle) {
            PageTitleHelper.setPageTitle(props.title, props.breadcrumbTitle);
        }
        this.state = {
            stickyState: 0,
        };
    }
    componentDidUpdate(prevProps: PaperLayoutProps) {
        // For various screen, we update the title dynamically...
        if ((prevProps.title !== this.props.title || prevProps.breadcrumbTitle !== this.props.breadcrumbTitle) && !prevProps.disableSettingPageTitle) {
            PageTitleHelper.setPageTitle(this.props.title, this.props.breadcrumbTitle);
        }
    }
    renderHeader() {
        if (this.props.title || this.props.sectionControl) {
            const commonHeader = (<div id={headerId} className={styles.headerContainer}>
                    {this.renderBusy()}
                    <SectionTitle title={this.props.title} titlePath={this.props.titlePath} titleLogo={this.props.titleLogo} breadcrumbTitle={this.props.breadcrumbTitle} breadcrumbPath={this.props.breadcrumbPath} breadcrumbOverflow={this.props.breadcrumbOverflow} breadcrumbChip={this.props.breadcrumbChip} sectionControl={this.props.sectionControl} className={cn(styles.title, { [styles.stickied]: this.state.stickyState === StickyStatus.STATUS_FIXED })} busy={this.props.busy} enableLessIntrusiveLoadingIndicator={this.props.enableLessIntrusiveLoadingIndicator} hideHelpIcon={this.props.hideHelpIcon}/>
                </div>);
            if (this.props.disableStickyHeader) {
                return commonHeader;
            }
            return (<Sticky top="#toppanel" innerZ={10} onStateChange={(x) => this.setState({ stickyState: x.status })}>
                    {this.props.statusSection && <>{this.props.statusSection}</>}
                    {commonHeader}

                    {/* UX: Render errors _inside_ our sticky header so these are always visible */}
                    {this.renderErrors()}
                    {this.props.callout}
                </Sticky>);
        }
        return this.renderBusy();
    }
    renderBusy() {
        // Note: Don't show the horizontal loading bar on subsequent auto-reloads (it "blips" too quickly and annoys everybody).
        return (<div className={styles.busyFromPromise}>
                <BusyFromPromise promise={this.props.busy}>{(busy: boolean) => <LinearProgress variant={"indeterminate"} show={busy && !this.props.enableLessIntrusiveLoadingIndicator}/>}</BusyFromPromise>
            </div>);
    }
    renderErrors() {
        if (this.props.hideErrors) {
            return null;
        }
        const errors = this.props.errors;
        if (!errors) {
            return null;
        }
        let scrollToActiveErrorPanel = true; // default
        if (this.props.disableScrollToActiveError) {
            scrollToActiveErrorPanel = false;
        }
        return <ErrorPanel message={errors.message} errors={errors.errors} parsedHelpLinks={errors.parsedHelpLinks} helpText={errors.helpText} helpLink={errors.helpLink} statusCode={errors.statusCode} scrollToPanel={scrollToActiveErrorPanel}/>;
    }
    render() {
        return (<div className={cn(styles.container, this.props.className)}>
                <div className={cn(this.props.flatStyle ? styles.paperContentFlat : styles.paperContent, this.props.fullWidth && styles.paperFullWidth, this.props.innerClassName)}>
                    {this.renderHeader()}
                    <TransitionAnimation disableAnimation={this.props.disableAnimations}>
                        <>{this.props.children}</>
                    </TransitionAnimation>
                </div>
            </div>);
    }
    static displayName = "PaperLayout";
}
export default PaperLayout;
