import { css } from "@emotion/css";
import { Button } from "@octopusdeploy/design-system-components";
import { exhaustiveCheck } from "@octopusdeploy/type-utils";
import React from "react";
import type { TenantsFilterState } from "~/areas/tenants/Tenants/hooks/useTenantsFilterState";
import { useTenantsFilterState } from "~/areas/tenants/Tenants/hooks/useTenantsFilterState";
import type { TenantFiltersData } from "~/areas/tenants/components/Filtering/hooks/useTenantFiltersData";
import { And, ExcludedEnvironmentChip, ExcludedNameChip, ExcludedProjectChip, ExcludedTagChips, IncludedEnvironmentChip, IncludedNameChip, IncludedProjectChip, IncludedTagChips, } from "~/areas/tenants/components/Toolbar/CurrentFilterSelection/CurrentFilterSelectionChips";
interface CurrentFilterSelectionProps {
    data: TenantFiltersData;
    onExpandCollapse: () => void;
}
export default function CurrentFilterSelection({ data, onExpandCollapse }: CurrentFilterSelectionProps) {
    const [filterState] = useTenantsFilterState();
    return (<div className={styles.container}>
            <FilteredByLabel filterState={filterState} onExpandCollapse={onExpandCollapse}/>
            <FilteredByChips filterState={filterState} data={data}/>
        </div>);
}
function FilteredByLabel({ filterState, onExpandCollapse }: {
    filterState: TenantsFilterState;
    onExpandCollapse: () => void;
}) {
    const none = isQueryEmpty(filterState) ? "None" : "";
    return (<div className={styles.filteredByLabel}>
            <Button label={`Filtered by: ${none}`} onClick={onExpandCollapse} importance="quiet"></Button>
        </div>);
}
function FilteredByChips({ filterState, data }: {
    filterState: TenantsFilterState;
    data: TenantFiltersData;
}) {
    const selectedFilters = getSelectedFilters(filterState);
    return (<React.Fragment>
            {selectedFilters.map((filter, index) => (<React.Fragment key={filter.type}>
                    {index === 0 ? null : <And />}
                    <SelectedChipGroup selectedFilter={filter} data={data}/>
                </React.Fragment>))}
        </React.Fragment>);
}
function SelectedChipGroup({ selectedFilter, data }: {
    selectedFilter: SelectedFilter;
    data: TenantFiltersData;
}) {
    switch (selectedFilter.type) {
        case "name-include":
            return <IncludedNameChip value={selectedFilter.value}/>;
        case "name-exclude":
            return <ExcludedNameChip value={selectedFilter.value}/>;
        case "project-include":
            return <IncludedProjectChip id={selectedFilter.value} projects={data.projects}/>;
        case "project-exclude":
            return <ExcludedProjectChip id={selectedFilter.value} projects={data.projects}/>;
        case "environment-include":
            return <IncludedEnvironmentChip id={selectedFilter.value} environments={data.environments}/>;
        case "environment-exclude":
            return <ExcludedEnvironmentChip id={selectedFilter.value} environments={data.environments}/>;
        case "tags-include":
            return <IncludedTagChips ids={selectedFilter.value} tags={data.tags}/>;
        case "tags-exclude":
            return <ExcludedTagChips ids={selectedFilter.value} tags={data.tags}/>;
        default:
            exhaustiveCheck(selectedFilter, "Unhandled filter type");
    }
}
type SingleValueType = "name-include" | "name-exclude" | "project-include" | "project-exclude" | "environment-include" | "environment-exclude";
type MultipleValuesType = "tags-include" | "tags-exclude";
type SingleValueFilter = {
    type: SingleValueType;
    value: string;
};
type MultipleValuesFilter = {
    type: MultipleValuesType;
    value: string[];
};
type SelectedFilter = SingleValueFilter | MultipleValuesFilter;
function getSelectedFilters(filterState: TenantsFilterState) {
    const selectedFilters: SelectedFilter[] = [];
    if (filterState.filterByName) {
        selectedFilters.push({ type: "name-include", value: filterState.filterByName });
    }
    if (filterState.filterByExcludedName) {
        selectedFilters.push({ type: "name-exclude", value: filterState.filterByExcludedName });
    }
    if (filterState.filterByProject) {
        selectedFilters.push({ type: "project-include", value: filterState.filterByProject });
    }
    if (filterState.filterByExcludedProject) {
        selectedFilters.push({ type: "project-exclude", value: filterState.filterByExcludedProject });
    }
    if (filterState.filterByEnvironment) {
        selectedFilters.push({ type: "environment-include", value: filterState.filterByEnvironment });
    }
    if (filterState.filterByExcludedEnvironment) {
        selectedFilters.push({ type: "environment-exclude", value: filterState.filterByExcludedEnvironment });
    }
    if (filterState.filterByTags.length > 0) {
        selectedFilters.push({ type: "tags-include", value: filterState.filterByTags });
    }
    if (filterState.filterByExcludedTags.length > 0) {
        selectedFilters.push({ type: "tags-exclude", value: filterState.filterByExcludedTags });
    }
    return selectedFilters;
}
function isQueryEmpty(query: TenantsFilterState) {
    return !query.filterByName && !query.filterByProject && !query.filterByExcludedProject && !query.filterByEnvironment && !query.filterByExcludedEnvironment && query.filterByTags.length === 0 && query.filterByExcludedTags.length === 0;
}
const styles = {
    container: css({
        display: "flex",
        flexFlow: "row wrap",
        alignItems: "center",
    }),
    filteredByLabel: css({
        marginLeft: "0.4rem",
    }),
};
