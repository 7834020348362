import { css } from "@emotion/css";
import { NavigationButton, NavigationButtonType } from "@octopusdeploy/design-system-components";
import { space } from "@octopusdeploy/design-system-tokens";
import type * as H from "history";
import * as React from "react";
import { Action, useProjectScopedAnalyticActionDispatch } from "~/analytics/Analytics";
import { processScopedEditPermission } from "~/areas/projects/components/Process/Common/CommonProcessHelpers";
import { useProcessContext } from "~/areas/projects/components/Process/Contexts/ProcessContext";
import { useProjectContext } from "~/areas/projects/context";
import OnboardingPage from "~/components/GettingStarted/OnboardingPage";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import ExternalVideoLink from "~/components/Navigation/ExternalLink/ExternalVideoLink";
import { PermissionCheck } from "~/components/PermissionCheck";
import SampleProcessButton from "~/components/ProjectBasedActivation/SampleProcessButton";
import processImageDark from "~/components/ProjectBasedActivation/assets/dark/darkimg-deploymentprocess.svg";
import processImage from "~/components/ProjectBasedActivation/assets/img-deploymentprocess.svg";
import routeLinks from "~/routeLinks";
import styles from "./style.module.less";
const actionButtons = css({
    display: "flex",
    gap: space[8],
});
interface OnboardingProps {
    location: H.Location;
    history: H.History;
}
const Onboarding = ({ location, history }: OnboardingProps) => {
    const project = useProjectContext();
    const projectId = project.state.model.Id;
    const projectSlug = project.state.model.Slug;
    const gitRef = project.state.gitRef;
    const dispatchAction = useProjectScopedAnalyticActionDispatch(projectId);
    const processContext = useProcessContext();
    const createProcessHref = routeLinks.projectBranch(projectSlug, gitRef).deploymentProcess.stepTemplates;
    const sampleProcessHref = routeLinks.projectBranch(projectSlug, gitRef).deploymentProcess.root;
    return (<OnboardingPage title="Create your deployment process" intro={`The deployment process is like a recipe for deploying your software. You define the recipe by adding steps and variables to a project.`} learnMore={<>
                    <ExternalLink href="OnboardingDeploymentProcessLearnMore">Docs</ExternalLink>
                    <ExternalVideoLink href="OnboardingDeploymentProcessVideo">Video (3 mins)</ExternalVideoLink>
                </>} image={processImage} imageDark={processImageDark} imageAlt={"Create Process"} actionButtons={<PermissionCheck permission={processScopedEditPermission(processContext.selectors.getProcessType())} project={projectId} wildcard={true}>
                    <div className={actionButtons}>
                        <NavigationButton label={"Create Process"} href={createProcessHref} onClick={() => dispatchAction("Add Deployment Step", { resource: "Deployment Process", action: Action.Add })} type={NavigationButtonType.Primary}/>
                        <SampleProcessButton href={sampleProcessHref} classNames={[styles.loadSampleButton]} location={location} history={history}/>
                    </div>
                </PermissionCheck>}/>);
};
export default Onboarding;
