/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */
import { Drawer } from "@material-ui/core";
import { AnalyticLinkLocationProvider } from "@octopusdeploy/portal-analytics";
import { usePage } from "@octopusdeploy/portal-routes";
import { OctoLink } from "@octopusdeploy/utilities";
import classNames from "classnames";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import MediaQuery from "react-responsive";
import { createHtmlPortalNode, InPortal, OutPortal } from "react-reverse-portal";
import { Action, useAnalyticActionDispatch } from "~/analytics/Analytics";
import configurationSelectors from "~/areas/configuration/reducers/selectors";
import drawerActions from "~/components/ContextualHelpLayout/reducers/actions";
import * as drawerSelectors from "~/components/ContextualHelpLayout/reducers/selectors";
import { OverflowMenu, OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import { Section } from "~/components/Section/Section";
import { ControlledTabsContainer } from "~/primitiveComponents/navigation/Tabs";
import TabItem from "~/primitiveComponents/navigation/Tabs/TabItem";
import type { GlobalState } from "../../store/reducers";
import ExternalLink from "../Navigation/ExternalLink";
import styles from "./ContextualHelpLayout.module.less";
import { PageHelpOverviewView, PageHelpTopicsView } from "./PageHelpRegistry/PageHelpSelector";
export const drawerBreakpointWidth = 810;
export function ContextualHelpLayout(props: React.PropsWithChildren<{}>) {
    const dispatch = useDispatch();
    const toggleDrawer: () => void = React.useCallback(() => dispatch(drawerActions.toggleDrawer()), [dispatch]);
    const isDrawerOpen = useSelector(drawerSelectors.isDrawerOpen);
    const isHelpSidebarEnabled = useSelector(configurationSelectors.createFeatureEnabledSelector((x) => x.isHelpSidebarEnabled));
    const portalNode = React.useMemo(() => createHtmlPortalNode(), []);
    return (<React.Fragment>
            <InPortal node={portalNode}>{props.children}</InPortal>
            <MediaQuery minWidth={drawerBreakpointWidth}>
                {(matches: boolean) => {
            if (matches && isHelpSidebarEnabled) {
                return (<div id="drawerwrapper">
                                <div className={styles.root}>
                                    <main className={classNames(styles.mainContent, {
                        [styles.mainContentShift]: isDrawerOpen,
                    })}>
                                        <OutPortal node={portalNode}/>
                                    </main>
                                    <ContextualHelpDrawer open={isDrawerOpen} toggleDrawer={toggleDrawer}/>
                                </div>
                            </div>);
            }
            else {
                return <OutPortal node={portalNode}/>;
            }
        }}
            </MediaQuery>
        </React.Fragment>);
}
enum DrawerTabKey {
    Overview = "overview",
    HelpTopics = "helpTopics"
}
interface ContextualHelpSidebarContentProps {
    additionalTabsElement: React.ReactElement | undefined;
}
function ContextualHelpSidebarContent(props: ContextualHelpSidebarContentProps) {
    const page = usePage();
    const helpSidebarSupportLink = useSelector((state: GlobalState) => state.configurationArea.features && state.configurationArea.features.helpSidebarSupportLink);
    const dispatchAction = useAnalyticActionDispatch();
    const [tab, setTab] = React.useState<string>(DrawerTabKey.Overview);
    const changeTab = (newTab: string) => {
        if (newTab === tab)
            return;
        setTab(newTab);
        switch (newTab) {
            case DrawerTabKey.Overview:
                dispatchAction("Sidebar: Click Help Tab", { action: Action.Select, resource: "Help Sidebar Overview" });
                break;
            case DrawerTabKey.HelpTopics:
                dispatchAction("Sidebar: Click Resources Tab", { action: Action.Select, resource: "Help Sidebar Resources" });
                break;
        }
    };
    return (<>
            <div className={styles.content}>
                <ControlledTabsContainer value={tab} onChange={changeTab} afterTabsElement={props.additionalTabsElement}>
                    <TabItem label="Help" value={DrawerTabKey.Overview}>
                        <Section>
                            <AnalyticLinkLocationProvider location="Help sidebar overview">
                                <PageHelpOverviewView pageId={page?.Id}/>
                            </AnalyticLinkLocationProvider>
                        </Section>
                    </TabItem>
                    <TabItem label="Resources" value={DrawerTabKey.HelpTopics}>
                        <Section>
                            <AnalyticLinkLocationProvider location="Help sidebar resources">
                                <PageHelpTopicsView pageId={page?.Id}/>
                            </AnalyticLinkLocationProvider>
                        </Section>
                    </TabItem>
                </ControlledTabsContainer>
            </div>
            <AnalyticLinkLocationProvider location="Help sidebar footer">
                <ExternalLink href={helpSidebarSupportLink ? helpSidebarSupportLink : OctoLink.Create("HelpGeneral")} showIcon={false}>
                    <div className={styles.footer}>
                        <h4>Having issues?</h4>
                        <span>
                            {helpSidebarSupportLink ? "Get support" : "Our support team is here to help"}
                            <em style={{ marginLeft: "0.25rem" }} className="fa-solid fa-external-link" aria-hidden="true"/>
                        </span>
                    </div>
                </ExternalLink>
            </AnalyticLinkLocationProvider>
        </>);
}
interface ContextualHelpDrawerProps {
    open: boolean;
    toggleDrawer: () => void;
}
function ContextualHelpDrawer({ open, toggleDrawer }: ContextualHelpDrawerProps) {
    const [height, setHeight] = React.useState(0);
    const drawerDiv = React.useRef<HTMLDivElement>(null);
    const calculateHeight = React.useCallback(() => {
        if (drawerDiv.current !== null) {
            const top = drawerDiv.current.getBoundingClientRect().top;
            const approxHeightOfPaddingAndOtherJunk = 0;
            setHeight(window.innerHeight - top - approxHeightOfPaddingAndOtherJunk);
        }
    }, []);
    //The material drawer in this layout uses a static position and a height of 100% shifts things like footers off screen
    //we therefore calculate the necessary height and set it
    React.useLayoutEffect(calculateHeight, [calculateHeight]);
    //Since we are manually handling the height, we also need to adjust things based on when the browser resizes
    React.useEffect(() => {
        window.addEventListener("resize", calculateHeight);
        return () => window.removeEventListener("resize", calculateHeight);
    }, [calculateHeight]);
    return (<Drawer className={classNames(styles.drawer, {
            [styles.drawerHidden]: !open,
        })} variant="persistent" anchor="right" open={open} classes={{
            paper: classNames(styles.drawerPaper),
        }}>
            <div className={styles.container} ref={drawerDiv} style={{ height }}>
                {open && <ContextualHelpSidebarContent additionalTabsElement={<OverflowMenu menuItems={[OverflowMenuItems.item("Close", toggleDrawer)]} tabIndex={-1}/>}/>}
            </div>
        </Drawer>);
}
