import * as React from "react";
import { AnalyticView } from "~/analytics/Analytics";
import styles from "~/areas/infrastructure/components/MachineSettings/Endpoints/styles.module.less";
import { KubernetesWithOctopusAgent } from "~/components/Images/MachineSettings/KubernetesWithOctopusAgent";
import ExternalLink from "~/components/Navigation/ExternalLink/index";
import kubernetesCategory from "./KubernetesCategoryDefinition";
import { InfoRegistrationCards } from "./RegistrationCards";
import type { InformationEndpointRegistration } from "./endpointRegistry";
import { CommunicationStyle, EndpointRegistrationKey } from "./endpointRegistry";
const KubernetesWithOctopusAgentEndpointDialogView = () => (<div>
        <AnalyticView resource={"KubernetesWithOctopusAgentEndpointDialogView"} name={"View Kubernetes with Octopus Agent Deployment Target"}/>
        <h3>Under consideration</h3>
        <p>We're exploring the option of developing an Octopus agent. The agent will run in a Kubernetes cluster and pull data from the Octopus server. Octopus server will use the agent for deployments and get live status for deployed resources.</p>
        <p>
            <ExternalLink href="K8sPlusOctoAgent">Learn more and share your feedback with us here</ExternalLink>
        </p>
    </div>);
const kubernetesWithOctopusAgentEndpointRegistration: InformationEndpointRegistration = {
    key: EndpointRegistrationKey.KubernetesWithOctopusAgent,
    displayOrder: 26,
    name: "Kubernetes cluster with Octopus agent",
    categories: [kubernetesCategory],
    communicationStyle: CommunicationStyle.None,
    visibilityFeatureToggle: "UnderConsiderationKubernetesTargetsFeatureToggle",
    renderCard: InfoRegistrationCards.basic(() => <KubernetesWithOctopusAgentEndpointDialogView />, ({ registration }) => ({
        logo: <KubernetesWithOctopusAgent className={styles.centreThumbnail} title={registration.name}/>,
        registrationName: registration.name,
        description: "Install an Octopus agent on a Kubernetes cluster.",
        buttonLabel: "Learn more",
    })),
};
export { kubernetesWithOctopusAgentEndpointRegistration };
