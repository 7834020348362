import type { UnknownRegisteredRoute } from "@octopusdeploy/portal-routes";
import { createRegisteredRoute, gitRoute, licenseRoute, projectsRoute, projectTasksRoute } from "@octopusdeploy/portal-routes";
import Projects from "app/areas/projects/components/Projects/Projects";
import * as React from "react";
import GitPage from "~/areas/configuration/components/Git/Git";
import { License as LicensePage } from "~/areas/configuration/components/License/License";
import ProjectTasks from "~/areas/projects/components/ProjectTasks/ProjectTasks";
import routeLinks from "~/routeLinks";
//These are experimental routes and won't have any impact on existing routes as we define them today.
//The better portal experience team is experimenting with adding an abstraction layer for our routing.
const allRegisteredRoutes: UnknownRegisteredRoute[] = [
    createRegisteredRoute(licenseRoute, () => <LicensePage />, () => routeLinks.configuration.license),
    createRegisteredRoute(gitRoute, () => <GitPage />, () => routeLinks.configuration.git),
    createRegisteredRoute(projectsRoute, () => <Projects />, ({ spaceId }) => routeLinks.forSpace(spaceId).projects.root),
    createRegisteredRoute(projectTasksRoute, () => <ProjectTasks />, ({ spaceId, projectSlug }) => routeLinks.forSpace(spaceId).project(projectSlug).tasks),
];
export { allRegisteredRoutes };
