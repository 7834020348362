import { ActionButton, ActionButtonType } from "@octopusdeploy/design-system-components";
import { logger } from "@octopusdeploy/logging";
import type { TaskResource, SensitiveValue, SpaceResource, ProjectImportFile, ProjectImportPreviewResponse, ProjectImportSource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { repository, session } from "~/clientInstance";
import ActionList from "~/components/ActionList";
import AreaTitle from "~/components/AreaTitle";
import { ContextualHelpLayout } from "~/components/ContextualHelpLayout/ContextualHelpLayout";
import DataBaseComponent from "~/components/DataBaseComponent";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import DialogOpener from "~/components/Dialog/DialogOpener";
import SaveDialogLayout from "~/components/DialogLayout/SaveDialogLayout";
import InternalRedirect from "~/components/Navigation/InternalRedirect";
import PaperLayout from "~/components/PaperLayout";
import { Select, Sensitive, Summary, FormSection } from "~/components/form";
import type { SummaryNode } from "~/components/form";
import FormSectionHeading from "~/components/form/Sections/FormSectionHeading";
import FileUploadDropzone from "~/primitiveComponents/form/FileUploadDragDrop/FileUploadDragDrop";
import routeLinks from "~/routeLinks";
import DateFormatter from "~/utils/DateFormatter";
import Section from "../../../../components/Section";
import ImportExportMenu from "./ImportExportMenu";
import MissingImportExportPermissionsCallout from "./MissingImportExportPermissionCallout";
import styles from "./style.module.less";
interface ImportProjectsState extends DataBaseComponentState {
    space?: SpaceResource;
    spaces?: SpaceResource[];
    password: SensitiveValue;
    importSourceType?: string;
    importFromTaskInSpaceId?: string;
    tasks: TaskResource[];
    importFromTask?: TaskResource;
    importFromTaskId?: string;
    redirectPath?: string;
    importFromFileId?: string;
    previewResponse?: ProjectImportPreviewResponse;
    file?: File;
    hasFileBeenUploaded: boolean;
    hasFileUploadStarted: boolean;
    exportFromSpaceDialog: boolean;
    uploadFileDialog: boolean;
}
class ImportProjectsInternal extends DataBaseComponent<{}, ImportProjectsState> {
    constructor(props: {}) {
        super(props);
        this.state = {
            password: { HasValue: false },
            tasks: [],
            hasFileBeenUploaded: false,
            hasFileUploadStarted: false,
            exportFromSpaceDialog: false,
            uploadFileDialog: false,
        };
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const [spaces] = await Promise.all([repository.Spaces.all()]);
            const otherSpaces = spaces.filter((s) => s.Id != repository.spaceId);
            this.setState({
                space: spaces.filter((s) => s.Id === repository.spaceId)[0],
                spaces: otherSpaces,
            });
        });
    }
    isSpaceManager(): boolean {
        if (!session.currentPermissions) {
            throw new Error("Attempted to access the current user's permissions, but they weren't found. This should never happen.");
        }
        return this.state.space !== undefined && session.currentPermissions.isSpaceManager(this.state.space);
    }
    getPreview = async () => {
        this.setState({
            previewResponse: undefined,
        });
        await this.doBusyTask(async () => {
            const request = { Password: this.state.password, ImportSource: this.buildImportSource() };
            const projectImportResponse = await repository.ImportExport.preview(request);
            this.setState({
                previewResponse: projectImportResponse,
            });
        });
    };
    buildImportSource = (): ProjectImportSource => {
        if (this.state.importSourceType === "space" && this.state.importFromTaskInSpaceId && this.state.importFromTask) {
            return { Type: "space", SpaceId: this.state.importFromTaskInSpaceId, TaskId: this.state.importFromTask.Id };
        }
        else if (this.state.importSourceType === "upload" && this.state.importFromFileId) {
            return { Type: "upload", UploadedFileId: this.state.importFromFileId };
        }
        throw new Error("Unknown import source type");
    };
    doImport = async () => {
        await this.doBusyTask(async () => {
            const request = { Password: this.state.password, ImportSource: this.buildImportSource() };
            const projectImportResource = await repository.ImportExport.import(request);
            this.setState({
                redirectPath: routeLinks.task(projectImportResource.TaskId).root,
            });
        });
    };
    afterUpload = async (file: ProjectImportFile) => {
        this.setState({
            importFromFileId: file.Id,
            hasFileBeenUploaded: true,
        });
    };
    spaceChanged = async (spaceId?: string) => {
        if (!spaceId) {
            this.setState({
                tasks: [],
                importFromTaskInSpaceId: undefined,
                importFromTask: undefined,
                previewResponse: undefined,
            });
            return;
        }
        logger.info("Select changed to space {spaceId}", { spaceId });
        await this.doBusyTask(async () => {
            const tasks = await this.getTasks(spaceId);
            this.setState({
                importFromTaskInSpaceId: spaceId,
                importFromTask: undefined,
                tasks,
                previewResponse: undefined,
            });
        });
    };
    getTasks = async (spaceId?: string): Promise<TaskResource[]> => {
        if (!spaceId || !repository.spaceId) {
            return [];
        }
        const itemsToTake = 100;
        const currentSpaceId = repository.spaceId;
        logger.info("Switching to space {spaceId}", { spaceId });
        await repository.switchToSpace(spaceId);
        const taskFilter = { name: "ExportProjects", itemsToTake, states: "Success" };
        const tasks = await repository.Tasks.list(taskFilter);
        logger.info("Switching back to space {spaceId}", { spaceId: currentSpaceId });
        await repository.switchToSpace(currentSpaceId);
        return tasks.Items;
    };
    uploadFile = async () => {
        this.setState({ hasFileUploadStarted: true });
        return this.doBusyTask(async () => {
            if (!this.state.file) {
                throw new Error("No file selected");
            }
            const uploadedFile = await repository.ImportExport.upload(this.state.file);
            await this.afterUpload(uploadedFile);
        });
    };
    sanitiseTaskDescription = (description: string): string => {
        const prefixPattern = /^Export projects? /;
        return description.replace(prefixPattern, "");
    };
    openDialogexportFromSpace = (e: boolean) => {
        this.setState({ exportFromSpaceDialog: e, importSourceType: "space" });
        if (e) {
            this.setState({ password: { HasValue: false } });
        }
        else {
            this.clearErrors();
        }
    };
    openDialogUploadFile = (e: boolean) => {
        this.setState({ uploadFileDialog: e, importSourceType: "upload" });
        if (e) {
            this.setState({ password: { HasValue: false } });
        }
        else {
            this.clearErrors();
        }
    };
    tryPreview = async (): Promise<boolean> => {
        await this.getPreview();
        return this.state.previewResponse !== undefined;
    };
    tryUploadPreview = async (): Promise<boolean> => {
        await this.uploadFile();
        await this.getPreview();
        return this.state.previewResponse !== undefined;
    };
    render() {
        if (this.state.redirectPath) {
            return <InternalRedirect to={this.state.redirectPath} push={true}/>;
        }
        const importAction = (<ActionButton type={ActionButtonType.Primary} label="Import" onClick={() => this.doImport()} disabled={this.state.busy || !this.state.previewResponse || (this.state.hasFileUploadStarted && !this.state.hasFileBeenUploaded)}/>);
        const exportTask = (<div>
                <ActionButton label="Select export task" onClick={() => this.openDialogexportFromSpace(true)}/>
                <DialogOpener open={this.state.exportFromSpaceDialog} onClose={() => this.openDialogexportFromSpace(false)} wideDialog={true}>
                    <SaveDialogLayout onSaveClick={async () => await this.tryPreview()} saveButtonLabel="Select" busyButtonLabel="Selecting..." busy={this.state.busy} errors={this.errors} title={"Select Export Task"} saveButtonDisabled={this.state.busy || !this.state.password || !this.state.password.NewValue || this.state.password.NewValue === "" || !this.state.importFromTaskInSpaceId || !this.state.importFromTaskId ? true : false}>
                        {this.state.spaces && this.isSpaceManager() && (<>
                                <Select label="Select space" value={this.state.importFromTaskInSpaceId} items={this.state.spaces.map((f) => ({ value: f.Id, text: f.Name }))} onChange={(importFromTaskInSpaceId) => this.spaceChanged(importFromTaskInSpaceId)} disabled={this.state.spaces.length === 0 ? true : false}/>
                                <Select label="Select export task" value={this.state.importFromTaskId} items={this.state.tasks.map((t) => ({ value: t.Id, text: this.sanitiseTaskDescription(t.Description), secondaryText: DateFormatter.dateToShortFormat(t.Completed) }))} onChange={(importFromTaskId) => this.setState({ importFromTaskId, importFromTask: this.state.tasks.find((t) => t.Id === importFromTaskId), previewResponse: undefined })} disabled={!this.state.importFromTaskInSpaceId} empty={"No export tasks found"}/>
                                <Sensitive label="Password" value={this.state.password} onChange={(password) => this.setState({ password, previewResponse: undefined })} disabled={!this.state.importFromTaskInSpaceId || !this.state.importFromTaskId}/>
                            </>)}
                    </SaveDialogLayout>
                </DialogOpener>
            </div>);
        const selectFile = (<div>
                <ActionButton label="Select Zip file" onClick={() => this.openDialogUploadFile(true)}/>
                <DialogOpener open={this.state.uploadFileDialog} onClose={() => this.openDialogUploadFile(false)} wideDialog={true}>
                    <SaveDialogLayout onSaveClick={async () => await this.tryUploadPreview()} saveButtonLabel="Select" busyButtonLabel="Selecting..." busy={this.state.busy} errors={this.errors} title={"Select Zip File"} saveButtonDisabled={this.state.busy || !this.state.password || !this.state.password.NewValue || this.state.password.NewValue === "" || !this.state.uploadFileDialog ? true : false}>
                        <FileUploadDropzone label={"Drag and drop a Zip file containing exported data"} onFilesChanged={(files) => this.setState({ file: files[0] })}/>
                        <Sensitive label="Password" value={this.state.password} onChange={(password) => this.setState({ password, previewResponse: undefined })} disabled={!this.state.uploadFileDialog}/>
                    </SaveDialogLayout>
                </DialogOpener>
            </div>);
        const writeExportTask = (<>
                {this.state.importFromTask && !this.state.file && (<p>
                        Selected export task <b>{this.sanitiseTaskDescription(this.state.importFromTask?.Description)}</b> from {this.state.spaces?.filter((s) => s.Id === this.state.importFromTaskInSpaceId)[0].Name} space.
                    </p>)}
            </>);
        const writeFileSelected = (<>
                {this.state.file && !this.state.importFromTask && (<p>
                        Selected file: <b>{this.state.file?.name}</b>
                    </p>)}
            </>);
        const overflowMenu = <ImportExportMenu />;
        const importFileHelp = <>{this.state.spaces && this.state.spaces.length === 0 ? "Select a Zip file to import projects." : "Select a Zip file or an export task to import projects."}</>;
        return (<main id="maincontent">
                <AreaTitle title="Projects" link={routeLinks.projects.root}>
                    <ActionList actions={[overflowMenu]}/>
                </AreaTitle>
                <ContextualHelpLayout>
                    <div className={styles.paperContainer}>
                        <PaperLayout title="Import Projects" busy={this.state.busy} errors={this.errors} sectionControl={<ActionList actions={[importAction]}/>}>
                            {this.state.space && !this.isSpaceManager() && <MissingImportExportPermissionsCallout />}
                            {this.state.space && this.isSpaceManager() && (<>
                                    <FormSection title="Import Source" help={importFileHelp} includeBorder={true}>
                                        <>
                                            {this.state.previewResponse && (<>
                                                    {writeFileSelected} {writeExportTask}
                                                </>)}
                                            {this.state.spaces && this.state.spaces.length === 0 ? <ActionList actions={[selectFile]} alignStart={true}/> : <ActionList actions={[selectFile, exportTask]} alignStart={true}/>}
                                        </>
                                    </FormSection>
                                    {this.state.previewResponse && (<>
                                            <FormSectionHeading title="Import Preview"/>
                                            <Section>
                                                <h4>The following projects will be imported:</h4>
                                                <ul className={styles.unorderedList}>
                                                    {this.state.previewResponse.Projects.map((l) => (<li>
                                                            <div>{l.Name}</div>
                                                        </li>))}
                                                </ul>
                                                <h4>The following resources will be imported:</h4>
                                                <ul>
                                                    {this.state.previewResponse.Accounts.length > 0 && (<li>
                                                            <div>
                                                                <b>Accounts</b>
                                                            </div>
                                                            <ul className={styles.unorderedList}>
                                                                {this.state.previewResponse.Accounts.map((l) => (<li>{l}</li>))}
                                                            </ul>
                                                        </li>)}
                                                    {this.state.previewResponse.Certificates.length > 0 && (<li>
                                                            <div>
                                                                <b>Certificates</b>
                                                            </div>
                                                            <ul className={styles.unorderedList}>
                                                                {this.state.previewResponse.Certificates.map((l) => (<li>{l}</li>))}
                                                            </ul>
                                                        </li>)}
                                                    {this.state.previewResponse.Environments.length > 0 && (<li>
                                                            <div>
                                                                <b>Environments</b>
                                                            </div>
                                                            <ul className={styles.unorderedList}>
                                                                {this.state.previewResponse.Environments.map((l) => (<li>{l}</li>))}
                                                            </ul>
                                                        </li>)}
                                                    {this.state.previewResponse.Feeds.length > 0 && (<li>
                                                            <div>
                                                                <b>Feeds</b>
                                                            </div>
                                                            <ul className={styles.unorderedList}>
                                                                {this.state.previewResponse.Feeds.map((l) => (<li>{l}</li>))}
                                                            </ul>
                                                        </li>)}
                                                    {this.state.previewResponse.Lifecycles.length > 0 && (<li>
                                                            <div>
                                                                <b>Lifecycles</b>
                                                            </div>
                                                            <ul className={styles.unorderedList}>
                                                                {this.state.previewResponse.Lifecycles.map((l) => (<li>{l}</li>))}
                                                            </ul>
                                                        </li>)}
                                                    {this.state.previewResponse.Projects.length > 0 && (<li>
                                                            <div>
                                                                <b>Projects</b>
                                                            </div>
                                                            <ul className={styles.unorderedList}>
                                                                {this.state.previewResponse.Projects.map((l) => (<li>
                                                                        <div>
                                                                            <b>{l.Name}</b>
                                                                        </div>
                                                                        <div>
                                                                            <i>Channels</i>
                                                                        </div>
                                                                        <ul>
                                                                            {l.Channels.map((c) => (<li>- {c}</li>))}
                                                                        </ul>
                                                                        {l.Runbooks.length > 0 && (<>
                                                                                <div>
                                                                                    <i>Runbooks</i>
                                                                                </div>
                                                                                <ul>
                                                                                    {l.Runbooks.map((c) => (<li>- {c}</li>))}
                                                                                </ul>
                                                                            </>)}
                                                                    </li>))}
                                                            </ul>
                                                        </li>)}
                                                    {this.state.previewResponse.WorkerPools.length > 0 && (<li>
                                                            <div>
                                                                <i>Worker Pools</i>
                                                            </div>
                                                            <ul className={styles.unorderedList}>
                                                                {this.state.previewResponse.WorkerPools.map((l) => (<li>
                                                                        {l.Name} {l.IsDynamic ? "(dynamic)" : ""}
                                                                    </li>))}
                                                            </ul>
                                                        </li>)}
                                                </ul>
                                            </Section>
                                        </>)}
                                </>)}
                        </PaperLayout>
                    </div>
                </ContextualHelpLayout>
            </main>);
    }
    isRequiredDataComplete(): boolean {
        return this.isRequiredPasswordDataComplete() && (this.isRequiredSpaceDataComplete() || this.isRequiredUploadDataComplete());
    }
    isRequiredPasswordDataComplete(): boolean {
        return this.state.password.NewValue !== undefined && this.state.password.NewValue !== "";
    }
    isRequiredSpaceDataComplete(): boolean {
        return this.state.importSourceType === "space" && this.state.importFromTaskInSpaceId !== undefined && this.state.importFromTaskId !== "";
    }
    isRequiredUploadDataComplete(): boolean {
        return this.state.importSourceType === "upload" && this.state.importFromFileId !== undefined;
    }
    importSourceSummary(): SummaryNode {
        if (this.state.importSourceType === "space") {
            if (this.state.importFromTaskId) {
                return Summary.summary(<div>Importing from a task ({this.sanitiseTaskDescription(this.state.tasks.find((t) => t.Id === this.state.importFromTaskId)?.Description ?? "unknown taskId")}) in another space.</div>);
            }
            else {
                return Summary.summary(<div>Importing from a task in another space. No task selected.</div>);
            }
        }
        if (this.state.importSourceType === "upload") {
            if (this.state.importFromFileId) {
                return Summary.summary(<div>Importing from an uploaded file. File has been uploaded.</div>);
            }
            else {
                return Summary.summary(<div>Importing from an uploaded file. No file uploaded.</div>);
            }
        }
        return Summary.default(`Unknown import source type ${this.state.importSourceType}`);
    }
    passwordSummary(): SummaryNode {
        return this.state.password && this.state.password.NewValue && this.state.password.NewValue !== "" ? Summary.summary(<div>Password has been provided</div>) : Summary.default("No password has been set");
    }
    static displayName = "ImportProjectsInternal";
}
const ImportProjects: React.FC = () => {
    return <ImportProjectsInternal />;
};
ImportProjects.displayName = "ImportProjects"
export default ImportProjects;
