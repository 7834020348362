import type { GitSourceReference } from "@octopusdeploy/step-inputs";
import type { RuntimeGitSourceSelection } from "@octopusdeploy/step-runtime-inputs";
export function convertToRuntimeGitSourceSelection(gitSourceSelection: GitSourceReference): RuntimeGitSourceSelection {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return gitSourceSelection as unknown as RuntimeGitSourceSelection;
}
export function convertFromRuntimeGitSourceSelection(packageSelection: RuntimeGitSourceSelection): GitSourceReference {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return packageSelection as unknown as GitSourceReference;
}
