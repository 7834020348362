import { css } from "@emotion/css";
import { Link as OctopusLink } from "@octopusdeploy/portal-routes";
import * as React from "react";
interface ListInternalLinkProps extends React.PropsWithChildren<{}> {
    href: string;
    accessibleName?: string;
}
export function ListInternalLink({ accessibleName, href, children }: ListInternalLinkProps) {
    return (<OctopusLink aria-label={accessibleName} className={listInternalLinkStyles} href={href}>
            {children}
        </OctopusLink>);
}
const listInternalLinkStyles = css({
    color: "inherit",
    width: "100%",
    height: "100%",
    display: "block",
    "&:hover": {
        color: "inherit",
    },
});
