// noinspection FunctionWithMoreThanThreeNegationsJS
import type { MachinePolicyResource } from "@octopusdeploy/octopus-server-client";
import type { MachineRpcCallRetryPolicy } from "@octopusdeploy/octopus-server-client/dist/src/resources/machineRpcCallRetryPolicy";
import type { AnalyticFields } from "@octopusdeploy/portal-analytics";
import { usePageEventDispatcher } from "@octopusdeploy/portal-routes";
import React from "react";
import { createBoolField, createOptionalBoolField, createOptionalStringField } from "~/analytics/fields";
export interface MachinePolicyUpdatedArguments {
    previous: MachinePolicyResource;
    current: MachinePolicyResource;
}
export type MachinePolicyUpdatedDispatcher = (args: MachinePolicyUpdatedArguments) => void;
export function useNotifyMachinePolicyUpdate(): MachinePolicyUpdatedDispatcher {
    const dispatcher = usePageEventDispatcher();
    const transientNetworkErrorRecoveryChanges: (original: MachineRpcCallRetryPolicy, updated: MachineRpcCallRetryPolicy) => AnalyticFields = (previous, current) => {
        const behaviorUpdated = previous.Enabled !== current.Enabled;
        const retryDurationUpdated = previous.RetryDuration !== current.RetryDuration;
        const healthyCheckRetryDuration = previous.HealthyMachineHealthCheckRetryDuration !== current.HealthyMachineHealthCheckRetryDuration;
        const unhealthyCheckRetryDuration = previous.UnHealthyMachineHealthCheckRetryDuration !== current.UnHealthyMachineHealthCheckRetryDuration;
        return {
            ...createBoolField("Transient Network Error Recovery updated", behaviorUpdated || retryDurationUpdated || healthyCheckRetryDuration || unhealthyCheckRetryDuration),
            ...createOptionalBoolField(behaviorUpdated, "Transient Network Error Recovery: Behavior (previous)", previous.Enabled),
            ...createOptionalBoolField(behaviorUpdated, "Transient Network Error Recovery: Behavior (current)", current.Enabled),
            ...createOptionalStringField(retryDurationUpdated, "Transient Network Error Recovery: Retry Duration (previous)", previous.RetryDuration),
            ...createOptionalStringField(retryDurationUpdated, "Transient Network Error Recovery: Retry Duration (current)", current.RetryDuration),
            ...createOptionalStringField(healthyCheckRetryDuration, "Transient Network Error Recovery: Health Check Retry Duration for a Healthy Tentacle (previous)", previous.HealthyMachineHealthCheckRetryDuration),
            ...createOptionalStringField(healthyCheckRetryDuration, "Transient Network Error Recovery: Health Check Retry Duration for a Healthy Tentacle (current)", current.HealthyMachineHealthCheckRetryDuration),
            ...createOptionalStringField(unhealthyCheckRetryDuration, "Transient Network Error Recovery: Health Check Retry Duration for an Unhealthy Tentacle (previous)", previous.UnHealthyMachineHealthCheckRetryDuration),
            ...createOptionalStringField(unhealthyCheckRetryDuration, "Transient Network Error Recovery: Health Check Retry Duration for an Unhealthy Tentacle (current)", current.UnHealthyMachineHealthCheckRetryDuration),
        };
    };
    return React.useCallback((args: MachinePolicyUpdatedArguments) => dispatcher("Machine Policy Updated", {
        ...transientNetworkErrorRecoveryChanges(args.previous.MachineRpcCallRetryPolicy, args.current.MachineRpcCallRetryPolicy),
    }), [dispatcher]);
}
