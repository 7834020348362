/* eslint-disable @typescript-eslint/no-explicit-any */
import type { RedirectProjectIfNotSlugProps } from "@octopusdeploy/routing-infrastructure";
import type * as H from "history";
import type { PropsWithChildren } from "react";
import * as React from "react";
import type { match as Match, match } from "react-router";
import { useLocation, useRouteMatch } from "react-router";
import { generatePath } from "react-router-dom";
import { repository } from "~/clientInstance";
import AreaTitle from "~/components/AreaTitle/index";
import BaseComponent from "~/components/BaseComponent/index";
import PaperLayout from "~/components/PaperLayout/index";
import routeLinks from "~/routeLinks";
import InternalRedirect from "../Navigation/InternalRedirect/InternalRedirect";
export function RedirectProjectIfNotSlug({ children }: PropsWithChildren<RedirectProjectIfNotSlugProps>) {
    return (<SlugSafeRedirect parameter={"projectSlug"} regexp={/^Projects-[0-9]+$/} getRealParam={loadSlugFromProjectId} loadingComponent={projectSlugLoading}>
            {children}
        </SlugSafeRedirect>);
}
function projectSlugLoading() {
    return (<main id="maincontent">
            <AreaTitle link={routeLinks.projects.root} title="Projects"/>
            <PaperLayout busy={true} fullWidth={true}/>
        </main>);
}
interface ProjectLocationState {
    project?: {
        Slug: string;
    };
}
async function loadSlugFromProjectId(projectId: string, location: H.Location) {
    if (locationHasProjectState(location)) {
        return location.state.project.Slug;
    }
    return (await repository.Projects.get(projectId)).Slug;
}
function locationHasProjectState(location: H.Location): location is H.Location<Required<ProjectLocationState>> {
    if (!location || !location?.state) {
        return false;
    }
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const casted = location as H.Location<ProjectLocationState>;
    if (!casted.state?.project) {
        return false;
    }
    return typeof casted.state.project.Slug === "string";
}
type SlugSafeRedirectProps = {
    parameter: string;
    regexp: RegExp;
    getRealParam(param: string, location?: H.Location, match?: Match<any>): Promise<string>;
    loadingComponent(): React.ReactElement<any>;
};
type SlugSafeRedirectState = {
    refreshId: number;
    paramValue?: string;
};
export function SlugSafeRedirect(props: PropsWithChildren<SlugSafeRedirectProps>) {
    const location = useLocation();
    const match = useRouteMatch<any>();
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return <SlugSafeRedirectInternal {...props} location={location} match={match!}/>;
}
type SlugSafeRedirectInternalProps = {
    parameter: string;
    regexp: RegExp;
    getRealParam(param: string, location?: H.Location, match?: Match<any>): Promise<string>;
    loadingComponent(): React.ReactElement<any>;
    location: H.Location;
    match: match<any>;
};
class SlugSafeRedirectInternal extends BaseComponent<SlugSafeRedirectInternalProps, SlugSafeRedirectState> {
    refreshId: number = 0;
    constructor(props: SlugSafeRedirectInternalProps) {
        super(props);
        this.state = { refreshId: 0 };
    }
    async UNSAFE_componentWillMount() {
        const { parameter, regexp, match } = this.props;
        this.refreshId += 1;
        const param = match.params[parameter];
        if (param && regexp.test(param)) {
            try {
                const paramValue = await this.props.getRealParam(param, this.props.location, this.props.match);
                this.setState({ paramValue, refreshId: this.refreshId });
            }
            catch (ex) {
                this.setState({ paramValue: param, refreshId: this.refreshId });
            }
        }
        else {
            this.setState({ paramValue: param, refreshId: this.refreshId });
        }
    }
    render() {
        if (this.isLoading()) {
            return this.props.loadingComponent();
        }
        if (this.isRedirecting()) {
            return this.redirect();
        }
        return React.Children.only(this.props.children);
    }
    isLoading() {
        return this.state.refreshId !== this.refreshId;
    }
    isRedirecting() {
        const urlParam = this.props.match.params[this.props.parameter];
        return urlParam !== this.state.paramValue;
    }
    redirect() {
        const newPath = this.calculateNewPath(this.props);
        const redirect = {
            ...this.props.location,
            pathname: newPath,
            state: {
                ...(typeof this.props.location.state === "object" ? this.props.location.state : {}),
                [this.props.parameter]: this.state.paramValue,
            },
        };
        return <InternalRedirect to={redirect} push={false}/>;
    }
    calculateNewPath(props: SlugSafeRedirectInternalProps): string {
        const newUrl = generatePath(props.match.path, {
            ...props.match.params,
            [props.parameter]: this.state.paramValue,
        });
        return newUrl + props.location.pathname.substr(props.match.url.length);
    }
    static displayName = "SlugSafeRedirectInternal";
}
