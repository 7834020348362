/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { AnalyticSessionProvider, useAnalyticSession } from "@octopusdeploy/portal-analytics";
import { PageProvider, usePage } from "@octopusdeploy/portal-routes";
import { RegisteredRouteProvider, useRegisteredRoutes } from "@octopusdeploy/routing-infrastructure";
import { OctopusSessionUserProvider, useOctopusSessionUser } from "@octopusdeploy/session";
import cn from "classnames";
import { cloneDeep } from "lodash";
import * as React from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { MemoryRouter } from "react-router-dom";
import * as showdown from "showdown";
import * as xss from "xss";
import filterXss from "xss";
import TextWithLinks from "~/components/TextWithLinks/TextWithLinks";
import styles from "./style.module.less";
interface MarkdownProps {
    markup: string;
    isTextSecondary?: boolean;
}
const Markdown: React.FC<MarkdownProps> = (props) => {
    //TODO: Page changed won't get synchronised to this detached tree. Ideally we would use something
    //which doesn't generate a detached react tree which would preserve our existing contexts etc.
    const currentPage = usePage();
    const currentUser = useOctopusSessionUser();
    const analyticsSession = useAnalyticSession();
    const allRegisteredRoutes = useRegisteredRoutes();
    showdown.extension("LinkRenderer", () => {
        return [
            {
                type: "lang",
                filter: (text, converter, options) => {
                    //Showdown creates a separate detached component hierarchy, therefore we have to setup any
                    //contexts again.
                    const rendered = renderToStaticMarkup(<RegisteredRouteProvider value={allRegisteredRoutes}>
                            <MemoryRouter>
                                <OctopusSessionUserProvider currentUser={currentUser}>
                                    <AnalyticSessionProvider session={analyticsSession}>
                                        <PageProvider initialPage={currentPage}>
                                            <TextWithLinks message={text} allowHtml={true}/>
                                        </PageProvider>
                                    </AnalyticSessionProvider>
                                </OctopusSessionUserProvider>
                            </MemoryRouter>
                        </RegisteredRouteProvider>);
                    //this is less than ideal, but as we've had to render without the normal router available, we cant resolve links as well as we'd like.
                    return rendered.replace("href=\"/", "href=\"#/");
                },
            },
        ];
    });
    const markdownConverter = new showdown.Converter({
        tables: true,
        strikethrough: true,
        openLinksInNewWindow: true,
        disableForced4SpacesIndentedSublists: true,
        literalMidWordUnderscores: true,
    });
    markdownConverter.useExtension("LinkRenderer");
    const potentiallyUnsafeHtml = markdownConverter.makeHtml(props.markup);
    const xssFilterWhitelist = cloneDeep(xss.whiteList);
    xssFilterWhitelist["em"]!.push("style");
    xssFilterWhitelist["em"]!.push("class");
    xssFilterWhitelist["a"]!.push("rel");
    const html = filterXss(potentiallyUnsafeHtml, { whiteList: xssFilterWhitelist });
    return <div className={cn(styles.markdown, props.isTextSecondary ? styles.secondaryText : "")} dangerouslySetInnerHTML={{ __html: html }}/>;
};
Markdown.displayName = "Markdown"
export default Markdown;
