import { resolveStringPathWithSpaceId } from "@octopusdeploy/portal-routes";
import type { PageUrl } from "@octopusdeploy/portal-routes";
import { useRegisteredRoutes } from "@octopusdeploy/routing-infrastructure";
import { useParams } from "react-router";
import { client } from "~/clientInstance";
import { useIsRoutingVNextEnabled } from "~/components/RootRoutes/useIsRoutingVNextEnabled";
export function usePageUrlResolver(): PageUrlResolver {
    const registeredRoutes = useRegisteredRoutes();
    const spaceId = useSpaceId();
    const isRoutingVNextEnabled = useIsRoutingVNextEnabled();
    return {
        resolve(pageUrl: PageUrl): string {
            if (isRoutingVNextEnabled) {
                return pageUrl.resolveWithSpaceId(spaceId);
            }
            const legacyUrl = pageUrl.resolveLegacyRoute(registeredRoutes);
            return resolveStringPathWithSpaceId(legacyUrl, spaceId);
        },
    };
}
function useSpaceId() {
    const { spaceId } = useParams<{
        spaceId?: string;
    }>();
    // The client's spaceId and the route's spaceId should be in sync,
    // but because we haven't modelled this relationship in a reliable way,
    // this is not guaranteed to be true, particularly in tests
    return (spaceId || client.spaceId) ?? null;
}
export interface PageUrlResolver {
    resolve(pageUrl: PageUrl): string;
}
