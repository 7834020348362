/* eslint-disable @typescript-eslint/init-declarations */
import { Permission } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { Switch } from "react-router-dom";
import { ActionTemplateRoutes } from "~/areas/library/components/ActionTemplates/ActionTemplateRoutes";
import VariableSetsRoutes from "~/areas/library/components/VariableSets";
import { RedirectAs404 } from "~/components/NotFound/NotFound";
import { withPage } from "~/components/Page/WithPage";
import { firstAuthorized } from "~/components/PermissionCheck/PermissionCheck";
import ReloadableRoute from "~/components/ReloadableRoute/ReloadableRoute";
import pageIds from "~/pageIds";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import routeLinks from "../../../../routeLinks";
import { BuildInformationDetail, BuildInformationOverview, BuildInformationVersionsList } from "../BuildInformation";
import { BuiltInRepositoryOverview, PackageDetail, PackageVersionsList } from "../BuiltInRepository";
import { ArchivedCertificates, Certificate, Certificates } from "../Certificates";
import ExternalFeedRoutes from "../ExternalFeeds";
import { GitCredentialRoutes } from "../GitCredentials/GitCredentialRoutes";
import Lifecycle from "../Lifecycle/Lifecycle";
import Lifecycles from "../Lifecycle/Lifecycles";
import { ScriptModule, ScriptModulesList } from "../Scripts";
import { TagSetEdit, TagSetsList } from "../TagSets";
export const PackageVersionsListPage = withPage({ page: pageIds.library.builtInRepository.versions })(PackageVersionsList);
export const PackageDetailsPage = withPage({ page: pageIds.library.builtInRepository.package })(PackageDetail);
export const BuiltInRepositoryOverviewPage = withPage({ page: pageIds.library.builtInRepository.root })(BuiltInRepositoryOverview);
export const BuildInformationOverviewPage = withPage({ page: pageIds.library.buildInformationRepository.root })(BuildInformationOverview);
export const BuildInformationVersionsListPage = withPage({ page: pageIds.library.buildInformationRepository.versions })(BuildInformationVersionsList);
export const BuildInformationDetailsPage = withPage({ page: pageIds.library.buildInformationRepository.package })(BuildInformationDetail);
export const CreateLifecyclePage = withPage({ page: pageIds.library.lifecyclesCreate })(Lifecycle);
export const LifecyclePage = withPage({ page: pageIds.library.lifecycle })(Lifecycle);
export const ListLifecyclesPage = withPage({ page: pageIds.library.lifecycles })(Lifecycles);
export const CreateScriptModulePage = withPage({ page: pageIds.library.scriptModules.create })(ScriptModule);
export const ScriptModulePage = withPage({ page: pageIds.library.scriptModule })(ScriptModule);
export const ListScriptModulePage = withPage({ page: pageIds.library.scriptModules.root })(ScriptModulesList);
export const CreateTagSetPage = withPage({ page: pageIds.library.tagSets.create })(TagSetEdit);
export const TagSetPage = withPage({ page: pageIds.library.tagSet })(TagSetEdit);
export const ListTagSetsPage = withPage({ page: pageIds.library.tagSets.root })(TagSetsList);
export const ArchivedCertificatesPage = withPage({ page: pageIds.library.certificates.archive })(ArchivedCertificates);
export const CreateCertificatePage = withPage({ page: pageIds.library.certificates.create })(Certificate);
export const CertificatePage = withPage({ page: pageIds.library.certificate })(Certificate);
export const ListCertficatesPage = withPage({ page: pageIds.library.certificates.root })(Certificates);
export class LibraryRoutes extends React.Component<RouteComponentProps> {
    render() {
        let defaultRoute;
        switch (firstAuthorized([Permission.FeedView, Permission.LifecycleEdit, Permission.VariableView, Permission.ActionTemplateView])) {
            case Permission.FeedView:
                defaultRoute = <InternalRedirect to={routeLinks.library.builtInRepository.root}/>;
                break;
            case Permission.LifecycleEdit:
                defaultRoute = <InternalRedirect to={routeLinks.library.lifecycles}/>;
                break;
            case Permission.VariableView:
                defaultRoute = <InternalRedirect to={routeLinks.library.scripts.root}/>;
                break;
            default:
                defaultRoute = <InternalRedirect to={routeLinks.library.stepTemplates.root}/>;
        }
        return (<Switch>
                <ReloadableRoute path={routeLinks.library.v3routes.certificates.create}>
                    <InternalRedirect to={{ pathname: routeLinks.library.certificates.create, search: this.props.location.search }}/>
                </ReloadableRoute>
                <ReloadableRoute path={routeLinks.library.v3routes.feeds.create}>
                    <InternalRedirect to={{ pathname: routeLinks.library.feedCreate, search: this.props.location.search }}/>
                </ReloadableRoute>
                <ReloadableRoute path={routeLinks.library.v3routes.lifecycles.create}>
                    <InternalRedirect to={{ pathname: routeLinks.library.lifecyclesCreate, search: this.props.location.search }}/>
                </ReloadableRoute>
                <ReloadableRoute path={routeLinks.library.v3routes.scripts.create}>
                    <InternalRedirect to={{ pathname: routeLinks.library.scripts.create, search: this.props.location.search }}/>
                </ReloadableRoute>
                <ReloadableRoute path={routeLinks.library.v3routes.tagSets.create}>
                    <InternalRedirect to={{ pathname: routeLinks.library.tagSets.create, search: this.props.location.search }}/>
                </ReloadableRoute>
                <ReloadableRoute path={routeLinks.library.v3routes.variableSets.create}>
                    <InternalRedirect to={{ pathname: routeLinks.library.variableSets, search: this.props.location.search }}/>
                </ReloadableRoute>
                <ReloadableRoute path={routeLinks.library.builtInRepository.root}>
                    <Switch>
                        <ReloadableRoute path={routeLinks.library.builtInRepository.versions(":packageId")} component={PackageVersionsListPage}/>
                        <ReloadableRoute path={routeLinks.library.builtInRepository.package(":id")} component={PackageDetailsPage}/>
                        <ReloadableRoute component={BuiltInRepositoryOverviewPage}/>
                    </Switch>
                </ReloadableRoute>
                <ReloadableRoute path={routeLinks.library.buildInformationRepository.root}>
                    <Switch>
                        <ReloadableRoute path={routeLinks.library.buildInformationRepository.versions(":packageId+", false)} component={BuildInformationVersionsListPage}/>
                        <ReloadableRoute path={routeLinks.library.buildInformationRepository.package(":id")} component={BuildInformationDetailsPage}/>
                        <ReloadableRoute component={BuildInformationOverviewPage}/>
                    </Switch>
                </ReloadableRoute>
                <ReloadableRoute path={routeLinks.library.feeds} component={ExternalFeedRoutes}/>
                <ReloadableRoute path={routeLinks.library.lifecycles}>
                    <Switch>
                        <ReloadableRoute path={routeLinks.library.lifecyclesCreate} render={(routeProps) => <CreateLifecyclePage create {...routeProps}/>}/>
                        <ReloadableRoute path={routeLinks.library.lifecycle(":lifecycleId")} render={(routeProps) => <LifecyclePage lifecycleId={routeProps.match.params.lifecycleId}/>}/>
                        <ReloadableRoute component={ListLifecyclesPage}/>
                    </Switch>
                </ReloadableRoute>
                <ReloadableRoute path={routeLinks.library.scripts.root}>
                    <Switch>
                        <ReloadableRoute path={routeLinks.library.scripts.create} render={(routeProps) => <CreateScriptModulePage create {...routeProps}/>}/>
                        <ReloadableRoute path={routeLinks.library.script(":scriptModuleId")} render={(routeProps) => <ScriptModulePage scriptModuleId={routeProps.match.params.scriptModuleId}/>}/>
                        <ReloadableRoute component={ListScriptModulePage}/>
                    </Switch>
                </ReloadableRoute>
                <ReloadableRoute path={routeLinks.library.stepTemplates.root} component={ActionTemplateRoutes}/>
                <ReloadableRoute path={routeLinks.library.tagSets.root}>
                    <Switch>
                        <ReloadableRoute exact={true} path={routeLinks.library.tagSets.create} component={CreateTagSetPage}/>
                        <ReloadableRoute path={routeLinks.library.tagSet(":tagSetId")} component={TagSetPage}/>
                        <ReloadableRoute component={ListTagSetsPage}/>
                    </Switch>
                </ReloadableRoute>
                <ReloadableRoute path={routeLinks.library.variableSets} component={VariableSetsRoutes}/>
                <ReloadableRoute path={routeLinks.library.certificates.root}>
                    <Switch>
                        <ReloadableRoute path={routeLinks.library.certificates.archive} component={ArchivedCertificatesPage}/>
                        <ReloadableRoute path={routeLinks.library.certificates.create} render={(routeProps) => <CreateCertificatePage create/>}/>
                        <ReloadableRoute path={routeLinks.library.certificate(":certificateId")} render={(routeProps) => <CreateCertificatePage certificateId={routeProps.match.params.certificateId}/>}/>
                        <ReloadableRoute component={ListCertficatesPage}/>
                    </Switch>
                </ReloadableRoute>
                <ReloadableRoute path={routeLinks.library.gitCredentials.root} component={GitCredentialRoutes}/>
                <ReloadableRoute exact={true} path={routeLinks.library.root}>
                    {defaultRoute}
                </ReloadableRoute>
                <RedirectAs404 />
            </Switch>);
    }
    static displayName = "LibraryRoutes";
}
export default LibraryRoutes;
