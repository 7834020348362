import { css } from "@emotion/css";
import { ActionButtonType, CircularProgress, SimpleDataTable } from "@octopusdeploy/design-system-components";
import { borderWidth, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { ConnectTenantsToProjectTaskResource, ProjectResource, ProjectTenant, TenantResource } from "@octopusdeploy/octopus-server-client";
import { BusinessProcessState, Permission } from "@octopusdeploy/octopus-server-client";
import pluralize from "pluralize";
import * as React from "react";
import { useState } from "react";
import { Action, useAnalyticConnectTenantsDispatch } from "~/analytics/Analytics";
import { ProjectStatus } from "~/areas/projects/components/ProjectStatus/ProjectStatus";
import ConnectTenantsButton from "~/areas/projects/components/ProjectTenants/ConnectTenantsButton";
import Onboarding from "~/areas/projects/components/ProjectTenants/Onboarding";
import { useConnectionTaskLoop } from "~/areas/projects/components/ProjectTenants/hooks/useConnectionTaskLoop";
import { useProjectTenantsState } from "~/areas/projects/components/ProjectTenants/hooks/useProjectTenantsState";
import { useProjectContext } from "~/areas/projects/context/index";
import { UpdateConnectionForTenantDialog } from "~/areas/tenants/TenantOverview/UpdateConnectionForTenantDialog";
import { AddOrCloneTenant } from "~/areas/tenants/Tenants/AddOrCloneTenant";
import { Leftovers } from "~/areas/tenants/components/DataTable/Cells/Leftovers";
import { TenantDataTableAllEnvironmentsCell } from "~/areas/tenants/components/DataTable/Cells/TenantDataTableEnvironmentsCell";
import { TenantsDataTableNameCellWithLink } from "~/areas/tenants/components/DataTable/Cells/TenantsDataTableNameCell";
import NumberedPagingBar from "~/areas/tenants/components/Paging/NumberedPagingBar";
import { repository } from "~/clientInstance";
import ActionList from "~/components/ActionList/index";
import type { DoBusyTask, Errors } from "~/components/DataBaseComponent/index";
import DataBaseComponent from "~/components/DataBaseComponent/index";
import { OverflowMenu, OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import PaperLayout from "~/components/PaperLayout/index";
import useLocalStorage from "~/hooks/useLocalStorage";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout";
export default class ProjectTenants extends DataBaseComponent<{}> {
    constructor(props: {}) {
        super(props);
        this.state = {};
    }
    render() {
        return <ProjectTenantsLayout busy={this.state.busy} errors={this.errors} doBusyTask={this.doBusyTask}/>;
    }
    static displayName = "ProjectTenants";
}
interface ProjectTenantsLayoutProps {
    busy?: Promise<void>;
    errors: Errors | undefined;
    doBusyTask: DoBusyTask;
}
function ProjectTenantsLayout({ busy, errors, doBusyTask }: ProjectTenantsLayoutProps) {
    const projectContext = useProjectContext();
    const project = projectContext.state && projectContext.state.model;
    if (!project) {
        throw new Error("Failed to find project from context. This should not happen.");
    }
    const dispatchAction = useAnalyticConnectTenantsDispatch();
    const [latestConnectionTask, loop] = useConnectionTaskLoop(doBusyTask, project.Id);
    const [showActionButtons, setShowActionButtons] = useState(false);
    const onTenantConnected = async (numberOfTenantsConnected: number) => {
        dispatchAction("Connect Tenants", { action: Action.Save, resource: "Tenant", numTenants: numberOfTenantsConnected });
        await loop.refresh();
    };
    return (<PaperLayout busy={busy} errors={errors} title="Tenants" fullWidth={true} statusSection={<ProjectStatus doBusyTask={doBusyTask}/>} sectionControl={showActionButtons ? (<ActionList actions={[<AddOrCloneTenant type={ActionButtonType.Secondary}/>, <ConnectTenantsButton disabled={latestConnectionTask?.State === BusinessProcessState.InProgress} project={project} onConnected={onTenantConnected}/>]}/>) : ([])}>
            <ProjectTenantsContent latestConnectionTask={latestConnectionTask} doBusyTask={doBusyTask} project={project} updateShowActionButtons={(show) => setShowActionButtons(show)} onTenantConnected={onTenantConnected}/>
        </PaperLayout>);
}
interface ProjectTenantsContentProps {
    doBusyTask: DoBusyTask;
    project: ProjectResource;
    updateShowActionButtons: (show: boolean) => void;
    onTenantConnected: (numberOfTenantsConnected: number) => void;
    latestConnectionTask: ConnectTenantsToProjectTaskResource | null;
}
function ProjectTenantsContent({ doBusyTask, project, updateShowActionButtons, onTenantConnected, latestConnectionTask }: ProjectTenantsContentProps) {
    const { tableState, pageState, actions } = useProjectTenantsState(project.Id, doBusyTask, updateShowActionButtons, latestConnectionTask);
    if (!tableState) {
        return null;
    }
    if (tableState.totalTenantCount === 0) {
        return (<React.Fragment>
                {latestConnectionTask ? <ConnectionTaskCallout latestConnectionTask={latestConnectionTask}/> : null}
                <Onboarding project={project} onConnected={onTenantConnected}/>
            </React.Fragment>);
    }
    const handleRemoveConfirm = async (tenantId: string) => {
        await doBusyTask(async () => {
            const tenant = await repository.Tenants.get(tenantId);
            delete tenant.ProjectEnvironments[project.Id];
            await repository.Tenants.save(tenant);
            actions.refreshTable();
        });
    };
    const onTenantUpdated = () => {
        actions.refreshTable();
    };
    return (<>
            {latestConnectionTask ? <ConnectionTaskCallout latestConnectionTask={latestConnectionTask}/> : null}
            <TenantCount totalCount={tableState.totalTenantCount}></TenantCount>
            <SimpleDataTable columns={[
            {
                accessibleName: "Tenant Name",
                columnSize: "medium",
                title: "Tenant",
                render: (tenant) => <TenantsDataTableNameCellWithLink tenantId={tenant.Id} tenantName={tenant.Name} tenantLogoLink={tenant.LogoLink}/>,
            },
            {
                accessibleName: "Connected Environments",
                columnSize: "large",
                title: "Environment",
                render: (tenant) => <LocalEnvironmentsSummaryCell tenant={tenant} summaryCount={3} handleRemoveConfirm={handleRemoveConfirm} onUpdated={onTenantUpdated} project={project}/>,
            },
        ]} data={tableState.tenants} getRowKey={(tenant) => tenant.Id} accessibleName={"Connected Tenants"}/>
            <div className={styles.pagingBar}>
                <NumberedPagingBar totalItems={tableState.totalTenantCount} pageNumber={pageState.pageNumber} pageSize={pageState.pageSize} onPagingSelectionChange={(newPageNumber, newPageSize) => {
            actions.setPageNumber(newPageNumber);
            actions.setPageSize(newPageSize);
        }} pageSizeOptions={[30, 50, 100]}/>
            </div>
        </>);
}
interface TenantCountProps {
    totalCount: number;
}
function TenantCount({ totalCount }: TenantCountProps) {
    return <div className={styles.result}>{pluralize("tenant", totalCount, true)}</div>;
}
interface ConnectionTaskCalloutProps {
    latestConnectionTask: ConnectTenantsToProjectTaskResource;
}
function ConnectionTaskCallout({ latestConnectionTask }: ConnectionTaskCalloutProps) {
    const [taskIdDismissed, setTaskIdDismissed] = useLocalStorage<string | undefined>("Octopus.Callout.TenantConnection.TaskIdDismissed", undefined);
    const onCloseCallout = () => {
        setTaskIdDismissed(latestConnectionTask.Id);
    };
    if (latestConnectionTask.State === BusinessProcessState.Completed) {
        if (taskIdDismissed && taskIdDismissed === latestConnectionTask.Id) {
            return null;
        }
        const tenantWording = latestConnectionTask.ConnectionsCompleted === 1 ? "tenant has" : "tenants have";
        return (<Callout title={"Successful tenant connection"} type={CalloutType.Success} canClose={true} onClose={onCloseCallout}>
                <div>
                    {latestConnectionTask.ConnectionsCompleted} {tenantWording} recently been connected
                </div>
            </Callout>);
    }
    return (<Callout title={"Connecting tenants..."} type={CalloutType.Information}>
            <div className={styles.connectionTaskInfo}>
                Connecting tenant {latestConnectionTask.ConnectionsCompleted + 1} of {latestConnectionTask.ConnectionsRequested}
                <CircularProgress size={"small"}/>
            </div>
        </Callout>);
}
interface LocalEnvironmentsSummaryCellProps {
    summaryCount: number;
    tenant: ProjectTenant;
    project: ProjectResource;
    handleRemoveConfirm: (tenantId: string) => void;
    onUpdated: (tenant: TenantResource) => void;
}
function LocalEnvironmentsSummaryCell({ summaryCount, tenant, project, handleRemoveConfirm, onUpdated }: LocalEnvironmentsSummaryCellProps) {
    const [showAll, setShowAll] = useState<boolean>(false);
    const environmentNames = tenant.Environments.map((e) => e.Name);
    const hasMissingVariables = tenant.HasMissingVariables;
    const change = () => {
        setShowAll(!showAll);
    };
    return (<div className={styles.environmentsCell}>
            <TenantDataTableAllEnvironmentsCell environments={showAll ? environmentNames : environmentNames.slice(0, summaryCount)} hasMissingVariables={hasMissingVariables}/>
            {environmentNames.length <= summaryCount ? <></> : <Leftovers count={environmentNames.length - summaryCount} name={"environment"} onShowAllChanged={change} showAll={showAll}/>}
            <OverflowMenu menuItems={[
            OverflowMenuItems.dialogItem("Edit", <UpdateConnectionForTenantDialog onUpdated={onUpdated} projectId={project.Id} projectName={project.Name} tenantId={tenant.Id} selectedEnvironmentIds={tenant.Environments.map((e) => e.Id)}/>, {
                permission: Permission.TenantEdit,
                tenant: tenant.Id,
            }),
            OverflowMenuItems.removeItem("Remove", `Unlink Tenant from Project`, () => handleRemoveConfirm(tenant.Id), <div>
                            Are you sure you want to unlink {tenant.Name} from {project.Name}?
                        </div>, {
                permission: Permission.TenantEdit,
                tenant: tenant.Id,
            }),
        ]}/>
        </div>);
}
const styles = {
    pagingBar: css({
        padding: "1rem 1rem",
    }),
    result: css({
        padding: "1rem 1rem",
        borderTop: `${borderWidth[1]} solid ${themeTokens.color.border.primary}`,
        font: text.interface.heading.medium,
        color: themeTokens.color.text.primary,
    }),
    environmentsCell: css({
        display: "grid",
        gridTemplateColumns: "1fr auto",
        alignItems: "center",
    }),
    connectionTaskInfo: css({
        display: "inline-flex",
        alignItems: "center",
        gap: "0.5rem",
    }),
};
