import type { IconButtonElement, IconButtonProps } from "@octopusdeploy/design-system-components";
import { IconButton } from "@octopusdeploy/design-system-components";
import React from "react";
import type { ToolTipContent } from "~/primitiveComponents/dataDisplay/ToolTip/index";
import ToolTip from "~/primitiveComponents/dataDisplay/ToolTip/index";
export type IconButtonWithTooltipProps = IconButtonProps & {
    toolTipContent: ToolTipContent;
};
export const IconButtonWithTooltip = React.forwardRef<IconButtonElement, IconButtonWithTooltipProps>(({ toolTipContent, ...iconButtonProps }, ref) => {
    return (<ToolTip content={toolTipContent}>
            <IconButton ref={ref} {...iconButtonProps}/>
        </ToolTip>);
});
type IconButtonWithOptionalTooltipProps = IconButtonProps & {
    toolTipContent?: ToolTipContent;
};
export const IconButtonWithOptionalTooltip = React.forwardRef<IconButtonElement, IconButtonWithOptionalTooltipProps>(({ toolTipContent, ...iconButtonProps }, ref) => {
    if (toolTipContent) {
        return <IconButtonWithTooltip ref={ref} toolTipContent={toolTipContent} {...iconButtonProps}/>;
    }
    return <IconButton ref={ref} {...iconButtonProps}/>;
});
