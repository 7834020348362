import cn from "classnames";
import type { PropsWithChildren } from "react";
import React, { useEffect, useState } from "react";
import { CustomDialog } from "~/components/Dialog/CustomDialog";
import ContentPanel from "~/components/DialogWithHelpPanel/ContentPanel";
import CustomTransparentDialogFrame from "~/components/DialogWithHelpPanel/CustomTransparentDialogFrame";
import HelpPanel from "~/components/DialogWithHelpPanel/HelpPanel";
import { IconButtonWithTooltip } from "~/components/IconButtonWithTooltip";
import useLocalStorage from "~/hooks/useLocalStorage";
import styles from "./styles.module.less";
interface DialogWithHelpPanelProps extends DialogWithHelpPanelFrameProps {
    open: boolean;
    close: () => void;
}
const DialogWithHelpPanel = ({ children, open, close, ...frameProps }: PropsWithChildren<DialogWithHelpPanelProps>) => (<CustomDialog open={open} close={close} render={() => <DialogWithHelpPanelFrame {...frameProps}>{children}</DialogWithHelpPanelFrame>}/>);
interface DialogWithHelpPanelFrameProps {
    helpPanelContent?: React.ReactNode;
    helpPanelImage: string;
    helpPanelAltImageText?: string;
    contentPanelClassName?: string;
    helpPanelClassName?: string;
    showToggleHelpOptions?: boolean;
    onToggleHelp?: (open: boolean) => void;
}
export const DialogWithHelpPanelFrame = ({ children, helpPanelContent, helpPanelImage, helpPanelAltImageText, contentPanelClassName, helpPanelClassName, onToggleHelp, showToggleHelpOptions }: PropsWithChildren<DialogWithHelpPanelFrameProps>) => {
    const shouldShowHelpPanelLocalStorageKey = "pba.shouldShowHelpPanel";
    const [showHelpPanel, setShowHelpPanel] = useLocalStorage(shouldShowHelpPanelLocalStorageKey, true);
    const [animate, setAnimate] = useState(false);
    showToggleHelpOptions = showToggleHelpOptions ?? true;
    useEffect(() => {
        setTimeout(() => setAnimate(false), 350);
    }, [animate]);
    return (<CustomTransparentDialogFrame>
            <ContentPanel className={cn({ [styles.contentPanelWithHelpPanel]: showHelpPanel }, contentPanelClassName)}>
                {children}
                {!showHelpPanel && showToggleHelpOptions && (<span className={styles.helpPanelBtn}>
                        <IconButtonWithTooltip toolTipContent={"Expand help"} onClick={() => {
                onToggleHelp?.(true);
                setShowHelpPanel(true);
                setAnimate(true);
            }} icon="OpenHelp"/>
                    </span>)}
            </ContentPanel>

            <HelpPanel content={helpPanelContent} image={helpPanelImage} className={cn({ [styles.active]: showToggleHelpOptions ? showHelpPanel : true, [styles.animate]: animate }, helpPanelClassName)} altImageText={helpPanelAltImageText ?? "Image"} onClose={() => {
            onToggleHelp?.(false);
            setShowHelpPanel(false);
            setAnimate(true);
        }} showToggle={showToggleHelpOptions}/>
        </CustomTransparentDialogFrame>);
};
export default DialogWithHelpPanel;
