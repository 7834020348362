/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ActionButton, ActionButtonType, Checkbox, RadioButtonGroup, RadioButton } from "@octopusdeploy/design-system-components";
import type { PackageSearchOptions, PackageVersionResource, ResourceCollection, FeedResource } from "@octopusdeploy/octopus-server-client";
import { FeedType } from "@octopusdeploy/octopus-server-client";
import cn from "classnames";
import moment from "moment";
import * as React from "react";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import OkDialogLayout from "~/components/DialogLayout/OkDialogLayout";
import FilterSearchBox from "~/components/FilterSearchBox/FilterSearchBox";
import { DataTable, DataTableBody, DataTableHeader, DataTableHeaderColumn, DataTableRow, DataTableRowColumn } from "~/primitiveComponents/dataDisplay/DataTable";
import Note from "~/primitiveComponents/form/Note/Note";
import Markdown from "../../../../../components/Markdown/index";
import ToolTip from "../../../../../primitiveComponents/dataDisplay/ToolTip/index";
import type { PackageEditInfo } from "../packageModel";
import styles from "./style.module.less";
interface PackageListDialogProps {
    package: PackageEditInfo;
    feed: FeedResource;
    channelFilters: {
        versionRange?: string;
        preReleaseTag?: string;
    };
    onVersionSelected(selectedVersion: string): void;
}
interface PackageListDialogState extends DataBaseComponentState {
    includePrereleasePackages: boolean;
    includeReleaseNotes: boolean;
    channelRules: boolean;
    selectedPackageVersion: string;
    validChannelVersions: string[];
    packageResults: ResourceCollection<PackageVersionResource>;
    take: number;
    packageFilter: string;
}
class PackageListDialogContent extends DataBaseComponent<PackageListDialogProps, PackageListDialogState> {
    constructor(props: PackageListDialogProps) {
        super(props);
        this.state = {
            includePrereleasePackages: false,
            includeReleaseNotes: false,
            channelRules: true,
            selectedPackageVersion: null!,
            validChannelVersions: [],
            packageResults: null!,
            take: 5,
            packageFilter: null!,
        };
    }
    componentDidMount() {
        if (this.props.package) {
            this.refresh();
        }
    }
    render() {
        const hasChannelRules = this.props.channelFilters.versionRange || this.props.channelFilters.preReleaseTag;
        const canFeedShowReleaseNotes = this.props.feed && (this.props.feed.FeedType === FeedType.BuiltIn || this.props.feed.FeedType === FeedType.Nuget || this.props.feed.FeedType === FeedType.GitHub);
        const canFeedShowPublishDates = this.props.feed && (this.props.feed.FeedType === FeedType.BuiltIn || this.props.feed.FeedType === FeedType.Nuget || this.props.feed.FeedType === FeedType.GitHub);
        return (<OkDialogLayout title={`${this.props.package.PackageId} previous versions`} onOkClick={() => this.onPackageSelected()} busy={this.state.busy} errors={this.errors}>
                {this.props.package && (<div>
                        <div>
                            {this.state.packageResults && (<p>
                                    Prior versions of package {this.props.package.PackageId} from feed {this.props.package.FeedName}
                                </p>)}
                            <div className={styles.optionsBar}>
                                <div className={styles.checkbox}>
                                    <Checkbox value={this.state.includePrereleasePackages} label="Pre-release packages" onChange={(includePrereleasePackages) => this.setState({ includePrereleasePackages }, this.refresh)}/>
                                </div>
                                {hasChannelRules && (<div className={styles.checkbox}>
                                        <Checkbox value={this.state.channelRules} label="Only this channel" onChange={(channelRules) => this.setState({ channelRules }, this.refresh)}/>
                                    </div>)}
                                {canFeedShowReleaseNotes && (<div className={styles.checkbox}>
                                        <Checkbox value={this.state.includeReleaseNotes} label="Release notes" onChange={(includeReleaseNotes) => this.setState({ includeReleaseNotes }, this.refresh)}/>
                                    </div>)}
                                <div style={{ flex: 1, textAlign: "right" }}>
                                    <ActionButton label="Refresh" disabled={this.state.busy} type={ActionButtonType.Secondary} onClick={() => this.refresh()}/>
                                </div>
                            </div>
                            <div className={styles.optionsBar}>
                                <div>
                                    <FilterSearchBox placeholder={"Search versions..."} value={this.state.packageFilter} onChange={(x) => {
                    this.setState({ packageFilter: x }, () => {
                        this.refresh();
                    });
                }} autoFocus={true}/>
                                </div>
                            </div>
                            {this.state.packageResults && (<DataTable>
                                    <DataTableHeader>
                                        <DataTableRow>
                                            <DataTableHeaderColumn>Version</DataTableHeaderColumn>
                                            {canFeedShowPublishDates && <DataTableHeaderColumn>Published</DataTableHeaderColumn>}
                                        </DataTableRow>
                                    </DataTableHeader>
                                    {this.state.packageResults.Items.map((row: PackageVersionResource, index: number) => (<DataTableBody key={index}>
                                            <DataTableRow>
                                                <DataTableRowColumn className={styles.tableColumn}>
                                                    <div className={styles.versionRadio} style={{ display: "flex", alignItems: "center" }}>
                                                        <div className={styles.infoCircle}>
                                                            {!this.state.validChannelVersions.includes(row.Version) && (<ToolTip content="Package version does not satisfy channel rules">
                                                                    <span className="fa-solid pull-right fa-circle-info"/>
                                                                </ToolTip>)}
                                                        </div>
                                                        <RadioButtonGroup value={this.state.selectedPackageVersion} onChange={() => this.setState({ selectedPackageVersion: row.Version })}>
                                                            <RadioButton value={row.Version} label={row.Version}/>
                                                        </RadioButtonGroup>
                                                    </div>
                                                </DataTableRowColumn>
                                                {canFeedShowPublishDates && <DataTableRowColumn className={styles.tableColumn}>{row.Published && moment(row.Published).format("llll")}</DataTableRowColumn>}
                                            </DataTableRow>
                                            {this.state.includeReleaseNotes && row.ReleaseNotes && (<DataTableRow>
                                                    <DataTableRowColumn colSpan={3}>
                                                        <Markdown markup={row.ReleaseNotes}/>
                                                    </DataTableRowColumn>
                                                </DataTableRow>)}
                                        </DataTableBody>))}
                                </DataTable>)}
                            {this.renderLoadMore()}
                            {!this.state.channelRules && (<div className={styles.legend}>
                                    <Note>
                                        Packages marked <span className={cn(styles.infoIcon, "fa-solid fa-circle-info")}/> don't match the selected channel version rules.
                                    </Note>
                                </div>)}
                        </div>
                    </div>)}
            </OkDialogLayout>);
    }
    private renderLoadMore = () => {
        if (!this.state.packageResults || this.state.packageResults.ItemsPerPage >= this.state.packageResults.TotalResults) {
            return null;
        }
        return (<div className={styles.loadMore}>
                <ActionButton label="Load more" onClick={() => this.refresh(this.state.take * 2)}/>
            </div>);
    };
    private onPackageSelected = () => {
        this.props.onVersionSelected(this.state.selectedPackageVersion);
        return true;
    };
    private refresh = (take: number = 20) => {
        this.setState({ take }, () => {
            return this.doBusyTask(async () => {
                if (this.state.channelRules) {
                    const packageResults = await this.getPackages(this.props.channelFilters);
                    const validChannelVersions = packageResults.Items.map((o) => o.Version);
                    this.setState({ packageResults, validChannelVersions });
                }
                else {
                    const packageResults = await this.getPackages();
                    this.setState({ packageResults });
                }
            });
        });
    };
    private getPackages = async (packageSearchOptions?: Partial<PackageSearchOptions>): Promise<ResourceCollection<PackageVersionResource>> => {
        return repository.Feeds.searchPackageVersions(this.props.feed, this.props.package.PackageId, {
            take: this.state.take,
            includePreRelease: this.state.includePrereleasePackages,
            includeReleaseNotes: this.state.includeReleaseNotes,
            filter: this.state.packageFilter ? this.state.packageFilter : null!,
            ...packageSearchOptions,
        });
    };
    static displayName = "PackageListDialogContent";
}
export default PackageListDialogContent;
