/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { AzurePowerShellProperties } from "@octopusdeploy/legacy-action-properties";
import type { AccountResource } from "@octopusdeploy/octopus-server-client";
import { AccountType, ActionExecutionLocation } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { TargetRoles } from "~/areas/projects/components/Process/types";
import { repository } from "~/clientInstance";
import { ScriptActionEdit } from "~/components/Actions/script/scriptAction";
import { BaseComponent } from "~/components/BaseComponent/BaseComponent";
import AccountSelect from "~/components/form/AccountSelect/AccountSelect";
import { withBoundField } from "~/components/form/BoundField/BoundField";
import ExpandableFormSection from "~/components/form/Sections/ExpandableFormSection";
import FormSectionHeading from "~/components/form/Sections/FormSectionHeading";
import { OverflowSummary, PlaceholderSummary } from "~/components/form/Sections/Summary";
import { SupportedLanguage } from "../../ScriptingLanguageSelector/ScriptingLanguageSelector";
import { AccountSelectionSummary } from "../../form/AccountSelect/AccountSelectionSummary";
import Roles from "../Roles";
import type { ActionSummaryProps } from "../actionSummaryProps";
import type { ActionEditProps } from "../pluginRegistry";
import pluginRegistry from "../pluginRegistry";
import type { ScriptPackageProperties } from "../script/ScriptPackageReferenceDialog";
import { AzureBundledToolsForScriptsEdit } from "./azureBundledTools";
class AzurePowerShellActionSummary extends BaseComponent<ActionSummaryProps> {
    render() {
        return (<div>
                Run a script using an Azure subscription, with Azure modules loaded by default
                {this.props.targetRolesAsCSV && (<span>
                        {", "}
                        on behalf of targets in <Roles rolesAsCSV={this.props.targetRolesAsCSV}/>
                    </span>)}
            </div>);
    }
    static displayName = "AzurePowerShellActionSummary";
}
interface AzurePowerShellActionEditState {
    accounts: AccountResource[];
    account: AccountResource;
}
const BoundAccountSelect = withBoundField(AccountSelect);
type AzurePowershellActionEditProps = ActionEditProps<AzurePowerShellProperties, ScriptPackageProperties>;
class AzurePowerShellActionEdit extends BaseComponent<AzurePowershellActionEditProps, AzurePowerShellActionEditState> {
    constructor(props: AzurePowershellActionEditProps) {
        super(props);
        this.state = {
            accounts: [],
            account: null!,
        };
    }
    async componentDidMount() {
        await this.props.doBusyTask(async () => {
            this.setState({
                accounts: await repository.Accounts.all(),
            });
        });
    }
    accountSummary() {
        const accountId = this.props.properties["Octopus.Action.Azure.AccountId"];
        return (<AccountSelectionSummary accountId={accountId} accounts={this.state.accounts} renderFound={(account) => <OverflowSummary>{account.Name}</OverflowSummary>} renderMissing={() => <PlaceholderSummary>No account has been selected</PlaceholderSummary>}/>);
    }
    render() {
        return (<div>
                <AzureBundledToolsForScriptsEdit {...this.props}/>

                <FormSectionHeading title="Azure"/>

                <ExpandableFormSection errorKey="Octopus.Action.Azure.AccountId" isExpandedByDefault={this.props.expandedByDefault} title="Account" summary={this.accountSummary()} help="Select the Azure account to use to run the script">
                    <BoundAccountSelect variableLookup={{
                localNames: this.props.localNames,
            }} resetValue="" value={this.props.properties["Octopus.Action.Azure.AccountId"]} type={[AccountType.AzureSubscription, AccountType.AzureServicePrincipal]} onChange={(x: string) => this.props.setProperties({ ["Octopus.Action.Azure.AccountId"]: x })} error={this.props.getFieldError("Octopus.Action.Azure.AccountId")} items={this.state.accounts} onRequestRefresh={this.refreshAccounts}/>
                </ExpandableFormSection>

                <ScriptActionEdit plugin={this.props.plugin} projectId={this.props.projectId} gitRef={this.props.gitRef} localNames={this.props.localNames} properties={this.props.properties} packages={this.props.packages} setProperties={this.props.setProperties} setPackages={this.props.setPackages} doBusyTask={this.props.doBusyTask} busy={this.props.busy} getFieldError={this.props.getFieldError} errors={this.props.errors} expandedByDefault={this.props.expandedByDefault} supportedLanguages={SupportedLanguage.PowerShellAndBash} parameters={this.props.parameters}/>
            </div>);
    }
    private refreshAccounts = () => {
        return this.props.doBusyTask(async () => {
            this.setState({ accounts: await repository.Accounts.all() });
        });
    };
    static displayName = "AzurePowerShellActionEdit";
}
pluginRegistry.registerAction({
    executionLocation: ActionExecutionLocation.AlwaysOnServer,
    actionType: "Octopus.AzurePowerShell",
    summary: (properties, targetRolesAsCSV) => <AzurePowerShellActionSummary properties={properties} targetRolesAsCSV={targetRolesAsCSV}/>,
    editSections: { default: AzurePowerShellActionEdit },
    canHaveChildren: (step) => true,
    canBeChild: true,
    targetRoleOption: (action) => TargetRoles.Optional,
    hasPackages: (action) => false,
    features: {
        optional: ["Octopus.Features.SubstituteInFiles", "Octopus.Features.JsonConfigurationVariables", "Octopus.Features.ConfigurationTransforms", "Octopus.Features.ConfigurationVariables", "Octopus.Features.SelectPowerShellEditionForWindows"],
    },
    getInitialProperties: () => {
        return {
            OctopusUseBundledTooling: "False",
        };
    },
});
