import * as React from "react";
import { AnalyticView } from "~/analytics/Analytics";
import styles from "~/areas/infrastructure/components/MachineSettings/Endpoints/styles.module.less";
import { KubernetesWithArgoCD } from "~/components/Images/MachineSettings/KubernetesWithArgoCD";
import ExternalLink from "~/components/Navigation/ExternalLink/index";
import kubernetesCategory from "./KubernetesCategoryDefinition";
import { InfoRegistrationCards } from "./RegistrationCards";
import type { InformationEndpointRegistration } from "./endpointRegistry";
import { CommunicationStyle, EndpointRegistrationKey } from "./endpointRegistry";
const KubernetesWithArgoCDEndpointDialogView = () => (<div>
        <AnalyticView resource={"KubernetesWithArgoCDEndpointDialogView"} name={"View Kubernetes with Argo Deployment Target"}/>
        <h3>Under consideration</h3>
        <p>We're exploring the option of connecting an Argo CD installed on a Kubernetes cluster. Octopus server will use the Argo CD instance for deployments and get live status for deployed resources.</p>
        <p>
            <ExternalLink href="K8sPlusArgoCD">Learn more and share your feedback with us here</ExternalLink>
        </p>
    </div>);
const kubernetesWithArgoCDEndpointRegistration: InformationEndpointRegistration = {
    key: EndpointRegistrationKey.KubernetesWithArgoCD,
    displayOrder: 25,
    name: "Kubernetes cluster with Argo CD",
    categories: [kubernetesCategory],
    communicationStyle: CommunicationStyle.None,
    visibilityFeatureToggle: "UnderConsiderationKubernetesTargetsFeatureToggle",
    renderCard: InfoRegistrationCards.basic(() => <KubernetesWithArgoCDEndpointDialogView />, ({ registration }) => ({
        logo: <KubernetesWithArgoCD className={styles.centreThumbnail} title={registration.name}/>,
        registrationName: registration.name,
        description: "Connect to a Kubernetes cluster with existing Argo CD.",
        buttonLabel: "Learn more",
    })),
};
export { kubernetesWithArgoCDEndpointRegistration };
