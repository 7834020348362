import { css } from "@emotion/css";
import { Button, Divider, SimpleDataTable } from "@octopusdeploy/design-system-components";
import { text, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { EnvironmentResource, ProjectResource, TenantResource } from "@octopusdeploy/octopus-server-client";
import { TenantedDeploymentMode } from "@octopusdeploy/octopus-server-client";
import pluralize from "pluralize";
import * as React from "react";
import { useState } from "react";
import { Action, useAnalyticActionDispatch } from "~/analytics/Analytics";
import type { AvailableEnvironments } from "~/areas/projects/components/ProjectTenants/ConnectMultipleTenantDialog";
import { defaultTenantWizardDialogPageSize, PagingSection } from "~/areas/projects/components/ProjectTenants/PagingSection";
import { TenantsDataTableNameCell } from "~/areas/tenants/components/DataTable/Cells/TenantsDataTableNameCell";
import { EnvironmentChip, MissingChip } from "~/components/Chips/index";
import { EnvironmentMultiSelect } from "~/components/MultiSelect/EnvironmentMultiSelect";
import { Callout, CalloutType } from "~/primitiveComponents/dataDisplay/Callout/index";
interface SelectEnvironmentsProps {
    selectedTenants: TenantResource[];
    availableEnvironments: AvailableEnvironments;
    selectedEnvironmentIds: string[];
    updateSelectedEnvironments: (environmentIds: string[]) => void;
    project: ProjectResource;
}
export default function SelectEnvironments({ selectedTenants, availableEnvironments, selectedEnvironmentIds, updateSelectedEnvironments, project }: SelectEnvironmentsProps) {
    const dispatchAction = useAnalyticActionDispatch();
    const onAssignAll = () => {
        dispatchAction("Assign All Available Environments", { resource: "Tenant", action: Action.Autofill });
        updateSelectedEnvironments(availableEnvironments.all.map((e) => e.Id));
    };
    return (<div className={styles.container}>
            <div className={styles.panelContainer}>
                <div className={styles.panelHeadingContainer}>
                    <div className={styles.panelHeading}>Select environments for {pluralize("tenant", selectedTenants.length, true)}</div>
                </div>
                <div className={styles.panelHeadingContainer}>
                    <div>
                        <EnvironmentMultiSelect onChange={(ids) => updateSelectedEnvironments(ids)} value={selectedEnvironmentIds} environments={availableEnvironments.all} fallbackLabel="Missing from Lifecycle" fallbackDescription="This environment is no longer part of the lifecycle. Please remove it from this tenant."/>
                        <MissingEnvironmentsWarning environments={availableEnvironments.lookup} selectedIds={selectedEnvironmentIds}/>
                    </div>
                    <div>
                        <Button label={"Assign all available environments"} onClick={onAssignAll} importance={"tertiary"}/>
                    </div>
                </div>
                <Divider />
                <ConnectionPreview tenants={selectedTenants} selectedEnvironmentIds={selectedEnvironmentIds} environments={availableEnvironments.lookup} project={project}/>
            </div>
        </div>);
}
interface ConnectionPreviewProps {
    tenants: TenantResource[];
    selectedEnvironmentIds: string[];
    environments: Map<string, EnvironmentResource>;
    project: ProjectResource;
}
const ConnectionPreview = ({ tenants, selectedEnvironmentIds, environments, project }: ConnectionPreviewProps) => {
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(defaultTenantWizardDialogPageSize);
    const selectedTenantsToDisplay = tenants.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
    return (<>
            <div className={styles.previewTitle}>Connection preview</div>
            {project.TenantedDeploymentMode === TenantedDeploymentMode.Untenanted ? <TenantedDeploymentNotice selectedTenants={tenants}/> : null}
            <div className={styles.tableContainer}>
                <SimpleDataTable columns={[
            {
                accessibleName: "Tenant Name",
                columnSize: "medium",
                title: "Tenant",
                render: (tenant) => <TenantsDataTableNameCell tenantName={tenant.Name} tenantLogoLink={tenant.Links.Logo} overflow={true}/>,
            },
            {
                accessibleName: "Environments",
                columnSize: "large",
                title: "Environments",
                render: () => {
                    return (<div>
                                        {selectedEnvironmentIds.map((id) => {
                            const name = environments.get(id)?.Name;
                            return name ? <EnvironmentChip key={id} environmentName={name}/> : <MissingChip lookupId={id}/>;
                        })}
                                    </div>);
                },
            },
        ]} data={selectedTenantsToDisplay} getRowKey={(tenant) => tenant.Id} accessibleName={"Tenants to Connect"}/>
            </div>
            <PagingSection itemCount={tenants.length} pageNumber={pageNumber} pageSize={pageSize} onPagingSelectionChange={(newPageNumber, newPageSize) => {
            setPageNumber(newPageNumber);
            setPageSize(newPageSize);
        }}/>
        </>);
};
interface TenantedDeploymentNoticeProps {
    selectedTenants: TenantResource[];
}
const TenantedDeploymentNotice = ({ selectedTenants }: TenantedDeploymentNoticeProps) => {
    if (selectedTenants.length <= 0) {
        return null;
    }
    return (<Callout key="callout" title={`You are about to connect ${pluralize("tenant", selectedTenants.length, true)} to the project`} type={CalloutType.Information}>
            By connecting the selected tenants, Octopus will enable tenanted deployments for this project
        </Callout>);
};
interface MissingEnvironmentsWarningProps {
    environments: Map<string, EnvironmentResource>;
    selectedIds: string[];
}
const MissingEnvironmentsWarning = ({ environments, selectedIds }: MissingEnvironmentsWarningProps) => {
    const hasMissingEnvironments = selectedIds.some((id) => !environments.has(id));
    if (hasMissingEnvironments) {
        return <Callout title="One or more environments are no longer part of the associated lifecycle. Please remove the missing environment(s) from this tenant." type={CalloutType.Warning}/>;
    }
    return null;
};
const styles = {
    container: css({
        width: "100%",
        height: "100%",
        padding: "1rem",
        backgroundColor: themeTokens.color.background.muted.default,
    }),
    panelContainer: css({
        height: "100%",
        display: "flex",
        flexDirection: "column",
        border: `1px solid ${themeTokens.color.border.primary}`,
        borderRadius: "0.25rem",
        backgroundColor: themeTokens.color.background.primary.default,
        overflowY: "hidden",
    }),
    panelHeadingContainer: css({
        display: "grid",
        gridTemplateColumns: "1fr auto",
        gap: "10rem",
        alignItems: "center",
        padding: "1rem 1rem",
    }),
    panelHeading: css({
        font: text.interface.body.bold.base,
        color: themeTokens.color.text.primary,
    }),
    previewTitle: css({
        padding: "1rem 1rem",
        font: text.interface.body.bold.base,
        color: themeTokens.color.text.primary,
    }),
    tableContainer: css({
        overflowY: "auto",
    }),
};
