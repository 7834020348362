import type { ReloadableRouteProps } from "@octopusdeploy/routing-infrastructure";
import * as React from "react";
import { Route } from "react-router-dom";
import ErrorBoundary from "~/components/ErrorBoundary";
import { ReloadableRouteConfiguration, useReloadableRouteIgnoreKeys } from "~/components/ReloadableRoute/ReloadableRouteConfiguration";
const ReloadableRoute: React.FC<ReloadableRouteProps> = ({ doNotReloadWhenTheseKeysChange, ...otherProps }) => {
    // We are tagging the parent route of each component with a key made out of params it depends
    // on so redirects to the same component with different params unmount it and mount it again.
    // This can also be done by responding to changes in props but this is a lot of work to do it
    // correctly and it is very easy to forget about it.
    // Details: https://github.com/ReactTraining/react-router/issues/1982
    const ignoreKeysFromContext = useReloadableRouteIgnoreKeys();
    const ignoreKeys = doNotReloadWhenTheseKeysChange ?? ignoreKeysFromContext ?? [];
    const key = otherProps.computedMatch?.params ? getComponentKey(otherProps.computedMatch.params, ignoreKeys) : "";
    return (<ErrorBoundary key={key}>
            {doNotReloadWhenTheseKeysChange === undefined ? renderRoute() : <ReloadableRouteConfiguration doNotReloadWhenTheseKeysChange={doNotReloadWhenTheseKeysChange}>{renderRoute()}</ReloadableRouteConfiguration>}
        </ErrorBoundary>);
    function renderRoute() {
        return <Route {...otherProps}/>;
    }
};
ReloadableRoute.displayName = "ReloadableRoute"
export function getComponentKey(params: Record<string, string>, ignoreTheseKeys: string[]) {
    const keyValues = Object.keys(params)
        .filter((key) => !ignoreTheseKeys.includes(key))
        .map((key) => params[key]);
    return keyValues.filter((x) => x).join("/");
}
export default ReloadableRoute;
