/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */
import { Box, useMediaQuery } from "@material-ui/core";
import { NavigationBar, useMenuState, SimpleMenu, dividerMenuItem } from "@octopusdeploy/design-system-components";
import type { MenuTargetAriaAttributes, SimpleMenuItem } from "@octopusdeploy/design-system-components";
import type { UserResource } from "@octopusdeploy/octopus-server-client";
import { OctoLink } from "@octopusdeploy/utilities";
import type { MouseEventHandler } from "react";
import * as React from "react";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useHiddenFeatures } from "~/areas/configuration/components/FeaturesLayout/useHiddenFeatures";
import { repository, session } from "~/clientInstance";
import { drawerBreakpointWidth } from "~/components/ContextualHelpLayout/ContextualHelpLayout";
import { CommonNeedHelpInformation } from "~/components/ContextualHelpLayout/PageHelpRegistry/Registrations/CommonComponents/CommonViews";
import { useAnalyticsWithMenuExternalLink, useExternalLinksWithAnalytics } from "~/components/Navigation/ExternalLink/useExternalLinksWithAnalytics";
import { useSetThemePaletteType, useThemePaletteType } from "~/components/Theme/useThemePaletteType";
import { Avatar } from "~/primitiveComponents/dataDisplay/Avatar/Avatar";
import ToolTip from "~/primitiveComponents/dataDisplay/ToolTip/ToolTip";
import routeLinks from "../../routeLinks";
import { useIsPageHeaderVNextFeature } from "../RootRoutes/useIsPageHeaderVNextEnabled";
import { useIsRoutingVNextFeature } from "../RootRoutes/useIsRoutingVNextEnabled";
import type { SpaceContext } from "../StandardLayout/SpaceLoader";
import { isSpaceNotFound } from "../StandardLayout/SpaceLoader";
interface ExposedUserAccountMenuProps {
    currentUser: UserResource;
    spaceContext: SpaceContext;
}
export function UserAccountMenu({ currentUser, spaceContext }: ExposedUserAccountMenuProps) {
    const [openMenu, menuState, buttonAriaAttributes] = useMenuState();
    const userAccountMenuItems = useUserAccountMenuItems(spaceContext);
    return (<div>
            <OpenUserAccountMenuButton currentUser={currentUser} onClick={openMenu} ariaAttributes={buttonAriaAttributes} isMenuOpen={menuState.isOpen}/>
            <SimpleMenu accessibleName={"User account"} items={userAccountMenuItems} menuState={menuState}/>
        </div>);
}
interface OpenUserAccountMenuButtonProps {
    onClick: MouseEventHandler;
    currentUser: UserResource;
    ariaAttributes: MenuTargetAriaAttributes;
    isMenuOpen: boolean;
}
export function OpenUserAccountMenuButton({ onClick, currentUser, ariaAttributes, isMenuOpen }: OpenUserAccountMenuButtonProps) {
    return (<ToolTip content="User account">
            <NavigationBar.IconButton onClick={onClick} accessibleName={isMenuOpen ? "Hide user account menu" : "Show user account menu"} icon={<Avatar avatarLink={currentUser && currentUser.Links && currentUser.Links.Avatar} isService={currentUser && currentUser.IsService} size={32}/>} {...ariaAttributes}/>
        </ToolTip>);
}
function useUserAccountMenuItems(spaceContext: SpaceContext): SimpleMenuItem[] {
    const darkThemeMenuItem = useDarkThemeMenuItem();
    const featureFlags = useFeatureFlags();
    const helpItems = useHelpItems();
    const downloadLink = createDownloadLinkMenuItem();
    const profileLink = createProfileLink(spaceContext);
    const recentChangesMenuItem = useRecentChangesMenuItem();
    return [downloadLink, recentChangesMenuItem, dividerMenuItem, darkThemeMenuItem, ...featureFlags, dividerMenuItem, signOutLink, profileLink, dividerMenuItem, ...helpItems];
}
function createProfileLink(spaceContext: SpaceContext): SimpleMenuItem {
    const meLink = isSpaceNotFound(spaceContext)
        ? routeLinks.withoutSpace().currentUser.me // Redirect back to `/` (no space prefix) to ensure we get redirected to an appropriate space context first
        : routeLinks.currentUser.me;
    return {
        type: "internal-link",
        label: "Profile",
        path: meLink,
        showAsActive: "never",
    };
}
const signOutLink: SimpleMenuItem = { type: "internal-link", label: "Sign Out", path: routeLinks.currentUser.signOut, showAsActive: "never" };
function createDownloadLinkMenuItem(): SimpleMenuItem {
    const version = repository.getServerInformation().version;
    const [major, minor, ...rest] = version.split(".");
    const build = rest.join(".");
    return {
        type: "external-link",
        label: `v${major}.${minor} (Build ${build})`,
        href: `https://octopus.com/downloads/${version}`,
    };
}
function useDarkThemeMenuItem(): SimpleMenuItem {
    const currentPalette = useThemePaletteType();
    const setPalette = useSetThemePaletteType();
    const toggleIsDarkModeEnabled = useCallback((isDarkModeEnabled: boolean) => setPalette(isDarkModeEnabled ? "dark" : "light"), [setPalette]);
    const isDarkModeEnabled = currentPalette === "dark";
    return useMemo(() => ({
        type: "toggle",
        label: "Dark Theme",
        isEnabled: isDarkModeEnabled,
        setIsEnabled: toggleIsDarkModeEnabled,
    }), [isDarkModeEnabled, toggleIsDarkModeEnabled]);
}
function useFeatureFlags(): SimpleMenuItem[] {
    const hiddenFeaturesEnabled = useHiddenFeatures();
    const [isRoutingVNextEnabled, setIsRoutingVNextEnabled] = useIsRoutingVNextFeature();
    const [isPageHeaderVNextEnabled, setIsPageHeaderVNextEnabled] = useIsPageHeaderVNextFeature();
    return useMemo(() => {
        if (!hiddenFeaturesEnabled)
            return [];
        return [
            { type: "toggle", label: "Use Routing vNext", isEnabled: isRoutingVNextEnabled, setIsEnabled: setIsRoutingVNextEnabled },
            { type: "toggle", label: "Use New Page Header", isEnabled: isPageHeaderVNextEnabled, setIsEnabled: setIsPageHeaderVNextEnabled },
        ];
    }, [hiddenFeaturesEnabled, isRoutingVNextEnabled, setIsRoutingVNextEnabled, isPageHeaderVNextEnabled, setIsPageHeaderVNextEnabled]);
}
function useRecentChangesMenuItem(): SimpleMenuItem {
    const host = "http://octopus.com/releasenotes";
    const installationHistory = session.installationHistory;
    const queryParams = new URLSearchParams();
    const installationsToSend = Math.min(4, installationHistory.length);
    installationHistory.slice(0, installationsToSend).forEach((i) => {
        queryParams.append("versions", i.Version);
        queryParams.append("installedAt", i.InstalledAt);
    });
    const externalLink: SimpleMenuItem = { type: "external-link", label: "Release Notes", href: `${host}?${queryParams.toString()}` };
    return useAnalyticsWithMenuExternalLink(externalLink);
}
function useHelpItems(): SimpleMenuItem[] {
    const isHelpSidebarEnabled = useSelector((state: GlobalState) => state.configurationArea.features.isHelpSidebarEnabled);
    const helpLinks = useHelpLinks();
    const isNarrowScreen = useMediaQuery(`(min-width:${drawerBreakpointWidth}px)`);
    return useMemo(() => {
        if (!isHelpSidebarEnabled || !isNarrowScreen) {
            return helpLinks;
        }
        return [
            {
                type: "information",
                content: (<Box flexDirection="column" style={{ maxWidth: "15rem" }}>
                        <h4>Need help?</h4>
                        <CommonNeedHelpInformation />
                    </Box>),
            },
        ];
    }, [helpLinks, isHelpSidebarEnabled, isNarrowScreen]);
}
function useHelpLinks(): SimpleMenuItem[] {
    const helpSidebarSupportLink = useSelector((state: GlobalState) => state.configurationArea.features.helpSidebarSupportLink);
    const links: SimpleMenuItem[] = useMemo(() => {
        return [
            { type: "external-link", label: "Documentation", href: OctoLink.Create("DocumentationHome") },
            { type: "external-link", label: "API Documentation", href: `${window.location.origin + window.location.pathname}/../swaggerui/` },
            { type: "external-link", label: "Support", href: helpSidebarSupportLink ? helpSidebarSupportLink : OctoLink.Create("HelpGeneral") },
            { type: "external-link", label: "Training Videos", href: OctoLink.Create("TrainingVideos") },
            { type: "external-link", label: "Suggestions", href: OctoLink.Create("HelpMakeSuggestion") },
            { type: "external-link", label: "Downloads", href: "https://octopus.com/downloads" },
        ];
    }, [helpSidebarSupportLink]);
    return useExternalLinksWithAnalytics(links);
}
