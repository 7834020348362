import { AnalyticSessionProvider, useAnalyticSession } from "@octopusdeploy/portal-analytics";
import { PageProvider, usePage } from "@octopusdeploy/portal-routes";
import { RegisteredRouteProvider, useRegisteredRoutes } from "@octopusdeploy/routing-infrastructure";
import { OctopusSessionUserProvider, useOctopusSessionUser } from "@octopusdeploy/session";
import * as React from "react";
import { MemoryRouter } from "react-router-dom";
import { Tooltip } from "react-tippy";
import "react-tippy/dist/tippy.css";
import styles from "./style.module.less";
export type ToolTipContent = string | (() => JSX.Element) | (() => string) | JSX.Element;
export type ToolTipPosition = "right" | "left" | "top" | "bottom";
export interface ToolTipProps {
    content?: ToolTipContent;
    children?: React.ReactNode;
    position?: ToolTipPosition;
    open?: boolean;
    trigger?: "mouseenter" | "focus" | "click" | "manual";
    style?: React.CSSProperties;
    distance?: number;
}
function ToolTip({ content, children, position = "top", open, trigger, style, distance }: ToolTipProps) {
    const page = usePage();
    const currentUser = useOctopusSessionUser();
    const analyticsSession = useAnalyticSession();
    const allRegisteredRoutes = useRegisteredRoutes();
    if (typeof content === "function") {
        content = content();
    }
    return (<Tooltip open={open} trigger={trigger} arrow={true} position={position} html={<RegisteredRouteProvider value={allRegisteredRoutes}>
                    <OctopusSessionUserProvider currentUser={currentUser}>
                        <PageProvider initialPage={page}>
                            <MemoryRouter>
                                <AnalyticSessionProvider session={analyticsSession}>
                                    <span className={styles.tooltipContent}>{content}</span>
                                </AnalyticSessionProvider>
                            </MemoryRouter>
                        </PageProvider>
                    </OctopusSessionUserProvider>
                </RegisteredRouteProvider>} size="small" style={{ ...style, display: "inline-block" }} distance={distance}>
            {children}
        </Tooltip>);
}
export default ToolTip;
