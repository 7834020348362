import { ActionButtonType } from "@octopusdeploy/design-system-components";
import * as React from "react";
import { ConfirmationDialogLayout } from "~/components/Dialog/ConfirmationDialog";
import OpenDialogButton from "~/components/Dialog/OpenDialogButton";
import DialogWithHelpPanel from "~/components/DialogWithHelpPanel/DialogWithHelpPanel";
import addProjectImage from "~/components/ProjectBasedActivation/assets/img-addproject.svg";
import StyleGuideEntry from "~/components/StyleGuide/StyleGuideEntry";
import { sleep1s } from "~/components/StyleGuide/helpers/sleep";
const DialogWithHelpPanelEntry = () => (<StyleGuideEntry heading="Dialogs - With Help Panel" context={<div>
                Dialogs with help panel consists of two components: the <code>ContentPanel</code> which shows the content of your dialog and the <code>HelpPanel</code> which contains an image and a text block. The help panel can be
                expanded/collapsed.
            </div>}>
        <DialogWithHelpPanelExample />
    </StyleGuideEntry>);
const DialogWithHelpPanelExample = () => {
    const helpPanelContent = (<span>
            This is the help text to describe what this dialog is about. The above help text image can be replaced by updating the prop <code>helpPanelImage</code>.
        </span>);
    return (<React.Fragment>
            <OpenDialogButton type={ActionButtonType.Secondary} label={"DIALOG WITH HELP PANEL EXAMPLE"} wideDialog={true} renderDialog={(renderProps) => (<DialogWithHelpPanel helpPanelContent={helpPanelContent} helpPanelImage={addProjectImage} open={renderProps.open} close={renderProps.closeDialog}>
                        <ConfirmationDialogLayout title="Main Dialog Content" continueButtonLabel="Continue" continueButtonBusyLabel="Saving..." open={renderProps.open} onClose={renderProps.closeDialog} onContinueClick={() => onContinueClick(renderProps.closeDialog)}>
                            <div>This is where the main dialog content resides. You can integrate any existing dialog layouts in Octopus here.</div>
                            <div>The side help panel can be expanded/collapsed.</div>
                        </ConfirmationDialogLayout>
                    </DialogWithHelpPanel>)}/>
        </React.Fragment>);
};
const onContinueClick = async (close: () => void) => {
    await sleep1s();
    close();
};
export default DialogWithHelpPanelEntry;
