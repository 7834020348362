/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */
import { ListItem } from "@material-ui/core";
import { IconButton } from "@octopusdeploy/design-system-components";
import cn from "classnames";
import * as React from "react";
import MediaQuery from "react-responsive";
import Logo from "~/components/Logo";
import ToolTip from "~/primitiveComponents/dataDisplay/ToolTip";
import type { ToolTipPosition } from "~/primitiveComponents/dataDisplay/ToolTip";
import { ProcessActionErrorIndicator } from "../ErrorIndicators/ProcessActionErrorIndicator";
import { ProcessActionWarningIndicator } from "../ErrorIndicators/ProcessActionWarningIndicator";
import type { CommonProcessListItemProps } from "./ProcessListItem";
import styles from "./ProcessListItemForSidebar.module.less";
type ListItemLinkProps = React.PropsWithChildren<{
    className?: string;
    onClick: () => void;
}>;
const ListItemLinkInternal: React.FC<ListItemLinkProps> = ({ onClick, ...rest }) => {
    const clickHandler = (ev: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        ev.stopPropagation();
        ev.preventDefault();
        onClick();
    };
    return <ListItem button component="a" disableGutters={true} style={{ paddingTop: 0, paddingBottom: 0 }} {...rest} onClick={clickHandler}/>;
};
ListItemLinkInternal.displayName = "ListItemLinkInternal"
const ListItemLink = React.memo(ListItemLinkInternal);
interface ProcessListItemForSidebarProps extends CommonProcessListItemProps {
    className?: string;
    isCurrentAction: boolean;
    onShowContextMenu?: React.MouseEventHandler;
    onClick: () => void;
}
export const toolTipBreakpoint = "1279px";
const renderLogoOrIcon = (actionType: string | JSX.Element, logoUrl: string | undefined, isDisabled: boolean, icon: JSX.Element | undefined, toolTipPosition: ToolTipPosition | undefined) => {
    return (<ToolTip content={actionType} position={toolTipPosition}>
            <div className={styles.stepLogo}>{logoUrl ? <Logo url={logoUrl} isDisabled={isDisabled} size={"2rem"} className={styles.logo}/> : icon}</div>
        </ToolTip>);
};
const ProcessListItemForSidebar: React.FC<ProcessListItemForSidebarProps> = ({ name, index, isCurrentAction, isDisabled, isParentGroup, isRunInParallelWithLast, actionErrors, actionWarnings, actionType, logoUrl, icon, onShowContextMenu: onContextMenu, onClick, }) => {
    return (<StepLink isDisabled={isDisabled} isCurrentAction={isCurrentAction} isParentGroup={isParentGroup} onClick={onClick}>
            {isRunInParallelWithLast && (<MediaQuery minWidth={toolTipBreakpoint}>
                    {(matches: boolean) => {
                const toolTipPosition = matches ? "left" : undefined;
                return (<div className={styles.stepWrapper}>
                                <ToolTip content="Runs in parallel" position={toolTipPosition}>
                                    <div className={styles.parallelContainer}>
                                        <div className={styles.parallelIcon}>
                                            <div className={styles.content}>&nbsp;</div>
                                        </div>
                                    </div>
                                </ToolTip>
                                {renderLogoOrIcon(actionType, logoUrl, isDisabled, icon, toolTipPosition)}
                            </div>);
            }}
                </MediaQuery>)}
            {!isRunInParallelWithLast && (<MediaQuery minWidth={toolTipBreakpoint}>
                    {(matches: boolean) => {
                const toolTipPosition = matches ? "left" : undefined;
                return renderLogoOrIcon(actionType, logoUrl, isDisabled, icon, toolTipPosition);
            }}
                </MediaQuery>)}
            {withDisabledTooltipWrapper(<div className={styles.stepContainer}>
                    <div className={styles.stepNumber}>{index}</div>
                    <div className={styles.stepName}>{name}</div>
                </div>, isDisabled)}
            {actionErrors.length > 0 && <ProcessActionErrorIndicator actionErrors={actionErrors}/>}
            {actionWarnings.length > 0 && <ProcessActionWarningIndicator actionWarnings={actionWarnings}/>}
            <IconButton className={styles.overflowButton} icon="OverflowMenu" onClick={onContextMenu}/>
        </StepLink>);
};
ProcessListItemForSidebar.displayName = "ProcessListItemForSidebar"
function withDisabledTooltipWrapper(children: React.ReactNode, isDisabled: boolean) {
    if (isDisabled) {
        return (<ToolTip content="Disabled" style={{ width: "100%" }}>
                {children}
            </ToolTip>);
    }
    return children;
}
type StepLinkProps = React.PropsWithChildren<{
    isDisabled: boolean;
    isCurrentAction: boolean;
    isParentGroup: boolean;
    onClick: () => void;
}>;
const StepLinkInternal: React.FC<StepLinkProps> = ({ children, isDisabled, isCurrentAction, isParentGroup, onClick }) => {
    return (<ListItemLink className={cn(styles.listItem, isCurrentAction ? styles.isActive : null, isDisabled ? styles.isDisabled : null, isParentGroup ? styles.isParentGroup : null)} onClick={onClick}>
            <div className={styles.listItemLine}/>
            {children}
        </ListItemLink>);
};
StepLinkInternal.displayName = "StepLinkInternal"
const StepLink = React.memo(StepLinkInternal);
export default React.memo(ProcessListItemForSidebar);
