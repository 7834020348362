import { css, cx } from "@emotion/css";
// eslint-disable-next-line @octopusdeploy/custom-portal-rules/no-restricted-imports
import { Collapse } from "@material-ui/core";
import { Button } from "@octopusdeploy/design-system-components";
import { borderWidth, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import { noOp } from "@octopusdeploy/utilities";
import React, { useState } from "react";
import URI from "urijs";
import { Action, useAnalyticActionDispatch } from "~/analytics/Analytics";
import type { TenantsFilterState } from "~/areas/tenants/Tenants/hooks/useTenantsFilterState";
import TenantFilters from "~/areas/tenants/components/Filtering/TenantFilters";
import type { TenantFiltersData } from "~/areas/tenants/components/Filtering/hooks/useTenantFiltersData";
import CurrentFilterSelection from "~/areas/tenants/components/Toolbar/CurrentFilterSelection/CurrentFilterSelection";
import CopyToClipboardButton from "~/components/CopyToClipboardButton/index";
import OpenDialogIconButton from "~/components/Dialog/OpenDialogIconButton";
import InfoDialogLayout from "~/components/DialogLayout/InfoDialogLayout";
import { IconButtonWithTooltip } from "~/components/IconButtonWithTooltip/index";
import { Text } from "~/components/form/index";
import { ThirdPartyIcon, ThirdPartyIconType } from "~/primitiveComponents/dataDisplay/Icon/index";
interface ToolbarProps {
    filteredCount: number | null;
    totalCount: number;
    onFilterChanged: (newFilter: TenantsFilterState) => void;
    onCsvDownloadRequested: () => Promise<void>;
    tenantFiltersData: TenantFiltersData;
}
export default function Toolbar({ filteredCount, totalCount, onFilterChanged, onCsvDownloadRequested, tenantFiltersData }: ToolbarProps) {
    const dispatchAction = useAnalyticActionDispatch();
    const [expanded, setExpanded] = useState(false);
    const handleOnExpandCollapse = () => {
        setExpanded(!expanded);
        dispatchAction("Toggle Filters", { resource: "Filters", action: Action.View });
    };
    return (<div className={styles.toolbar} aria-label="current-filter-selection">
            <ResultsCount filteredCount={filteredCount} totalCount={totalCount}/>
            <CurrentFilterSelection data={tenantFiltersData} onExpandCollapse={handleOnExpandCollapse}/>
            <ToolbarActions onCsvDownloadRequested={onCsvDownloadRequested}/>
            <ExpandCollapseToggle expanded={expanded} onExpandCollapse={handleOnExpandCollapse}/>
            <Collapse className={styles.filters} in={expanded} timeout="auto" unmountOnExit={true}>
                <TenantFilters onFilterChange={onFilterChanged} data={tenantFiltersData}/>
            </Collapse>
        </div>);
}
function ToolbarActions({ onCsvDownloadRequested }: {
    onCsvDownloadRequested: () => Promise<void>;
}) {
    return (<div className={cx(styles.separator, styles.toolbarIconsContainer)}>
            <ShareButton />
            <DownloadCsvButton onCsvDownloadRequested={onCsvDownloadRequested}/>
        </div>);
}
function ShareButton() {
    return (<div className={styles.shareIcon}>
            <OpenDialogIconButton icon={"Share"} toolTipContent={"Share filter"}>
                <ShareDialogLayout />
            </OpenDialogIconButton>
        </div>);
}
function ShareDialogLayout() {
    const currentUri = URI(window.location).valueOf();
    return (<InfoDialogLayout title={"Share filter"}>
            All users who have view permission to this page can view this filter.
            <div className={styles.shareContainer}>
                <Text value={currentUri} onChange={noOp} readOnly={true}/>
                <CopyToClipboardButton value={currentUri}/>
            </div>
        </InfoDialogLayout>);
}
function DownloadCsvButton({ onCsvDownloadRequested }: {
    onCsvDownloadRequested: () => Promise<void>;
}) {
    return <IconButtonWithTooltip icon={"SaveAlt"} onClick={onCsvDownloadRequested} toolTipContent={"Download CSV"}/>;
}
interface ResultCountProps {
    filteredCount: number | null;
    totalCount: number;
}
function ResultsCount({ filteredCount, totalCount }: ResultCountProps) {
    const resultWording = totalCount === 1 ? "result" : "results";
    const isFiltered = filteredCount !== null;
    return isFiltered ? (<div className={cx(styles.filteredResult, styles.separator)}>
            Showing {filteredCount} of {totalCount}
        </div>) : (<div className={cx(styles.result, styles.separator)}>
            {totalCount} {resultWording}
        </div>);
}
interface ExpandCollapseToggleProps {
    expanded: boolean;
    onExpandCollapse: () => void;
}
function ExpandCollapseToggle({ expanded, onExpandCollapse }: ExpandCollapseToggleProps) {
    const icon = expanded ? <ThirdPartyIcon iconType={ThirdPartyIconType.Collapse}/> : <ThirdPartyIcon iconType={ThirdPartyIconType.Expand}/>;
    return (<div className={styles.expandCollapseToggle}>
            <Button label={expanded ? "Collapse filters" : "Expand filters"} onClick={onExpandCollapse} importance="quiet" rightIcon={icon}/>
        </div>);
}
const borderColor = `${borderWidth[1]} solid ${themeTokens.color.border.primary}`;
const styles = {
    toolbar: css({
        flexGrow: 1,
        display: "grid",
        gridTemplateColumns: "auto 1fr auto auto",
        alignItems: "center",
        padding: "0.6rem 1rem",
        marginTop: 0,
        marginBottom: "1rem",
        backgroundColor: themeTokens.color.background.primary.default,
        color: themeTokens.color.text.muted,
        border: borderColor,
    }),
    filters: css({
        padding: "0 1rem 0.6rem 1rem",
        gridColumn: "2 / span 2",
    }),
    result: css({
        font: text.interface.heading.medium,
        color: themeTokens.color.text.primary,
    }),
    filteredResult: css({
        font: text.interface.body.bold.base,
        color: themeTokens.color.text.primary,
    }),
    separator: css({
        position: "relative",
        paddingRight: "1rem",
        ":after": {
            content: "''",
            position: "absolute",
            height: "60%",
            right: 0,
            top: "20%",
            borderRight: borderColor,
        },
    }),
    expandCollapseToggle: css({
        paddingLeft: "1rem",
    }),
    toolbarIconsContainer: css({
        display: "inline-flex",
        alignItems: "center",
    }),
    shareIcon: css({
        paddingRight: "1rem",
    }),
    shareContainer: css({
        display: "inline-flex",
        alignItems: "center",
        width: "100%",
    }),
};
