/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */
import { DialogContent, DialogActions, DialogTitle } from "@material-ui/core";
import { LinearProgress } from "@octopusdeploy/design-system-components";
import cn from "classnames";
import * as React from "react";
import type { BusyState } from "~/components/BusyFromPromise/BusyFromPromise";
import BusyFromPromise from "~/components/BusyFromPromise/BusyFromPromise";
import type { Errors } from "~/components/DataBaseComponent/Errors";
import ErrorPanel from "~/components/ErrorPanel/ErrorPanel";
import KeyboardHandler, { Key } from "../KeyboardHandler/KeyboardHandler";
import styles from "./style.module.less";
export interface DialogLayoutDispatchProps {
    close: () => void;
}
export interface DialogLayoutCommonProps {
    title: string;
    titleIcon?: React.ReactNode;
    extraHeaderContent?: (alignmentClassName: string) => React.ReactNode;
    additionalActions?: React.ReactNode;
    secondaryActions?: React.ReactNode;
    errors?: Errors | null;
    busy?: BusyState | null;
    headerClassName?: string;
}
interface DialogLayoutProps extends DialogLayoutCommonProps {
    actions: React.ReactNode;
    closeDialog(): void;
}
interface DialogLayoutState {
    dialogResizeKey: string;
}
export class DialogLayout extends React.Component<DialogLayoutProps, DialogLayoutState> {
    constructor(props: DialogLayoutProps) {
        super(props);
        this.state = {
            dialogResizeKey: new Date().getDate().toString(),
        };
    }
    render() {
        const keyboardRegistrations = [{ key: Key.Esc, onKeyPressed: this.onEscKeyPressed }];
        return (<KeyboardHandler key={this.state.dialogResizeKey} registrations={keyboardRegistrations} className={styles.dialogLayout}>
                <DialogTitle className={cn(this.props.headerClassName ? this.props.headerClassName : styles.headerContainer)}>
                    <BusyFromPromise promise={this.props.busy}>{(busy: boolean) => <LinearProgress variant={"indeterminate"} show={busy}/>}</BusyFromPromise>
                    <div className={styles.title}>
                        {this.props.titleIcon && <div className={styles.titleIcon}>{this.props.titleIcon}</div>}
                        {this.props.title}
                    </div>
                </DialogTitle>
                <DialogContent className={styles.body}>
                    {this.props.extraHeaderContent && this.props.extraHeaderContent(styles.headerContentAlignment)}
                    {this.props.errors && <ErrorPanel message={this.props.errors.message} errors={this.props.errors.errors} scrollToPanel={false}/>}
                    {this.props.children}
                </DialogContent>
                <DialogActions className={styles.actions}>
                    <div key="additional" className={styles.secondaryActions}>
                        {this.props.additionalActions}
                    </div>
                    <div key="primary" className={styles.primaryActions}>
                        {this.props.actions}
                    </div>
                </DialogActions>
            </KeyboardHandler>);
    }
    onEscKeyPressed = () => {
        if (this.props.closeDialog) {
            this.props.closeDialog();
            return true;
        }
        return false;
    };
    static displayName = "DialogLayout";
}
