/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { ActionButton, ActionButtonType, Checkbox } from "@octopusdeploy/design-system-components";
import { logger } from "@octopusdeploy/logging";
import type { ActionProperties, ActionTemplateResource, ActionTemplateSearchResource, DeploymentActionResource, FeedResource, GitDependencyReference, GitRef, PackageReference, StepPackageInputs, VariableSetResource, } from "@octopusdeploy/octopus-server-client";
import { HasManualInterventionResponsibleTeams, HasVariablesInGit, PackageRequirement, ProcessType, RunConditionForAction, TenantedDeploymentMode, VariableType } from "@octopusdeploy/octopus-server-client";
import type { ObjectResourceInputs } from "@octopusdeploy/step-runtime-inputs";
import { asResourceInputs, asRuntimeInputs, convertFromJsonSchemaToInputSchema } from "@octopusdeploy/step-runtime-inputs";
import { generateSlug } from "@octopusdeploy/utilities";
import { difference, intersection, isEqual, keyBy, memoize, uniq } from "lodash";
import * as React from "react";
import { useSelector } from "react-redux";
import type { StepEditorEvent } from "~/analytics/Analytics";
import { Action, useAnalyticsStepEditorDispatch } from "~/analytics/Analytics";
import { ContainersFeedbackCallout } from "~/areas/ContainersFeedbackCallout";
import { ExportConfigurationDialog } from "~/areas/projects/components/Process/ExportConfigurationDialog/ExportConfigurationDialog";
import { StepPackageVersionBanner } from "~/areas/projects/components/Process/StepPackageVersioning";
import { ChangeVersionDialog } from "~/areas/projects/components/Process/StepPackageVersioning/ChangeVersionDialog";
import { StepPackageInfoDialog } from "~/areas/projects/components/Process/StepPackageVersioning/StepPackageInfoDialog";
import type { CommitMessageWithDetails } from "~/areas/projects/components/VersionControl/CommitMessageWithDetails";
import { getFormattedCommitMessage } from "~/areas/projects/components/VersionControl/CommitMessageWithDetails";
import { repository } from "~/clientInstance";
import ActionList from "~/components/ActionList";
import { ProjectActionPropertiesEditor } from "~/components/ActionPropertiesEditor/ProjectActionPropertiesEditor";
import ActionTemplateEditor from "~/components/ActionTemplateEditor/ActionTemplateEditor";
import pluginRegistry from "~/components/Actions/pluginRegistry";
import type { ActionEditProps, AdditionalActions } from "~/components/Actions/pluginRegistry";
import { ScriptActionContext } from "~/components/Actions/script/ScriptActionContext";
import BaseComponent from "~/components/BaseComponent";
import ContextualMissingChip, { ChannelChip, ChipIcon } from "~/components/Chips";
import type { DoBusyTask } from "~/components/DataBaseComponent";
import type { FieldErrors } from "~/components/DataBaseComponent/Errors";
import { ExpandableContainer } from "~/components/Expandable";
import { useExpandAllExpanders } from "~/components/Expandable/useExpandAllExpanders";
import { useExpandExpanders } from "~/components/Expandable/useExpandExpanders";
import FeatureEditor from "~/components/FeatureEditor/FeatureEditor";
import { Feature } from "~/components/FeatureToggle";
import FeatureToggle from "~/components/FeatureToggle/FeatureToggle";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import Logo from "~/components/Logo";
import Lookup from "~/components/Lookup";
import Markdown from "~/components/Markdown";
import { ChannelMultiSelect } from "~/components/MultiSelect/ChannelMultiSelect";
import ExternalLink from "~/components/Navigation/ExternalLink/index";
import OpenFeatureDialog from "~/components/OpenFeatureDialog/OpenFeatureDialog";
import type { MenuItem } from "~/components/OverflowMenu/OverflowMenu";
import { OverflowMenu, OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import PaperLayout from "~/components/PaperLayout";
import { noteToElement } from "~/components/StepPackageEditor/Inputs/Note/Note";
import { mapRootInitialInputs } from "~/components/StepPackageEditor/Inputs/mapInitialInputs";
import { ExpandableFormSection, ExpansionButtons, FormSectionHeading, Note, required, Summary, UnstructuredFormSection } from "~/components/form";
import MarkdownEditor from "~/components/form/MarkdownEditor/MarkdownEditor";
import { Callout, CalloutType } from "~/primitiveComponents/dataDisplay/Callout";
import { ThirdPartyIcon, ThirdPartyIconType } from "~/primitiveComponents/dataDisplay/Icon";
import ToolTip from "~/primitiveComponents/dataDisplay/ToolTip";
import NumberPicker from "~/primitiveComponents/form/Number/NumberPicker";
import NameSummaryWithSlug from "~/primitiveComponents/form/Slugs/NameSummaryWithSlug";
import SlugEditor from "~/primitiveComponents/form/Slugs/SlugEditor";
import { DebounceText } from "~/primitiveComponents/form/Text/Text";
import { stepPackageResolver } from "~/stepPackages/stepPackageResolver";
import ParseHelper from "~/utils/ParseHelper/ParseHelper";
import type { WithProjectContextInjectedProps } from "../../context";
import { useProjectContext } from "../../context";
import { CloudConnections, CloudConnectionUsageType } from "../CloudConnection/CloudConnections";
import Environments from "../Process/Common/Environments";
import ExecutionPlan from "../Process/Common/ExecutionPlan";
import PackageRequirementExpander from "../Process/Common/PackageRequirementExpander";
import RunTriggerExpander from "../Process/Common/RunTriggerExpander";
import RunTriggerForChildActionExpander from "../Process/Common/RunTriggerForChildActionExpander";
import StartTriggerExpander from "../Process/Common/StartTriggerExpander";
import StepName from "../Process/Common/StepName";
import TenantsExpander from "../Process/Common/TenantsExpander";
import type { WithOptionalRunbookContextInjectedProps } from "../Runbooks/RunbookContext";
import { useOptionalRunbookContext } from "../Runbooks/RunbookContext";
import getActionLogoUrl from "../getActionLogoUrl";
import { deleteActionAndRedirect, getDeleteProcessMenuItem, isRunOnServerOrWorkerPool, isVersionControlledProcess, processScopedEditPermission, runsOnServer, whereConfiguredToRun } from "./Common/CommonProcessHelpers";
import { ConflictConfirmationDialog } from "./Common/ConflictConfirmationDialog";
import { ErrorsForAction } from "./Common/ErrorsForAction";
import { WarningsForAction } from "./Common/WarningsForAction";
import { useActionTemplatesFromContext } from "./Contexts/ProcessActionTemplatesContextProvider";
import type { WithProcessContextInjectedProps } from "./Contexts/ProcessContext";
import { useProcessContext } from "./Contexts/ProcessContext";
import type { ProcessStateSelectors } from "./Contexts/ProcessContextState";
import type { WithProcessErrorSelectorContextInjectedProps } from "./Contexts/ProcessErrors/ProcessErrorsContext";
import { useProcessErrorSelectors } from "./Contexts/ProcessErrors/ProcessErrorsContext";
import { useFeedsFromContext, useRefreshFeedsFromContext } from "./Contexts/ProcessFeedsContextProvider";
import type { WithProcessQueryStringContextInjectedProps } from "./Contexts/ProcessQueryString/ProcessQueryStringContext";
import { useProcessQueryStringContext } from "./Contexts/ProcessQueryString/ProcessQueryStringContext";
import type { WithProcessWarningSelectorContextInjectedProps } from "./Contexts/ProcessWarnings/ProcessWarningsContext";
import { useProcessWarningSelectors } from "./Contexts/ProcessWarnings/ProcessWarningsContext";
import type { ProcessStepActionData } from "./ProcessStepsLayout";
import { getProjectVariables } from "./ProcessStepsLayout";
import type { FailedPermissionCheck, ProcessStepActionState } from "./ProcessStepsLayoutTypes";
import { isFailedPermissionCheck } from "./ProcessStepsLayoutTypes";
import { generateGuid } from "./generation";
import styles from "./style.module.less";
import type { RunOn, RunOnServerOrWorkerPool, StoredAction, StoredStep } from "./types";
import { EnvironmentOption, ExecutionLocation } from "./types";
//TODO: @Cleanup - We want to remove the state from this component if possible. Functional components would make this easier to reason about.
interface ProcessActionDetailsState {
    runOnServerOrWorkerPoolCopy: RunOnServerOrWorkerPool | null;
    runOn: RunOn;
    environmentOption: EnvironmentOption;
    stepVersionChangeDialogIsOpen: boolean;
    stepPackageInfoDialogIsOpen: boolean;
    stepVersionChangeError?: string;
    showNotes: boolean;
    maximumRetries: number;
    actionExecutionTimeOut: number;
}
export type ProcessActionDetailsProps = {
    processType: ProcessType;
    isNew: boolean;
    actionType?: string;
    parentStepId?: string;
    reloadKey?: string;
    errors: FieldErrors | undefined;
    doBusyTask: DoBusyTask;
    busy?: Promise<void>;
    step: StoredStep;
    action: StoredAction;
    cleanAction: StoredAction | null;
    setCurrentActionName: (name: string) => void;
    setCurrentStepName: (name: string) => void;
    refreshStepLookups: () => void;
} & ProcessStepActionData;
interface GlobalConnectedProps {
    isBuiltInWorkerEnabled: boolean;
}
type Props = ProcessActionDetailsProps & WithProjectContextInjectedProps & WithProcessContextInjectedProps & WithOptionalRunbookContextInjectedProps & WithProcessQueryStringContextInjectedProps & WithProcessErrorSelectorContextInjectedProps & WithProcessWarningSelectorContextInjectedProps & GlobalConnectedProps & {
    feeds: FeedResource[];
    actionTemplates: ActionTemplateSearchResource[];
    refreshFeeds: () => Promise<unknown>;
    analyticsStepEditorDispatch: (name: string, event: StepEditorEvent) => void;
};
const AutoExpandActionErrors: React.FC<{
    actionId: string;
}> = (props) => {
    const expandExpanders = useExpandExpanders();
    const selectors = useProcessErrorSelectors();
    const processContext = useProcessContext();
    const errors = Object.keys(selectors.getActionFieldErrors(props.actionId, processContext.selectors));
    React.useEffect(() => {
        expandExpanders(errors);
    }, [errors, expandExpanders]);
    return null;
};
AutoExpandActionErrors.displayName = "AutoExpandActionErrors"
const AutoExpandActionWarnings: React.FC<{
    actionId: string;
}> = (props) => {
    const expandExpanders = useExpandExpanders();
    const selectors = useProcessWarningSelectors();
    const processContext = useProcessContext();
    const warnings = Object.keys(selectors.getActionFieldWarnings(props.actionId, processContext.selectors));
    React.useEffect(() => {
        expandExpanders(warnings);
    }, [expandExpanders, warnings]);
    return null;
};
AutoExpandActionWarnings.displayName = "AutoExpandActionWarnings"
const AutoExpandAllExpanders: React.FC = () => {
    const expandAll = useExpandAllExpanders();
    React.useEffect(() => expandAll(true), [expandAll]);
    return null;
};
AutoExpandAllExpanders.displayName = "AutoExpandAllExpanders"
export interface EnvironmentSelection {
    unavailable: string[];
    unavailableExclusive: string[];
    inclusive: string[];
    exclusive: string[];
}
type NoActionTemplatePermissionsProps = {
    action: StoredAction;
    stepActionNumber: string;
    stepOther: ProcessStepActionState;
    actions: (JSX.Element | undefined)[];
    message: string;
};
function FailedPermissionCheckLayout({ action, stepActionNumber, stepOther, actions, message }: NoActionTemplatePermissionsProps) {
    return (<ProcessActionLayout title={<StepName name={action.Name} number={stepActionNumber} stepType={stepOther.actionTypeName}/>} titleLogo={action && <Logo url={getActionLogoUrl(action)}/>} sectionControl={<ActionList actions={actions}/>}>
            <Callout title={message} type={CalloutType.Danger}/>
        </ProcessActionLayout>);
}
type ProcessActionLayoutProps = {
    title: JSX.Element;
    titleLogo: JSX.Element | undefined;
    sectionControl: JSX.Element;
    children: JSX.Element;
};
function ProcessActionLayout({ title, titleLogo, sectionControl, children }: ProcessActionLayoutProps) {
    return (<PaperLayout title={title} titleLogo={titleLogo} busy={undefined} // Handled by parent.
     errors={undefined} // Handled by parent.
     sectionControl={sectionControl} disableHeaderAnimations={true} // Disabling due to the way the ProcessStepDetailsLoader and this component work together.
     fullWidth={true} flatStyle={true} hideHelpIcon={true} disableScrollToActiveError={true} // We have custom error handling for our process form.
     disableStickyHeader={true} // We have a custom layout above this which is already sticky.
    >
            {children}
        </PaperLayout>);
}
class ProcessActionDetailsInternal extends BaseComponent<Props, ProcessActionDetailsState> {
    private get isNew(): boolean {
        return this.props.isNew;
    }
    private get environmentSelection(): EnvironmentSelection {
        const environmentsByKey = keyBy(this.props.stepLookups.environments, "Id");
        const action = this.props.action;
        const knownEnvironments = Object.keys(environmentsByKey);
        const unavailableEnvironments = difference(action.Environments || [], knownEnvironments);
        const unavailableExcludedEnvironments = difference(action.ExcludedEnvironments || [], knownEnvironments);
        const inclusiveEnvironments = intersection(action.Environments || [], knownEnvironments);
        const exclusiveEnvironments = intersection(action.ExcludedEnvironments || [], knownEnvironments);
        return {
            unavailable: unavailableEnvironments,
            unavailableExclusive: unavailableExcludedEnvironments,
            inclusive: inclusiveEnvironments,
            exclusive: exclusiveEnvironments,
        };
    }
    private get hasHiddenEnvironments(): boolean {
        const selection = this.environmentSelection;
        return selection.unavailableExclusive.length > 0 || selection.unavailable.length > 0;
    }
    //Memoize does not take all arguments into account for the cache key.
    private memoizeLoadVariables = memoize((projectId: string, runbookId?: string, gitRef?: GitRef) => {
        return runbookId ? repository.Variables.getNamesForRunbookProcess(this.props.projectContext.state.model.Id, runbookId) : repository.Variables.getNamesForDeploymentProcess(projectId, null, gitRef);
    }, (...args) => args.join("_"));
    constructor(props: Props) {
        super(props);
        this.state = this.getStateUpdate();
    }
    getDefaultRunOn(action: StoredAction): RunOn {
        return whereConfiguredToRun(!!this.props.step.Properties["Octopus.Action.TargetRoles"], this.props.action, this.props.stepLookups.availableWorkerPools, this.props.action.plugin);
    }
    getStateUpdate(): ProcessActionDetailsState {
        const props = this.props;
        const runOn = props.stepOther.runOn ?? this.getDefaultRunOn(this.props.action);
        const action = props.action;
        return {
            runOnServerOrWorkerPoolCopy: isRunOnServerOrWorkerPool(runOn) ? runOn : null,
            runOn,
            environmentOption: (action.Environments || []).length > 0 ? EnvironmentOption.Include : (action.ExcludedEnvironments || []).length > 0 ? EnvironmentOption.Exclude : EnvironmentOption.All,
            stepVersionChangeDialogIsOpen: false,
            stepPackageInfoDialogIsOpen: false,
            stepVersionChangeError: undefined,
            showNotes: !!action.Notes,
            maximumRetries: Number(action.Properties["Octopus.Action.AutoRetry.MaximumCount"]),
            actionExecutionTimeOut: Number(action.Properties["Octopus.Action.ExecutionTimeout.Minutes"] ?? 0),
        };
    }
    componentDidMount() {
        const resource = !this.props.processType ? "Step Editor" : this.props.processType === ProcessType.Runbook ? "Runbook" : "Deployment Process";
        const ev: StepEditorEvent = {
            action: Action.Edit,
            stepTemplate: this.getStepTemplateName(),
            resource: resource,
        };
        this.props.analyticsStepEditorDispatch("Edit Step", ev);
    }
    componentDidUpdate(prevProps: Props) {
        if (!isEqual(prevProps.stepOther, this.props.stepOther) || prevProps.action.Id !== this.props.action.Id) {
            this.setState(this.getStateUpdate());
        }
        // We need to keep these properties in sync for routing purposes.
        if (!isEqual(prevProps.action.Name, this.props.action.Name)) {
            this.props.setCurrentActionName(this.props.action.Name);
        }
        if (!isEqual(prevProps.step.Name, this.props.step.Name)) {
            this.props.setCurrentStepName(this.props.step.Name);
        }
    }
    getStepTemplateName(): string {
        if (this.isStepTemplate(this.props.stepLookups.actionTemplate)) {
            return this.props.stepLookups.actionTemplate.Name;
        }
        else if (this.props.action.StepPackageVersion) {
            return this.props.action.plugin.stepPackage?.name ?? this.props.action.ActionType;
        }
        return this.props.actionTemplates.find((x) => x.Type === this.props.action.ActionType)?.Name ?? "";
    }
    isStepTemplate(stepTemplate: ActionTemplateResource | null | FailedPermissionCheck): stepTemplate is ActionTemplateResource {
        return stepTemplate !== null && !(stepTemplate as FailedPermissionCheck).type;
    }
    refreshRunOn() {
        const runOn = whereConfiguredToRun(!!this.props.step.Properties["Octopus.Action.TargetRoles"], this.props.action, this.props.stepLookups.availableWorkerPools, this.props.action.plugin);
        this.setState({ runOn }, () => {
            this.setActionProperties({ ["Octopus.Action.RunOnServer"]: runOn.executionLocation === ExecutionLocation.DeploymentTarget ? "false" : "true" });
        });
    }
    isChildAction = () => {
        return this.props.processContext.selectors.isChildAction(this.props.action.Id);
    };
    doBusyForChildren = (action: () => Promise<void>): Promise<boolean> => {
        // don't clear errors on child tasks since they should just
        // be loading and we don't want to clear a Save error
        // just because we load some lookup data
        return this.props.doBusyTask(action, { preserveCurrentErrors: true });
    };
    loadVariablesKey = () => {
        const runbookId = this.props.runbookContext?.state.runbook?.Id;
        const projectId = this.props.projectContext.state.model.Id;
        const gitRef = this.props.projectContext.state.gitRef?.CanonicalName;
        return [runbookId, projectId, gitRef].join("_");
    };
    loadVariables = () => {
        const runbookId = this.props.runbookContext?.state.runbook?.Id;
        const projectId = this.props.projectContext.state.model.Id;
        const gitRef = this.props.projectContext.state.gitRef?.CanonicalName;
        return this.memoizeLoadVariables(projectId, runbookId, gitRef);
    };
    render() {
        const { step, action, cleanAction, processType, stepLookups, stepOther, projectContext, processContext, processQueryStringContext, busy, errors } = this.props;
        const { actions: contextActions, selectors: contextSelectors } = processContext;
        const { actions: queryStringActions } = processQueryStringContext;
        const addFeaturesElement = processContext.selectors.actionHasFeatures(action.Id) && !stepLookups.actionTemplate ? (<OpenFeatureDialog actionType={action.ActionType} properties={action.Properties} saveDone={(x) => this.setActionProperties({ ["Octopus.Action.EnabledFeatures"]: x })}/>) : undefined;
        const actionEditorAdditionalActions: AdditionalActions = {
            packageAcquisition: {
                stepPackageRequirement: step.PackageRequirement,
                onStepPackageRequirementChanged: (x: PackageRequirement) => {
                    this.setStepMetaProperties({ PackageRequirement: x });
                },
            },
            stepTargetRoles: step.Properties["Octopus.Action.TargetRoles"] as string,
            actionType: action.plugin.actionType,
        };
        const hasManualInterventionResponsibleTeams = action && HasManualInterventionResponsibleTeams(action);
        const processEditPermission = { permission: processScopedEditPermission(processType), project: projectContext.state.model.Id, wildcard: true };
        const menuActions: Array<MenuItem | MenuItem[]> = [];
        const stepVersionMenuActions: Array<MenuItem> = [];
        if (action.StepPackageVersion) {
            stepVersionMenuActions.push(OverflowMenuItems.item("About this step", async () => this.handleShowStepPackageDetails()));
        }
        if (action.AvailableStepPackageVersions && action.AvailableStepPackageVersions.length > 1) {
            stepVersionMenuActions.push(OverflowMenuItems.item("Choose version", async () => this.handleChangeStepVersion()));
        }
        menuActions.push(stepVersionMenuActions);
        if (step && step.Id) {
            // doesn't make sense to allow enable/disable/delete if the step hasn't been saved
            // it will also cause havoc - eg delete will delete *another* step.
            if (action) {
                menuActions.push(OverflowMenuItems.item(action.IsDisabled ? "Enable" : "Disable", () => this.handleEnabledToggle(), processEditPermission));
            }
            if (action) {
                menuActions.push(getDeleteProcessMenuItem("step", () => deleteActionAndRedirect(step, action, true, contextActions, contextSelectors, queryStringActions), processEditPermission, projectContext.state.model, step, action));
            }
            else {
                menuActions.push(getDeleteProcessMenuItem("parent step", () => deleteActionAndRedirect(step, action, true, contextActions, contextSelectors, queryStringActions), processEditPermission, projectContext.state.model, step, action));
            }
            if (stepLookups.actionTemplate) {
                menuActions.push(OverflowMenuItems.item("Detach Step Template", async () => this.handleDetachStepTemplate()));
            }
            else {
                menuActions.push(OverflowMenuItems.item("Extract Step Template", async () => this.handleCreateStepTemplate()));
            }
            if (this.props.action.plugin.stepPackage && this.props.action.plugin.stepPackage.stepUI.exportConfiguration) {
                const exportConfiguration = this.props.action.plugin.stepPackage.stepUI.exportConfiguration;
                exportConfiguration.map((config) => {
                    menuActions.push(OverflowMenuItems.dialogItem(config.name, <ExportConfigurationDialog name={config.name} description={config.description} inputSchema={this.props.action.plugin.stepPackage?.inputSchema} generateExports={config.export} inputs={action.Inputs}/>));
                });
            }
        }
        const actions = [];
        actions.push(addFeaturesElement);
        actions.push(<OverflowMenu menuItems={menuActions} accessibleName="More Step Actions"/>);
        const isChildAction = contextSelectors.isChildAction(action.Id);
        const stepActionNumber = isChildAction ? `${contextSelectors.getStepNumber(step.Id)}.${contextSelectors.getActionNumber(action.Id)}` : `${contextSelectors.getStepNumber(step.Id)}`;
        const actionErrors = action ? this.props.processErrorSelectors.getActionErrors(action.Id, processContext.selectors) : [];
        const actionWarnings = action ? this.props.processWarningSelectors.getActionWarnings(action.Id, processContext.selectors) : [];
        if (stepLookups.actionTemplate && isFailedPermissionCheck(stepLookups.actionTemplate)) {
            return <FailedPermissionCheckLayout action={action} stepActionNumber={stepActionNumber} stepOther={stepOther} actions={actions} message={"You currently don't have permission to view this action template"}/>;
        }
        const showNotesControlsToggle = this.state.showNotes ? (<MarkdownEditor label="Notes" value={action.Notes || undefined} onChange={(notes) => this.setActionMetaProperties({ Notes: notes })}/>) : (<ActionButton label="Add Notes" type={ActionButtonType.Ternary} onClick={(e) => {
                e.preventDefault();
                this.setState({ showNotes: true });
            }}/>);
        const nameSummary = (stepName: string, stepSlug: string | null, notes: string | null) => (<div className={styles.nameSummaryContainer}>
                <NameSummaryWithSlug name={stepName} slug={stepSlug}/>
                {!!notes && (<ToolTip content={<Markdown markup={notes}/>} position="right">
                        <ThirdPartyIcon iconType={ThirdPartyIconType.Note} className={styles.icon}/>
                    </ToolTip>)}
            </div>);
        const TopSection: React.ComponentType<ActionEditProps> | undefined = action.plugin.editSections.top;
        return (<ExpandableContainer containerKey={action.Id}>
                <ProcessActionLayout title={<StepName name={action.Name} number={stepActionNumber} stepType={stepOther.actionTypeName}/>} titleLogo={action && <Logo url={getActionLogoUrl(action)}/>} sectionControl={<ActionList actions={actions}/>}>
                    <React.Fragment>
                        <ChangeVersionDialog open={this.state.stepVersionChangeDialogIsOpen} stepPackageId={action.ActionType} availableVersions={action.AvailableStepPackageVersions} currentVersion={action.StepPackageVersion} onChangeClick={(targetVersion) => {
                this.migrateToStepPackageVersion(targetVersion);
                this.setState({ stepVersionChangeDialogIsOpen: false });
            }} onCancelClick={() => this.setState({ stepVersionChangeDialogIsOpen: false })} doBusyTask={this.props.doBusyTask}/>
                        <StepPackageInfoDialog open={this.state.stepPackageInfoDialogIsOpen} stepPackage={action.plugin.stepPackage} onClose={() => this.setState({ stepPackageInfoDialogIsOpen: false })}/>
                        <ExpansionButtons containerKey={action.Id} errors={this.props.errors} expandAllOnMount={this.isNew}/>
                        <StepPackageVersionBanner action={action} cleanAction={cleanAction} onUpgrade={this.migrateToStepPackageVersion} onCancel={() => {
                this.resetMigratedActionProperties();
                this.setState({ stepVersionChangeError: undefined });
            }} error={this.state.stepVersionChangeError}/>
                        <AutoExpandActionErrors actionId={action.Id}/>
                        <AutoExpandActionWarnings actionId={action.Id}/>
                        <ErrorsForAction actionErrors={actionErrors}/>
                        <WarningsForAction actionWarnings={actionWarnings}/>
                        {actionErrors.length === 0 && actionWarnings.length === 0 && <ContainersFeedbackCallout actionTypes={[action.ActionType]}></ContainersFeedbackCallout>}
                        {(actionErrors.length === 0 || actionWarnings.length === 0) && this.props.isNew && <AutoExpandAllExpanders />}
                        <ScriptActionContext.Provider value={{ loadVariables: this.loadVariables, key: this.loadVariablesKey() }}>
                            <div>
                                <ConflictConfirmationDialog />

                                {action.IsDisabled && (<UnstructuredFormSection stretchContent={true}>
                                        <Callout type={CalloutType.Warning} title={"This step is currently disabled"}/>
                                    </UnstructuredFormSection>)}

                                <ExpandableFormSection isExpandedByDefault={this.props.isNew} errorKey="Name" title="Step Name" focusOnExpandAll summary={action.Name ? Summary.summary(nameSummary(action.Name, action.Slug ?? null, action.Notes)) : Summary.placeholder("Please enter a name for your step")} help="A short, memorable, unique name for this step.">
                                    <DebounceText value={action.Name} onChange={(x) => this.setActionMetaProperties({ Name: x })} label="Step name" error={this.getFieldError("Name")} validate={required("Please enter a step name")} autoFocus={true}/>
                                    {!this.isNew && (<SlugEditor value={action.Slug ?? generateSlug(action.Name)} name={action.Name} originalSlug={cleanAction?.Slug ?? ""} onChange={(x) => this.setActionMetaProperties({ Slug: x })} label="Step slug" error={this.getFieldError("Slug")} validate={required("Please enter a step slug")}/>)}
                                    {showNotesControlsToggle}
                                </ExpandableFormSection>
                                {!stepLookups.actionTemplate && TopSection && (<TopSection plugin={action.plugin} projectId={projectContext.state.model.Id} doBusyTask={this.doBusyForChildren} busy={busy} inputs={action.Inputs} properties={action.Properties} packages={action.Packages} gitDependencies={action.GitDependencies} runOn={this.state.runOn} setInputs={(inputs, callback) => this.setActionInputs(inputs, callback)} setProperties={(p, i, c) => this.setActionProperties(p, c)} setPackages={(p) => this.setActionPackages(p)} setGitDependencies={(gds) => this.setActionGitDependencies(gds)} additionalActions={actionEditorAdditionalActions} getFieldError={this.getFieldError} errors={errors} expandedByDefault={this.props.isNew} refreshRunOn={() => this.refreshRunOn()} getProcessResource={contextSelectors.getProcessResource}/>)}
                                <ExecutionPlan projectId={projectContext.state.model.Id} gitRef={projectContext.state.gitRef} expandedByDefault={this.props.isNew} doBusyTask={this.doBusyForChildren} executionLocation={action.plugin.executionLocation} runOnServerOrWorkerPoolCopy={this.state.runOnServerOrWorkerPoolCopy} runOn={this.state.runOn} onRunOnChanged={this.onRunOnChanged} targetRoleOption={action.plugin.targetRoleOption(action)} targetRoles={step.Properties["Octopus.Action.TargetRoles"] as string} onTargetRolesChanged={(roles) => this.setStepProperties({ ["Octopus.Action.TargetRoles"]: ParseHelper.encodeCSV(roles) })} targetRolesError={this.getFieldError("Octopus.Action.TargetRoles")} isChildStep={contextSelectors.isChildAction(action.Id)} maxParallelism={step.Properties["Octopus.Action.MaxParallelism"] as string} onMaxParallelismChanged={(max) => this.setStepProperties({ ["Octopus.Action.MaxParallelism"]: max })} availableRoles={stepLookups.machineRoles} availableWorkerPools={stepLookups.availableWorkerPools} canRunOnWorker={contextSelectors.canActionRunOnWorker(action.Id)} isBuiltInWorkerEnabled={stepOther.isBuiltInWorkerEnabled} targetWorkerPool={action.WorkerPoolId} targetWorkerPoolVariable={action.WorkerPoolVariable} onTargetWorkerPoolChanged={(workerPoolId, workerPoolVariable) => this.setActionMetaProperties({ WorkerPoolId: workerPoolId, WorkerPoolVariable: workerPoolVariable })} runsOnServer={runsOnServer(action, action.plugin.executionLocation)} getFieldError={this.getFieldError} feeds={this.props.feeds} loadFeeds={this.loadFeeds} canRunInContainer={this.canRunInContainer()} containerImageRecommendation={this.containerImageRecommendation()} imageNameError={this.getFieldError("Container.FeedId")} analyticsStepEditorDispatch={(eventName, ev) => this.props.analyticsStepEditorDispatch(eventName, { ...ev, stepTemplate: this.getStepTemplateName() })}/>

                                {action.plugin.targetDiscoveryCloudConnectionProviders && action.plugin.targetDiscoveryCloudConnectionProviders().length > 0 && this.props.stepLookups.projectVariables && (<CloudConnections connectionType={CloudConnectionUsageType.TargetDiscovery} isExpandedByDefault={this.props.isNew} providers={action.plugin.targetDiscoveryCloudConnectionProviders()} sectionErrorKey="Octopus.TargetDiscovery.CloudConnections" sectionHelpText="Configure a cloud connection to enable cloud target discovery" projectVariables={this.props.stepLookups.projectVariables} libraryVariableSets={this.props.stepLookups.libraryVariableSets} refreshVariables={() => this.refreshStepLookupsImmediately()} saveVariables={async (variables, commitMessage): Promise<VariableSetResource> => {
                    const updatedVariables = await this.saveVariables(variables, commitMessage);
                    this.refreshStepLookupsImmediately();
                    return updatedVariables;
                }} project={this.props.projectContext.state.model} isDefaultBranch={this.props.projectContext.state.isDefaultBranch} gitRef={this.props.projectContext.state.gitRef?.CanonicalName} haveVariablesChanged={async (variables) => {
                    const serverVariables = await getProjectVariables(this.props.projectContext.state);
                    return !isEqual(variables, serverVariables);
                }}/>)}

                                {!stepLookups.actionTemplate && (<div>
                                        <ProjectActionPropertiesEditor editor={action.plugin.editSections.default} plugin={action.plugin} projectId={projectContext.state.model.Id} isNew={this.isNew} doBusyTask={this.doBusyForChildren} busy={busy} inputs={action.Inputs} properties={action.Properties} packages={action.Packages} gitDependencies={action.GitDependencies} runOn={this.state.runOn} setInputs={(inputs, callback) => this.setActionInputs(inputs, callback)} setProperties={(p, i, c) => this.setActionProperties(p, c)} setPackages={(p) => this.setActionPackages(p)} setGitDependencies={(gd) => this.setActionGitDependencies(gd)} additionalActions={actionEditorAdditionalActions} getFieldError={this.getFieldError} errors={errors} expandedByDefault={this.props.isNew} refreshRunOn={() => this.refreshRunOn()} getProcessResource={contextSelectors.getProcessResource}/>

                                        {processContext.selectors.actionHasFeatures(action.Id) && (<FeatureEditor plugin={action.plugin} projectId={projectContext.state.model.Id} gitRef={projectContext.state.gitRef} isNew={this.isNew} doBusyTask={this.doBusyForChildren} busy={busy} properties={action.Properties} gitDependencies={action.GitDependencies} packages={action.Packages} runOn={this.state.runOn} setProperties={(p, i, c) => this.setActionProperties(p, c)} setPackages={(p) => this.setActionPackages(p)} setGitDependencies={(gds) => this.setActionGitDependencies(gds)} enabledFeatures={(action.Properties["Octopus.Action.EnabledFeatures"] as string) || ""} getFieldError={this.getFieldError} errors={errors} expandedByDefault={this.props.isNew} openFeaturesElement={addFeaturesElement} refreshRunOn={() => this.refreshRunOn()}/>)}
                                    </div>)}

                                {stepLookups.actionTemplate && (<ActionTemplateEditor actionTemplate={stepLookups.actionTemplate} process={processContext.state.model} processType={processContext.selectors.getProcessType()} actionId={action.Id} properties={action.Properties} setProperties={(p) => this.setActionProperties(p)} setPackages={(p) => this.setActionPackages(p)} setGitDependencies={(gds) => this.setActionGitDependencies(gds)} doBusyTask={this.doBusyForChildren} onActionTemplateUpdate={processContext.actions.refreshFromServer}/>)}

                                <FormSectionHeading title="Conditions"/>
                                <Environments environmentOption={this.state.environmentOption} hasHiddenEnvironments={this.hasHiddenEnvironments} environments={this.props.stepLookups.environments} inclusiveEnvironments={action.Environments} exclusiveEnvironments={action.ExcludedEnvironments} onEnvironmentOptionChanged={(environmentOption) => this.updateEnvironmentOption(environmentOption)} onInclusiveEnvironmentsChanged={(val) => this.updateEnvironmentSelection({ ...this.environmentSelection, inclusive: val })} onExclusiveEnvironmentsChanged={(val) => this.updateEnvironmentSelection({ ...this.environmentSelection, exclusive: val })} onInclusiveEnvironmentRemoved={this.handleInclusiveEnvironmentRemoval} onExclusiveEnvironmentRemoved={this.handleExclusiveEnvironmentRemoval}/>

                                {processContext.state.processType === ProcessType.Deployment && (action.Channels.length > 0 || stepLookups.channels.length > 1) && (<ExpandableFormSection title="Channels" help="Choose which channels this step applies to." summary={this.channelsSummary()} errorKey="channels">
                                        <Note>If nothing is selected this step will run for releases in any channel, otherwise it will only run for releases belonging to the selected channels.</Note>
                                        <ChannelMultiSelect items={stepLookups.channels} onChange={(val) => this.setActionMetaProperties({ Channels: val })} value={action.Channels}/>
                                    </ExpandableFormSection>)}

                                <FeatureToggle feature={Feature.MultiTenancy}>
                                    {(projectContext.state.model.TenantedDeploymentMode !== TenantedDeploymentMode.Untenanted || action.TenantTags.length > 0) && (<TenantsExpander doBusyTask={this.doBusyForChildren} tenantTags={action.TenantTags} tagIndex={stepLookups.tagIndex} onTenantTagsChange={(tags) => this.setActionMetaProperties({ TenantTags: tags })}/>)}
                                </FeatureToggle>

                                {!this.isChildAction() && step && (<RunTriggerExpander isFirstStep={contextSelectors.isFirstStep(step.Id)} condition={step.Condition} onConditionChange={(val) => this.setStepMetaProperties({ Condition: val })} variableExpression={step.Properties["Octopus.Step.ConditionVariableExpression"] as string} onVariableExpressionChange={(x) => this.setStepProperties({ ["Octopus.Step.ConditionVariableExpression"]: x })} projectId={projectContext.state.model.Id} variableExpressionError={this.getFieldError("ConditionVariableExpression")}/>)}
                                {this.isChildAction() && action && (<RunTriggerForChildActionExpander isFirstStep={contextSelectors.isFirstChildAction(action.Id)} condition={action.Condition || RunConditionForAction.Success} onConditionChange={(val) => this.setActionMetaProperties({ Condition: val })} variableExpression={action.Properties["Octopus.Action.ConditionVariableExpression"] as string} onVariableExpressionChange={(x) => this.setActionProperties({ ["Octopus.Action.ConditionVariableExpression"]: x })} projectId={projectContext.state.model.Id} variableExpressionError={this.getFieldError("ConditionVariableExpression")}/>)}

                                {contextSelectors.shouldShowRunTrigger(action.Id) && <StartTriggerExpander startTrigger={step.StartTrigger} onChange={(val) => this.setStepMetaProperties({ StartTrigger: val })}/>}

                                {contextSelectors.shouldShowPackageRequirementOptionForAction(action.Id) && step && (<PackageRequirementExpander packageRequirement={step.PackageRequirement} onChange={(val) => {
                    this.setStepMetaProperties({ PackageRequirement: val });
                    if (val === PackageRequirement.AfterPackageAcquisition)
                        this.props.processContext.actions.resetPackageRequirementAfterPackageAcquisitionStep();
                }}/>)}
                                <ExpandableFormSection title="Required" summary={action.IsRequired || hasManualInterventionResponsibleTeams
                ? Summary.summary(<span>
                                                      This step is <strong>required</strong> and cannot be skipped
                                                  </span>)
                : Summary.summary(<span>
                                                      This step is <strong>not required</strong> and can be skipped
                                                  </span>)} help="Required steps cannot be skipped when deploying a release" errorKey="required">
                                    {hasManualInterventionResponsibleTeams && <Note>Responsible teams are specified, therefore this step is always required.</Note>}

                                    <Checkbox value={action.IsRequired || hasManualInterventionResponsibleTeams} label="Prevent this step from being skipped when deploying" disabled={hasManualInterventionResponsibleTeams} onChange={(val) => this.setActionMetaProperties({ IsRequired: val })}/>
                                </ExpandableFormSection>

                                {this.shouldShowRetries(action, contextSelectors) && (<ExpandableFormSection title="Retries" summary={this.state.maximumRetries > 0 ? Summary.summary(<span>Upon failure, this step will automatically retry</span>) : Summary.default(<span>No</span>)} help="Upon failure, this step will automatically retry" errorKey="retries">
                                        <Callout title="Early Access" type={CalloutType.Warning}>
                                            This feature is still in development. We'd love to hear <ExternalLink href={"AutoRetryFeedbackForm"}> your feedback</ExternalLink>.
                                        </Callout>
                                        <Checkbox value={this.state.maximumRetries > 0} label="Allow retries (max. 3)" onChange={(val) => {
                    const maxRetries = 3; //We anticipate that we'll have plans to make this configurable in the future (but not now), which is why it's hard coded as for the moment.
                    this.setState({ maximumRetries: val ? maxRetries : 0 });
                    this.setActionProperties({ ["Octopus.Action.AutoRetry.MaximumCount"]: val ? `${maxRetries}` : "0" });
                }}/>
                                    </ExpandableFormSection>)}
                                {this.shouldShowExecutionTimeout(action, contextSelectors) && (<ExpandableFormSection title="Time out" summary={typeof this.state.actionExecutionTimeOut === "number" && this.state.actionExecutionTimeOut > 0
                    ? Summary.summary(<span>This step will timeout after {this.state.actionExecutionTimeOut} minutes.</span>)
                    : Summary.default(<span>Never</span>)} help="If this step is still running after the set time has lapsed, we will assume the task is stuck and force failure. We recommend using this option in conjunction with retries where appropriate." errorKey="timeout">
                                        <Callout title="Early Access" type={CalloutType.Warning}>
                                            This feature is still in development. We'd love to hear <ExternalLink href={"ActionExecutionTimeOutFeedbackForm"}> your feedback</ExternalLink>.
                                        </Callout>
                                        <NumberPicker value={this.state.actionExecutionTimeOut} label="Time out after minutes (0 means never time out)" onChange={(val) => {
                    if (val === undefined || val <= 0) {
                        val = 0;
                    }
                    this.setState({ actionExecutionTimeOut: val });
                    this.setActionProperties({ ["Octopus.Action.ExecutionTimeout.Minutes"]: `${val}` });
                }} note="The execution timeout encompasses all processes involved in executing a step, such as connecting to a target, bootstrapper scripts, execution container startup, package cache cleanups, etc but is contained to each machine"/>
                                    </ExpandableFormSection>)}
                            </div>
                        </ScriptActionContext.Provider>
                    </React.Fragment>
                </ProcessActionLayout>
            </ExpandableContainer>);
    }
    handleExclusiveEnvironmentRemoval = (value: string) => {
        //This only applies to version-controlled projects because when the project is version controlled we need to be able
        //to remove environments which have been marked as unavailable. This is something that can easily happen by simply
        //renaming an environment for example. It's not a missing id for example, it's just a name so we should be able
        //to remove it and add something that makes sense.
        const isVersionControlled = isVersionControlledProcess(this.props.projectContext.state.model.IsVersionControlled, this.props.processContext.selectors.getProcessType());
        if (!isVersionControlled) {
            return;
        }
        const previous = this.environmentSelection;
        const unavailableExclusive = previous.unavailableExclusive.filter((x) => x !== value);
        const exclusive = previous.exclusive.filter((x) => x !== value);
        this.updateEnvironmentSelection({
            ...previous,
            exclusive,
            unavailableExclusive,
        });
    };
    handleInclusiveEnvironmentRemoval = (value: string) => {
        //This only applies to version-controlled projects because when the project is version controlled we need to be able
        //to remove environments which have been marked as unavailable. This is something that can easily happen by simply
        //renaming an environment for example. It's not a missing id for example, it's just a name so we should be able
        //to remove it and add something that makes sense.
        const isVersionControlled = isVersionControlledProcess(this.props.projectContext.state.model.IsVersionControlled, this.props.processContext.selectors.getProcessType());
        if (!isVersionControlled) {
            return;
        }
        const previous = this.environmentSelection;
        const unavailable = previous.unavailable.filter((x) => x !== value);
        const inclusive = previous.inclusive.filter((x) => x !== value);
        this.updateEnvironmentSelection({
            ...previous,
            inclusive,
            unavailable,
        });
    };
    private updateEnvironmentOption(environmentOption: EnvironmentOption) {
        this.setState({ environmentOption }, () => {
            this.updateEnvironments(this.environmentSelection, environmentOption);
        });
    }
    private updateEnvironmentSelection(environmentSelection: EnvironmentSelection) {
        this.updateEnvironments(environmentSelection, this.state.environmentOption);
    }
    private updateEnvironments(selection: EnvironmentSelection | null, environmentOption: EnvironmentOption | null) {
        const environments = selection ? uniq((selection.inclusive || []).concat(selection.unavailable)) : [];
        const excludedEnvironments = selection ? uniq((selection.exclusive || []).concat(selection.unavailableExclusive)) : [];
        if (environmentOption && environmentOption !== EnvironmentOption.Include) {
            environments.splice(0);
        }
        if (environmentOption && environmentOption !== EnvironmentOption.Exclude) {
            excludedEnvironments.splice(0);
        }
        this.setActionMetaProperties({ Environments: environments, ExcludedEnvironments: excludedEnvironments });
    }
    private getFieldError = (value: string): string => {
        const error = this.props.processErrorSelectors.getActionFieldError(this.props.action.Id, this.props.processContext.selectors, value);
        return error;
    };
    private getFieldWarning = (value: string): string => {
        const warning = this.props.processWarningSelectors.getActionFieldWarning(this.props.action.Id, this.props.processContext.selectors, value);
        return warning;
    };
    private shouldShowRetries = (action: StoredAction, contextSelectors: ProcessStateSelectors) => {
        return contextSelectors.canBeRetried(action.Id);
    };
    private shouldShowExecutionTimeout = (action: StoredAction, contextSelectors: ProcessStateSelectors) => {
        return isFeatureToggleEnabled("ActionExecutionTimeOutFeatureToggle") && contextSelectors.canUseExecutionTimeouts(action.Id);
    };
    private onRunOnChanged = (runOn: RunOn) => {
        if (this.state.runOn && isRunOnServerOrWorkerPool(this.state.runOn)) {
            this.setState({ runOnServerOrWorkerPoolCopy: this.state.runOn });
        }
        this.setState({ runOn }, () => {
            this.props.processContext.actions.runOnChanged(this.props.action.Id, this.props.step.Id, runOn);
        });
    };
    private loadFeeds = async (callback?: (feeds: FeedResource[]) => void) => {
        await this.props.doBusyTask(async () => {
            await this.props.refreshFeeds();
            if (callback) {
                callback(this.props.feeds);
            }
        });
    };
    private handleEnabledToggle = async () => {
        const action = this.props.action;
        this.setActionMetaProperties({ IsDisabled: !action.IsDisabled });
    };
    private refreshStepLookupsImmediately() {
        this.props.refreshStepLookups();
    }
    private async saveVariables(variables: VariableSetResource, commitMessage: CommitMessageWithDetails | undefined): Promise<VariableSetResource> {
        const project = this.props.projectContext.state.model;
        const projectRepo = this.props.projectContext.state.projectContextRepository;
        if (HasVariablesInGit(project.PersistenceSettings)) {
            if (!commitMessage)
                throw new Error("No commit message received.");
            const nonSensitiveVariables = variables.Variables.filter((variable) => variable.Type !== VariableType.Sensitive);
            const sensitiveVariables = variables.Variables.filter((variable) => variable.Type === VariableType.Sensitive);
            const formattedCommitMessage = getFormattedCommitMessage(commitMessage, "Update variables");
            const nonSensitivePromise = projectRepo.Variables.modify({ ...variables, Variables: nonSensitiveVariables, ChangeDescription: formattedCommitMessage });
            const sensitiveVariableSet = await this.props.projectContext.state.projectContextRepository.Variables.getSensitive();
            const sensitivePromise = projectRepo.Variables.modify({ ...sensitiveVariableSet, Variables: sensitiveVariables });
            const variableArray = await Promise.all([nonSensitivePromise, sensitivePromise]);
            const variableSet = variableArray[0];
            variableSet.Variables = variableSet.Variables.concat(variableArray[1].Variables);
            return variableSet;
        }
        else {
            return await projectRepo.Variables.modify(variables);
        }
    }
    private handleDetachStepTemplate = async () => {
        const action = this.props.action;
        this.props.processContext.actions.removeActionProperties(action.Id, ["Octopus.Action.Template.Id", "Octopus.Action.Template.Version"]);
        this.refreshStepLookupsImmediately();
    };
    private handleCreateStepTemplate = async () => {
        const templateExists = (templates: ActionTemplateResource[], actionName: string) => {
            return templates.some((s) => s.Name.toLocaleUpperCase() === actionName.toLocaleUpperCase());
        };
        const getNewTemplateName = (templates: ActionTemplateResource[], action: DeploymentActionResource) => {
            let suffix = "";
            let counter = 1;
            while (templateExists(templates, action.Name + suffix)) {
                suffix = " (" + counter + ")";
                counter++;
            }
            return action.Name + suffix;
        };
        const createStepTemplateFromAction = async (action: DeploymentActionResource) => {
            const existingTemplates = await repository.ActionTemplates.all();
            const newName = getNewTemplateName(existingTemplates, action);
            const newTemplate = JSON.parse(JSON.stringify(action));
            newTemplate.Name = newName;
            newTemplate.Id = generateGuid();
            newTemplate.Version = 1;
            newTemplate.Description = "Created from step '" + this.props.action.Name + "' in project '" + this.props.projectContext.state.model.Name + "'";
            return newTemplate;
        };
        await this.props.doBusyTask(async () => {
            const newTemplate = await createStepTemplateFromAction(this.props.action);
            const result = await repository.ActionTemplates.create(newTemplate);
            if (result) {
                this.setActionProperties({ ["Octopus.Action.Template.Id"]: result.Id, "Octopus.Action.Template.Version": result.Version.toString() });
                this.refreshStepLookupsImmediately();
            }
        });
    };
    private handleShowStepPackageDetails() {
        this.setState({ stepPackageInfoDialogIsOpen: true });
    }
    private handleChangeStepVersion() {
        this.setState({ stepVersionChangeDialogIsOpen: true });
    }
    private canRunInContainer() {
        return this.props.action.plugin.canRunInContainer === false ? this.props.action.plugin.canRunInContainer : true;
    }
    private containerImageRecommendation() {
        return this.props.action.plugin.stepPackage?.stepUI.configuration?.canRunInExecutionContainer && this.props.action.plugin.stepPackage.stepUI.configuration.containerImageRecommendation
            ? noteToElement(this.props.action.plugin.stepPackage.stepUI.configuration.containerImageRecommendation)
            : undefined;
    }
    private notesSummary() {
        const action = this.props.action;
        return action && action.Notes ? Summary.summary(<Markdown markup={action.Notes}/>) : Summary.placeholder("None");
    }
    private channelsSummary() {
        const action = this.props.action;
        return action && action.Channels.length > 0 ? Summary.summary(<span>This step will only run for releases in {action.Channels.map((ch) => this.getChipForChannel(ch))}</span>) : Summary.default("This step will run for releases in any channel");
    }
    private getChipForChannel(id: string) {
        return (<Lookup lookupCollection={this.props.stepLookups.channels} lookupId={id} getIdFromElement={(element) => element.Id} render={(channel) => <ChannelChip key={id} channelName={channel.Name}/>} renderFallback={<ContextualMissingChip key={id} lookupKey={id} type={ChipIcon.Channel}/>}/>);
    }
    private setActionInputs = (inputs: StepPackageInputs, callback?: () => void) => {
        const action = this.props.action;
        this.props.processContext.actions.setActionInputs(action.Id, inputs);
        if (callback) {
            callback();
        }
    };
    private setMigratedActionProperties = async (inputs: StepPackageInputs, stepPackageVersion: string) => {
        const action = this.props.action;
        const plugin = await pluginRegistry.getAction(action.ActionType, stepPackageVersion);
        this.props.processContext.actions.setMigratedActionProperties(action.Id, inputs, plugin, stepPackageVersion);
    };
    private migrateToStepPackageVersion = (targetVersion: string) => this.props.doBusyTask(async () => {
        const { action } = this.props;
        try {
            const { StepPackageId, StepPackageVersion, Inputs } = await repository.StepPackageRepository.migrateStepPackageInputs(action.ActionType, action.StepPackageVersion || "", action.Inputs, parseInt(targetVersion));
            const stepPackage = await stepPackageResolver.getStepPackageByIdAndVersion(StepPackageId, StepPackageVersion);
            // Map our migrated inputs to ensure the data is initialized
            // correctly for any properties where Octopus owns the type
            const resourceInputs = Inputs as ObjectResourceInputs<unknown>;
            const inputSchema = convertFromJsonSchemaToInputSchema(stepPackage.schema, resourceInputs);
            const mappedInputs = mapRootInitialInputs(inputSchema, asRuntimeInputs(inputSchema.properties, resourceInputs));
            this.setMigratedActionProperties(asResourceInputs(inputSchema.properties, mappedInputs), StepPackageVersion);
        }
        catch (e) {
            this.setState({ stepVersionChangeError: e.ErrorMessage });
            logger.error(e, "Error migrating {action} to {targetVersion}", { action, targetVersion });
        }
    });
    private resetMigratedActionProperties = () => this.props.doBusyTask(async () => {
        const cleanAction = this.props.cleanAction;
        if (cleanAction === null || cleanAction.StepPackageVersion === undefined)
            return;
        const plugin = await pluginRegistry.getAction(cleanAction.ActionType, cleanAction.StepPackageVersion);
        this.props.processContext.actions.setMigratedActionProperties(cleanAction.Id, cleanAction.Inputs, plugin, cleanAction.StepPackageVersion);
    });
    private setActionProperties = (properties: Partial<ActionProperties>, callback?: () => void) => {
        const action = this.props.action;
        this.props.processContext.actions.setActionProperties(action.Id, properties);
        if (callback) {
            callback();
        }
    };
    private setActionPackages = (packages: PackageReference[]) => {
        this.setActionMetaProperties({ Packages: packages });
        const processContext = this.props.processContext;
        if (processContext.selectors.getStepRightAfterPackageAcquisition()?.Id === this.props.step.Id)
            processContext.actions.resetPackageRequirementAfterPackageAcquisitionStep();
    };
    private setActionGitDependencies = (gitDependencies: GitDependencyReference[]) => {
        this.setActionMetaProperties({ GitDependencies: gitDependencies });
    };
    private setStepProperties = (properties: Partial<ActionProperties>) => {
        const step = this.props.step;
        this.props.processContext.actions.setStepProperties(step.Id, properties);
    };
    private setActionMetaProperties<K extends keyof StoredAction>(state: Pick<StoredAction, K> | StoredAction, callback?: () => void) {
        const action = this.props.action;
        this.props.processContext.actions.setActionMetaProperties(action.Id, (prev) => ({ ...prev, ...state }));
        if (callback) {
            callback();
        }
    }
    private setStepMetaProperties<K extends keyof StoredStep>(state: Pick<StoredStep, K>, callback?: () => void) {
        const step = this.props.step;
        this.props.processContext.actions.setStepMetaProperties(step.Id, (prev) => ({ ...prev, ...state }));
        if (callback) {
            callback();
        }
    }
    static displayName = "ProcessActionDetailsInternal";
}
const isBuiltInWorkerEnabledSelector = (state: GlobalState) => state.configurationArea.features.isBuiltInWorkerEnabled;
const ProcessActionDetails: React.FC<ProcessActionDetailsProps> = (props) => {
    const isBuiltInWorkerEnabled = useSelector(isBuiltInWorkerEnabledSelector);
    const projectContext = useProjectContext();
    const processContext = useProcessContext();
    const processErrorSelectors = useProcessErrorSelectors();
    const processWarningSelectors = useProcessWarningSelectors();
    const processQueryStringContext = useProcessQueryStringContext();
    const runbookContext = useOptionalRunbookContext();
    const feeds = useFeedsFromContext();
    const actionTemplates = useActionTemplatesFromContext();
    const refreshFeeds = useRefreshFeedsFromContext();
    const analyticsStepEditorDispatch = useAnalyticsStepEditorDispatch(props.action.Id, projectContext.state.model.Id);
    return (<ProcessActionDetailsInternal {...props} feeds={feeds} refreshFeeds={refreshFeeds} actionTemplates={actionTemplates} projectContext={projectContext} processContext={processContext} processErrorSelectors={processErrorSelectors} processWarningSelectors={processWarningSelectors} processQueryStringContext={processQueryStringContext} runbookContext={runbookContext} isBuiltInWorkerEnabled={isBuiltInWorkerEnabled} analyticsStepEditorDispatch={analyticsStepEditorDispatch}/>);
};
ProcessActionDetails.displayName = "ProcessActionDetails"
export default ProcessActionDetails;
