import { ControlType, Permission, HasVariablesInGit, VariableType } from "@octopusdeploy/octopus-server-client";
import type { LibraryVariableSetResource, ProjectResource, TenantResource, TenantVariableResource, ScopeValues, VariableSetResource } from "@octopusdeploy/octopus-server-client";
import { flatten } from "lodash";
import * as React from "react";
import { useProjectContext } from "~/areas/projects/context";
import mergeScopeValues from "~/areas/variables/MergeScopeValues";
import { FilterableVariableDisplayer } from "~/areas/variables/VariableDisplayer/FilterableVariableDisplayer";
import type { ValueWithSource } from "~/areas/variables/VariableDisplayer/VariableDisplayer";
import { convertVariableResourcesToVariablesWithSource } from "~/areas/variables/convertVariableResourcesToVariablesWithSource";
import { repository } from "~/clientInstance";
import type { DoBusyTask, Errors } from "~/components/DataBaseComponent";
import DataBaseComponent, { useDoBusyTaskEffect } from "~/components/DataBaseComponent";
import PaperLayout from "~/components/PaperLayout/PaperLayout";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import convertPropertyValueResourceToString from "~/components/convertPropertyValueResourceToString";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import type { ValueSource } from "../../../../variables/SourceLink/SourceLink";
import type { VariableWithSource } from "../../../../variables/VariableDisplayer";
import { default as groupVariablesByName } from "../../../../variables/groupVariablesByName";
import { ProjectStatus } from "../../ProjectStatus/ProjectStatus";
interface LibraryVariableSetWithVariables {
    variableSet: VariableSetResource;
    libraryVariableSet: LibraryVariableSetResource;
}
type AllVariablesProps = {
    doBusyTask: DoBusyTask;
    errors?: Errors;
    busy?: Promise<unknown> | boolean;
};
const AllVariables: React.FC<AllVariablesProps> = (props: AllVariablesProps) => {
    const allVariables = useAllVariablesDataLoading(props.doBusyTask);
    const projectContext = useProjectContext();
    const { model: project } = projectContext.state;
    const getVariables = (): ReadonlyArray<VariableWithSource> => {
        if (!allVariables.projectVariableSet) {
            return [];
        }
        return [
            ...buildProjectVariables(project, allVariables.projectVariableSet),
            ...buildLibraryVariableSetVariables(allVariables.libraryVariableSets),
            ...buildTenantLibraryVariables(project, allVariables.tenants, allVariables.tenantVariables),
            ...buildTenantProjectVariables(project, allVariables.tenants, allVariables.tenantVariables),
        ];
    };
    const getAvailableScopes = (): ScopeValues => {
        const allScopeValues: ScopeValues[] = allVariables.projectVariableSet ? [allVariables.projectVariableSet.ScopeValues, ...allVariables.libraryVariableSets.map((set) => set.variableSet.ScopeValues)] : [];
        return mergeScopeValues(allScopeValues);
    };
    return (<PaperLayout busy={props.busy} breadcrumbTitle={project.Name} errors={props.errors} fullWidth={true} title={"All Variables"} statusSection={<ProjectStatus doBusyTask={props.doBusyTask}/>}>
            <FilterableVariableDisplayer availableScopes={getAvailableScopes()} variableSections={[getVariables()]} doBusyTask={props.doBusyTask}/>
        </PaperLayout>);
};
AllVariables.displayName = "AllVariables"
function getVariableTypeFromDisplaySettings(type?: ControlType): VariableType {
    switch (type) {
        case ControlType.Sensitive:
            return VariableType.Sensitive;
        case ControlType.Certificate:
            return VariableType.Certificate;
        case ControlType.AmazonWebServicesAccount:
            return VariableType.AmazonWebServicesAccount;
        case ControlType.AzureAccount:
            return VariableType.AzureAccount;
        case ControlType.GoogleCloudAccount:
            return VariableType.GoogleCloudAccount;
        case ControlType.WorkerPool:
            return VariableType.WorkerPool;
        default:
            return VariableType.String;
    }
}
interface AllVariablesForProject {
    projectVariableSet: VariableSetResource | undefined;
    libraryVariableSets: LibraryVariableSetWithVariables[];
    tenants: TenantResource[];
    tenantVariables: TenantVariableResource[];
}
const initialEmptyProjectVariableState: AllVariablesForProject = {
    tenants: [],
    libraryVariableSets: [],
    tenantVariables: [],
    projectVariableSet: undefined,
};
function useAllVariablesDataLoading(doBusyTask: DoBusyTask): AllVariablesForProject {
    const projectContext = useProjectContext();
    const project = projectContext.state.model;
    const loadProjectVariableSet = useProjectVariableSetLoader();
    const [state, setState] = React.useState<AllVariablesForProject>(initialEmptyProjectVariableState);
    useDoBusyTaskEffect(doBusyTask, async () => {
        const libraryVariableSetVariables = loadLibraryVariableSetVariables(project);
        const projectVariableSet = loadProjectVariableSet();
        const projectId = project.Id;
        const tenants = isAllowed({ permission: Permission.TenantView, tenant: "*" }) ? repository.Tenants.all({ projectId }) : Promise.resolve([]);
        const tenantVariables = repository.TenantVariables.getOnlyProjectScoped(projectId);
        setState({
            projectVariableSet: await projectVariableSet,
            tenants: await tenants,
            tenantVariables: await tenantVariables,
            libraryVariableSets: await libraryVariableSetVariables,
        });
    }, [project, projectContext.state.gitRef], { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    return state;
}
function useProjectVariableSetLoader() {
    const projectContext = useProjectContext();
    const project = projectContext.state.model;
    return React.useCallback(async () => {
        const variableSet = await projectContext.state.projectContextRepository.Variables.get();
        if (HasVariablesInGit(project.PersistenceSettings)) {
            const sensitiveVariableSet = await projectContext.state.projectContextRepository.Variables.getSensitive();
            // We are making two separate variable set requests for Git projects (text and secret variables use a
            // separate endpoint), but we need to return a single VariableSetResource. We're re-building a variable
            // set using all of the values from variableSet but combining the variables from both the text and secret
            // sets
            return {
                ...variableSet,
                Variables: [...variableSet.Variables, ...sensitiveVariableSet.Variables],
            };
        }
        else {
            // If the project doesn't have Git variables, everything will be in variableSet so we just return that
            // as is.
            return variableSet;
        }
    }, [projectContext.state.projectContextRepository.Variables, project.PersistenceSettings]);
}
export interface LoadedLibraryVariableSets {
    variableSet: VariableSetResource;
    libraryVariableSet: LibraryVariableSetResource;
}
export async function loadLibraryVariableSetVariables(project: ProjectResource): Promise<LoadedLibraryVariableSets[]> {
    const libraryVariableSets = await repository.LibraryVariableSets.all({ ids: project.IncludedLibraryVariableSetIds });
    return Promise.all(libraryVariableSets.map(async (libraryVariableSet) => ({
        variableSet: await repository.Variables.get(libraryVariableSet.VariableSetId),
        libraryVariableSet,
    })));
}
const buildTenantProjectVariables = (project: ProjectResource, tenants: TenantResource[], tenantVariables: TenantVariableResource[]): ReadonlyArray<VariableWithSource> => {
    const projectId = project.Id;
    const namedValues = flatten(tenantVariables.map(getAllProjectVariablesForTenant));
    const groupedByNameValues = groupVariablesByName(namedValues, (namedValue) => namedValue.name);
    return Object.keys(groupedByNameValues).map((name) => ({ name, values: groupedByNameValues[name].map((nv) => nv.value) }));
    function getAllProjectVariablesForTenant(tenantVariables: TenantVariableResource): Array<{
        name: string;
        value: ValueWithSource;
    }> {
        const tenant = tenants.find((t) => t.Id === tenantVariables.TenantId);
        const projectVariables = tenantVariables.ProjectVariables[projectId];
        const source: ValueSource = {
            tenantId: tenant?.Id ?? tenantVariables.TenantId,
            tenantName: tenant?.Name ?? "Unknown tenant",
            type: "project",
        };
        return flatten(projectVariables.Templates.map((template) => Object.keys(projectVariables.Variables).map((environmentId) => {
            const environmentValues = projectVariables.Variables[environmentId];
            return {
                name: template.Name,
                value: {
                    type: getVariableTypeFromDisplaySettings(template.DisplaySettings["Octopus.ControlType"]),
                    scope: {
                        Environment: [environmentId],
                    },
                    value: convertPropertyValueResourceToString(environmentValues[template.Id] || template.DefaultValue),
                    source,
                    isPrompted: false,
                },
            };
        })));
    }
};
const buildLibraryVariableSetVariables = (libraryVariableSets: LibraryVariableSetWithVariables[]): ReadonlyArray<VariableWithSource> => flatten(libraryVariableSets.map((set) => {
    const source = {
        variableSetName: set.libraryVariableSet.Name,
        variableSetId: set.libraryVariableSet.Id,
    };
    return convertVariableResourcesToVariablesWithSource(set.variableSet.Variables, source);
}));
const buildTenantLibraryVariables = (project: ProjectResource, tenants: TenantResource[], tenantVariables: TenantVariableResource[]): ReadonlyArray<VariableWithSource> => {
    const libraryVariableSetIds = project.IncludedLibraryVariableSetIds;
    const namedValues = flatten(tenantVariables.map(getAllLibrarySetVariablesForTenant));
    const groupedByNameValues = groupVariablesByName(namedValues, (namedValue) => namedValue.name);
    return Object.keys(groupedByNameValues).map((name) => ({ name, values: groupedByNameValues[name].map((nv) => nv.value) }));
    function getAllLibrarySetVariablesForTenant(tenantVariables: TenantVariableResource): Array<{
        name: string;
        value: ValueWithSource;
    }> {
        const tenant = tenants.find((t) => t.Id === tenantVariables.TenantId);
        const libraryVariablesLookup = tenantVariables.LibraryVariables;
        const source: ValueSource = {
            tenantId: tenant?.Id ?? tenantVariables.TenantId,
            tenantName: tenant?.Name ?? "Unknown tenant",
            type: "library",
        };
        return flatten(libraryVariableSetIds.map((variableSetId) => {
            const projectLibraryVariables = libraryVariablesLookup[variableSetId];
            const templates = projectLibraryVariables.Templates;
            const values = projectLibraryVariables.Variables;
            return templates.map((varTemplate) => ({
                name: varTemplate.Name,
                value: {
                    type: getVariableTypeFromDisplaySettings(varTemplate.DisplaySettings["Octopus.ControlType"]),
                    scope: {},
                    value: convertPropertyValueResourceToString(values[varTemplate.Id] || varTemplate.DefaultValue),
                    source,
                    isPrompted: false,
                },
            }));
        }));
    }
};
function buildProjectVariables(project: ProjectResource, variableSet: VariableSetResource): ReadonlyArray<VariableWithSource> {
    const source = {
        projectName: project.Name,
        projectId: project.Id,
    };
    return convertVariableResourcesToVariablesWithSource(variableSet.Variables, source);
}
class AllVariablesLoader extends DataBaseComponent<{}> {
    render() {
        return <AllVariables doBusyTask={this.doBusyTask} errors={this.errors} busy={this.state?.busy}/>;
    }
    static displayName = "AllVariablesLoader";
}
export default AllVariablesLoader;
