import { css, cx } from "@emotion/css";
import { text, themeTokens } from "@octopusdeploy/design-system-tokens";
import * as React from "react";
import { normal } from "~/fontWeights";
import type { DropdownMenuOption } from "./DropDownMenu";
import { DropDownMenu } from "./DropDownMenu";
const newStyles = {
    floatingLabelStyle: css({
        position: "absolute",
        fontWeight: normal,
        color: themeTokens.color.textField.text.muted,
        lineHeight: "0.75rem",
        transition: "all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms",
        fontSize: "0.75rem",
    }),
    floatingLabelErrorStyle: css({
        color: themeTokens.color.textField.text.danger,
    }),
    hintLabelStyle: css({
        position: "absolute",
        fontWeight: normal,
        color: themeTokens.color.textField.text.muted,
        font: text.interface.body.default.base,
        top: "1.2rem",
    }),
    hintLabelErrorStyle: css({
        color: themeTokens.color.textField.text.danger,
    }),
    icon: css({
        right: 0,
        top: 0,
        marginTop: 0,
        fill: themeTokens.color.textField.icon.primary,
    }),
    errorText: css({
        color: themeTokens.color.textField.text.danger,
        font: text.interface.body.default.xSmall,
        marginTop: "3px",
    }),
    container: css({
        position: "relative",
        margin: "0 0 1rem 0", // Needed to match the bottom-spacing of our multi-selects. When you stack
        // various filters, this helps to keep them all consistent (example, see Task screen's advanced filters).
    }),
};
type Item = DropdownMenuOption;
interface SelectFieldProps {
    floatingLabelText?: string | JSX.Element;
    errorText?: string;
    placeholder?: string;
    className?: string;
    allowClear?: boolean;
    items: DropdownMenuOption[];
    onCloseDropdown?: () => void;
    disabled?: boolean;
    onChange?: (value: string) => void;
    selectionRenderer?: (value: string, menuItem: Item) => React.ReactNode;
    value: string;
    empty?: string;
    filter?: React.ReactNode;
    autoFocus?: boolean;
    selectedValueAccessibleName?: string;
    children?: never;
}
export class SelectField extends React.Component<SelectFieldProps> {
    static defaultProps: Partial<SelectFieldProps> = {
        disabled: false,
    };
    render() {
        const { allowClear, items, onCloseDropdown, disabled, onChange, selectionRenderer, value, filter, autoFocus, floatingLabelText, errorText, placeholder, selectedValueAccessibleName, className, empty } = this.props;
        const hasError = Boolean(errorText);
        return (<div className={cx(newStyles.container, className)} role="combobox">
                {floatingLabelText && <label className={cx(newStyles.floatingLabelStyle, { [newStyles.floatingLabelErrorStyle]: hasError })}>{floatingLabelText}</label>}
                {!value && placeholder && <div className={cx(newStyles.hintLabelStyle, { [newStyles.hintLabelErrorStyle]: hasError })}>{placeholder}</div>}
                <DropDownMenu allowClear={allowClear} disabled={disabled} value={value} selectedValueAccessibleName={selectedValueAccessibleName} onChange={onChange} selectionRenderer={selectionRenderer} filter={filter} autoFocus={autoFocus} items={items} onClose={onCloseDropdown} hasError={hasError}/>
                {errorText && <div className={newStyles.errorText}>{errorText}</div>}
            </div>);
    }
    static displayName = "SelectField";
}
