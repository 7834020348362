import type { TagSetResource } from "@octopusdeploy/octopus-server-client";
import { useState } from "react";
import type { TenantsFilterState } from "~/areas/tenants/Tenants/hooks/useTenantsFilterState";
import { excludeKey, fixedFilters, includeKey } from "~/areas/tenants/components/Filtering/TenantFilters";
import type { TenantFiltersData, TagWithSet } from "~/areas/tenants/components/Filtering/hooks/useTenantFiltersData";
export interface TenantsFilterRowsState {
    availableFilterRows: FilterRow[];
    selectedFilterRows: FilterRow[];
}
type ReplaceFilter = (previous: FilterRow | undefined, newFilter: FilterRow | undefined) => void;
type SelectValue = (key: string, values: string[]) => void;
export interface TenantsFilterRowsActions {
    replaceFilter: ReplaceFilter;
    clearSelectedFilters: () => void;
    selectValue: SelectValue;
}
export const useTenantsFilterRows = (filterState: TenantsFilterState, data: TenantFiltersData): [
    TenantsFilterRowsState,
    TenantsFilterRowsActions
] => {
    const { allFilters, available, selected } = buildFilters(filterState, data);
    const [availableFilterRows, setAvailableFilterRows] = useState<FilterRow[]>(available);
    const [selectedFilterRows, setSelectedFilterRows] = useState<FilterRow[]>(selected);
    const selectValue = (key: string, newValues: string[]) => {
        const newSelectedFilters = selectedFilterRows.map((filter) => (filter.key === key ? { ...filter, value: newValues } : filter));
        setSelectedFilterRows(newSelectedFilters);
    };
    const replaceFilter = (previous: FilterRow | undefined, newFilter: FilterRow | undefined) => {
        const filters = replaceSelectedFilters(selectedFilterRows, previous, newFilter);
        const selected = new Set(filters.map((f) => f.key));
        const availableFilters = allFilters.filter((filter) => !selected.has(filter.key));
        setSelectedFilterRows(filters);
        setAvailableFilterRows(availableFilters);
    };
    const clearSelectedFilters = () => {
        setSelectedFilterRows([]);
        setAvailableFilterRows([...allFilters]);
    };
    return [
        { availableFilterRows, selectedFilterRows },
        { replaceFilter, clearSelectedFilters, selectValue },
    ];
};
function buildFilters(filterState: TenantsFilterState, data: TenantFiltersData) {
    const tagSetFilters: FilterRow[] = generateTagSetFilters(Array.from(data.tagSets.values()));
    const allFilters: FilterRow[] = [...Object.values(fixedFilters), ...tagSetFilters];
    const queryValues = buildSelectedValuesFromQuery(filterState, data.tags);
    const { available, selected } = splitAllFiltersIntoAvailableAndSelected(allFilters, queryValues);
    return { allFilters, available, selected };
}
function generateTagSetFilters(tagSets: TagSetResource[]) {
    return tagSets.flatMap((tagSet) => [
        { groupId: tagSet.Id, name: tagSet.Name, key: includeKey(tagSet.Id), action: { value: "include", text: "in" }, value: [] },
        { groupId: tagSet.Id, name: tagSet.Name, key: excludeKey(tagSet.Id), action: { value: "exclude", text: "not in" }, value: [] },
    ]);
}
function splitAllFiltersIntoAvailableAndSelected(allFilters: FilterRow[], queryValues: Map<string, string[]>) {
    return allFilters.reduce((filters: {
        available: FilterRow[];
        selected: FilterRow[];
    }, filter: FilterRow) => {
        const value = queryValues.get(filter.key);
        if (value) {
            filters.selected.push({ ...filter, value });
        }
        else {
            filters.available.push(filter);
        }
        return filters;
    }, { available: [], selected: [] });
}
export interface FilterRow {
    groupId: string;
    name: string;
    key: string;
    action: {
        value: string;
        text: string;
    };
    value: string[];
}
function buildSelectedValuesFromQuery(query: TenantsFilterState, tags: Map<string, TagWithSet>): Map<string, string[]> {
    const values = new Map<string, string[]>();
    if (query.filterByName) {
        values.set(fixedFilters.nameInclude.key, [query.filterByName]);
    }
    if (query.filterByExcludedName) {
        values.set(fixedFilters.nameExclude.key, [query.filterByExcludedName]);
    }
    if (query.filterByProject) {
        values.set(fixedFilters.projectInclude.key, [query.filterByProject]);
    }
    if (query.filterByExcludedProject) {
        values.set(fixedFilters.projectExclude.key, [query.filterByExcludedProject]);
    }
    if (query.filterByEnvironment) {
        values.set(fixedFilters.environmentInclude.key, [query.filterByEnvironment]);
    }
    if (query.filterByExcludedEnvironment) {
        values.set(fixedFilters.environmentExclude.key, [query.filterByExcludedEnvironment]);
    }
    for (const tagId of query.filterByTags) {
        const item = tags.get(tagId);
        if (item) {
            const key = includeKey(item.set.Id);
            const selectedSet = values.get(key) || [];
            values.set(key, [...selectedSet, tagId]);
        }
    }
    for (const tagId of query.filterByExcludedTags) {
        const item = tags.get(tagId);
        if (item) {
            const key = excludeKey(item.set.Id);
            const selectedSet = values.get(key) || [];
            values.set(key, [...selectedSet, tagId]);
        }
    }
    return values;
}
function replaceSelectedFilters(filters: FilterRow[], previous: FilterRow | undefined, newFilter: FilterRow | undefined): FilterRow[] {
    if (previous && !newFilter) {
        // remove
        return filters.filter((filter) => filter.key !== previous.key);
    }
    if (!previous && newFilter) {
        // add
        return [...filters, newFilter];
    }
    if (previous && newFilter) {
        // replace
        return filters.map((filter) => (filter.key === previous.key ? replaceFilter(filter, newFilter) : filter));
    }
    return filters;
}
const replaceFilter = (original: FilterRow, newFilter: FilterRow) => (original.groupId === newFilter.groupId ? { ...newFilter, value: original.value } : newFilter);
