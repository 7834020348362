import { css } from "@emotion/css";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import type { AnalyticActionDispatcher } from "~/analytics/Analytics";
import { Action, useAnalyticActionDispatch } from "~/analytics/Analytics";
import { AddOrCloneTenant } from "~/areas/tenants/Tenants/AddOrCloneTenant";
import Onboarding from "~/areas/tenants/Tenants/Onboarding";
import type { TenantsFilterState } from "~/areas/tenants/Tenants/hooks/useTenantsFilterState";
import { useTenantsState } from "~/areas/tenants/Tenants/hooks/useTenantsState";
import TenantsDataTable from "~/areas/tenants/components/DataTable/TenantsDataTable";
import { useTenantFiltersData } from "~/areas/tenants/components/Filtering/hooks/useTenantFiltersData";
import NumberedPagingBar from "~/areas/tenants/components/Paging/NumberedPagingBar";
import { repository } from "~/clientInstance";
import AreaTitle from "~/components/AreaTitle/index";
import { ContextualHelpLayout } from "~/components/ContextualHelpLayout/ContextualHelpLayout";
import type { DoBusyTask, Errors } from "~/components/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import ExternalLink from "~/components/Navigation/ExternalLink";
import PaperLayout from "~/components/PaperLayout/index";
import routeLinks from "~/routeLinks";
import { themeTokens } from "../../../../../../packages/design-system-tokens/src";
import Toolbar from "../components/Toolbar/Toolbar";
type TenantsV2Props = {
    trackAction: AnalyticActionDispatcher;
} & RouteComponentProps;
class TenantsV2Internal extends DataBaseComponent<TenantsV2Props> {
    constructor(props: TenantsV2Props) {
        super(props);
        this.state = {};
    }
    render() {
        return (<TenantsV2Layout errors={this.errors} busy={this.state.busy}>
                <TenantsV2Content doBusyTask={this.doBusyTask}/>
            </TenantsV2Layout>);
    }
    static displayName = "TenantsV2Internal";
}
interface TenantsV2LayoutProps {
    busy?: Promise<void>;
    errors: Errors | undefined;
}
function TenantsV2Layout({ children, busy, errors }: React.PropsWithChildren<TenantsV2LayoutProps>) {
    return (<main id="maincontent">
            <AreaTitle link={routeLinks.tenants} title="Tenants">
                <AddOrCloneTenant />
            </AreaTitle>

            <ContextualHelpLayout>
                <PaperLayout title={null} fullWidth={true} flatStyle={true} busy={busy} errors={errors}>
                    {children}
                </PaperLayout>
            </ContextualHelpLayout>
        </main>);
}
interface TenantsV2ContentProps {
    doBusyTask: DoBusyTask;
}
function TenantsV2Content({ doBusyTask }: TenantsV2ContentProps) {
    const trackAction = useAnalyticActionDispatch();
    const { tableState, pageState, filterState, tenantsActions } = useTenantsState(doBusyTask);
    const filterData = useTenantFiltersData(doBusyTask);
    function onFilterChanged(newFilter: TenantsFilterState) {
        tenantsActions.setTenantsFilter(newFilter);
        tenantsActions.setPageNumber(1);
    }
    async function onCsvDownloadRequested() {
        trackAction("Download CSV", { resource: "Tenant", action: Action.Download });
        await doBusyTask(async () => repository.Tenants.tenantsCsv(filterState));
    }
    if (!tableState || !filterData) {
        return null;
    }
    const { tenants, filteredTenantCount, totalTenantCount } = tableState;
    const { pageNumber, pageSize } = pageState;
    const paginationTotalCount = filteredTenantCount === null ? totalTenantCount : filteredTenantCount;
    if (totalTenantCount === 0) {
        return <Onboarding />;
    }
    return (<>
            <EarlyAccessCallout />
            <div className={styles.tenantsContainer}>
                <Toolbar tenantFiltersData={filterData} filteredCount={filteredTenantCount} totalCount={totalTenantCount} onFilterChanged={onFilterChanged} onCsvDownloadRequested={onCsvDownloadRequested}/>
                <TenantsDataTable tenants={tenants} totalItems={totalTenantCount} doBusyTask={doBusyTask}>
                    <NumberedPagingBar totalItems={paginationTotalCount} pageNumber={pageNumber} pageSize={pageSize} onPagingSelectionChange={(newPageNumber, newPageSize) => {
            tenantsActions.setPageNumber(newPageNumber);
            tenantsActions.setPageSize(newPageSize);
        }} pageSizeOptions={[30, 50, 100]}/>
                </TenantsDataTable>
            </div>
        </>);
}
function EarlyAccessCallout() {
    return (<div className={styles.eapContainer}>
            <span className={styles.eapFeedbackCta}>
                We're currently working on improving the tenant overview dashboard, and we would love to get your input. Please <ExternalLink href="https://oc.to/NewTenantOverviewFeedback">share your feedback</ExternalLink> and let's team up to make
                this feature even better.
            </span>
        </div>);
}
const styles = {
    eapContainer: css({
        padding: "0.5rem 1rem",
    }),
    eapFeedbackCta: css({
        fontSize: "0.9rem",
        color: themeTokens.color.text.subtle,
    }),
    tenantsContainer: css({
        marginLeft: "1rem",
        marginRight: "1rem",
    }),
};
export const TenantsV2 = withRouter(TenantsV2Internal);
