/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */
import { ActionButton, ActionButtonType } from "@octopusdeploy/design-system-components";
import type { ProjectResource, GitRef } from "@octopusdeploy/octopus-server-client";
import { HasGitPersistenceSettings, toGitBranch, toGitRefShort } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { useState } from "react";
import type { CommitMessageWithDetails } from "~/areas/projects/components/VersionControl/CommitMessageWithDetails";
import BusyFromPromise from "~/components/BusyFromPromise";
import { DialogTrigger } from "~/components/Dialog/DialogTrigger";
import type { PrimaryActionProps } from "~/components/FormPaperLayout/FormPaperLayout";
import { GitCommitIcon } from "~/primitiveComponents/dataDisplay/Icon";
import ToolTip from "~/primitiveComponents/dataDisplay/ToolTip/ToolTip";
import CommitDialog from "./CommitDialog";
import styles from "./styles.module.less";
interface CommitButtonProps {
    busyLabel: string;
    label: string;
    disabled: boolean | undefined;
    gitRef: GitRef;
    canCommitToGitRef: boolean;
    defaultSummary: string;
    commit(): Promise<unknown>;
    commitMessage: CommitMessageWithDetails;
    onCommitMessageChanged: (commitMessage: CommitMessageWithDetails) => void;
    commitButtonAccessibleName: string;
    commitMessageAccessibleName: string;
    commitDetailsAccessibleName: string;
    quickCommitAccessibleName?: string;
    onNewBranchCreating?: (branchName: string) => Promise<void>;
    onInitializing?: (openDialog: () => void) => void;
    projectId: string;
    hideNewBranchOptions?: boolean;
}
const CommitButton: React.FC<CommitButtonProps> = (props) => {
    const [commitPromise, setCommitPromise] = useState<Promise<unknown> | undefined>(undefined);
    const iconStyle = {
        margin: "0 10px",
        width: "20px",
        height: "100%",
    };
    const commit = () => {
        // We need both buttons to de disabled together.
        // Currently, the disabled state is encapsulated in a single ActionButton, so we can't reuse our existing logic and have to re-invent the wheel a little bit here
        const promise = props.commit();
        setCommitPromise(promise);
    };
    return (<DialogTrigger render={({ open, openDialog, closeDialog }) => {
            if (props.onInitializing) {
                props.onInitializing(openDialog);
            }
            return (<>
                        <BusyFromPromise promise={commitPromise}>
                            {(busy: boolean) => {
                    const disabled = props.disabled || busy;
                    const label = busy ? props.busyLabel : props.label;
                    return (<div role="group" className={styles.buttonContainer}>
                                        <ActionButton label={label} type={ActionButtonType.Save} disabled={disabled} onClick={openDialog}/>
                                        {/*While it would be nicer to use IconButton here, the styling is all wrong here - this is more like an ActionButton with an Icon as its content */}
                                        {/*We should revisit our components to see if we can make this less awkward*/}
                                        <ToolTip content={<>
                                                    {props.canCommitToGitRef ? (<>
                                                            Quick commit to <strong>{toGitRefShort(props.gitRef)}</strong>
                                                        </>) : (<>Cannot commit directly to {props.gitRef}</>)}
                                                </>}>
                                            <ActionButton label={"Quick commit"} hideTitle={true} accessibleName={props.quickCommitAccessibleName ?? "Quick commit"} type={ActionButtonType.Save} disabled={disabled || !props.canCommitToGitRef} className={styles.openDialogButton} onClick={commit}>
                                                <GitCommitIcon key="icon" style={iconStyle}/>
                                            </ActionButton>
                                        </ToolTip>
                                    </div>);
                }}
                        </BusyFromPromise>

                        <CommitDialog gitRef={props.gitRef} canCommitToGitRef={props.canCommitToGitRef} defaultSummary={props.defaultSummary} commitMessage={props.commitMessage} onCloseWithoutCommit={closeDialog} open={open} onCommit={() => {
                    commit();
                    closeDialog();
                }} onCommitMessageChanged={props.onCommitMessageChanged} commitMessageAccessibleName={props.commitMessageAccessibleName} commitDetailsAccessibleName={props.commitDetailsAccessibleName} onNewBranchCreating={async (branchName: string) => {
                    if (props.onNewBranchCreating) {
                        await props.onNewBranchCreating(branchName);
                        closeDialog();
                    }
                }} projectId={props.projectId} hideNewBranchOptions={props.hideNewBranchOptions}/>
                    </>);
        }}/>);
};
CommitButton.displayName = "CommitButton"
export default CommitButton;
export interface GetCommitButtonProps {
    project: ProjectResource;
    gitRef: GitRef | undefined;
    canCommitToGitRef: boolean;
    defaultCommitMessage: string;
    commitMessage: CommitMessageWithDetails;
    updateCommitMessage: (commitMessage: CommitMessageWithDetails) => void;
    commitMessageAccessibleName: string;
    commitDetailsAccessibleName: string;
    commitButtonAccessibleName: string;
    quickCommitAccessibleName?: string;
    actionButtonProps: PrimaryActionProps;
    onNewBranchCreating?: (branchName: string) => Promise<void>;
    onInitializing?: (openDialog: () => void) => void;
    disableNewBranchCreation?: boolean;
}
export const GetCommitButton: React.FC<GetCommitButtonProps> = (props) => {
    if (!HasGitPersistenceSettings(props.project.PersistenceSettings))
        throw new Error("Config as Code: Trying to access a VCS Property on a non-VCS Project.");
    return (<CommitButton gitRef={props.gitRef ?? toGitBranch(props.project.PersistenceSettings.DefaultBranch)} canCommitToGitRef={props.canCommitToGitRef} label={props.actionButtonProps.label} disabled={props.actionButtonProps.disabled} busyLabel={props.actionButtonProps.busyLabel} defaultSummary={props.defaultCommitMessage} commitMessage={props.commitMessage} onCommitMessageChanged={(commitMessage) => props.updateCommitMessage(commitMessage)} commit={props.actionButtonProps.onClick} commitMessageAccessibleName={props.commitMessageAccessibleName} commitDetailsAccessibleName={props.commitDetailsAccessibleName} commitButtonAccessibleName={props.commitButtonAccessibleName} quickCommitAccessibleName={props.quickCommitAccessibleName} onNewBranchCreating={props.onNewBranchCreating} onInitializing={props.onInitializing} projectId={props.project.Id} hideNewBranchOptions={props.disableNewBranchCreation}/>);
};
GetCommitButton.displayName = "GetCommitButton"
