/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Button, NavigationButton } from "@octopusdeploy/design-system-components";
import type { ProjectResource } from "@octopusdeploy/octopus-server-client";
import { DashboardRenderMode } from "@octopusdeploy/octopus-server-client";
import { AnalyticLinkLocationProvider } from "@octopusdeploy/portal-analytics";
import { isEqual, sortBy } from "lodash";
import MobileDetect from "mobile-detect";
import * as React from "react";
import type { AnalyticActionDispatcher } from "~/analytics/Analytics";
import { Action, useAnalyticActionDispatch, useAnalyticSampleProjectDispatch } from "~/analytics/Analytics";
import type { DashboardDataSourceState, RenderDashboardProps } from "~/areas/projects/components/DashboardDataSource/DashboardDataSource";
import DashboardDataSource, { hasReachedMinimumThresholdForHidingOnboardingOnDashboard } from "~/areas/projects/components/DashboardDataSource/DashboardDataSource";
import type { DashboardFilters } from "~/areas/projects/components/DashboardDataSource/DataCube";
import { DimensionTypes } from "~/areas/projects/components/DashboardDataSource/DataCube";
import { generateGuid } from "~/areas/projects/components/Process/generation/index";
import ProjectDashboard from "~/areas/projects/components/ProjectDashboard/index";
import { client, repository } from "~/clientInstance";
import AreaTitle from "~/components/AreaTitle";
import { BaseComponent } from "~/components/BaseComponent/BaseComponent";
import { ContextualHelpLayout } from "~/components/ContextualHelpLayout/ContextualHelpLayout";
import FeatureToggleVisibility from "~/components/FeatureToggle/New/FeatureToggleVisibility";
import { InternalRedirect } from "~/components/Navigation/InternalRedirect/index";
import DashboardOnboardingLayout from "~/components/ProjectBasedActivation/DashboardOnboardingLayout";
import type { IQuery } from "~/components/QueryStringFilters/QueryStringFilters";
import { QueryStringFilters } from "~/components/QueryStringFilters/QueryStringFilters";
import Section from "~/components/Section";
import { Select } from "~/components/form";
import ComponentRow from "../../../components/ComponentRow/index";
import FilterSearchBox from "../../../components/FilterSearchBox/index";
import PaperLayout from "../../../components/PaperLayout/index";
import routeLinks from "../../../routeLinks";
import DashboardLimitSummary from "./DashboardLimitSummary";
import styles from "./style.module.less";
interface DashboardOverviewFilter {
    projectGroupId: string;
    projectName: string;
}
interface DashboardOverviewState {
    filter: DashboardOverviewFilter;
    matchCount: number;
    onboardingProject?: ProjectResource;
    redirectTo?: string;
}
const defaultFilter: DashboardOverviewFilter = {
    projectName: "",
    projectGroupId: "",
};
interface DashboardOverviewQuery extends IQuery {
    projectGroupId?: string;
    projectName?: string;
}
interface DashboardOverviewInternalProps {
    dispatchAction: AnalyticActionDispatcher;
}
const DashboardQueryStringFilters = QueryStringFilters.For<DashboardOverviewFilter, DashboardOverviewQuery>();
const DashboardDispatcher = ({ dispatchAction }: {
    dispatchAction: AnalyticActionDispatcher;
}) => {
    const [dispatched, setDispatched] = React.useState(false);
    if (!dispatched) {
        dispatchAction("Dashboard Viewed", { action: Action.View, resource: "Dashboard" });
        setDispatched(true);
    }
    return <></>;
};
interface SampleProjectButtonProps {
    onClick: (sampleProjectRoute: string) => void;
}
export function SampleProjectButton({ onClick }: SampleProjectButtonProps) {
    const dispatch = useAnalyticSampleProjectDispatch();
    return (<FeatureToggleVisibility toggle={"SampleProjectsFeatureToggle"}>
            <Button label={"Sample Project"} onClick={async () => {
            const sampleProjectReference = generateGuid();
            dispatch("Request Sample Project", {
                sampleProjectReference,
            });
            const sampleProject = await repository.Projects.createSampleProject("Sample Project", sampleProjectReference);
            const route = routeLinks.project(sampleProject.Project).overview;
            onClick(route);
        }} importance={"primary"}></Button>
        </FeatureToggleVisibility>);
}
class DashboardOverviewInternal extends BaseComponent<DashboardOverviewInternalProps, DashboardOverviewState> {
    constructor(props: DashboardOverviewInternalProps) {
        super(props);
        this.state = {
            filter: defaultFilter,
            matchCount: 0,
            onboardingProject: undefined,
            redirectTo: undefined,
        };
    }
    render() {
        if (client.spaceId === null) {
            return (<main className={styles.container}>
                    <AreaTitle link={routeLinks.root} title="Dashboard"/>
                </main>);
        }
        if (this.state.redirectTo !== undefined) {
            return <InternalRedirect to={this.state.redirectTo}/>;
        }
        return (<main className={styles.container}>
                <DashboardQueryStringFilters filter={this.state.filter} getQuery={getQueryFromFilters} getFilter={getFilter} onFilterChange={(filter) => this.setState({ filter })}/>
                <AreaTitle link={routeLinks.root} title="Dashboard">
                    <NavigationButton label="Configure" href={routeLinks.dashboard.configure}/>
                    <SampleProjectButton onClick={(sampleProjectRoute) => this.setState({ redirectTo: sampleProjectRoute })}/>
                </AreaTitle>
                <ContextualHelpLayout>
                    <DashboardDataSource filters={this.createDashboardFilters()} render={this.renderDataSource}/>
                </ContextualHelpLayout>
            </main>);
    }
    private showDashboard(dashboardData: DashboardDataSourceState) {
        const cube = dashboardData.cube;
        const groups = sortBy(cube!.projectGroupIndex, (g) => g.Name.toLowerCase()).map((g) => ({ value: g.Id, text: g.Name }));
        const hasFilter = !isEqual(defaultFilter, this.state.filter);
        // Disable autoFocus filtering for mobile (Android has issues and is annoying users).
        const md = new MobileDetect(window.navigator.userAgent);
        const autoFocus = md.isPhoneSized() ? false : true;
        return (<Section>
                <DashboardDispatcher dispatchAction={this.props.dispatchAction}/>
                <div className={styles.filterHeaderContainer} key="A" role="search">
                    <div className={styles.filterFieldContainer}>
                        <ComponentRow className={styles.filter}>
                            {groups.length > 1 && (<div className={styles.filterField}>
                                    <Select placeholder="Filter by project group" items={groups} onChange={this.handleGroupChange} value={this.state.filter.projectGroupId} allowClear={true}/>
                                </div>)}
                            <div className={styles.filterField}>
                                <FilterSearchBox placeholder="Filter by project name" inputClassName={styles.filterInput} value={this.state.filter.projectName} onChange={this.handleNameChange} autoFocus={autoFocus} fullWidth={true}/>
                            </div>
                            <DashboardLimitSummary matchCount={this.state.matchCount} projectLimit={dashboardData.projectLimit ?? null} hasFilter={hasFilter} totalProjects={Object.keys(dashboardData.cube!.projectIndex).length}/>
                        </ComponentRow>
                    </div>
                </div>
                <ProjectDashboard key="B" cube={dashboardData.cube!} filters={this.createDashboardFilters()} maximumRows={dashboardData.projectLimit} showDeploymentCounts={true} onProjectCountChanged={this.handleProjectCountChange} dashboardRenderMode={DashboardRenderMode.VirtualizeColumns}/>
            </Section>);
    }
    private renderDataSource = (dataSource: RenderDashboardProps) => {
        if (!dataSource.hasInitialLoaded)
            return <PaperLayout flatStyle={true} fullWidth={true} busy={dataSource.busy} errors={dataSource.errors} className={styles.paper}></PaperLayout>;
        const totalProjects = Object.keys(dataSource.cube!.projectIndex).length;
        const hasCompletedDeployments = dataSource.cube!.deployments.find((d) => d.IsCompleted) !== undefined;
        const canContinueOnboarding = totalProjects === 1 && !hasCompletedDeployments;
        if (hasReachedMinimumThresholdForHidingOnboardingOnDashboard(dataSource) || canContinueOnboarding) {
            const project = dataSource.cube!.projectIndex[Object.keys(dataSource.cube!.projectIndex)[0]];
            return (<div className={styles.onboardingPanelOuterContainer}>
                    <AnalyticLinkLocationProvider location="Onboarding dashboard">
                        <DashboardOnboardingLayout canContinueOnboarding={canContinueOnboarding} onboardingProject={project}/>
                    </AnalyticLinkLocationProvider>
                </div>);
        }
        return (<PaperLayout flatStyle={true} fullWidth={true} busy={dataSource.busy} errors={dataSource.errors} className={styles.paper}>
                {dataSource.hasInitialLoaded && this.showDashboard(dataSource)}
            </PaperLayout>);
    };
    private handleGroupChange = (projectGroupId: string | undefined) => {
        this.setState((prev) => ({ filter: { ...prev.filter, projectGroupId } }));
    };
    private handleNameChange = (projectName: string) => {
        this.setState((prev) => ({ filter: { ...prev.filter, projectName } }));
    };
    private createDashboardFilters: () => DashboardFilters = () => {
        const filter = this.state.filter;
        const groupFilters = filter.projectGroupId !== "" ? { [filter.projectGroupId]: true } : null!;
        const projectNameFilters = filter.projectName !== "" ? { [filter.projectName]: true } : null!;
        return {
            rowDimension: DimensionTypes.Project,
            columnDimension: DimensionTypes.Environment,
            groupBy: DimensionTypes.ProjectGroup,
            [DimensionTypes.ProjectGroup]: groupFilters,
            [DimensionTypes.ProjectName]: projectNameFilters,
        };
    };
    private handleProjectCountChange = (matchCount: number) => {
        // don't re-render if count is the same. Easier to check
        // here than shouldComponentUpdate
        if (matchCount !== this.state.matchCount) {
            this.setState({ matchCount });
        }
    };
    static displayName = "DashboardOverviewInternal";
}
function getQueryFromFilters(filter: DashboardOverviewFilter): DashboardOverviewQuery {
    return {
        projectGroupId: filter.projectGroupId,
        projectName: filter.projectName,
    };
}
function getFilter(query: DashboardOverviewQuery): DashboardOverviewFilter {
    return {
        projectGroupId: query.projectGroupId || "",
        projectName: query.projectName || "",
    };
}
const DashboardOverview = () => {
    const dispatchAction = useAnalyticActionDispatch();
    return <DashboardOverviewInternal dispatchAction={dispatchAction}/>;
};
export default DashboardOverview;
