import { BooleanRadioButtonGroup, BooleanRadioButton } from "@octopusdeploy/design-system-components";
import { Permission } from "@octopusdeploy/octopus-server-client";
import React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import { ExpandableFormSection, Note, Summary } from "~/components/form";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout";
import ToolTip from "~/primitiveComponents/dataDisplay/ToolTip";
type BuiltInWorkerFeatureProps = {
    isEnabled: boolean;
    onChange: (isEnabled: boolean) => void;
};
export function BuiltInWorkerFeature({ isEnabled, onChange }: BuiltInWorkerFeatureProps) {
    return (<ExpandableFormSection key="IsBuiltInWorkerEnabled" errorKey="IsBuiltInWorkerEnabled" title="Run steps on Octopus Server" summary={isEnabled ? Summary.default("Enabled") : Summary.summary("Disabled")} help="Enable steps to execute on the Octopus Server's built-in worker.">
            {isAllowed({ permission: Permission.AdministerSystem }) ? getBuiltInWorkerRadioButtons(isEnabled, onChange) : getPermissionRequiredMessage(isEnabled)}

            <Note style={{ marginTop: "1rem" }}>
                This feature enables Azure, AWS, Terraform and some scripts steps to use the
                <ExternalLink href="BuiltinWorker"> built-in worker</ExternalLink> to run Calamari on the Octopus Server. If the built-in worker is disabled, these steps can't run on the Octopus Server and worker pools should be provisioned to allow
                these steps to run. Learn more about <ExternalLink href="Worker">workers</ExternalLink>.
            </Note>
        </ExpandableFormSection>);
}
function getBuiltInWorkerRadioButtons(isEnabled: boolean, onChange: (isEnabled: boolean) => void): React.ReactNode {
    return (<>
            <BooleanRadioButtonGroup value={isEnabled} onChange={onChange}>
                <BooleanRadioButton value={true} label="Enabled"/>
                <BooleanRadioButton value={false} label="Disabled"/>
            </BooleanRadioButtonGroup>
            {!isEnabled && (<Callout title={"Be careful when disabling the built-in worker"} type={CalloutType.Warning}>
                    <span>Disabling this option may impact existing steps that are configured to run on the built in worker. Deployments containing those steps will fail unless an external worker is provisioned. </span>
                </Callout>)}
        </>);
}
function getPermissionRequiredMessage(isEnabled: boolean): React.ReactNode {
    return (<ToolTip content="This feature can only be modified by the System Administrator" position="left">
            <Callout type={CalloutType.Information} title={"Permission required"}>
                The {Permission.AdministerSystem} permission is required to {isEnabled} the Built-in Worker.
            </Callout>
        </ToolTip>);
}
