import type { EnvironmentResource, ProjectSummaryResource, TagResource, TagSetResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { useState } from "react";
import { repository } from "~/clientInstance";
import type { DoBusyTask } from "~/components/DataBaseComponent/index";
import { useDoBusyTaskEffect } from "~/components/DataBaseComponent/index";
import { hasPermission } from "~/components/PermissionCheck/PermissionCheck";
import * as tenantTagsets from "~/components/tenantTagsets";
export interface TenantFiltersData {
    projects: Map<string, ProjectSummaryResource>;
    environments: Map<string, EnvironmentResource>;
    tagSets: Map<string, TagSetResource>;
    tags: Map<string, TagWithSet>;
}
export interface TagWithSet {
    tag: TagResource;
    set: TagSetResource;
}
export const useTenantFiltersData = (doBusyTask: DoBusyTask): TenantFiltersData | null => {
    const [projects, setProjects] = useState<Map<string, ProjectSummaryResource> | null>(null);
    const [environments, setEnvironments] = useState<Map<string, EnvironmentResource> | null>(null);
    const [tagSets, setTagSets] = useState<Map<string, TagSetResource> | null>(null);
    const [tags, setTags] = useState<Map<string, TagWithSet> | null>(null);
    useDoBusyTaskEffect(doBusyTask, async () => {
        const [projects, environments, tagSets] = await Promise.all([loadProjects(), loadEnvironments(), tenantTagsets.getAll()]);
        const setLookup = tagSets.reduce((lookup, env) => lookup.set(env.Id, env), new Map());
        const tagLookup = tagSets.flatMap((ts) => ts.Tags.map((tag) => ({ tag, set: ts }))).reduce((lookup, item) => lookup.set(item.tag.Id, item), new Map<string, TagWithSet>());
        setProjects(projects);
        setEnvironments(environments);
        setTagSets(setLookup);
        setTags(tagLookup);
    }, []);
    return projects && environments && tagSets && tags ? { projects, environments, tagSets, tags } : null;
};
const loadProjects = async (): Promise<Map<string, ProjectSummaryResource>> => {
    if (!hasPermission(Permission.ProjectView)) {
        return new Map();
    }
    const all = await repository.Projects.summaries();
    return all.reduce((lookup, project) => lookup.set(project.Id, project), new Map<string, ProjectSummaryResource>());
};
const loadEnvironments = async (): Promise<Map<string, EnvironmentResource>> => {
    if (!hasPermission(Permission.EnvironmentView)) {
        return new Map();
    }
    const all = await repository.Environments.all();
    return all.reduce((lookup, env) => lookup.set(env.Id, env), new Map<string, EnvironmentResource>());
};
