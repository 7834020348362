import { css } from "@emotion/css";
import type { EnvironmentResource, ProjectSummaryResource, TagResource } from "@octopusdeploy/octopus-server-client";
import React from "react";
import type { TagWithSet } from "~/areas/tenants/components/Filtering/hooks/useTenantFiltersData";
import { EnvironmentChip, FilterTextChip, MissingChip, ProjectChip } from "~/components/Chips/index";
import Tag from "~/components/Tag/index";
export function IncludedNameChip({ value }: {
    value: string;
}) {
    return <FilterTextChip filterText={`"${value}"`}/>;
}
export function ExcludedNameChip({ value }: {
    value: string;
}) {
    return (<React.Fragment>
            <IsNot></IsNot>
            <FilterTextChip filterText={`"${value}"`}/>
        </React.Fragment>);
}
export function IncludedProjectChip({ id, projects }: {
    id: string;
    projects: Map<string, ProjectSummaryResource>;
}) {
    return <SelectedProject id={id} projects={projects}/>;
}
export function ExcludedProjectChip({ id, projects }: {
    id: string;
    projects: Map<string, ProjectSummaryResource>;
}) {
    return (<React.Fragment>
            <IsNot></IsNot>
            <SelectedProject id={id} projects={projects}/>
        </React.Fragment>);
}
interface SelectedProjectProps {
    id: string;
    projects: Map<string, ProjectSummaryResource>;
}
const SelectedProject = ({ id, projects }: SelectedProjectProps) => {
    const project = projects.get(id);
    return project ? <ProjectChip projectName={project.Name}/> : <MissingChip lookupId={id}/>;
};
export function IncludedEnvironmentChip({ id, environments }: {
    id: string;
    environments: Map<string, EnvironmentResource>;
}) {
    return <SelectedEnvironment id={id} environments={environments}/>;
}
export function ExcludedEnvironmentChip({ id, environments }: {
    id: string;
    environments: Map<string, EnvironmentResource>;
}) {
    return (<React.Fragment>
            <IsNot></IsNot>
            <SelectedEnvironment id={id} environments={environments}/>
        </React.Fragment>);
}
interface SelectedEnvironmentProps {
    id: string;
    environments: Map<string, EnvironmentResource>;
}
const SelectedEnvironment = ({ id, environments }: SelectedEnvironmentProps) => {
    const environment = environments.get(id);
    return environment ? <EnvironmentChip environmentName={environment.Name}/> : <MissingChip lookupId={id}/>;
};
export function IncludedTagChips({ ids, tags }: {
    ids: string[];
    tags: Map<string, TagWithSet>;
}) {
    const { selected, unknown } = groupTagsByFoundSets(ids, tags);
    return (<React.Fragment>
            <TagChipsGroupedBySet selected={selected}/>
            {selected.size > 0 && unknown.length > 0 ? <And /> : null}
            <MissingTagChips ids={unknown}/>
        </React.Fragment>);
}
export function ExcludedTagChips({ ids, tags }: {
    ids: string[];
    tags: Map<string, TagWithSet>;
}) {
    const { selected, unknown } = groupTagsByFoundSets(ids, tags);
    return (<React.Fragment>
            <TagChipsGroupedBySet selected={selected} excluded={true}/>
            {selected.size > 0 && unknown.length > 0 ? <And /> : null}
            <MissingTagChips ids={unknown}/>
        </React.Fragment>);
}
function TagChipsGroupedBySet({ selected, excluded = false }: {
    selected: Map<string, TagResource[]>;
    excluded?: boolean;
}) {
    return (<React.Fragment>
            {Array.from(selected.entries()).map(([tagSetId, tags], index) => (<React.Fragment key={tagSetId}>
                    {index !== 0 ? <And /> : null}
                    {excluded ? <NotIn /> : null}
                    <TagChips tags={tags}/>
                </React.Fragment>))}
        </React.Fragment>);
}
function TagChips({ tags }: {
    tags: TagResource[];
}) {
    return <React.Fragment>{tags.map((tag) => buildTagChip(tag))}</React.Fragment>;
}
function MissingTagChips({ ids }: {
    ids: string[];
}) {
    return <React.Fragment>{ids.map((id) => buildMissingTagChip(id))}</React.Fragment>;
}
const buildTagChip = (tag: TagResource) => <Tag key={tag.Id} tagName={tag.Name} description={tag.Description} tagColor={tag.Color} small={false}/>;
const buildMissingTagChip = (id: string) => <MissingChip key={id} lookupId={id}/>;
export function And() {
    return <span className={styles.delimiter}>and</span>;
}
function IsNot() {
    return <span className={styles.negation}>is not</span>;
}
function NotIn() {
    return <span className={styles.negation}>not in</span>;
}
interface GroupedTags {
    selected: Map<string, TagResource[]>;
    unknown: string[];
}
function groupTagsByFoundSets(tagIds: string[], tags: Map<string, TagWithSet>): GroupedTags {
    const selected = new Map<string, TagResource[]>();
    const unknown: string[] = [];
    for (const tagId of tagIds) {
        const { tag, set } = tags.get(tagId) || {};
        if (tag && set) {
            const alreadyAdded = selected.get(set.Id) || [];
            selected.set(set.Id, [...alreadyAdded, tag]);
        }
        else {
            unknown.push(tagId);
        }
    }
    return { selected, unknown };
}
const styles = {
    delimiter: css({
        fontSize: "0.9rem",
    }),
    negation: css({
        fontSize: "0.9rem",
        fontStyle: "italic",
        marginLeft: "0.3rem",
    }),
};
