import { BooleanRadioButtonGroup, BooleanRadioButton } from "@octopusdeploy/design-system-components";
import React from "react";
import { ExpandableFormSection, Summary } from "~/components/form";
type NewTenantsOverviewProps = {
    isEnabled: boolean;
    onChange: (isEnabled: boolean) => void;
};
export function NewTenantsOverview({ isEnabled, onChange }: NewTenantsOverviewProps) {
    return (<ExpandableFormSection key="IsNewTenantsOverviewEnabled" errorKey="IsNewTenantsOverviewEnabled" title="New Tenants Overview" summary={isEnabled ? Summary.default("Enabled") : Summary.summary("Disabled")} help="Try out the updated Tenants Overview page">
            <BooleanRadioButtonGroup value={isEnabled} onChange={onChange}>
                <BooleanRadioButton value={true} label="Enabled" isDefault={true}/>
                <BooleanRadioButton value={false} label="Disabled"/>
            </BooleanRadioButtonGroup>
        </ExpandableFormSection>);
}
