/* eslint-disable @typescript-eslint/init-declarations */
/* eslint-disable no-eq-null */
import { Checkbox } from "@octopusdeploy/design-system-components";
import { logger } from "@octopusdeploy/logging";
import type { DeploymentSettingsResource, ProjectResource } from "@octopusdeploy/octopus-server-client";
import { Permission, ProcessType } from "@octopusdeploy/octopus-server-client";
import type * as H from "history";
import { flatten } from "lodash";
import React, { useEffect, useState } from "react";
import { useProjectContext } from "~/areas/projects/context";
import AdvancedFilterLayout from "~/components/AdvancedFilterLayout/AdvancedFilterLayout";
import type { DoBusyTask, Errors } from "~/components/DataBaseComponent";
import FilterSearchBox from "~/components/FilterSearchBox";
import { NoResults } from "~/components/Images/NoResults/NoResults";
import InternalLink from "~/components/Navigation/InternalLink";
import { PermissionCheck } from "~/components/PermissionCheck";
import SidebarLayout from "~/components/SidebarLayout/SidebarLayout";
import { Select, UnstructuredFormSection } from "~/components/form";
import EnvironmentSelect from "~/components/form/EnvironmentSelect/EnvironmentSelect";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout";
import routeLinks from "~/routeLinks";
import { isVersioningConfigurationValid } from "../DeploymentProcessSettings/InvalidConfigurationCallout";
import RunbookOnboarding from "../Runbooks/RunbookOnboarding";
import { RunbookProcessOverviewSidebar } from "../Runbooks/RunbookProcessOverviewSidebar";
import Onboarding from "./Common/Onboarding";
import SideBar from "./Common/SideBar";
import { useActionTemplatesFromContext } from "./Contexts/ProcessActionTemplatesContextProvider";
import type { ProcessContextProps } from "./Contexts/ProcessContext";
import { useProcessContext } from "./Contexts/ProcessContext";
import type { ProcessEditorSearchFilterNamedResource } from "./Contexts/ProcessSearchFilter";
import { useProcessSearchFilterContext } from "./Contexts/ProcessSearchFilter/ProcessSearchFilterContext";
import processListItemStyles from "./ListItems/ProcessListItem.module.less";
import type { ProcessSearchFilter } from "./ListItems/ProcessListItemContextMenu";
import ProcessListPageListItemForAction from "./ListItems/ProcessListPageListItemForAction";
import ProcessListPageListItemForParentStep from "./ListItems/ProcessListPageListItemForParentStep";
import type { ProcessListLayoutLoaderLookupData } from "./ProcessListLayoutLoader";
class FilterLayout extends AdvancedFilterLayout<ProcessSearchFilter> {
}
export interface ProcessListProps {
    lookups: ProcessListLayoutLoaderLookupData;
    busy?: boolean;
    errors?: Errors;
    doBusyTask: DoBusyTask;
    location: H.Location;
    history: H.History;
}
const ProcessList: React.FC<ProcessListProps> = ({ lookups, busy, errors, doBusyTask, location, history }) => {
    const processContext = useProcessContext();
    const processType = processContext.selectors.getProcessType();
    const projectContext = useProjectContext();
    const projectContextRepository = projectContext.state.projectContextRepository;
    const processSearchFilterContext = useProcessSearchFilterContext();
    const project = projectContext.state.model;
    const actionTemplates = useActionTemplatesFromContext();
    const [deploymentSettings, setDeploymentSettings] = useState<DeploymentSettingsResource | undefined>(undefined);
    useEffect(() => {
        async function getDeploymentSettings() {
            const result = await projectContextRepository.DeploymentSettings.get();
            setDeploymentSettings(result);
        }
        doBusyTask(async () => getDeploymentSettings());
    }, [projectContextRepository, doBusyTask]);
    const { state: processContextState } = processContext;
    const { state: processSearchFilterContextLookupsState, actions: processSearchFilterContextActions } = processSearchFilterContext;
    const channelsById = lookups.channelsById;
    const hasValidProcess: boolean = processContext.selectors.hasValidProcess();
    const hasSteps: boolean = processContext.selectors.hasSteps();
    return (<>
            {hasValidProcess && !hasSteps && (processType === ProcessType.Deployment ? <Onboarding location={location} history={history}/> : <RunbookOnboarding />)}
            {hasValidProcess && hasSteps && (<>
                    <SidebarLayout sideBar={processType === ProcessType.Deployment ? (<SideBar process={processContextState.model.process} includedScriptModules={lookups.includedScriptModules} lifecyclePreview={lookups.lifecyclePreview} environments={Object.values(lookups.environmentsById || {})} onDataChanged={() => processContext.actions.refreshLookupData()}/>) : (<RunbookProcessOverviewSidebar projectId={projectContext.state.model.Id} onDataChanged={() => processContext.actions.refreshLookupData()} includedScriptModules={lookups.includedScriptModules}/>)} hideTopDivider={processType === ProcessType.Runbook} // Our tabs already provide a dividing line in runbooks.
        >
                        <FilterLayout filter={processSearchFilterContextLookupsState.searchFilter} filterSections={[
                {
                    render: (<>
                                            <PermissionCheck permission={Permission.EnvironmentView} wildcard={true}>
                                                <EnvironmentSelect value={processSearchFilterContextLookupsState.searchFilter.environment?.Id} onChange={(environmentId) => {
                            let environment: ProcessEditorSearchFilterNamedResource;
                            if (environmentId) {
                                const resource = lookups.environmentsById[environmentId];
                                environment = {
                                    Id: resource.Id,
                                    Name: resource.Name,
                                };
                            }
                            processSearchFilterContextActions.onFilterChange((t) => ({ ...t, environment }));
                        }} environments={Object.values(lookups.environmentsById)} allowClear={true} allowFilter={true} fieldName="environment"/>
                                            </PermissionCheck>
                                            {processType === ProcessType.Deployment && channelsById && Object.keys(channelsById).length > 1 && (<Select value={processSearchFilterContextLookupsState.searchFilter.channel?.Id} onChange={(channelId) => {
                                let channel: ProcessEditorSearchFilterNamedResource;
                                if (channelId) {
                                    const resource = channelsById[channelId];
                                    channel = {
                                        Id: resource.Id,
                                        Name: resource.Name,
                                    };
                                }
                                processSearchFilterContextActions.onFilterChange((t) => ({ ...t, channel }));
                            }} items={Object.values(channelsById).map((e) => ({ value: e.Id, text: e.Name }))} allowClear={true} allowFilter={true} fieldName="channel"/>)}
                                            <Checkbox label="Include unscoped steps" value={!!processSearchFilterContextLookupsState.searchFilter.includeUnscoped} onChange={(includeUnscoped) => {
                            processSearchFilterContextActions.onFilterChange((prev) => ({ ...prev, includeUnscoped }));
                        }}/>
                                        </>),
                },
            ]} onFilterReset={(filter) => processSearchFilterContextActions.onFilterChange(() => filter)} defaultFilter={processSearchFilterContext.getEmptyFilter()} initiallyShowFilter={processSearchFilterContext.isFiltering} additionalHeaderFilters={[
                <FilterSearchBox placeholder="Filter by name..." value={processSearchFilterContextLookupsState.searchFilter.filterKeyword} onChange={(filterKeyword) => processSearchFilterContextActions.onFilterChange((prev) => ({ ...prev, filterKeyword }))} autoFocus={true}/>,
            ]} renderContent={() => (<>
                                    {processType === ProcessType.Deployment && deploymentSettings && getInvalidConfigurationCallouts(processContext, project, deploymentSettings)}
                                    <div className={processListItemStyles.stepList}>
                                        {processSearchFilterContext.filteredSteps.steps.length > 0 ? (processSearchFilterContext.filteredSteps.steps
                    .filter((x) => x.filtered)
                    .map(({ step: filteredStep, index }) => {
                    const step = processContext.selectors.getStepById(filteredStep.Id);
                    if (!step) {
                        logger.info("Failed to find step with name {stepName}", { stepName: filteredStep.Name });
                        return null;
                    }
                    if (step.ActionIds.length === 1) {
                        const action = processContext.selectors.getActionById(step.ActionIds[0]);
                        return <ProcessListPageListItemForAction key={action.Id} actionTemplates={actionTemplates} step={step} action={action} actionIndex={index} lookups={lookups}/>;
                    }
                    else {
                        return <ProcessListPageListItemForParentStep key={step.Id} actionTemplates={actionTemplates} step={step} stepIndex={index} lookups={lookups} errors={errors}/>;
                    }
                })) : (<NoResults />)}
                                    </div>
                                </>)}/>
                    </SidebarLayout>
                </>)}
        </>);
};
ProcessList.displayName = "ProcessList"
function calculateDetailsUrl(project: ProjectResource, id: string): string {
    return routeLinks.project(project.Id).deployments.process.step(id);
}
function getInvalidAutomaticReleaseCreationConfigurationCallout(processContext: ProcessContextProps, project: ProjectResource) {
    if (project.AutoCreateRelease) {
        if (project.ReleaseCreationStrategy == null || project.ReleaseCreationStrategy.ReleaseCreationPackage == null) {
            return (<div>
                    This project is configured to use Automatic Release Creation, but the step is missing. Please adjust the <InternalLink to={routeLinks.project(project.Slug).triggers}>Automatic Release Creation</InternalLink> configuration.
                </div>);
        }
        else {
            const action = flatten(processContext.selectors.getAllActions()).filter((a) => a.Name === project.ReleaseCreationStrategy.ReleaseCreationPackage.DeploymentAction);
            if (action && action.length > 0 && action[0].IsDisabled) {
                return (<div>
                        <span>
                            Step <InternalLink to={calculateDetailsUrl(project, action[0].Id)}>{action[0].Name}</InternalLink> is currently used for Automatic Release Creation, but it has been disabled.
                        </span>
                        <span>
                            Please re-enable the step, or adjust the <InternalLink to={routeLinks.project(project.Slug).triggers}>Automatic Release Creation</InternalLink> configuration.
                        </span>
                    </div>);
            }
        }
    }
    return null;
}
function getInvalidConfigurationCallouts(processContext: ProcessContextProps, project: ProjectResource, deploymentSettings: DeploymentSettingsResource) {
    const arcCallout = getInvalidAutomaticReleaseCreationConfigurationCallout(processContext, project);
    const actions = flatten(processContext.selectors.getAllActions());
    const versioningCallout = isVersioningConfigurationValid(project, deploymentSettings, actions);
    if (arcCallout || versioningCallout) {
        return (<UnstructuredFormSection stretchContent={true}>
                <Callout type={CalloutType.Warning} title="Invalid Configuration">
                    {arcCallout}
                    {versioningCallout}
                </Callout>
            </UnstructuredFormSection>);
    }
}
export default ProcessList;
