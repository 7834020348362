/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { NavigationButton, NavigationButtonType, DatePicker } from "@octopusdeploy/design-system-components";
import { logger } from "@octopusdeploy/logging";
import type { EnvironmentResource, OctopusServerNodeResource, ProjectResource, RunbookResource, SpaceResource, StatsResourceCollection, TaskResource, TaskTypeResource, TenantResource } from "@octopusdeploy/octopus-server-client";
import { Permission, Repository, TaskState } from "@octopusdeploy/octopus-server-client";
import { orderBy } from "lodash";
import moment from "moment";
import * as React from "react";
import { ProjectStatus } from "~/areas/projects/components/ProjectStatus/ProjectStatus";
import { client, repository, session } from "~/clientInstance";
import AdvancedFilterLayout, { AdvancedFilterCheckbox } from "~/components/AdvancedFilterLayout";
import type { DataBaseComponentState, Refresh } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import type { Errors } from "~/components/DataBaseComponent/Errors";
import { Feature, FeatureToggle } from "~/components/FeatureToggle";
import { SpaceMultiSelect } from "~/components/MultiSelect/SpaceMultiSelect";
import InternalLink from "~/components/Navigation/InternalLink/InternalLink";
import { PagingDataTable } from "~/components/PagingDataTable/PagingDataTable";
import PaperLayout from "~/components/PaperLayout/PaperLayout";
import PermissionCheck, { hasPermission, isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import type { IQuery } from "~/components/QueryStringFilters/QueryStringFilters";
import { QueryStringFilters } from "~/components/QueryStringFilters/QueryStringFilters";
import TaskDetails from "~/components/TaskDetails/TaskDetails";
import EnvironmentSelect from "~/components/form/EnvironmentSelect/EnvironmentSelect";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout";
import Select from "~/primitiveComponents/form/Select/Select";
import routeLinks from "~/routeLinks";
import DateFormatter from "~/utils/DateFormatter";
import { timeOperation, timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import { arrayValueFromQueryString } from "~/utils/ParseHelper/ParseHelper";
import styles from "./style.module.less";
const DateTimeFormat = global.Intl.DateTimeFormat;
export interface Filter {
    ids?: string[];
    state?: TaskFilterState;
    project?: string;
    runbook?: string;
    environment?: string;
    name?: string;
    node?: string;
    tenant?: string;
    spaces: string[];
    includeSystem: boolean;
    hasPendingInterruptions?: boolean | null;
    hasWarningsOrErrors?: boolean;
    dateFilterType?: DateFilterType;
    fromDate?: Date;
    toDate?: Date;
}
export interface TasksQuery extends IQuery {
    serverNode?: string;
    state?: string;
    ids?: string[];
    project?: string;
    runbook?: string;
    environment?: string;
    name?: string;
    tenant?: string;
    spaces?: string[];
    hasPendingInterruptions?: string;
    includeSystem?: string;
    hasWarningsOrErrors?: string;
    dateFilterType?: DateFilterType;
    fromDate?: string;
    toDate?: string;
}
interface TasksState extends DataBaseComponentState {
    tasks?: StatsResourceCollection;
    projects: {
        Id: string;
        Name: string;
    }[];
    runbooks: {
        Id: string;
        Name: string;
        ProjectId: string;
    }[];
    environments: EnvironmentResource[];
    nodes: OctopusServerNodeResource[];
    tenants: TenantResource[];
    spaces: SpaceResource[];
    taskTypes: TaskTypeResource[];
    currentPageIndex: number; // We manage our own paging due to automatic refresh / timers.
    filter: Filter;
    hasLoadedOnce?: boolean;
}
class TaskResourceDataTable extends PagingDataTable<TaskResource<any>> {
}
class FilterLayout extends AdvancedFilterLayout<Filter> {
}
export enum TaskFilterState {
    Incomplete = "Incomplete",
    Running = "Running",
    Completed = "Completed",
    Unsuccessful = "Unsuccessful",
    Queued = "Queued",
    Executing = "Executing",
    Cancelling = "Cancelling",
    Success = "Success",
    Canceled = "Canceled",
    TimedOut = "TimedOut",
    Failed = "Failed"
}
enum DateFilterType {
    QueueTime = "Queue Time",
    StartTime = "Start Time",
    CompletedTime = "Completed Time"
}
function getTaskStatesFromFilterState(taskFilterState: TaskFilterState) {
    switch (taskFilterState) {
        case TaskFilterState.Incomplete:
            return [TaskState.Queued, TaskState.Executing, TaskState.Cancelling].join(",");
        case TaskFilterState.Running:
            return [TaskState.Executing, TaskState.Cancelling].join(",");
        case TaskFilterState.Completed:
            return [TaskState.Canceled, TaskState.Success, TaskState.Failed, TaskState.TimedOut].join(",");
        case TaskFilterState.Unsuccessful:
            return [TaskState.Canceled, TaskState.Failed, TaskState.TimedOut].join(",");
        case TaskFilterState.Queued:
            return TaskState.Queued;
        case TaskFilterState.Executing:
            return TaskState.Executing;
        case TaskFilterState.Cancelling:
            return TaskState.Cancelling;
        case TaskFilterState.Success:
            return TaskState.Success;
        case TaskFilterState.Canceled:
            return TaskState.Canceled;
        case TaskFilterState.TimedOut:
            return TaskState.TimedOut;
        case TaskFilterState.Failed:
            return TaskState.Failed;
    }
}
function getTaskFilter(query: TasksQuery): Filter {
    return {
        node: query.serverNode,
        state: TaskFilterState[query.state as keyof typeof TaskFilterState],
        ids: arrayValueFromQueryString(query.ids),
        hasPendingInterruptions: query.hasPendingInterruptions === "true",
        hasWarningsOrErrors: query.hasWarningsOrErrors === "true",
        environment: query.environment,
        project: query.project,
        runbook: query.runbook,
        tenant: query.tenant,
        spaces: arrayValueFromQueryString(query.spaces),
        name: query.name,
        includeSystem: query.includeSystem === "true",
        dateFilterType: query.dateFilterType,
        fromDate: query.fromDate ? moment(query.fromDate).toDate() : moment().subtract(1, "month").startOf("day").toDate(),
        toDate: query.toDate ? moment(query.toDate).toDate() : moment().endOf("day").toDate(),
    };
}
export function getTaskQuery(filter: Filter): TasksQuery {
    return {
        serverNode: filter.node,
        state: filter.state,
        ids: filter.ids,
        environment: filter.environment,
        hasPendingInterruptions: filter.hasPendingInterruptions ? "true" : undefined,
        hasWarningsOrErrors: filter.hasWarningsOrErrors ? "true" : undefined,
        name: filter.name,
        project: filter.project,
        runbook: filter.runbook,
        tenant: filter.tenant,
        dateFilterType: filter.dateFilterType,
        fromDate: filter.dateFilterType && filter.fromDate ? moment(filter.fromDate).format() : undefined,
        toDate: filter.dateFilterType && filter.toDate ? moment(filter.toDate).format() : undefined,
        ...(filter.spaces.length !== 0 ? { spaces: filter.spaces } : {}),
        ...(filter.includeSystem ? { includeSystem: "true" } : {}),
    };
}
const TasksQueryStringFilters = QueryStringFilters.For<Filter, TasksQuery>();
export interface TasksLayoutRenderProps {
    busy: Promise<void>;
    errors: Errors;
    children: React.ReactNode;
}
export interface TaskCellRenderProps {
    task: TaskResource<any>;
}
export interface TasksProps {
    restrictToProjectId?: string;
    restrictToRunbookId?: string;
    restrictToTenantId?: string;
    restrictToTaskTypes?: string[];
    hideScriptConsoleAction?: boolean;
    hideAdvancedFilters?: boolean;
    renderLayout?: (props: TasksLayoutRenderProps) => React.ReactElement<any>;
    renderCell?: (props: TaskCellRenderProps) => React.ReactElement<any>;
    onNewItems?(items: any[]): Promise<any[]>;
}
class Tasks extends DataBaseComponent<TasksProps, TasksState> {
    private isRestrictedView = false;
    private timeLastRefereshTookInMilliseconds = 0;
    constructor(props: TasksProps) {
        super(props);
        this.state = {
            currentPageIndex: 0,
            filter: createEmptyTaskFilter(this.props.restrictToTaskTypes),
            environments: [],
            nodes: [],
            projects: [],
            runbooks: [],
            tenants: [],
            taskTypes: [],
            spaces: [],
        };
        this.isRestrictedView = this.isRestrictedDocumentView();
    }
    componentDidMount() {
        return this.doBusyTask(async () => {
            let restrictedToProject: ProjectResource | null = null;
            if (this.props.restrictToProjectId && hasPermission(Permission.ProjectView)) {
                restrictedToProject = await repository.Projects.get(this.props.restrictToProjectId);
            }
            let getProjects = Promise.resolve([] as {
                Id: string;
                Name: string;
            }[]);
            if (hasPermission(Permission.ProjectView)) {
                if (this.props.restrictToProjectId) {
                    getProjects = Promise.resolve([restrictedToProject!]);
                }
                else {
                    getProjects = repository.Projects.summaries();
                }
            }
            let getRunbooks = Promise.resolve([] as RunbookResource[]);
            if (hasPermission(Permission.RunbookView)) {
                if (this.props.restrictToRunbookId) {
                    getRunbooks = Promise.resolve([await repository.Runbooks.get(this.props.restrictToRunbookId)]);
                }
                else {
                    if (!!restrictedToProject) {
                        const projectRunbooks = await repository.Projects.getRunbooks(restrictedToProject, { take: Repository.takeAll });
                        getRunbooks = Promise.resolve(projectRunbooks.Items);
                    }
                    else {
                        getRunbooks = repository.Runbooks.all();
                    }
                }
            }
            const getEnvironments = hasPermission(Permission.EnvironmentView) ? repository.Environments.all() : Promise.resolve([] as EnvironmentResource[]);
            const getNodes = repository.OctopusServerNodes.all();
            const getTenants = !this.props.restrictToTenantId && isAllowed({ permission: Permission.TenantView, tenant: "*" }) ? repository.Tenants.all() : Promise.resolve([]);
            const getTaskTypes = repository.Tasks.taskTypes();
            const spaces = await repository.Users.getSpaces(session.currentUser!);
            const runbooks = await getRunbooks;
            const sortedRunbooks = orderBy(runbooks, [(x) => x.ProjectId], "asc");
            this.setState({
                projects: await getProjects,
                runbooks: sortedRunbooks,
                environments: await getEnvironments,
                nodes: await getNodes,
                tenants: await getTenants,
                spaces: spaces,
                taskTypes: await getTaskTypes,
            });
            this.doRefresh = await this.startRefreshLoop(() => this.searchInternal(this.state.filter, this.state.currentPageIndex), this.getRefreshInterval, false, timeOperationOptions.forRefresh());
        }, { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    }
    getRefreshInterval = (hidden: boolean) => {
        if (this.timeLastRefereshTookInMilliseconds > 750) {
            // Delay is 5 seconds if it took 0.75s and then linearly up to 60 seconds if it took 90s or more
            const delay = Math.min(this.timeLastRefereshTookInMilliseconds * (20 / 3), 60 * 1000);
            // If hidden, 4 times as long, but no more than 2 minutes
            return hidden ? Math.min(delay * 4, 120 * 1000) : delay;
        }
        return hidden ? 20000 : 5000;
    };
    isRestrictedDocumentView(): boolean {
        return !!this.props.restrictToProjectId || !!this.props.restrictToRunbookId || !!this.props.restrictToTenantId;
    }
    search(filter: Filter) {
        this.setState({ filter, hasLoadedOnce: false, currentPageIndex: 0 }, async () => this.doRefresh());
    }
    composeDateFilter() {
        const { filter: { dateFilterType, fromDate, toDate }, } = this.state;
        // We add one second to 'toDate' because moment considers the end of day to be 11:59:59.
        // We convert it to UTC because that's how we store dates in the backend.
        switch (dateFilterType) {
            case DateFilterType.CompletedTime:
                return {
                    fromCompletedDate: moment.utc(fromDate).format(),
                    toCompletedDate: moment.utc(toDate).add(1, "second").format(),
                };
            case DateFilterType.QueueTime:
                return {
                    fromQueueDate: moment.utc(fromDate).format(),
                    toQueueDate: moment.utc(toDate).add(1, "second").format(),
                };
            case DateFilterType.StartTime:
                return {
                    fromStartDate: moment.utc(fromDate).format(),
                    toStartDate: moment.utc(toDate).add(1, "second").format(),
                };
            default:
                return {};
        }
    }
    async searchInternal<K extends keyof Filter>(filter: Filter, currentPageIndex: number) {
        this.setState({ currentPageIndex });
        const startTime = moment();
        const searchFilter = {
            states: getTaskStatesFromFilterState(filter.state!),
            project: this.props.restrictToProjectId ? this.props.restrictToProjectId! : filter.project!,
            runbook: this.props.restrictToRunbookId ? this.props.restrictToRunbookId! : filter.runbook!,
            environment: filter.environment,
            name: filter.name,
            node: filter.node,
            tenant: this.props.restrictToTenantId ? this.props.restrictToTenantId : filter.tenant,
            spaces: getSpacesFilter(),
            skip: this.state.tasks ? currentPageIndex * this.state.tasks.ItemsPerPage : 0,
            ids: filter.ids && filter.ids.length ? filter.ids.join(",") : undefined!,
            hasPendingInterruptions: filter.hasPendingInterruptions ? true : undefined!,
            includeSystem: filter.includeSystem,
            hasWarningsOrErrors: filter.hasWarningsOrErrors ? true : undefined!,
            ...this.composeDateFilter(),
        };
        const useOptimization = session.featureToggles?.includes("PermissionCheckOptimizationFeatureToggle");
        const tasks = await timeOperation(timeOperationOptions.forRefresh(), () => (useOptimization ? repository.Tasks.getUnpaginatedTasks(searchFilter as any) : repository.Tasks.list(searchFilter as any)));
        this.timeLastRefereshTookInMilliseconds = moment().diff(startTime, "milliseconds");
        if (this.props.onNewItems) {
            await this.props.onNewItems(tasks.Items);
        }
        return {
            tasks,
            hasLoadedOnce: true,
        };
        function getSpacesFilter() {
            const hasTaskViewInAnySpace = session!.currentPermissions!.hasPermissionInAnyScope(Permission.TaskView);
            if (filter.spaces.length === 0) {
                if (hasTaskViewInAnySpace) {
                    return ["all"];
                }
                else {
                    return [];
                }
            }
            return filter.spaces;
        }
    }
    clear() {
        return this.search(createEmptyTaskFilter(this.props.restrictToTaskTypes));
    }
    restrictedViewMode() {
        return !!this.props.restrictToProjectId || !!this.props.restrictToRunbookId || !!this.props.restrictToTenantId;
    }
    getProjectName() {
        const project = this.state.projects.find(({ Id }) => Id === this.props.restrictToProjectId);
        return project?.Name;
    }
    render() {
        const list = this.state.tasks && (<TaskResourceDataTable title="Tasks" initialData={this.state.tasks} onRow={(item: any) => this.buildRow(item)} onRowRedirectUrl={(task: TaskResource<any>) => routeLinks.task(task.Id).root} onNewItems={this.props.onNewItems} rowColumnClassName={styles.taskDetailsCell} headerColumns={["", "State", "Start Time", "Completed Time", "Duration"]} onEmpty={this.handleOnEmpty} showPagingInNumberedStyle={true} currentPageIndex={this.state.currentPageIndex} onPageSelected={async (skip: number, p: number) => {
                this.setState({ hasLoadedOnce: false, currentPageIndex: p }, async () => this.doRefresh());
            }} rowClassName={styles.taskRow}/>);
        const filterSections = [
            {
                render: (<div>
                        <div className={styles.checkboxFiltersContainer}>
                            <AdvancedFilterCheckbox label="Awaiting manual intervention" value={this.state.filter.hasPendingInterruptions!} onChange={(hasPendingInterruptions) => this.search({ ...this.state.filter, hasPendingInterruptions })}/>
                            {this.renderIncludeSystem()}
                            <AdvancedFilterCheckbox label="Has warnings or errors" value={this.state.filter.hasWarningsOrErrors!} onChange={(hasWarningsOrErrors) => this.search({ ...this.state.filter, hasWarningsOrErrors })}/>
                        </div>
                        {this.renderSpaceSelector()}
                        <Select value={this.state.filter.name} allowFilter={true} onChange={(name) => this.search({ ...this.state.filter, name })} items={this.state.taskTypes.map((t) => ({ value: t.Id, text: t.Name }))} allowClear={true} fieldName="task type" placeholder="All task types" label="By task type"/>
                        {!this.restrictedViewMode() && (<Select value={this.state.filter.node} onChange={(node) => this.search({ ...this.state.filter, node })} items={this.state.nodes.map((n) => ({ value: n.Id, text: n.Name }))} allowClear={true} label="By node" placeholder="All nodes"/>)}
                        {this.renderSpaceSpecificSelectors()}
                        {this.renderDateSelectors()}
                    </div>),
            },
        ];
        const stateFilter = (<div className={styles.states}>
                <Select value={this.state.filter.state} onChange={(state) => this.search({ ...this.state.filter, state: state as TaskFilterState })} items={Object.keys(TaskFilterState).map((t) => ({ value: t, text: t }))} allowClear={true} fieldName="task state" placeholder="All task states"/>
            </div>);
        return (<>
                <TasksQueryStringFilters filter={this.state.filter} onFilterChange={(filter) => this.search(filter)} getFilter={getTaskFilter} getQuery={getTaskQuery}/>
                {this.renderWithLayout(<>
                        {!this.isRestrictedView && this.state.tasks && this.state.tasks.TotalCounts && (<StatisticsPanel totals={this.state.tasks.TotalCounts} setFilter={(partialStatisticsFilter) => {
                        const filterWithPreservedSpacePartitions = {
                            spaces: this.state.filter.spaces,
                            includeSystem: this.state.filter.includeSystem,
                        };
                        this.search({ ...filterWithPreservedSpacePartitions, ...partialStatisticsFilter });
                    }}/>)}
                        {!this.props.hideAdvancedFilters ? (<FilterLayout filter={this.state.filter} defaultFilter={createEmptyTaskFilter(this.props.restrictToTaskTypes)} additionalHeaderFilters={[stateFilter]} onFilterReset={(resetFilter) => {
                        this.search(resetFilter);
                    }} filterSections={filterSections} renderContent={() => list}/>) : (list)}
                    </>)}
            </>);
    }
    private renderWithLayout(children: React.ReactNode) {
        if (this.props.renderLayout) {
            return this.props.renderLayout({ busy: this.state.busy!, errors: this.errors!, children });
        }
        return (<PaperLayout title="Tasks" breadcrumbTitle={this.props.restrictToProjectId && this.getProjectName()} busy={this.state.busy} enableLessIntrusiveLoadingIndicator={this.state.hasLoadedOnce} errors={this.errors} sectionControl={!this.props.hideScriptConsoleAction && (<PermissionCheck permission={[Permission.AdministerSystem, Permission.TaskCreate]} wildcard={true}>
                            <NavigationButton label="Script Console" href={routeLinks.tasks.console} type={NavigationButtonType.Primary}/>
                        </PermissionCheck>)} statusSection={<ProjectStatus doBusyTask={this.doBusyTask}/>} fullWidth={true}>
                {children}
            </PaperLayout>);
    }
    private renderDateSelectors = () => {
        const getFriendlyDate = () => new DateTimeFormat("en-US", {
            day: "numeric",
            month: "long",
            year: "numeric",
        }).format;
        const fromError = this.state.filter.fromDate! > this.state.filter.toDate! ? "Must be before the \"To\" date" : undefined;
        const toError = this.state.filter.fromDate! > this.state.filter.toDate! ? "Must be after the \"From\" date" : undefined;
        return (<>
                <Select value={this.state.filter.dateFilterType} allowFilter={false} sortItems={false} onChange={(dateFilterType) => this.search({ ...this.state.filter, dateFilterType: dateFilterType as DateFilterType })} items={Object.values(DateFilterType).map((v) => ({ value: v, text: v }))} allowClear={true} fieldName="date filter type" placeholder="Select a date field" label="By date"/>
                {this.state.filter.dateFilterType && (<>
                        <div className={styles.datepickerWrap}>
                            <DatePicker value={this.state.filter.fromDate!} onChange={(fromDate) => this.search({ ...this.state.filter, fromDate })} label="From" error={fromError} format={datePickerFormat} variant={"inline"}/>
                        </div>
                        <div className={styles.datepickerWrap}>
                            <DatePicker value={this.state.filter.toDate!} onChange={(toDate) => this.search({ ...this.state.filter, toDate: moment(toDate).endOf("day").toDate() })} label="To" error={toError} format={datePickerFormat} variant={"inline"}/>
                        </div>
                    </>)}
            </>);
    };
    private renderSpaceSpecificSelectors = () => {
        // These are linked to access in the current space, because that's where the data will come from
        // we need to revisit how these will work going forward to make the filtering easier to do cross-space
        const isWithinASpace = client.spaceId;
        return (isWithinASpace && (<>
                    {!this.props.restrictToProjectId && (<PermissionCheck permission={Permission.ProjectView} wildcard={true}>
                            <Select value={this.state.filter.project} onChange={(project) => this.search({ ...this.state.filter, project })} items={this.state.projects.map((p) => ({ value: p.Id, text: p.Name }))} allowClear={true} allowFilter={true} fieldName="project" placeholder="All projects"/>
                        </PermissionCheck>)}
                    {!this.props.restrictToRunbookId && (<PermissionCheck permission={Permission.RunbookView} wildcard={true}>
                            <Select value={this.state.filter.runbook} onChange={(runbook) => this.search({ ...this.state.filter, runbook })} items={this.state.runbooks.map((r) => {
                    const defaultSelectItem = {
                        value: r.Id,
                        text: r.Name,
                    };
                    if (!!this.props.restrictToProjectId || !!this.props.restrictToRunbookId) {
                        return defaultSelectItem;
                    }
                    const runbookProject = this.state.projects.find((p) => p.Id === r.ProjectId);
                    if (!runbookProject) {
                        logger.error("Failed to find project for runbook. This should not happen.");
                        return defaultSelectItem;
                    }
                    return {
                        value: r.Id,
                        text: `${runbookProject.Name} - ${r.Name}`,
                    };
                })} allowClear={true} allowFilter={true} fieldName="runbook" placeholder="All runbooks"/>
                        </PermissionCheck>)}
                    <PermissionCheck permission={Permission.EnvironmentView} wildcard={true}>
                        <EnvironmentSelect value={this.state.filter.environment} onChange={(environment) => this.search({ ...this.state.filter, environment })} environments={this.state.environments} allowClear={true} allowFilter={true} fieldName="environment" placeholder="All environments"/>
                    </PermissionCheck>
                    {!this.props.restrictToTenantId && (<FeatureToggle feature={Feature.MultiTenancy}>
                            <PermissionCheck permission={Permission.TenantView} tenant="*">
                                <Select value={this.state.filter.tenant} onChange={(tenant) => this.search({ ...this.state.filter, tenant })} items={this.state.tenants.map((t) => ({ value: t.Id, text: t.Name }))} allowClear={true} allowFilter={true} fieldName="tenant" placeholder="All tenants" label="By tenant"/>
                            </PermissionCheck>
                        </FeatureToggle>)}
                </>));
    };
    private renderIncludeSystem = () => {
        if (this.restrictedViewMode()) {
            return null;
        }
        const hasSystemTaskView = session.currentPermissions!.scopeToSystem().hasPermissionInAnyScope(Permission.TaskView);
        if (hasSystemTaskView) {
            return <AdvancedFilterCheckbox label="Include system tasks" value={this.state.filter.includeSystem} onChange={(includeSystem) => this.search({ ...this.state.filter, includeSystem })}/>;
        }
        return null;
    };
    private renderSpaceSelector = () => {
        if (this.restrictedViewMode()) {
            return null;
        }
        const hasTaskViewInAnySpace = session.currentPermissions!.hasPermissionInAnyScope(Permission.TaskView);
        if (!hasTaskViewInAnySpace) {
            return (<div style={{ margin: "1rem 0 0 0" }}>
                    <Callout type={CalloutType.Information} title={"Permission required"}>
                        You do not have {Permission.TaskView} permission in any given Space.
                    </Callout>
                </div>);
        }
        return (<SpaceMultiSelect items={this.state.spaces} label={"By space"} placeholder={this.state.filter.spaces.length ? undefined : "All spaces"} onChange={(spaces: string[]) => this.search({ ...this.state.filter, spaces })} value={this.state.filter.spaces}/>);
    };
    private doRefresh: Refresh = () => Promise.resolve();
    private handleOnEmpty = () => {
        return <div>No tasks found</div>;
    };
    private buildRow = (task: TaskResource<any>) => {
        const doNotShowDuration = task.State === TaskState.Queued || (task.State === TaskState.Canceled && task.StartTime === null);
        return [
            this.renderCell(task),
            task.State,
            task.State === TaskState.Queued ? DateFormatter.dateToLongFormat(task.QueueTime) + " (" + moment(task.QueueTime).fromNow() + ")" : DateFormatter.dateToLongFormat(task.StartTime),
            DateFormatter.dateToLongFormat(task.CompletedTime),
            doNotShowDuration ? null : task.Duration,
        ];
    };
    private renderCell(task: TaskResource<any>) {
        if (this.props.renderCell) {
            return this.props.renderCell({ task });
        }
        return (
        // mark.siedle: We want this InternalLink here so users have the option of standard
        // anchor-behaviour (ie. right click) that you don't get with the onClick from our SimpleDataTable component.
        <InternalLink to={(!!task.SpaceId ? routeLinks.forSpace(task.SpaceId) : routeLinks).task(task).root}>
                <TaskDetails task={task} stripTopBottomPadding={true}/>
            </InternalLink>);
    }
    static displayName = "Tasks";
}
const datePickerFormat = "MMM dd, yyyy";
interface StatisticsPanelProps {
    totals: {
        [state: string]: number;
    };
    setFilter(filter: Partial<Filter>): void;
}
const StatisticsPanel: React.StatelessComponent<StatisticsPanelProps> = (props) => {
    const addSIfNeeded = (length: number) => {
        return length > 1 ? "s" : "";
    };
    const stat = () => {
        function setFilter(e: React.MouseEvent<HTMLAnchorElement>, filter: Partial<Filter>) {
            e.preventDefault();
            props.setFilter(filter);
        }
        const result: JSX.Element[] = [];
        if (props.totals) {
            if (props.totals.Interrupted > 0) {
                result.push(<span key="interrupted">
                        {props.totals.Interrupted} task{addSIfNeeded(props.totals.Interrupted)}{" "}
                        <a href="#" onClick={(e) => setFilter(e, { hasPendingInterruptions: true })}>
                            awaiting intervention
                        </a>
                    </span>);
            }
            if (props.totals.Executing > 0) {
                result.push(<span key="executing">
                        {props.totals.Executing} task{addSIfNeeded(props.totals.Executing)}{" "}
                        <a href="#" onClick={(e) => setFilter(e, { state: TaskFilterState.Executing })}>
                            running
                        </a>
                    </span>);
            }
            if (props.totals.Cancelling > 0) {
                result.push(<span key="cancelling">
                        {props.totals.Cancelling} task{addSIfNeeded(props.totals.Cancelling)}{" "}
                        <a href="#" onClick={(e) => setFilter(e, { state: TaskFilterState.Cancelling })}>
                            cancelling
                        </a>
                    </span>);
            }
            if (props.totals.Queued > 0) {
                result.push(<span key="queued">
                        {props.totals.Queued} task{addSIfNeeded(props.totals.Queued)}{" "}
                        <a href="#" onClick={(e) => setFilter(e, { state: TaskFilterState.Queued })}>
                            waiting in queue
                        </a>
                    </span>);
            }
        }
        if (result.length === 0) {
            return "No active tasks";
        }
        if (result.length === 1) {
            return result;
        }
        const delimited = result.map((v, i) => {
            if (i === result.length - 1) {
                return v;
            }
            if (i === result.length - 2) {
                return [v, <span> and </span>];
            }
            return [v, <span>, </span>];
        });
        return delimited;
    };
    return <div className={styles.stats}>{stat()}</div>;
};
StatisticsPanel.displayName = "StatisticsPanel"
function createEmptyTaskFilter(taskTypes?: string[]): Filter {
    const hasTaskViewInCurrentSpace = session.currentPermissions!.scopeToSpace(client.spaceId).hasPermissionInAnyScope(Permission.TaskView);
    const shouldFilterToCurrentSpace = client.spaceId && hasTaskViewInCurrentSpace;
    return getTaskFilter({
        spaces: shouldFilterToCurrentSpace ? [client.spaceId!] : [],
        includeSystem: shouldFilterToCurrentSpace ? "" : "true",
        name: taskTypes?.join(","),
        fromDate: moment().subtract(1, "month").startOf("day").format(),
        toDate: moment().endOf("day").format(),
    });
}
export default Tasks;
