import * as React from "react";
import type { RouteComponentProps } from "react-router-dom";
import { Switch } from "react-router-dom";
import { RawLog } from "~/areas/tasks/components/RawLog/RawLog";
import ScriptConsole from "~/areas/tasks/components/ScriptConsole/ScriptConsole";
import { withPage } from "~/components/Page/WithPage";
import ReloadableRoute from "~/components/ReloadableRoute/ReloadableRoute";
import pageIds from "~/pageIds";
import routeLinks from "~/routeLinks";
import { TaskScreen } from "../Task/TaskScreen";
import TasksLayout from "../Tasks/TasksLayout";
import InterruptionToProjectRedirect from "./InterruptionToTaskRedirect";
const ScriptConsolePage = withPage({ page: pageIds.tasks.console })(ScriptConsole);
const RawLogPage = withPage({ page: pageIds.task().raw })(RawLog);
const TaskScreenPage = withPage({ page: pageIds.task().root })(TaskScreen);
const TasksLayoutPage = withPage({ page: pageIds.tasks.root })(TasksLayout);
export default class TaskRoutes extends React.Component<RouteComponentProps> {
    render() {
        return (<Switch>
                <ReloadableRoute path={routeLinks.tasks.interruption(":interruptionId")} component={InterruptionToProjectRedirect} exact={true}/>
                <ReloadableRoute path={routeLinks.tasks.console} component={ScriptConsolePage}/>
                <ReloadableRoute path={routeLinks.task(":taskId").raw} component={RawLogPage}/>
                <ReloadableRoute path={routeLinks.task(":taskId").root} component={TaskScreenPage}/>
                <ReloadableRoute path={routeLinks.tasks.root} render={(routeProps) => <TasksLayoutPage {...routeProps}/>}/>
            </Switch>);
    }
    static displayName = "TaskRoutes";
}
