import { css } from "@emotion/css";
// eslint-disable-next-line @octopusdeploy/custom-portal-rules/no-restricted-imports
import { Checkbox } from "@material-ui/core";
import type { TagResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import React from "react";
import type { TenantFiltersData } from "~/areas/tenants/components/Filtering/hooks/useTenantFiltersData";
import type { FilterRow, TenantsFilterRowsActions } from "~/areas/tenants/components/Filtering/hooks/useTenantsFilterRows";
import { MissingChip } from "~/components/Chips/index";
import { IconButtonWithTooltip } from "~/components/IconButtonWithTooltip/index";
import { MultiSelect } from "~/components/MultiSelect/MultiSelect";
import { PermissionCheck } from "~/components/PermissionCheck/index";
import Tag from "~/components/Tag/index";
import type { SelectItem } from "~/components/VirtualListWithKeyboard/SelectItem";
import EnvironmentSelect from "~/components/form/EnvironmentSelect/EnvironmentSelect";
import { Select } from "~/components/form/index";
import type { Item } from "~/primitiveComponents/form/Select/Select";
import Text from "~/primitiveComponents/form/Text/Text";
interface SingleFilterProps {
    selected: FilterRow | undefined;
    availableFilterRows: FilterRow[];
    actions: TenantsFilterRowsActions;
    data: TenantFiltersData;
    index: number;
}
interface SingleFilterOption extends Item {
    actions: SingleFilterAction[];
}
interface SingleFilterAction extends Item {
    filter: FilterRow;
}
export default function SingleFilter({ selected, availableFilterRows, actions, data, index }: SingleFilterProps) {
    const { availableOptions, currentOption } = groupAvailableFiltersIntoSelectorOptions(selected, availableFilterRows);
    const onOptionChanged = (selectedOptionId: string | undefined) => {
        if (selectedOptionId === selected?.groupId) {
            return;
        }
        const newOption = availableOptions.find((f) => f.value === selectedOptionId);
        if (newOption) {
            const newFilter = newOption.actions[0].filter;
            actions.replaceFilter(selected, newFilter);
        }
    };
    const onActionChanged = (actionId: string | undefined) => {
        if (currentOption && actionId) {
            const newFilter = currentOption.actions.map((a) => a.filter).find((filter) => filter.action.value === actionId);
            actions.replaceFilter(selected, newFilter);
        }
    };
    const onFilterRemoved = () => actions.replaceFilter(selected, undefined);
    if (selected) {
        return (<>
                <FilterByLabel rowNumber={index + 1}/>
                <FilterSelect selected={selected} availableOptions={availableOptions} onChange={onOptionChanged}/>
                <ActionSelect selected={selected} currentOption={currentOption} onChange={onActionChanged}/>
                <ValueSelect selected={selected} actions={actions} data={data}/>
                <RemoveFilterIcon onFilterRemoved={onFilterRemoved}/>
            </>);
    }
    else {
        return (<>
                <FilterByLabel rowNumber={index + 1}/>
                <FilterSelect selected={selected} availableOptions={availableOptions} onChange={onOptionChanged}/>
            </>);
    }
}
function FilterByLabel({ rowNumber }: {
    rowNumber: number;
}) {
    return <div className={styles.filterByLabel}>{rowNumber}. Filter by</div>;
}
interface FilterSelectProps {
    selected: FilterRow | undefined;
    availableOptions: SingleFilterOption[];
    onChange: (selectedOptionId: string | undefined) => void;
}
function FilterSelect({ selected, availableOptions, onChange }: FilterSelectProps) {
    return (<div>
            <Select value={selected?.groupId} onChange={onChange} items={availableOptions} allowClear={false} allowFilter={true} placeholder="Select a filter" sortItems={false}/>
        </div>);
}
interface ActionSelectProps {
    selected: FilterRow;
    currentOption: SingleFilterOption | undefined;
    onChange: (selectedActionId: string | undefined) => void;
}
function ActionSelect({ currentOption, onChange, selected }: ActionSelectProps) {
    return <div>{currentOption && <Select value={selected.action.value} onChange={onChange} items={currentOption.actions} allowClear={false}/>}</div>;
}
interface ValueSelectProps {
    selected: FilterRow;
    actions: TenantsFilterRowsActions;
    data: TenantFiltersData;
}
function ValueSelect({ data, selected, actions }: ValueSelectProps) {
    return (<div>
            <TenantFilterInput filter={selected} actions={actions} data={data}></TenantFilterInput>
        </div>);
}
interface RemoveFilterIconProps {
    onFilterRemoved: () => void;
}
function RemoveFilterIcon({ onFilterRemoved }: RemoveFilterIconProps) {
    return (<div className={styles.removeButton}>
            <IconButtonWithTooltip toolTipContent="Remove filter" onClick={onFilterRemoved} icon="Cancel"/>
        </div>);
}
function groupAvailableFiltersIntoSelectorOptions(selected: FilterRow | undefined, available: FilterRow[]): {
    availableOptions: SingleFilterOption[];
    currentOption: SingleFilterOption | undefined;
} {
    const availableWithSelected = selected ? available.concat([selected]) : available;
    const groupedOptions = availableWithSelected.reduce((group, filter) => {
        const item = group.get(filter.groupId);
        if (item) {
            item.actions.push({ value: filter.action.value, text: filter.action.text, filter });
        }
        else {
            const newItem: SingleFilterOption = { value: filter.groupId, text: filter.name, actions: [{ value: filter.action.value, text: filter.action.text, filter }] };
            group.set(filter.groupId, newItem);
        }
        return group;
    }, new Map<string, SingleFilterOption>());
    const currentOption = selected ? groupedOptions.get(selected.groupId) : undefined;
    const availableOptions = Array.from(groupedOptions.values());
    return { availableOptions, currentOption };
}
interface TenantFilterInputProps {
    filter: FilterRow;
    actions: TenantsFilterRowsActions;
    data: TenantFiltersData;
}
function TenantFilterInput({ filter, actions, data }: TenantFilterInputProps) {
    if (filter.groupId === "name") {
        return <NameFilter filter={filter} actions={actions}/>;
    }
    if (filter.groupId === "project") {
        return <ProjectFilter filter={filter} actions={actions} data={data}/>;
    }
    if (filter.groupId === "environment") {
        return <EnvironmentFilter filter={filter} actions={actions} data={data}/>;
    }
    if (data.tagSets.has(filter.groupId)) {
        return <TenantTagFilter filter={filter} actions={actions} data={data}/>;
    }
    return <span>Not implemented</span>;
}
interface NameFilterProps {
    filter: FilterRow;
    actions: TenantsFilterRowsActions;
}
function NameFilter({ filter, actions }: NameFilterProps) {
    const currentName = filter.value[0] || "";
    const setName = (name: string) => actions.selectValue(filter.key, [name]);
    return <Text type="text" value={currentName} onChange={setName} autoFocus={false} accessibleName={"Filter by name"} placeholder="Tenant name"/>;
}
function ProjectFilter({ filter, actions, data }: TenantFilterInputProps) {
    const selectable: Item[] = Array.from(data.projects.values(), (p) => ({ value: p.Id, text: p.Name }));
    const current = filter.value[0];
    const setProject = (project: string | undefined) => actions.selectValue(filter.key, project ? [project] : []);
    return (<PermissionCheck permission={Permission.ProjectView} wildcard={true}>
            <Select value={current} onChange={setProject} items={selectable} allowClear={false} allowFilter={true} placeholder="Select a project"/>
        </PermissionCheck>);
}
function EnvironmentFilter({ filter, actions, data }: TenantFilterInputProps) {
    const environments = Array.from(data.environments.values());
    const setEnvironment = (environment: string | undefined) => actions.selectValue(filter.key, environment ? [environment] : []);
    return (<PermissionCheck permission={Permission.EnvironmentView} wildcard={true}>
            <EnvironmentSelect value={filter.value[0]} onChange={setEnvironment} environments={environments} allowClear={false} allowFilter={true} placeholder="Select an environment"/>
        </PermissionCheck>);
}
const TenantTagMultiSelector = MultiSelect<TagResource>();
function TenantTagFilter({ filter, actions, data }: TenantFilterInputProps) {
    const currentSet = data.tagSets.get(filter.groupId);
    if (!currentSet) {
        return <MissingChip lookupId={filter.groupId}/>;
    }
    const setTags = (tags: string[]) => actions.selectValue(filter.key, tags);
    const renderChip = (item: TagResource | SelectItem, onRemove: () => void) => {
        const tag = data.tags.get(item.Id)?.tag;
        if (tag) {
            return <Tag onRequestDelete={onRemove} description={tag.Description} tagName={tag.Name} tagColor={tag.Color} deleteButtonAccessibleName={`Delete ${tag.Name}`}/>;
        }
        else {
            return <MissingChip lookupId={item.Id}/>;
        }
    };
    return (<TenantTagMultiSelector key={filter.key} value={filter.value} items={currentSet.Tags} renderItem={(tag) => ({
            primaryText: (<div className={styles.checkboxRowContainer}>
                        <Checkbox checked={filter.value.includes(tag.Id)}/>
                        {tag.Name}
                    </div>),
        })} onChange={setTags} renderChip={renderChip} fieldName={"tags"} autoFocus={false} accessibleName={`tagSetFilter-${filter.name}-${filter.action.text}`} disableFilterSelectedItems={true} disableCloseOnSelected={true}/>);
}
const styles = {
    filterByLabel: css({
        marginBottom: "1.5rem",
    }),
    removeButton: css({
        display: "flex",
        alignSelf: "center",
    }),
    checkboxRowContainer: css({
        display: "inline-flex",
        alignItems: "center",
    }),
};
