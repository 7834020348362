/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ActionButton, ActionButtonType, NavigationButton, NavigationButtonType } from "@octopusdeploy/design-system-components";
import type { DashboardItemResource, GitReference } from "@octopusdeploy/octopus-server-client";
import { DashboardRenderMode, Permission, Repository, TaskState } from "@octopusdeploy/octopus-server-client";
import cn from "classnames";
import type { Dictionary } from "lodash";
import { cloneDeep, flatten, isEqual, memoize } from "lodash";
import * as React from "react";
import { CellMeasurer, CellMeasurerCache } from "react-virtualized";
import PaperLayout from "~/components/PaperLayout";
import { PermissionCheck } from "~/components/PermissionCheck";
import ToolTip from "~/primitiveComponents/dataDisplay/ToolTip/ToolTip";
import DashboardLimiter from "~/utils/DashboardLimiter/DashboardLimiter";
import InternalNavLink from "../../../../components/Navigation/InternalNavLink/InternalNavLink";
import routeLinks from "../../../../routeLinks";
import type { DashboardFilters, DataCube } from "../DashboardDataSource/DataCube";
import { DimensionTypes } from "../DashboardDataSource/DataCube";
import type { DataSet } from "../DashboardDataSource/DataSet";
import { getDataSet } from "../DashboardDataSource/DataSet";
import { GitRefChip } from "../Releases/GitRefChip/GitRefChip";
import { DeploymentCreateGoal } from "../Releases/ReleasesRoutes/releaseRouteLinks";
import { DashboardCell } from "./DashboardCell/DashboardCell";
import DashboardGrid from "./DashboardGrid";
import styles from "./style.module.less";
interface ProjectDashboardProps {
    cube: DataCube;
    filters: DashboardFilters;
    maximumRows?: number;
    allowDeployments?: boolean;
    showDeploymentCounts?: boolean;
    flatStyle?: boolean;
    dashboardRenderMode: DashboardRenderMode;
    onProjectCountChanged?(projectCount: number): void;
}
interface ProjectDashboardState {
    height: number;
    groupTakeSizeLookup: Record<string, number>; // to minimise rendering for performance.
}
type Group = {
    groupId: string;
    rowsInGroup: string[];
};
export type DeploymentContext = {
    environmentId: string;
    releaseId: string;
    tenantId: string;
    projectId: string;
};
const fixedCellWidth = 260;
export default class ProjectDashboard extends React.Component<ProjectDashboardProps, ProjectDashboardState> {
    private boundingDiv: HTMLElement | null = null;
    private cellMeasurerCaches: ((groupId: string) => CellMeasurerCache) | null = null;
    private initialTop: number | null = null;
    private loadMoreTakeSize: number = Repository.takeDefaultPageSize;
    constructor(props: ProjectDashboardProps) {
        super(props);
        this.cellMeasurerCaches = memoize(this.getCellMeasurerCache);
        this.state = {
            height: 0,
            groupTakeSizeLookup: {},
        };
    }
    componentDidMount() {
        this.calculateTop();
        this.calculateHeight();
        window.addEventListener("resize", this.calculateHeight);
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.calculateHeight);
    }
    calculateTop() {
        if (this.boundingDiv !== null) {
            this.initialTop = this.boundingDiv.getBoundingClientRect().top;
        }
    }
    calculateHeight = () => {
        const approxHeightOfPaddingAndOtherJunk = 90;
        const height = window.innerHeight - this.initialTop! - approxHeightOfPaddingAndOtherJunk;
        this.setState({ height });
    };
    shouldComponentUpdate(nextProps: ProjectDashboardProps, nextState: ProjectDashboardState) {
        return !isEqual(getPropsForComparison(nextProps), getPropsForComparison(this.props)) || nextState.height !== this.state.height || nextState.groupTakeSizeLookup !== this.state.groupTakeSizeLookup;
        function getPropsForComparison(props: ProjectDashboardProps) {
            const { onProjectCountChanged, ...rest } = props;
            return rest;
        }
    }
    render() {
        if (this.state.height === 0) {
            return <div ref={(div) => (this.boundingDiv = div)}/>;
        }
        const dataSet = getDataSet(this.props.filters, this.props.cube);
        const deploymentContext = this.getDeploymentContext(dataSet);
        const groups = this.getProjectRowsLimitedToMaximum(dataSet, this.props.maximumRows!);
        // We calling this in a timeout because the parent sets state
        setTimeout(() => this.notifyProjectCountChanged(flatten(groups.map((g) => g.rowsInGroup)).length), 0);
        return this.redrawMatrix(dataSet, groups, deploymentContext);
    }
    private notifyProjectCountChanged = (count: number) => {
        if (this.props.onProjectCountChanged) {
            this.props.onProjectCountChanged(count);
        }
    };
    private getCellMeasurerCache(groupId: string) {
        // Be careful about changing this width or height. Virtual scrolling requires a set width, but we need to accommodate
        // for a lot of customer scenarios. Too big and we annoy users who have small version numbers. Too small and we annoy
        // customers with long version numbers.
        const height = 70;
        return new CellMeasurerCache({
            defaultWidth: fixedCellWidth,
            minWidth: fixedCellWidth,
            fixedWidth: true,
            defaultHeight: height,
            minHeight: height,
        });
    }
    private getDeploymentContext(dataSet: DataSet): (groupId: string, rowId: string, columnId: string) => DeploymentContext {
        return (groupId: string, rowId: string, columnId: string) => ({
            environmentId: this.tryGetValue(dataSet, DimensionTypes.Environment)(groupId, rowId, columnId),
            releaseId: this.tryGetValue(dataSet, DimensionTypes.Release)(groupId, rowId, columnId),
            tenantId: this.tryGetValue(dataSet, DimensionTypes.Tenant)(groupId, rowId, columnId),
            projectId: this.tryGetValue(dataSet, DimensionTypes.Project)(groupId, rowId, columnId),
        });
    }
    private tryGetValue(dataSet: DataSet, dimensionType: DimensionTypes): (groupId: string, rowId: string, columnId: string) => string {
        const filters = this.props.filters;
        if (dataSet.groupDimension === dimensionType) {
            return (groupId, rowId, columnId) => groupId;
        }
        else if (dataSet.rowDimension === dimensionType) {
            return (groupId, rowId, columnId) => rowId;
        }
        else if (dataSet.columnDimension === dimensionType) {
            return (groupId, rowId, columnId) => columnId;
        }
        else if (filters[dimensionType]) {
            const environmentIds = Object.keys(filters[dimensionType]);
            if (environmentIds.length === 1) {
                return (groupId, rowId, columnId) => environmentIds[0];
            }
        }
        return () => null!;
    }
    private buildColumnTitle(cube: DataCube, dataSet: DataSet, groupId: string, columnId: string) {
        const environmentId = columnId;
        if (dataSet.columnDimension === DimensionTypes.Environment && dataSet.rowDimension === DimensionTypes.Tenant && this.props.filters[DimensionTypes.Release]) {
            const releaseFilters = Object.keys(this.props.filters[DimensionTypes.Release]);
            if (releaseFilters.length === 1) {
                const releaseId = Object.keys(this.props.filters[DimensionTypes.Release])[0];
                const projectId = Object.keys(this.props.filters[DimensionTypes.Project])[0];
                const groupTenantsWithoutSuccess = dataSet
                    .getRowsForGroup(groupId)
                    .filter((tenantId) => tenantId !== null &&
                    cube.tenantIndex[tenantId].ProjectEnvironments[projectId].indexOf(environmentId) !== -1 &&
                    (!dataSet.matrix[groupId] || !dataSet.matrix[groupId][tenantId] || !dataSet.matrix[groupId][tenantId][environmentId] || !dataSet.matrix[groupId][tenantId][environmentId].find((item) => item.State === TaskState.Success)));
                const availableDeployments = (cube.nextAvailableDeployments[releaseFilters[0]] || {})[environmentId];
                if (availableDeployments) {
                    const canDeployAll = availableDeployments.filter((t) => groupTenantsWithoutSuccess.indexOf(t) !== -1);
                    if (canDeployAll.length > 0) {
                        const uri = routeLinks
                            .project(cube.projectIndex[projectId])
                            .release(cube.releaseIndex[releaseId] ? cube.releaseIndex[releaseId].Version : releaseId)
                            .deployments.create(DeploymentCreateGoal.To, environmentId, groupTenantsWithoutSuccess);
                        return (<div className={styles.deployAllCell}>
                                {dataSet.columnTitle(columnId)}
                                <ToolTip content={"Prepare and preview a deployment to this environment"}>
                                    <NavigationButton label="Deploy All..." href={uri} type={NavigationButtonType.Ternary}/>
                                </ToolTip>
                            </div>);
                    }
                }
            }
        }
        return dataSet.columnTitle(columnId);
    }
    private emptyGroupMessage = (dataSet: DataSet, groupId: string): React.ReactNode => {
        const projectId = this.tryGetValue(dataSet, DimensionTypes.Project)(null!, null!, null!);
        if (projectId === null) {
            return null;
        }
        const releaseCreateUri = routeLinks.project(this.props.cube.projectIndex[projectId]).releaseCreate;
        if (dataSet.groupDimension === DimensionTypes.Channel) {
            return (<div className={styles.emptyCell}>
                    There are no releases for this channel yet.&nbsp;
                    <PermissionCheck permission={Permission.ReleaseCreate} project={projectId} tenant="*">
                        <InternalNavLink to={`${releaseCreateUri}?channelId=${groupId}`}>Create a release</InternalNavLink>
                    </PermissionCheck>
                </div>);
        }
        else if (dataSet.groupDimension === DimensionTypes.None) {
            return (<div className={styles.emptyCell}>
                    There are no releases for this project yet.&nbsp;
                    <PermissionCheck permission={Permission.ReleaseCreate} project={projectId} tenant="*">
                        <InternalNavLink to={releaseCreateUri}>Create a release</InternalNavLink>
                    </PermissionCheck>
                </div>);
        }
        return null;
    };
    private redrawMatrix(dataSet: DataSet, groups: Group[], deploymentContext: (groupId: string, rowId: string, columnId: string) => DeploymentContext) {
        if (!dataSet) {
            return null;
        }
        const rowAndColumnCountsPerGroup = groups.map((g) => {
            const groupId = g.groupId;
            return { row: g.rowsInGroup.length, column: dataSet.getColumnsForGroup(groupId).length };
        });
        const dashboardLimit = new DashboardLimiter(rowAndColumnCountsPerGroup);
        // We don't want to clutter cells with channel chips if the UI is grouping things by channel already.
        const shouldShowChannelChips = groups && groups.length === 1 && dataSet.groupDimension !== DimensionTypes.Channel;
        const cube = this.props.cube;
        const tenantsInCube = Object.values(cube.tenantIndex);
        const hasNonDefaultChannels = Object.keys(cube.channelIndex).length > 1;
        const hasReleases = Object.keys(cube.releaseIndex).length > 0;
        const projectEnvironmentTenants: Dictionary<Dictionary<string[]>> = {};
        tenantsInCube.forEach((tenant) => {
            Object.keys(tenant.ProjectEnvironments).forEach((projectId) => {
                tenant.ProjectEnvironments[projectId].forEach((environmentId) => {
                    if (!projectEnvironmentTenants[projectId]) {
                        projectEnvironmentTenants[projectId] = {};
                    }
                    if (!projectEnvironmentTenants[projectId][environmentId]) {
                        projectEnvironmentTenants[projectId][environmentId] = [];
                    }
                    projectEnvironmentTenants[projectId][environmentId].push(tenant.Id);
                });
            });
        });
        return groups.map((g, index) => {
            const groupId = g.groupId;
            const rowsInGroup = g.rowsInGroup;
            const columnsInGroup = dataSet.getColumnsForGroup(groupId);
            const emptyGroupMessage = this.emptyGroupMessage(dataSet, groupId);
            if (rowsInGroup.length === 0) {
                return !emptyGroupMessage ? null : (<PaperLayout key={groupId} fullWidth={true} innerClassName={styles.container} flatStyle={this.props.flatStyle}>
                        <div className={cn(styles.headerCell, styles.highlightColumn)}>{dataSet.groupTitle(groupId) || dataSet.rowLabel()}</div>
                        {emptyGroupMessage}
                    </PaperLayout>);
            }
            const group = dataSet.matrix[groupId] || {};
            const firstColumns = [<div className={cn(styles.centerCell, styles.fullHeight)}>{dataSet.groupTitle(groupId) || dataSet.rowLabel()}</div>, ...rowsInGroup.map((rowId) => dataSet.rowTitle(rowId))];
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const cellRenderer = ({ columnIndex, rowIndex, key, style, parent }: any) => {
                const rowId = rowsInGroup[rowIndex - 1];
                //React-virtualized does not apply the fixed dimension styles for us and we need them otherwise the non-fixed dimension (height) may be calculated incorrectly
                const styleWithWidth = { ...style, width: fixedCellWidth };
                if (columnIndex === 0) {
                    const vcsRef = this.getReleaseVcsRef(rowId);
                    return (<CellMeasurer cache={this.cellMeasurerCaches!(groupId)!} columnIndex={columnIndex} key={key} parent={parent} rowIndex={rowIndex}>
                            <div style={styleWithWidth} className={cn(styles.headerCell, rowIndex > 0 ? styles.border : styles.highlightColumn)} key={key}>
                                {firstColumns[rowIndex]}
                                {vcsRef && (<div className={cn(styles.headerChip)}>
                                        <GitRefChip vcsRef={vcsRef} showCommit={false}/>
                                    </div>)}
                            </div>
                        </CellMeasurer>);
                }
                const columnId = columnsInGroup[columnIndex - 1];
                if (rowIndex === 0) {
                    return (<CellMeasurer cache={this.cellMeasurerCaches!(groupId)} columnIndex={columnIndex} key={key} parent={parent} rowIndex={rowIndex}>
                            <div style={styleWithWidth} className={cn(styles.columnHead, styles.centerCell)} key={key}>
                                {this.buildColumnTitle(this.props.cube, dataSet, groupId, columnId)}
                            </div>
                        </CellMeasurer>);
                }
                const context = deploymentContext(groupId, rowId, columnId);
                const row = group[rowId] || {};
                const deployments = row[columnId];
                const deployment = this.getDeployment(deployments);
                const projectEnvironmentTenantIds = projectEnvironmentTenants[context.projectId] && projectEnvironmentTenants[context.projectId][context.environmentId] ? projectEnvironmentTenants[context.projectId][context.environmentId] : [];
                return (<CellMeasurer cache={this.cellMeasurerCaches!(groupId)} columnIndex={columnIndex} key={key} parent={parent} rowIndex={rowIndex}>
                        <div style={styleWithWidth} className={cn(styles.centerCell, styles.border)} key={key}>
                            <DashboardCell deployment={deployment} deployments={deployments} deploymentContext={context} allowDeployments={this.props.allowDeployments!} showDeploymentCounts={this.props.showDeploymentCounts!} tenants={tenantsInCube} showChannelChips={hasNonDefaultChannels && shouldShowChannelChips} channelName={deployment ? this.getChannelName(deployment.ChannelId)! : null!} hasReleases={hasReleases} environment={cube.environmentIndex[context.environmentId]} project={cube.projectIndex[context.projectId]} nextAvailableDeploymentEnvironments={cube.nextAvailableDeployments[context.releaseId]} releaseVersion={context ? this.getReleaseVersion(context.releaseId)! : null!} projectEnvironmentTenantIds={projectEnvironmentTenantIds}/>
                        </div>
                    </CellMeasurer>);
            };
            const rowAndColumnLimit = dashboardLimit.getLimit(index);
            const isLimited = rowAndColumnLimit.row < rowsInGroup.length || rowAndColumnLimit.column < columnsInGroup.length;
            // For customers NOT using virtual scrolling for rows, we're implementing client-side paging
            // to avoid rendering performance issues ootb. People can then "load more" when they want to
            // see more data (or "load all"), which will encourage them to use the filters.
            let loadMoreComponent: JSX.Element | null = null;
            let loadMoreHeaderIndicator: JSX.Element | null = null;
            if (this.props.dashboardRenderMode === DashboardRenderMode.VirtualizeColumns) {
                const takeNumberOfRows = this.state.groupTakeSizeLookup[groupId] ? this.state.groupTakeSizeLookup[groupId] : this.loadMoreTakeSize;
                const showLoadMoreAction = takeNumberOfRows < rowAndColumnLimit.row;
                rowAndColumnLimit.row = takeNumberOfRows > rowAndColumnLimit.row ? rowAndColumnLimit.row : takeNumberOfRows;
                loadMoreComponent = (<React.Fragment>
                        {showLoadMoreAction && (<div className={styles.loadMoreContainer} key={`lm_${groupId}`}>
                                <div className={styles.loadMoreActions}>
                                    <ActionButton type={ActionButtonType.Secondary} label="Load more" onClick={(e) => this.onLoadMore(groupId)}/>
                                    <div className={styles.loadMoreSubText}>
                                        Or use filters to narrow the dashboard results (or{" "}
                                        <a href="#" onClick={async (e) => {
                            e.preventDefault();
                            await this.onLoadAll(groupId);
                        }}>
                                            load all
                                        </a>
                                        )
                                    </div>
                                </div>
                            </div>)}
                    </React.Fragment>);
                loadMoreHeaderIndicator = <React.Fragment>{showLoadMoreAction && <div>{`${takeNumberOfRows} of ${rowsInGroup.length} projects displayed`}</div>}</React.Fragment>;
            }
            return (<DashboardGrid key={groupId} rowCount={rowAndColumnLimit!.row + 1} columnCount={rowAndColumnLimit!.column + 1} cellRenderer={cellRenderer!} flatStyle={this.props.flatStyle} cube={this.props.cube} cellMeasurerCache={this.cellMeasurerCaches!(groupId)!} availableHeight={this.state.height} showCapDataCallout={isLimited!} dashboardRenderMode={this.props.dashboardRenderMode} headerComponent={loadMoreHeaderIndicator!} footerComponent={loadMoreComponent!}/>);
        });
    }
    private onLoadMore = async (groupId: string) => {
        const newGroupTakeSizeLookup = cloneDeep(this.state.groupTakeSizeLookup); // cloneDeep required for shouldComponentUpdate comparison to work.
        let takeNumberOfRows = this.state.groupTakeSizeLookup[groupId] ? this.state.groupTakeSizeLookup[groupId] : this.loadMoreTakeSize;
        newGroupTakeSizeLookup[groupId] = takeNumberOfRows += this.loadMoreTakeSize;
        this.setState({ groupTakeSizeLookup: newGroupTakeSizeLookup });
    };
    private onLoadAll = async (groupId: string) => {
        const newGroupTakeSizeLookup = cloneDeep(this.state.groupTakeSizeLookup); // cloneDeep required for shouldComponentUpdate comparison to work.
        newGroupTakeSizeLookup[groupId] = Repository.takeAll;
        this.setState({ groupTakeSizeLookup: newGroupTakeSizeLookup });
    };
    private getDeployment(deployments: DashboardItemResource[] | null) {
        if (!deployments || !deployments.length) {
            return null;
        }
        return deployments.find((item) => item.State === TaskState.Executing || item.State === TaskState.Failed || (item.State === TaskState.Success && item.HasWarningsOrErrors)) || deployments[0];
    }
    private getChannelName = (channelId: string): string | null => {
        const channel = this.props.cube.channelIndex[channelId];
        if (channel) {
            return channel.Name;
        }
        return null;
    };
    private getReleaseVersion = (releaseId: string): string | null => {
        const release = this.props.cube.releaseIndex[releaseId];
        if (release) {
            return release.Version;
        }
        return null;
    };
    private getReleaseVcsRef = (releaseId: string): GitReference | undefined => {
        const release = this.props.cube.releaseIndex[releaseId];
        if (release) {
            return release.VersionControlReference;
        }
        return undefined;
    };
    private getProjectRowsLimitedToMaximum(dataSet: DataSet, maximumRows: number): Group[] {
        const hasLimit = maximumRows !== null && maximumRows !== undefined;
        let maxRows = hasLimit ? maximumRows : undefined;
        // get rows for each group, track our use against the limit
        const groups = dataSet.getGroups().reduce<Group[]>((acc, groupId) => {
            if (maxRows! > 0 || !hasLimit) {
                const value = {
                    groupId,
                    rowsInGroup: hasLimit ? dataSet.getRowsForGroup(groupId!, maxRows) : dataSet.getRowsForGroup(groupId!),
                };
                if (hasLimit) {
                    maxRows = maxRows! - value.rowsInGroup.length;
                }
                acc.push(value);
            }
            return acc;
        }, []);
        return groups;
    }
    static displayName = "ProjectDashboard";
}
