/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { css } from "@emotion/css";
import { List, ListItem } from "@octopusdeploy/design-system-components";
import { space } from "@octopusdeploy/design-system-tokens";
import * as React from "react";
import type { match as Match } from "react-router-dom";
import { NoResults } from "~/components/NoResults/NoResults";
import type { HasId, PagingBaseProps, PagingBaseState } from "~/components/PagingBaseComponent";
import PagingBaseComponent from "~/components/PagingBaseComponent";
import { getNavigationUrl } from "~/components/PagingBaseComponent/PagingBaseComponent";
import { Section } from "~/components/Section/Section";
import TransitionAnimation from "~/components/TransitionAnimation/TransitionAnimation";
import { Note } from "~/components/form";
import InternalRedirect from "../Navigation/InternalRedirect/InternalRedirect";
import { ListInternalLink } from "./ListInternalLink";
interface PagingListProps<R extends HasId> extends PagingBaseProps<R> {
    empty?: React.ReactNode;
    match?: Match;
    wrapRowsInListItem?: boolean;
    onRowAccessibleName?(item: R): string;
    includeRowGaps?: boolean;
}
interface ListState<R extends HasId> extends PagingBaseState<R> {
    redirectTo: string;
}
export abstract class PagingList<R extends HasId> extends PagingBaseComponent<R, PagingListProps<R>, ListState<R>> {
    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo} push={true}/>;
        }
        if (!this.state.data || !this.state.data.Items) {
            return <Section />;
        }
        return (<TransitionAnimation>
                {this.renderFilterSearchComponents()}
                <List includeRowGaps={this.props.includeRowGaps} includeVerticalPadding empty={<EmptyListResult empty={this.props.empty}/>} rowKey={(item) => item.Id} items={this.state.data.Items.filter((item) => this.onFilter(this.state.filter, item))} renderRow={({ item }) => this.buildItem(item)}/>
                {this.state.data.Items.length < this.state.data.TotalResults ? (this.props.showPagingInNumberedStyle ? this.showPagingInNumberedStyle() : this.showPagingInLoadMoreStyle()) : null}
            </TransitionAnimation>);
    }
    buildItem = (item: R) => {
        return (<ListItem>
                <NavigateListItemContent item={item} onRowRedirectUrl={this.props.onRowRedirectUrl} match={this.props.match} onRowAccessibleName={this.props.onRowAccessibleName}>
                    <ListItemContent useDefaultStyles={this.props.wrapRowsInListItem ?? true}>{this.props.onRow(item)}</ListItemContent>
                </NavigateListItemContent>
            </ListItem>);
    };
    static displayName = "PagingList";
}
function NavigateListItemContent<T>({ item, onRowAccessibleName, match, onRowRedirectUrl, children }: React.PropsWithChildren<{
    item: T;
    onRowAccessibleName?: (item: T) => string;
    match?: Match;
    onRowRedirectUrl?: (item: T) => string | null;
}>) {
    const accessibleName = onRowAccessibleName?.(item);
    const redirectUrl = getNavigationUrl({ match, onRowRedirectUrl }, item);
    if (redirectUrl) {
        // Make these actual href links, so people can still right-click > open new window (this is expected usability for things you can click).
        return (<ListInternalLink href={redirectUrl} accessibleName={accessibleName}>
                {children}
            </ListInternalLink>);
    }
    return <>{children}</>;
}
type ListItemContentProps = {
    useDefaultStyles: boolean;
};
function ListItemContent({ children, useDefaultStyles }: React.PropsWithChildren<ListItemContentProps>) {
    if (useDefaultStyles) {
        return <div className={listItemContentStyles}>{children}</div>;
    }
    return <>{children}</>;
}
const listItemContentStyles = css({
    padding: space[16],
    lineHeight: "16px",
    fontSize: "0.875rem",
    wordBreak: "break-word",
});
function EmptyListResult({ empty = <NoResults /> }: {
    empty?: React.ReactNode;
}) {
    return (<Section>
            <Note>{empty}</Note>
        </Section>);
}
(PagingList as any).defaultProps = {
    showFilterWithinSection: true,
    autoFocusOnFilterSearch: true,
    wrapRowsInListItem: true,
};
