/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { NavigationButton, BooleanRadioButtonGroup, BooleanRadioButton } from "@octopusdeploy/design-system-components";
import { CommunicationStyle, Permission, EndpointsHelper } from "@octopusdeploy/octopus-server-client";
import type { CertificateConfigurationResource, ListeningTentacleEndpointResource, MachineResource, ProxyResource, SshEndpointResource, EndpointCommunicationStyle } from "@octopusdeploy/octopus-server-client";
import type { LocationDescriptorObject } from "history";
import { cloneDeep } from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import type { RouteComponentProps } from "react-router";
import URI from "urijs";
import LinuxCategoryDefinition from "~/areas/infrastructure/components/MachineSettings/Endpoints/LinuxCategoryDefinition";
import WindowsCategoryDefinition from "~/areas/infrastructure/components/MachineSettings/Endpoints/WindowsCategoryDefinition";
import { LinuxListeningTentacleCallout, WindowsListeningTentacleCallout } from "~/areas/infrastructure/components/TentacleCallout";
import { repository } from "~/clientInstance";
import FormBaseComponent from "~/components/FormBaseComponent";
import type { FormBaseComponentState } from "~/components/FormBaseComponent/FormBaseComponent";
import FormPage from "~/components/FormPage/FormPage";
import FormPaperLayout from "~/components/FormPaperLayout/FormPaperLayout";
import ExternalLink from "~/components/Navigation/ExternalLink";
import type { BreadcrumbProps } from "~/components/PaperLayout/PaperLayout";
import ServerThumbprint from "~/components/ServerThumbprint/ServerThumbprint";
import { ExpandableFormSection, Note, required, Select, Summary, Text } from "~/components/form";
import routeLinks from "~/routeLinks";
import CommonSummaryHelper from "~/utils/CommonSummaryHelper";
import ParseHelper from "~/utils/ParseHelper";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import endpointRegistry, { EndpointRegistrationKey } from "../MachineSettings/Endpoints/endpointRegistry";
interface DiscoveryEndpoint {
    key: EndpointRegistrationKey;
    communicationStyle: CommunicationStyle;
    host: string;
    port: number;
    proxyId: string | undefined;
}
interface DiscoveryLayoutProps extends RouteComponentProps<MachineDiscoveryParams> {
    breadcrumbs?: BreadcrumbProps;
    title: string;
    machineTypeDescription: string;
    createLink: string;
    helpText: JSX.Element;
    discover(host: string, port: number, type: {}, proxyId: string | undefined): Promise<MachineResource>;
}
interface MachineDiscoveryProps extends DiscoveryLayoutProps {
    initialData: InitialData;
}
interface MachineDiscoveryParams {
    environmentId: string;
    workerPoolId: string;
    key: EndpointRegistrationKey;
}
interface MachineDiscoveryState extends FormBaseComponentState<DiscoveryEndpoint> {
    communicationStyles: EndpointCommunicationStyle[];
    octopusVersion: string;
    shouldUseProxy: boolean;
    redirectTo?: LocationDescriptorObject;
    open: boolean;
}
interface InitialData {
    proxies: ProxyResource[];
    globalCertificate: CertificateConfigurationResource;
    communicationStyle: CommunicationStyle;
    queryParams: {
        [k: string]: string;
    };
    key: EndpointRegistrationKey;
    category?: string;
}
const DiscoveryLayoutFormPage = FormPage<InitialData>();
const DiscoveryLayout: React.FC<DiscoveryLayoutProps> = (props: DiscoveryLayoutProps) => {
    return (<DiscoveryLayoutFormPage title={props.title} load={async () => {
            const proxies = repository.Proxies.all();
            const globalCertificate = repository.CertificateConfiguration.global();
            const environmentId = props.match.params.environmentId;
            const workerPoolId = props.match.params.workerPoolId;
            const search = new URI(props.location.search).search(true);
            const key = props.match.params.key;
            const communicationStyle = endpointRegistry.getEndpoint(key).communicationStyle;
            const queryParams: {
                [k: string]: string | CommunicationStyle;
            } = {
                type: communicationStyle,
            };
            if (environmentId) {
                queryParams.environment = environmentId;
            }
            if (workerPoolId) {
                queryParams.workerPool = workerPoolId;
            }
            return {
                proxies: await proxies,
                globalCertificate: await globalCertificate,
                communicationStyle,
                queryParams,
                key,
                category: search.category,
            };
        }} renderWhenLoaded={(data) => {
            return <MachineDiscoveryLayoutInternal initialData={data} {...props}/>;
        }}/>);
};
DiscoveryLayout.displayName = "DiscoveryLayout"
class MachineDiscoveryLayoutInternal extends FormBaseComponent<MachineDiscoveryProps, MachineDiscoveryState, DiscoveryEndpoint> {
    private environmentId: string | null = undefined!; // if adding deploy target from the environment tab otherwise its null (env is specified via wizard)
    private workerPoolId: string | null = undefined!; // if adding deploy target from workerpool tab
    constructor(props: MachineDiscoveryProps) {
        super(props);
        const port = EndpointsHelper.getDefaultPort(props.initialData.communicationStyle);
        const model = {
            key: props.initialData.key,
            communicationStyle: props.initialData.communicationStyle,
            host: "",
            port,
            proxyId: "",
        };
        this.state = {
            communicationStyles: EndpointsHelper.communicationStyles,
            octopusVersion: repository.getServerInformation().version,
            shouldUseProxy: false,
            model,
            open: false,
            cleanModel: cloneDeep(model),
        };
    }
    render() {
        const { breadcrumbs = {} } = this.props;
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo}/>;
        }
        const newQS = new URI().search(this.props.initialData.queryParams).search();
        const enterDetailsButton = EndpointsHelper.canDiscover(this.state.model.communicationStyle) && <NavigationButton label="Enter details manually" href={this.props.createLink + newQS}/>;
        return (<FormPaperLayout title={this.props.title} {...breadcrumbs} busy={this.state.busy} errors={this.errors} model={this.state.model} cleanModel={this.state.cleanModel} disableDirtyFormChecking={true} savePermission={{ permission: Permission.MachineCreate, environment: "*", tenant: "*" }} onSaveClick={async () => this.handleNextAction()} hideSectionControls={!(EndpointsHelper.canDiscover(this.state.model.communicationStyle) || (this.state.model.communicationStyle && !EndpointsHelper.isPollingTentacle(this.state.model.communicationStyle)))} saveButtonLabel={"Next"} saveButtonBusyLabel={EndpointsHelper.canDiscover(this.state.model.communicationStyle) ? "Discovering" : "Saving"} secondaryAction={enterDetailsButton} expandAllOnMount={true}>
                {this.state.model.key === EndpointRegistrationKey.TentaclePassive && (this.props.initialData.category === WindowsCategoryDefinition.category || this.props.initialData.category === undefined) && (<WindowsListeningTentacleCallout thumbprint={<ServerThumbprint thumbprint={this.props.initialData.globalCertificate.Thumbprint}/>}/>)}
                {this.state.model.key === EndpointRegistrationKey.TentaclePassive && this.props.initialData.category === LinuxCategoryDefinition.category && (<LinuxListeningTentacleCallout thumbprint={<ServerThumbprint thumbprint={this.props.initialData.globalCertificate.Thumbprint}/>}/>)}

                {EndpointsHelper.canDiscover(this.state.model.communicationStyle) && (<div>
                        <ExpandableFormSection errorKey="host" title="Hostname" summary={this.state.model.host ? Summary.summary(this.state.model.host) : Summary.placeholder("No host")} help={<span>
                                    Enter the DNS name or IP of the {this.props.machineTypeDescription} to discover, eg <code>example.com</code>, <code>10.0.1.23</code>.
                                </span>}>
                            <Text label="Hostname" value={this.state.model.host} onChange={(host) => this.setModelState({ host })} validate={required("Please enter a hostname")} error={this.getFieldError("host")} type="url" autoFocus={EndpointsHelper.canDiscover(this.state.model.communicationStyle)}/>
                        </ExpandableFormSection>
                        <ExpandableFormSection errorKey="Port" title="Port" summary={CommonSummaryHelper.portSummary(this.state.model.port)} help="Enter the port on which the endpoint is listening">
                            <Text label="Port" value={this.state.model.port.toString()} onChange={(x) => {
                    const portAsNumber: number = ParseHelper.safeParseInt(x, undefined);
                    if (portAsNumber) {
                        this.setModelState({ port: portAsNumber });
                    }
                }} type="number"/>
                        </ExpandableFormSection>
                        <ExpandableFormSection errorKey="proxyId" title="Proxy" summary={CommonSummaryHelper.resourceSummary(this.state.model.proxyId, this.props.initialData.proxies, "proxy")} help={"Select whether to use a proxy to connect to this " + this.props.machineTypeDescription + "."}>
                            <BooleanRadioButtonGroup title="Connection method" onChange={(shouldUseProxy) => {
                    const discoveryEndpoint = this.state.model;
                    discoveryEndpoint.proxyId = undefined;
                    this.setState({
                        shouldUseProxy,
                        model: discoveryEndpoint,
                    });
                }} value={this.state.shouldUseProxy}>
                                <BooleanRadioButton value={false} label={"Connect to this " + this.props.machineTypeDescription + " directly"} isDefault={true}/>
                                <BooleanRadioButton value={true} label={"Connect to this " + this.props.machineTypeDescription + " through a proxy server"}/>
                            </BooleanRadioButtonGroup>
                            {this.state.shouldUseProxy && (<Select label="Proxy" onChange={(proxyId) => {
                        if (proxyId) {
                            this.setModelState({ proxyId });
                        }
                    }} value={this.state.model.proxyId} items={this.props.initialData.proxies.map((p) => ({ value: p.Id, text: p.Name }))}/>)}
                        </ExpandableFormSection>
                    </div>)}
            </FormPaperLayout>);
    }
    private async handleNextAction() {
        return this.doBusyTask(async () => {
            const queryParams: {
                [k: string]: string | number | CommunicationStyle;
            } = {
                type: this.state.model.communicationStyle,
            };
            if (this.environmentId) {
                queryParams.environment = this.environmentId;
            }
            if (this.workerPoolId) {
                queryParams.workerPool = this.workerPoolId;
            }
            queryParams["type"] = this.state.model.communicationStyle;
            const endpoint = this.state.model;
            if (EndpointsHelper.canDiscover(endpoint.communicationStyle)) {
                const discoveredMachine = await this.props.discover(endpoint.host, endpoint.port, endpoint.communicationStyle, endpoint.proxyId);
                if (discoveredMachine.Endpoint.CommunicationStyle === CommunicationStyle.TentaclePassive) {
                    const tentacleEndpoint = discoveredMachine.Endpoint as ListeningTentacleEndpointResource;
                    queryParams["uri"] = tentacleEndpoint.Uri;
                    queryParams["thumbprint"] = tentacleEndpoint.Thumbprint;
                    if (tentacleEndpoint.ProxyId) {
                        queryParams["proxyId"] = tentacleEndpoint.ProxyId;
                    }
                }
                else if (discoveredMachine.Endpoint.CommunicationStyle === CommunicationStyle.Ssh) {
                    const sshEndpoint = discoveredMachine.Endpoint as SshEndpointResource;
                    queryParams["uri"] = sshEndpoint.Uri;
                    queryParams["host"] = sshEndpoint.Host;
                    queryParams["port"] = sshEndpoint.Port;
                    queryParams["hostKeyAlgorithm"] = sshEndpoint.HostKeyAlgorithm;
                    queryParams["fingerprint"] = sshEndpoint.Fingerprint;
                    if (sshEndpoint.ProxyId) {
                        queryParams["proxyId"] = sshEndpoint.ProxyId;
                    }
                }
            }
            const newQS = new URI().search(queryParams).search();
            this.setState({
                redirectTo: {
                    pathname: this.props.createLink,
                    search: newQS,
                },
            });
        });
    }
    static displayName = "MachineDiscoveryLayoutInternal";
}
const mapGlobalStateToPropsForDeploymentTarget = (state: GlobalState, props: RouteComponentProps<MachineDiscoveryParams>) => {
    const registration = endpointRegistry.getMachine(props.match.params.key);
    return {
        title: `Create ${registration ? registration.name : "deployment target"}`,
        breadcrumbs: {
            breadcrumbPath: routeLinks.infrastructure.machines.new(props.match.params.environmentId),
            breadcrumbTitle: "New Deployment Target",
        },
        machineTypeDescription: "deployment target",
        createLink: routeLinks.infrastructure.machines.create(),
        helpText: (<Note>
                Learn more about <ExternalLink href="DeploymentTargets">deployment targets</ExternalLink>.
            </Note>),
        discover: (host: string, port: number, type: {}, proxyId: string | undefined) => repository.Machines.discover(host, port, type, proxyId),
    };
};
const mapGlobalStateToPropsForWorkerMachines = (state: GlobalState, props: RouteComponentProps<MachineDiscoveryParams>) => {
    const registration = endpointRegistry.getMachine(props.match.params.key);
    return {
        title: `Create ${registration ? registration.name : "worker"}`,
        breadcrumbs: {
            breadcrumbPath: routeLinks.infrastructure.workerMachines.new(props.match.params.workerPoolId),
            breadcrumbTitle: "New Worker",
        },
        machineTypeDescription: "worker",
        createLink: routeLinks.infrastructure.workerMachines.create(),
        discover: (host: string, port: number, type: {}, proxyId: string | undefined) => repository.Workers.discover(host, port, type, proxyId),
    };
};
const mapGlobalActionDispatchersToProps = () => {
    return {};
};
export const DeploymentTargetDiscoveryLayout = connect(mapGlobalStateToPropsForDeploymentTarget, mapGlobalActionDispatchersToProps)(DiscoveryLayout);
export const WorkerMachineDiscoveryLayout = connect(mapGlobalStateToPropsForWorkerMachines, mapGlobalActionDispatchersToProps)(DiscoveryLayout);
