import { ActionButton, ActionButtonType } from "@octopusdeploy/design-system-components";
import type { ChannelResource, EnvironmentResource } from "@octopusdeploy/octopus-server-client";
import cn from "classnames";
import type { LocationDescriptor } from "history";
import * as React from "react";
import MediaQuery from "react-responsive";
import { ChannelChip, EnvironmentChip, MissingChip, ChipIcon, ContextualMissingChip, DisabledChip } from "~/components/Chips/index";
import FeatureToggle, { Feature } from "~/components/FeatureToggle/FeatureToggle";
import Logo from "~/components/Logo";
import InternalLink from "~/components/Navigation/InternalLink";
import TenantTagsList from "~/components/TenantTagsList/TenantTagsList";
import ToolTip from "~/primitiveComponents/dataDisplay/ToolTip";
import Markdown from "../../../../../components/Markdown";
import type { ToolTipPosition } from "../../../../../primitiveComponents/dataDisplay/ToolTip";
import styles from "./ProcessListItem.module.less";
import { toolTipBreakpoint } from "./ProcessListItemForSidebar";
import { truncateActionNotes } from "./truncateActionNotes";
export interface CommonProcessListItemProps {
    name: string;
    actionType: string | JSX.Element;
    logoUrl?: string;
    icon?: JSX.Element;
    index: string;
    children?: React.ReactNode;
    isDisabled: boolean;
    notes: string | null;
    isParentGroup: boolean;
    isRunInParallelWithLast?: boolean;
    actionErrors: string[];
    actionWarnings: string[];
}
interface ChannelsLookup {
    Key: string;
    Channel: ChannelResource;
}
interface EnvironmentsLookup {
    Key: string;
    Environment: EnvironmentResource;
}
interface ProcessListItemProps extends CommonProcessListItemProps, StepDetailedSummaryProps {
    detailsUrl: string;
}
interface ListItemMenuTooltipPositionRenderProps {
    toolTipPosition: ToolTipPosition;
}
interface BreakpointTooltipPositionProps {
    children: (props: ListItemMenuTooltipPositionRenderProps) => React.ReactNode;
}
const BreakpointTooltipPosition: React.FC<BreakpointTooltipPositionProps> = ({ children }) => {
    return (<MediaQuery minWidth={toolTipBreakpoint}>
            {(matches: boolean) => {
            const toolTipPosition = matches ? "left" : "right";
            return children({ toolTipPosition });
        }}
        </MediaQuery>);
};
BreakpointTooltipPosition.displayName = "BreakpointTooltipPosition"
const StepLogoContainer: React.FC = (props) => <div className={styles.stepLogo}>{props.children}</div>;
StepLogoContainer.displayName = "StepLogoContainer"
interface StepIndicatorProps {
    tooltipPosition: ToolTipPosition;
    tooltipContent: string | React.ReactElement;
    icon: React.ReactElement | undefined;
}
type ParallelIndicatorProps = StepIndicatorProps;
const ParallelStepIndicator: React.FC<ParallelIndicatorProps> = ({ icon, tooltipPosition, tooltipContent }) => {
    return (<div className={styles.stepWrapper}>
            <ToolTip content="Runs in parallel" position={tooltipPosition}>
                <div className={styles.parallelContainer}>
                    <div className={styles.parallelIcon}>
                        <div className={styles.content}>&nbsp;</div>
                    </div>
                </div>
            </ToolTip>
            <ToolTip position={tooltipPosition} content={tooltipContent}>
                <StepLogoContainer>{icon}</StepLogoContainer>
            </ToolTip>
        </div>);
};
ParallelStepIndicator.displayName = "ParallelStepIndicator"
type SequentialStepIndicatorProps = StepIndicatorProps;
const SequentialStepIndicator: React.FC<SequentialStepIndicatorProps> = ({ tooltipPosition, tooltipContent, icon }) => (<ToolTip content={tooltipContent} position={tooltipPosition}>
        <StepLogoContainer>{icon}</StepLogoContainer>
    </ToolTip>);
SequentialStepIndicator.displayName = "SequentialStepIndicator"
const StepLinkContent: React.FC<{
    stepNumber: string;
    isDisabled: boolean;
    name: React.ReactNode;
}> = (props) => {
    return (<div className={styles.stepContainer}>
            <div className={styles.stepNumber}>{props.stepNumber}</div>
            <div className={styles.stepName}>
                <div>
                    {props.name}
                    {props.isDisabled && (<>
                            &nbsp;
                            <DisabledChip />
                        </>)}
                </div>
                {props.children}
            </div>
        </div>);
};
StepLinkContent.displayName = "StepLinkContent"
interface StepDetailedSummaryProps {
    environmentsLookup?: EnvironmentsLookup[];
    excludedEnvironmentsLookup?: EnvironmentsLookup[];
    channelsLookup?: ChannelsLookup[];
    tags?: string[];
}
interface StepNotesProps {
    notes: string | null;
}
const getChipForEnvironment = (lookupKey: string, environment: EnvironmentResource, isExcluded: boolean) => {
    return environment ? <EnvironmentChip key={lookupKey} environmentName={environment.Name} isExcluded={isExcluded}/> : <ContextualMissingChip lookupKey={lookupKey} type={ChipIcon.Environment}/>;
};
const getChipForChannel = (lookupKey: string, channel: ChannelResource) => {
    return channel ? <ChannelChip key={channel.Id} channelName={channel.Name}/> : <MissingChip lookupId={lookupKey} type={ChipIcon.Channel}/>;
};
const StepSummary: React.FC = (props) => <div className={styles.summary}>{props.children}</div>;
StepSummary.displayName = "StepSummary"
const StepNotes: React.FC<StepNotesProps> = (props) => {
    const [showFullNotes, setShowFullNotes] = React.useState(false);
    const [notes, isTruncated] = truncateActionNotes(props.notes, 15);
    return (<div className={styles.stepNotes}>
            {notes && (<div className={styles.notes}>
                    {showFullNotes ? (<Markdown markup={props.notes || ""}/>) : (<div>
                            <Markdown markup={notes}/>
                            {isTruncated && <ActionButton type={ActionButtonType.Ternary} onClick={() => setShowFullNotes(true)} label="show more"/>}
                        </div>)}
                </div>)}
        </div>);
};
StepNotes.displayName = "StepNotes"
const StepAnnexDetail: React.FC<StepDetailedSummaryProps> = (props) => {
    return (<div>
            {props.environmentsLookup && props.environmentsLookup.map((e) => getChipForEnvironment(e.Key, e.Environment, false))}
            {props.excludedEnvironmentsLookup && props.excludedEnvironmentsLookup.map((e) => getChipForEnvironment(e.Key, e.Environment, true))}
            {props.channelsLookup && props.channelsLookup.map((ch) => getChipForChannel(ch.Key, ch.Channel))}
            <FeatureToggle feature={Feature.MultiTenancy} enabled={true}>
                {props.tags && props.tags.length > 0 && <TenantTagsList tags={props.tags}/>}
            </FeatureToggle>
        </div>);
};
StepAnnexDetail.displayName = "StepAnnexDetail"
type LogoOrIconProps = Pick<ProcessListItemProps, "logoUrl" | "isDisabled" | "icon">;
const renderLogoOrIcon = (props: LogoOrIconProps) => {
    return props.logoUrl ? <Logo url={props.logoUrl} isDisabled={props.isDisabled} size={"3rem"} className={styles.logo}/> : props.icon;
};
const ProcessListItemInternal: React.FC<ProcessListItemProps> = (props) => {
    const processListItemNameSpan = <span>{props.name}</span>;
    return (<StepLink isDisabled={props.isDisabled} detailsUrl={props.detailsUrl} isParentGroup={props.isParentGroup}>
            <BreakpointTooltipPosition>
                {({ toolTipPosition }) => (<React.Fragment>
                        {props.isRunInParallelWithLast && <ParallelStepIndicator tooltipContent={props.actionType} tooltipPosition={toolTipPosition} icon={renderLogoOrIcon(props)}/>}
                        {!props.isRunInParallelWithLast && <SequentialStepIndicator tooltipContent={props.actionType} tooltipPosition={toolTipPosition} icon={renderLogoOrIcon(props)}/>}
                    </React.Fragment>)}
            </BreakpointTooltipPosition>
            <StepLinkContent name={processListItemNameSpan} stepNumber={props.index} isDisabled={props.isDisabled}>
                <div className={styles.stepContent}>
                    <StepSummary>
                        {props.children}
                        <StepAnnexDetail channelsLookup={props.channelsLookup} environmentsLookup={props.environmentsLookup} excludedEnvironmentsLookup={props.excludedEnvironmentsLookup} tags={props.tags}/>
                    </StepSummary>
                    <StepNotes notes={props.notes}/>
                </div>
            </StepLinkContent>
        </StepLink>);
};
ProcessListItemInternal.displayName = "ProcessListItemInternal"
const ProcessListItem = React.memo(ProcessListItemInternal);
const StepLink: React.FC<{
    isDisabled: boolean;
    detailsUrl: LocationDescriptor;
    isParentGroup: boolean;
}> = ({ detailsUrl, isDisabled, isParentGroup, children }) => {
    return (<InternalLink className={cn(styles.listItem, isDisabled ? styles.isDisabled : null, isParentGroup ? styles.isParentGroup : null)} to={detailsUrl}>
            <div className={styles.listItemLine}/>
            {children}
        </InternalLink>);
};
StepLink.displayName = "StepLink"
export default ProcessListItem;
