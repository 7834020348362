/* eslint-disable no-eq-null */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { NavigationButton } from "@octopusdeploy/design-system-components";
import type { CommunicationStyle, MachineResource, NewWorkerMachineResource, WorkerMachineResource } from "@octopusdeploy/octopus-server-client";
import { isStepPackageEndpointResource, Permission, EndpointsHelper } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { connect } from "react-redux";
import type { RouteComponentProps } from "react-router";
import { useLocation, useParams } from "react-router";
import type { Action, Dispatch } from "redux";
import URI from "urijs";
import { machineActions } from "~/areas/infrastructure/reducers/machines";
import { repository } from "~/clientInstance";
import { workerPoolChipList } from "~/components/Chips";
import FormPage from "~/components/FormPage/FormPage";
import { WorkerPoolMultiSelect } from "~/components/MultiSelect/WorkerPoolMultiSelect";
import ExternalLink from "~/components/Navigation/ExternalLink";
import type { BreadcrumbProps } from "~/components/PaperLayout";
import PermissionCheck from "~/components/PermissionCheck/PermissionCheck";
import { ExpandableFormSection, FormSectionHeading } from "~/components/form";
import Summary from "~/components/form/Sections/Summary";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout";
import routeLinks from "~/routeLinks";
import { getMachineIconUrl } from "~/utils/MachineIconUrlFetchers/machineIconUrlFetchers";
import type { BaseMachineSettingsProps, GlobalProps, MachineSettingsInitialData } from "../BaseMachineSettings/BaseMachineSettings";
import { BaseMachineSettingsLayout, LoadMachineSettingsData } from "../BaseMachineSettings/BaseMachineSettings";
import CreateNewMachineResource from "../BaseMachineSettings/NewMachineResourceFactory";
import type { MachineSettingsRouteParams } from "../DeploymentTargetSettings/DeploymentTargetSettings";
type WorkerMachineSettingsProps = BaseMachineSettingsProps<WorkerMachineResource, NewWorkerMachineResource>;
type WorkerMachineProps = BaseMachineSettingsProps<WorkerMachineResource, NewWorkerMachineResource>;
const IsNew = "IsNew";
const MachineSettingsFormPage = FormPage<MachineSettingsInitialData>();
const WorkerMachinePage: React.FC<WorkerMachineProps> = (props: WorkerMachineProps) => {
    const { machineId } = useParams<MachineSettingsRouteParams>();
    const location = useLocation();
    const query = URI(location.search);
    return (<MachineSettingsFormPage title="Settings" load={async () => {
            const machine = machineId ? await props.repository.get(machineId) : IsNew;
            const communicationStyle = machine !== IsNew ? machine.Endpoint.CommunicationStyle : (query.search(true).type as CommunicationStyle);
            const machineSettingData = await LoadMachineSettingsData(machine, communicationStyle);
            return {
                ...machineSettingData,
                workerPools: (await repository.WorkerPools.all()).filter((x) => x.CanAddWorkers),
            };
        }} renderWhenLoaded={(initialData) => <WorkerMachineSettingsLayoutInternal {...props} initialData={initialData} query={query}/>}/>);
};
WorkerMachinePage.displayName = "WorkerMachinePage"
class WorkerMachineSettingsLayoutInternal extends BaseMachineSettingsLayout<WorkerMachineSettingsProps, WorkerMachineResource, NewWorkerMachineResource> {
    constructor(props: WorkerMachineSettingsProps) {
        super(props);
    }
    protected enableDisablePermission(): Permission {
        return Permission.WorkerEdit;
    }
    protected createPermission(): Permission {
        return Permission.WorkerEdit;
    }
    protected editPermission(): Permission {
        return Permission.WorkerEdit;
    }
    protected deletePermission(): Permission {
        return Permission.WorkerEdit;
    }
    protected machineLink(machineId: string): string {
        return routeLinks.infrastructure.workerMachine(machineId).root;
    }
    protected getModel(location: URI, defaultMachinePolicyId: string): NewWorkerMachineResource {
        const query = location.search(true);
        const workerPoolIds = query.workerPool;
        return {
            ...CreateNewMachineResource(location, defaultMachinePolicyId),
            WorkerPoolIds: workerPoolIds ? [workerPoolIds] : [],
        };
    }
    protected mapToModel(model: WorkerMachineResource): NewWorkerMachineResource {
        return {
            ...model,
        };
    }
    protected renderTenantComponent(): JSX.Element | null {
        return null;
    }
    protected renderTypeSpecificComponents(): JSX.Element {
        return (<div>
                <FormSectionHeading title="Workers"/>

                <ExpandableFormSection errorKey="WorkerPoolIds" title="Worker Pool" summary={this.workerPoolsSummary()} help={"Choose at least one worker pool for the machine."}>
                    <WorkerPoolMultiSelect items={this.props.initialData.workerPools} onChange={(WorkerPoolIds) => this.setModelState({ WorkerPoolIds })} value={this.state.model.WorkerPoolIds}/>

                    {this.selectedPoolsChangesBuiltinSetting() && (<Callout type={CalloutType.Warning} title={"Built-in Worker will be disabled"}>
                            Adding Workers to the default pool turns off the Built-in Worker and changes where some steps are run. Learn about the <ExternalLink href="AddingWorkerToDefaultPool">Built-in Worker</ExternalLink>.
                        </Callout>)}
                </ExpandableFormSection>
            </div>);
    }
    protected renderSecondaryAction(): JSX.Element {
        return (<PermissionCheck permission={Permission.MachineCreate} environment="*" tenant="*">
                <NavigationButton href={routeLinks.infrastructure.workerMachines.new()} label="Add another"/>
            </PermissionCheck>);
    }
    private selectedPoolsChangesBuiltinSetting(): boolean {
        if (this.props.initialData.workerPoolSummaries == null) {
            return false;
        }
        const defaultPoolSummary = this.props.initialData.workerPoolSummaries.WorkerPoolSummaries.filter((wp) => wp.WorkerPool.IsDefault)[0];
        return defaultPoolSummary.TotalMachines === 0 && this.state.model.WorkerPoolIds.includes(defaultPoolSummary.WorkerPool.Id);
    }
    private workerPoolsSummary() {
        return this.state.model.WorkerPoolIds.length > 0 ? Summary.summary(workerPoolChipList(this.props.initialData.workerPools, this.state.model.WorkerPoolIds)) : Summary.placeholder("No worker pools");
    }
}
const mapGlobalStateToPropsForWorkers = (state: GlobalState, props: RouteComponentProps<MachineSettingsRouteParams>): GlobalProps<WorkerMachineResource, NewWorkerMachineResource> => {
    const query = URI(props.location.search).search(true);
    const machineId = props.match && props.match.params ? props.match.params.machineId : null;
    const breadcrumbs: BreadcrumbProps = machineId
        ? {}
        : {
            breadcrumbPath: routeLinks.infrastructure.workerMachines.new(query.workerPoolId),
            breadcrumbTitle: "New Worker",
        };
    return {
        breadcrumbs,
        rootLink: routeLinks.infrastructure.workerMachines.root,
        isBuiltInWorkerEnabled: state.configurationArea.features.isBuiltInWorkerEnabled,
        repository: repository.Workers,
        isMultiTenancyEnabled: false,
        isWorkerMachine: true,
    };
};
const mapGlobalActionDispatchersToProps = (dispatch: Dispatch<Action<{}>>) => {
    return {
        onMachineSaved: (machine: MachineResource) => {
            const machineIconUrl = getMachineIconUrl(machine);
            dispatch(machineActions.machineSaved({
                id: machine.Id,
                name: machine.Name,
                machineType: EndpointsHelper.getFriendlyName(machine.Endpoint.CommunicationStyle),
                machineIconUrl,
                isDisabled: machine.IsDisabled,
                isStepPackage: isStepPackageEndpointResource(machine.Endpoint),
                healthStatus: machine.HealthStatus,
            }));
        },
    };
};
export const WorkerMachineSettingsLayout = connect(mapGlobalStateToPropsForWorkers, mapGlobalActionDispatchersToProps)(WorkerMachinePage);
