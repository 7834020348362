import type { TenantsFilterQueryParameters } from "@octopusdeploy/octopus-server-client";
import { stringQueryParam, useQueryStringParams } from "@octopusdeploy/portal-routes";
import { useMemo } from "react";
export type TenantsFilterState = TenantsFilterQueryParameters;
export function useTenantsFilterState(): [
    TenantsFilterState,
    (filter: TenantsFilterState) => void
] {
    const searchParameter = stringQueryParam("search");
    const excludedSearchParameter = stringQueryParam("excludedSearch");
    const projectParameter = stringQueryParam("project");
    const excludedProjectParameter = stringQueryParam("excludedProject");
    const environmentParameter = stringQueryParam("environment");
    const excludedEnvironmentParameter = stringQueryParam("excludedEnvironment");
    const tagsParameter = stringQueryParam("tags");
    const excludedTagsParameter = stringQueryParam("excludedTags");
    const [filter, setFilter] = useQueryStringParams([searchParameter, excludedSearchParameter, projectParameter, excludedProjectParameter, environmentParameter, excludedEnvironmentParameter, tagsParameter, excludedTagsParameter]);
    function setTenantsFilterState(filter: TenantsFilterState) {
        setFilter({
            search: filter.filterByName === "" ? undefined : filter.filterByName,
            excludedSearch: filter.filterByExcludedName === "" ? undefined : filter.filterByExcludedName,
            project: filter.filterByProject ?? undefined,
            excludedProject: filter.filterByExcludedProject ?? undefined,
            environment: filter.filterByEnvironment ?? undefined,
            excludedEnvironment: filter.filterByExcludedEnvironment ?? undefined,
            tags: filter.filterByTags.length > 0 ? filter.filterByTags.join(",") : undefined,
            excludedTags: filter.filterByExcludedTags.length > 0 ? filter.filterByExcludedTags.join(",") : undefined,
        });
    }
    const tenantsFilterState = useMemo((): TenantsFilterState => ({
        filterByName: filter.search ?? "",
        filterByExcludedName: filter.excludedSearch ?? "",
        filterByProject: filter.project ?? undefined,
        filterByExcludedProject: filter.excludedProject ?? undefined,
        filterByEnvironment: filter.environment ?? undefined,
        filterByExcludedEnvironment: filter.excludedEnvironment ?? undefined,
        filterByTags: filter.tags ? filter.tags.split(",") : [],
        filterByExcludedTags: filter.excludedTags ? filter.excludedTags.split(",") : [],
    }), [filter.search, filter.excludedSearch, filter.project, filter.excludedProject, filter.environment, filter.excludedEnvironment, filter.tags, filter.excludedTags]);
    return [tenantsFilterState, setTenantsFilterState];
}
