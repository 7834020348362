import { css, cx } from "@emotion/css";
import { space } from "@octopusdeploy/design-system-tokens";
import type { ReactElement } from "react";
import React from "react";
import { useMediaQuery } from "react-responsive";
import { LeadingSegment } from "./LeadingSegment";
import { TrailingSegment } from "./TrailingSegment";
export interface HeaderPrimaryProps {
    /**
     * The href associated to navigate up link.
     *
     * The navigate up link is only visible when this property is defined.
     */
    backButtonHref?: string;
    /**
     * The logo to show to the left of the header title.
     *
     * The logo is only visible when this property is defined.
     */
    logo?: HeaderPrimaryLogo;
    /** The title of the HeaderPrimary. */
    title: string;
    /**
     * The icon to show next to the header title.
     *
     * The icon next to the title is only visible when this property is defined.
     */
    titleIcon?: ReactElement;
    /**
     * The primary action button to show at the end of HeaderPrimary.
     *
     * The value of this property should be a React element that having similar design as Primary Action Button.
     * The action button is only visible when this property is defined.
     */
    primaryAction?: ReactElement;
}
export interface HeaderPrimaryLogo {
    /** The url of the logo */
    href: string;
    /** The accessible name of the logo */
    accessibleName: string;
}
export function HeaderPrimary({ backButtonHref, logo, title, titleIcon, primaryAction }: HeaderPrimaryProps) {
    const isLargerThanIpad = useMediaQuery({ query: `(min-width: 811px)` });
    return (<header className={cx(headerPrimaryStyles, { [headerPrimaryMobileStyles]: !isLargerThanIpad })}>
            <LeadingSegment backButtonHref={backButtonHref} logo={logo} title={title} titleIcon={titleIcon}/>
            <TrailingSegment primaryAction={primaryAction}/>
        </header>);
}
const headerPrimaryStyles = css({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: `${space["40"]} ${space["40"]} ${space["24"]}`,
    gap: space["48"],
});
const headerPrimaryMobileStyles = css({
    flexDirection: "column",
    alignItems: "unset",
    padding: `${space["40"]} ${space["16"]} ${space["12"]}`,
    gap: space["24"],
});
