import type { InputPathToValue } from "@octopusdeploy/step-inputs";
import type { ObjectRuntimeInputs } from "@octopusdeploy/step-runtime-inputs";
import { createInputValueAccessor, isNotBoundValue } from "@octopusdeploy/step-runtime-inputs";
import type { CodeEditorComponent, NoteExpression } from "@octopusdeploy/step-ui";
import React from "react";
import { CodeEditor as BuiltInCodeEditor } from "~/components/CodeEditor/CodeEditor";
import type { InputSummary } from "~/components/StepPackageEditor/Summary/InputSummary";
export function getCodeEditorSummary<StepInputs>(component: CodeEditorComponent, inputs: ObjectRuntimeInputs<StepInputs>): InputSummary {
    const inputAccessor = createInputValueAccessor(component.input);
    const inputValue = inputAccessor.getInputValue(inputs);
    if (isNotBoundValue(inputValue)) {
        return {
            isDefaultValue: false,
            value: "Expand to edit source code",
        };
    }
    else {
        return {
            isDefaultValue: false,
            value: inputValue.expression,
        };
    }
}
interface CodeEditorProps<StepInputs> {
    configuredStepUIProps: CodeEditorComponent;
    input: InputPathToValue<string>;
    label: string;
    note?: NoteExpression[];
    inputs: ObjectRuntimeInputs<StepInputs>;
    setInputs(inputs: ObjectRuntimeInputs<StepInputs>): void;
    localNames: string[] | undefined;
}
export function CodeEditor<StepInputs>(props: CodeEditorProps<StepInputs>) {
    const inputAccessor = createInputValueAccessor<StepInputs, string>(props.input);
    const inputValue = inputAccessor.getInputValue(props.inputs);
    const value = isNotBoundValue(inputValue) ? inputValue : inputValue.expression;
    return (<BuiltInCodeEditor value={value} language={props.configuredStepUIProps.language} localNames={props.localNames} allowFullScreen={true} autoExpand={true} onChange={(newValue) => {
            const updatedInputs = inputAccessor.changeInputValue(props.inputs, newValue);
            props.setInputs(updatedInputs);
        }} showToolbar={true} showCopyButton={true} showInsertVariableButton={true}/>);
}
