/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ActionButton, RadioButtonGroup, RadioButton } from "@octopusdeploy/design-system-components";
import type { KubernetesRawYamlProperties } from "@octopusdeploy/legacy-action-properties";
import type { GitCredentialResource } from "@octopusdeploy/octopus-server-client";
import { ActionExecutionLocation, GetNamedPackageReferences, GetPrimaryPackageReference, InitialisePrimaryPackageReference, isContainerImageRegistry, PackageAcquisitionLocation, PackageSelectionMode, Permission, RemovePrimaryPackageReference, Repository, SetPrimaryPackageReference, } from "@octopusdeploy/octopus-server-client";
import { noOp } from "@octopusdeploy/utilities";
import * as _ from "lodash";
import * as React from "react";
import { TargetRoles } from "~/areas/projects/components/Process/types";
import { repository } from "~/clientInstance";
import type { ActionSummaryProps } from "~/components/Actions/actionSummaryProps";
import KubernetesRawYamlGitSourceComponent from "~/components/Actions/kubernetes/kubernetesRawYamlGitSourceComponent";
import KubernetesStatusCheckComponent, { InitialStatusCheckWithTimeoutProperties } from "~/components/Actions/kubernetes/kubernetesStatusCheckComponent";
import type { ActionEditProps } from "~/components/Actions/pluginRegistry";
import pluginRegistry from "~/components/Actions/pluginRegistry";
import { BaseComponent } from "~/components/BaseComponent/BaseComponent";
import { CodeEditor } from "~/components/CodeEditor/CodeEditor";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import StructuredConfigurationVariablesEdit from "~/components/Features/structuredConfigurationVariables/structuredConfigurationVariables";
import PackageSelector from "~/components/PackageSelector/PackageSelector";
import { hasPermission } from "~/components/PermissionCheck/PermissionCheck";
import { ProcessFeedLookup, useFeedsFromContext, useRefreshFeedsFromContext } from "../../../areas/projects/components/Process/Contexts/ProcessFeedsContextProvider";
import ListTitle from "../../../primitiveComponents/dataDisplay/ListTitle";
import CommonSummaryHelper from "../../../utils/CommonSummaryHelper";
import { TextFormat } from "../../CodeEditor/CodeEditor";
import DialogOpener from "../../Dialog/DialogOpener";
import OpenDialogButton from "../../Dialog/OpenDialogButton";
import ExternalLink from "../../Navigation/ExternalLink";
import { RemoveItemsList } from "../../RemoveItemsList/RemoveItemsList";
import SourceCodeDialog from "../../SourceCodeDialog/SourceCodeDialog";
import { FormSectionHeading, Note, required, Summary } from "../../form";
import ExpandableFormSection, { CardFill } from "../../form/Sections/ExpandableFormSection";
import { VariableLookupText } from "../../form/VariableLookupText";
import type { ActionWithFeeds } from "../commonActionHelpers";
import type { ScriptPackageProperties, ScriptPackageReference } from "../script/ScriptPackageReferenceDialog";
import { ScriptPackageReferenceDialog } from "../script/ScriptPackageReferenceDialog";
import { getKubernetesTargetDiscoveryCloudProviders } from "./getKubernetesTargetDiscoveryCloudProviders";
import KubernetesNamespaceFormSection from "./kubernetesNamespaceFormSection";
class PackageReferenceList extends RemoveItemsList<ScriptPackageReference> {
}
interface KubernetesDeployRawYamlActionEditState {
    editPackageReference?: ScriptPackageReference;
    editPackageReferenceIndex?: number;
    gitCredentials: GitCredentialResource[];
}
class KubernetesDeployRawYamlActionSummary extends BaseComponent<ActionSummaryProps, never> {
    constructor(props: any) {
        super(props);
    }
    render() {
        return <div>Deploy raw YAML resources to Kubernetes</div>;
    }
    static displayName = "KubernetesDeployRawYamlActionSummary";
}
type KubernetesDeployRawYamlActionEditProps = ActionEditProps<KubernetesRawYamlProperties, ScriptPackageProperties>;
type KubernetesDeployRawYamlActionEditInternalProps = KubernetesDeployRawYamlActionEditProps & ActionWithFeeds;
export class KubernetesDeployRawYamlActionEditInternal extends BaseComponent<KubernetesDeployRawYamlActionEditInternalProps, KubernetesDeployRawYamlActionEditState> {
    constructor(props: KubernetesDeployRawYamlActionEditInternalProps) {
        super(props);
        this.state = {
            gitCredentials: [],
        };
    }
    private setDefaultScriptSource() {
        if (this.kubernetesRawYamlGitSourceFeatureEnabled) {
            this.props.setProperties({ ["Octopus.Action.Script.ScriptSource"]: "GitRepository" });
        }
        else {
            this.props.setProperties({ ["Octopus.Action.Script.ScriptSource"]: "Inline" });
        }
    }
    private kubernetesRawYamlGitSourceFeatureEnabled = !isFeatureToggleEnabled("GitSourcedYamlManifestsReverseFeatureToggle");
    private multiGlobPathsForRawYamlFeatureToggleEnabled = !isFeatureToggleEnabled("MultiGlobPathsForRawYamlInvertedFeatureToggle");
    private globPathsGroupSupport = isFeatureToggleEnabled("GlobPathsGroupSupportFeatureToggle");
    async componentDidMount() {
        if (!this.props.properties["Octopus.Action.Script.ScriptSource"]) {
            this.setDefaultScriptSource();
        }
        if (this.kubernetesRawYamlGitSourceFeatureEnabled) {
            await this.props.doBusyTask(async () => await this.refreshGitCredentials());
        }
        else {
            this.props.setGitDependencies?.([]);
            if (this.props.properties["Octopus.Action.Script.ScriptSource"] === "GitRepository") {
                this.props.setProperties({ ["Octopus.Action.Script.ScriptSource"]: "Inline" });
            }
        }
    }
    render() {
        const pkg = GetPrimaryPackageReference(this.props.packages);
        const packageReferences = GetNamedPackageReferences(this.props.packages);
        const localNames = _.concat(this.props.localNames ? this.props.localNames : [], this.packageVariableNames());
        const editPackageReferenceDialog = (<DialogOpener open={!!this.state.editPackageReference} onClose={this.resetSelectedPackageReference}>
                <ScriptPackageReferenceDialog packageReference={this.state.editPackageReference!} runOn={this.props.runOn} 
        // The docker images will be acquired by the k8s cluster, not by Octopus or targets
        feeds={this.props.feeds
                .filter((f) => isContainerImageRegistry(f.FeedType))
                .map((f) => {
                f.PackageAcquisitionLocationOptions = [PackageAcquisitionLocation.NotAcquired];
                return f;
            })} localNames={localNames} projectId={this.props.projectId!} onChange={(packageReference) => this.savePackageReference(packageReference)} refreshFeeds={this.loadFeeds} parameters={this.props.parameters}/>
            </DialogOpener>);
        if (!this.kubernetesRawYamlGitSourceFeatureEnabled && this.props.properties["Octopus.Action.Script.ScriptSource"] === "GitRepository")
            this.onChangeTemplateSource("Inline");
        return (<div>
                {editPackageReferenceDialog}
                <ExpandableFormSection errorKey="Octopus.Action.Script.ScriptSource|Octopus.Action.KubernetesContainers.CustomResourceYaml" isExpandedByDefault={this.props.expandedByDefault} title="YAML Source" fillCardWidth={CardFill.FillRight} summary={this.summaryRawYaml()} help={"Select the source of the Kubernetes resources."}>
                    {this.kubernetesRawYamlGitSourceFeatureEnabled && <Note>Kubernetes resources can be selected from a Git repository, package, or entered inline.</Note>}
                    {!this.kubernetesRawYamlGitSourceFeatureEnabled && <Note>Kubernetes resources can be selected from a package or entered inline.</Note>}
                    <RadioButtonGroup accessibleName="Script Source" value={this.props.properties["Octopus.Action.Script.ScriptSource"]} onChange={(val) => this.onChangeTemplateSource(val)} error={this.props.getFieldError("Octopus.Action.Script.ScriptSource")}>
                        {this.kubernetesRawYamlGitSourceFeatureEnabled && (<>
                                <RadioButton accessibleName="Git Repository" value={"GitRepository"} label="Git Repository" isDefault={true}/>
                                <RadioButton accessibleName="Package" value={"Package"} label="Package"/>
                                <RadioButton accessibleName="Inline" value={"Inline"} label="Inline YAML"/>
                            </>)}
                        {!this.kubernetesRawYamlGitSourceFeatureEnabled && (<>
                                <RadioButton value={"Inline"} label="Inline YAML" isDefault={true}/>
                                <RadioButton value={"Package"} label="Package"/>
                            </>)}
                    </RadioButtonGroup>
                    {this.props.properties["Octopus.Action.Script.ScriptSource"] === "Inline" && (<div>
                            <br />
                            {this.props.properties["Octopus.Action.KubernetesContainers.CustomResourceYaml"] && (<>
                                    <CodeEditor value={this.props.properties["Octopus.Action.KubernetesContainers.CustomResourceYaml"]} language={TextFormat.YAML} allowFullScreen={false} readOnly={true} onChange={noOp}/>
                                    <br />
                                </>)}
                            <div>
                                <OpenDialogButton label={this.props.properties["Octopus.Action.KubernetesContainers.CustomResourceYaml"] ? "Edit Source Code" : "Add Source Code"} wideDialog={true} renderDialog={(openProps) => (<SourceCodeDialog open={openProps.open} close={openProps.closeDialog} value={this.props.properties["Octopus.Action.KubernetesContainers.CustomResourceYaml"]} autocomplete={[]} onSave={(value) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.CustomResourceYaml"]: value })} language={TextFormat.YAML}/>)}/>
                            </div>
                        </div>)}
                </ExpandableFormSection>
                {this.props.properties["Octopus.Action.Script.ScriptSource"] === "Package" && (<div>
                        <ExpandableFormSection errorKey="Octopus.Action.Package.FeedId|Octopus.Action.Package.PackageId" isExpandedByDefault={this.props.expandedByDefault} title="Package" summary={CommonSummaryHelper.packageSummary(pkg, this.props.feeds)} help={"Choose the package that contains the Kubernetes resource YAML."}>
                            <PackageSelector packageId={pkg?.PackageId} feedId={pkg?.FeedId} onPackageIdChange={(packageId) => this.props.setPackages(SetPrimaryPackageReference({ PackageId: packageId }, this.props.packages))} onFeedIdChange={(feedId) => this.props.setPackages(SetPrimaryPackageReference({ FeedId: feedId }, this.props.packages))} packageIdError={this.props.getFieldError("Octopus.Action.Package.PackageId")} feedIdError={this.props.getFieldError("Octopus.Action.Package.FeedId")} projectId={this.props.projectId} feeds={this.props.feeds} localNames={this.props.localNames} refreshFeeds={this.loadFeeds}/>
                            <VariableLookupText localNames={this.props.localNames} value={this.props.properties["Octopus.Action.KubernetesContainers.CustomResourceYamlFileName"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.CustomResourceYamlFileName"]: x })} label={this.getPackagePathsLabel()} error={this.props.getFieldError("Octopus.Action.KubernetesContainers.CustomResourceYamlFileName")} validate={this.globPathsGroupSupport ? (p) => validatePaths(p, this.getPathsNotSetValidationMessage()) : required(this.getPathsNotSetValidationMessage())} accessibleName={this.getPackagePathsAccessibleName()} multiline={this.multiGlobPathsForRawYamlFeatureToggleEnabled}/>
                            {this.getPackagePathsNote()}
                        </ExpandableFormSection>
                    </div>)}
                {this.props.properties["Octopus.Action.Script.ScriptSource"] === "GitRepository" && (<KubernetesRawYamlGitSourceComponent properties={this.props.properties} gitCredentials={this.state.gitCredentials} gitDependencies={this.props.gitDependencies} packages={this.props.packages} plugin={this.props.plugin} errors={this.props.errors} busy={this.props.busy} expandedByDefault={this.props.expandedByDefault} getFieldError={this.props.getFieldError} setProperties={this.props.setProperties} setGitDependencies={this.props.setGitDependencies} doBusyTask={this.props.doBusyTask} refreshGitCredentials={this.refreshGitCredentials} setPackages={this.props.setPackages} filePathValidation={this.globPathsGroupSupport ? validatePaths : required(this.getPathsNotSetValidationMessage())}/>)}
                <FormSectionHeading title="Additional Configuration Options"/>
                <KubernetesStatusCheckComponent jobsSupported={true} timeoutSupported={true} statusCheckSupported={true} showLegacyWait={false} properties={this.props.properties} packages={this.props.packages} plugin={this.props.plugin} errors={this.props.errors} busy={this.props.busy} expandedByDefault={this.props.expandedByDefault} getFieldError={this.props.getFieldError} setProperties={this.props.setProperties} setPackages={this.props.setPackages} doBusyTask={this.props.doBusyTask}/>
                <StructuredConfigurationVariablesEdit {...this.props} defaultTargetsGlob={"**/*.{yml,yaml}"} supportedDataTypes={["YAML"]} cannotSupplyTargets={true}/>
                <ExpandableFormSection title={"Referenced Packages"} isExpandedByDefault={this.props.expandedByDefault} errorKey="Octopus.Action.Script.Packages" summary={this.packageReferenceSummary()} help={"Add packages to be referenced by your scripts at execution-time"}>
                    <Note>
                        Learn more about <ExternalLink href="ScriptStepPackageReferences">package references</ExternalLink>.
                    </Note>
                    <PackageReferenceList listActions={[<ActionButton key="add" label="Add" onClick={() => this.addPackageReference()}/>]} data={packageReferences} onRow={(p) => this.packageReferenceListItem(p)} onRowTouch={(pkg) => this.editPackageReference(pkg)} onRemoveRow={(pkg) => this.removePackageReference(pkg)}/>
                </ExpandableFormSection>
                <KubernetesNamespaceFormSection namespace={this.props.properties["Octopus.Action.KubernetesContainers.Namespace"]} onChange={(ns) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.Namespace"]: ns })}/>
            </div>);
    }
    getPackagePathsNote() {
        if (this.multiGlobPathsForRawYamlFeatureToggleEnabled) {
            return (<Note>
                    This field supports glob patterns syntax e.g. <code>my/folder/*</code> will include all files in a folder.
                    <br />
                    Separate multiple paths with new lines. See our <ExternalLink href={"glob-patterns-cheat-sheet"}>glob patterns cheat sheet</ExternalLink> for more info.
                </Note>);
        }
        else {
            return (<Note>
                    The relative path to the YAML file that contains the Kubernetes resources.
                    <br />
                    e.g. <code>MyPod.yaml</code> or <code>Resources\MyPod.yaml</code>
                </Note>);
        }
    }
    getPathsNotSetValidationMessage() {
        if (this.multiGlobPathsForRawYamlFeatureToggleEnabled) {
            return "Enter the path(s) to your YAML file(s).";
        }
        else {
            return "Enter the path to your YAML file.";
        }
    }
    getPackagePathsAccessibleName() {
        if (this.multiGlobPathsForRawYamlFeatureToggleEnabled) {
            return "Paths to the YAML files in your Package";
        }
        else {
            return "Path to the YAML file in your Package";
        }
    }
    getPackagePathsLabel() {
        if (this.multiGlobPathsForRawYamlFeatureToggleEnabled) {
            return "Kubernetes YAML file paths";
        }
        else {
            return "Kubernetes YAML file name";
        }
    }
    refreshGitCredentials = async () => {
        if (hasPermission(Permission.GitCredentialView)) {
            const gitCredentials = await repository.GitCredentials.list({ take: Repository.takeAll });
            this.setState({
                gitCredentials: gitCredentials.Items,
            });
        }
        else {
            this.setState({
                gitCredentials: [],
            });
        }
    };
    savePackageReference = (packageReference: ScriptPackageReference) => {
        const packageReferences = [...this.props.packages];
        if (this.state.editPackageReferenceIndex) {
            packageReferences[this.state.editPackageReferenceIndex] = packageReference;
        }
        else {
            packageReferences.push(packageReference);
        }
        this.props.setPackages(packageReferences);
        this.resetSelectedPackageReference();
        return true;
    };
    packageVariableNames = (): string[] => _.flatten(GetNamedPackageReferences(this.props.packages).map((pkg) => [
        `Octopus.Action.Package[${pkg.Name}].PackageId`,
        `Octopus.Action.Package[${pkg.Name}].FeedId`,
        `Octopus.Action.Package[${pkg.Name}].PackageVersion`,
        `Octopus.Action.Package[${pkg.Name}].Path`,
    ]));
    resetSelectedPackageReference = () => this.setState({
        editPackageReference: null!,
        editPackageReferenceIndex: null!,
    });
    addPackageReference = () => {
        const additionalPackage: ScriptPackageReference = {
            Id: null!,
            Name: null!,
            FeedId: null!,
            PackageId: null!,
            // The packages are docker images to be acquired by the cluster, not the target
            AcquisitionLocation: PackageAcquisitionLocation.NotAcquired,
            Properties: {
                Extract: "false",
            },
        };
        this.setState({ editPackageReference: additionalPackage, editPackageReferenceIndex: null! });
    };
    editPackageReference = (pkg: ScriptPackageReference) => this.setState({
        editPackageReference: _.clone(pkg),
        editPackageReferenceIndex: this.props.packages.indexOf(pkg),
    });
    removePackageReference = (pkg: ScriptPackageReference) => {
        const packages = [...this.props.packages];
        packages.splice(packages.indexOf(pkg), 1);
        this.props.setPackages(packages);
    };
    packageReferenceListItem = (pkg: ScriptPackageReference) => (<ProcessFeedLookup feedId={pkg.FeedId}>
            {(feed) => (<div>
                    <ListTitle>{pkg.Name}</ListTitle>
                    <div>
                        {pkg.Properties["SelectionMode"] === PackageSelectionMode.Immediate && (<React.Fragment>
                                <strong>{pkg.PackageId}</strong> from feed <strong>{!!feed ? feed.Name : pkg.FeedId}</strong>
                            </React.Fragment>)}
                        {pkg.Properties["SelectionMode"] === PackageSelectionMode.Deferred && <React.Fragment>Package will be selected by the project</React.Fragment>}
                    </div>
                </div>)}
        </ProcessFeedLookup>);
    packageReferenceSummary = () => {
        const namedPackageReferences = GetNamedPackageReferences(this.props.packages);
        if (namedPackageReferences.length === 0) {
            return Summary.placeholder("No additional packages referenced");
        }
        return Summary.summary(`${namedPackageReferences.length} package references`);
    };
    private summaryRawYaml = () => {
        const source = this.props.properties["Octopus.Action.Script.ScriptSource"];
        if (source === "GitRepository") {
            return Summary.summary("File(s) from a Git repository");
        }
        if (source === "Inline") {
            return Summary.summary("Source code");
        }
        if (source === "Package") {
            return Summary.summary("File inside a package");
        }
        return Summary.placeholder("Resource source not specified");
    };
    private onChangeTemplateSource(value: string) {
        this.props.setProperties({
            ["Octopus.Action.Script.ScriptSource"]: value,
        });
        if (value !== "GitRepository") {
            this.props.setGitDependencies?.([]);
        }
        if (value !== "Package") {
            this.props.setPackages(RemovePrimaryPackageReference(this.props.packages));
        }
        if (value === "Package") {
            this.props.setPackages(InitialisePrimaryPackageReference(this.props.packages, this.props.feeds));
        }
    }
    private loadFeeds = async () => {
        await this.props.refreshFeeds();
    };
    static displayName = "KubernetesDeployRawYamlActionEditInternal";
}
export const invalidPathsErrorMessage = "The path to your YAML file(s) cannot stem from a root directory or use '.' or '..' directories.";
export function validatePaths(pathsString: string, notSetValidationMessage: string) {
    const requiredMessage = required(notSetValidationMessage)(pathsString);
    if (requiredMessage || pathsString.trim().length < 1) {
        return notSetValidationMessage;
    }
    const paths = pathsString
        .split(/[\n\r]/)
        .map((p) => p.trim())
        .filter((p) => p.length > 0) ?? [];
    if (paths.length === 0)
        return "";
    // If you change this regex, also change it in KubernetesDeployRawYamlActionValidator.cs
    // This Regex matches:
    //  - Paths which start with a root directory eg: '/', '\', 'C:\'
    //  - Paths which use either '.' or '..' directories eg: '.\', 'folder/../', '../'
    // See kubernetesRawYamlAction.spec.tsx for examples of valid and invalid paths.
    const invalidPathRegexes = ["^[A-Za-z]:(\\\\|/|$)", "(^|(^|\\\\|/)[.][.]?)(\\\\|/)"];
    if (paths.some((p) => invalidPathRegexes.some((r) => p.match(r)))) {
        return invalidPathsErrorMessage;
    }
    return "";
}
export function KubernetesDeployRawYamlActionEdit(props: React.PropsWithChildren<KubernetesDeployRawYamlActionEditProps>) {
    const feeds = useFeedsFromContext();
    const refreshFeeds = useRefreshFeedsFromContext();
    return <KubernetesDeployRawYamlActionEditInternal {...props} feeds={feeds} refreshFeeds={refreshFeeds}/>;
}
pluginRegistry.registerAction({
    executionLocation: ActionExecutionLocation.AlwaysOnServer,
    actionType: "Octopus.KubernetesDeployRawYaml",
    summary: (properties, targetRolesAsCSV) => <KubernetesDeployRawYamlActionSummary properties={properties} targetRolesAsCSV={targetRolesAsCSV}/>,
    editSections: { default: KubernetesDeployRawYamlActionEdit },
    canHaveChildren: (step) => true,
    canBeChild: true,
    targetRoleOption: (action) => TargetRoles.Required,
    hasPackages: (action) => false,
    targetDiscoveryCloudConnectionProviders: getKubernetesTargetDiscoveryCloudProviders,
    getInitialProperties: () => InitialStatusCheckWithTimeoutProperties,
    canUseExecutionTimeouts: false,
});
