import { css } from "@emotion/css";
import { ActionButtonType } from "@octopusdeploy/design-system-components";
import type { ProjectResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import ConnectTenantsButton from "~/areas/projects/components/ProjectTenants/ConnectTenantsButton";
import { AddOrCloneTenant } from "~/areas/tenants/Tenants/AddOrCloneTenant";
import OnboardingPage from "~/components/GettingStarted/OnboardingPage";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
export interface OnboardingProps {
    project: ProjectResource;
    onConnected: (numberOfTenantsConnected: number) => void;
}
export default function Onboarding(props: OnboardingProps) {
    return (<OnboardingPage title="Connect Tenants to start deploying your software to multiple end-customers" intro="Use Tenants to deploy your projects to multiple end-customers, with the software tailored to each specific customer." learnMore={<ExternalLink href="MultiTenantDeployments">Learn more</ExternalLink>} actionButtons={<div className={styles.actionButtonsContainer}>
                    <ConnectTenantsButton {...props}/>
                    <div className={styles.addTenantButton}>
                        <AddOrCloneTenant type={ActionButtonType.Secondary}/>
                    </div>
                </div>}/>);
}
const styles = {
    actionButtonsContainer: css({
        display: "inline-flex",
    }),
    addTenantButton: css({
        marginLeft: "1rem",
    }),
};
