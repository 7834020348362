import { css } from "@emotion/css";
import { ActionButton, ActionButtonType } from "@octopusdeploy/design-system-components";
import type { TagSetResource } from "@octopusdeploy/octopus-server-client";
import React from "react";
import type { FilterEvent } from "~/analytics/Analytics";
import { Action, useAnalyticFilterDispatch } from "~/analytics/Analytics";
import type { TenantsFilterState } from "~/areas/tenants/Tenants/hooks/useTenantsFilterState";
import { useTenantsFilterState } from "~/areas/tenants/Tenants/hooks/useTenantsFilterState";
import SingleFilter from "~/areas/tenants/components/Filtering/SingleFilter";
import type { TenantFiltersData } from "~/areas/tenants/components/Filtering/hooks/useTenantFiltersData";
import type { TenantsFilterRowsState, FilterRow, TenantsFilterRowsActions } from "~/areas/tenants/components/Filtering/hooks/useTenantsFilterRows";
import { useTenantsFilterRows } from "~/areas/tenants/components/Filtering/hooks/useTenantsFilterRows";
interface TenantFiltersProps {
    onFilterChange: (selections: TenantsFilterState) => void;
    data: TenantFiltersData;
}
export default function TenantFilters({ onFilterChange, data }: TenantFiltersProps) {
    const [filterState] = useTenantsFilterState();
    const dispatchAction = useAnalyticFilterDispatch();
    const [filterRows, filterRowsActions] = useTenantsFilterRows(filterState, data);
    const handleSubmit = async () => {
        const tenantsFilter = buildTenantsFilter(filterRows.selectedFilterRows, data.tagSets);
        const event = buildFilterEvent(tenantsFilter);
        await dispatchAction("Apply Filters", event, async () => {
            await onFilterChange(tenantsFilter);
            return true;
        });
    };
    const handleReset = async () => {
        filterRowsActions.clearSelectedFilters();
        await onFilterChange(emptyQuery);
    };
    return (<form onSubmit={handleSubmit}>
            <div className={styles.filterRowsContainer}>
                <SelectedFilters filterRows={filterRows} filterRowsActions={filterRowsActions} data={data}/>
                {filterRows.availableFilterRows.length > 0 ? <UnselectedFilter filterRows={filterRows} filterRowsActions={filterRowsActions} data={data}/> : null}
            </div>
            <div className={styles.actions}>
                <ActionButton label="Apply" onClick={handleSubmit} type={ActionButtonType.Primary}/>
                <ActionButton label="Reset" onClick={handleReset} type={ActionButtonType.Secondary}/>
            </div>
        </form>);
}
interface SelectedFiltersProps {
    filterRows: TenantsFilterRowsState;
    filterRowsActions: TenantsFilterRowsActions;
    data: TenantFiltersData;
}
function SelectedFilters({ filterRows, filterRowsActions, data }: SelectedFiltersProps) {
    return (<React.Fragment>
            {filterRows.selectedFilterRows.map((filter, index) => {
            return <SingleFilter key={filter.key} selected={filter} availableFilterRows={filterRows.availableFilterRows} actions={filterRowsActions} data={data} index={index}/>;
        })}
        </React.Fragment>);
}
function UnselectedFilter({ filterRows, filterRowsActions, data }: SelectedFiltersProps) {
    return <SingleFilter key={"undefined-filter"} selected={undefined} availableFilterRows={filterRows.availableFilterRows} actions={filterRowsActions} data={data} index={filterRows.selectedFilterRows.length}/>;
}
function buildFilterEvent(query: TenantsFilterState): FilterEvent {
    // Unique operators refer to the "kind" of filtering being done. E.g. IS and IN are treated as one operator, while IS NOT and NOT IN are treated as another
    const isIsOperatorUsed = query.filterByName || query.filterByProject || query.filterByEnvironment || query.filterByTags.length > 0 ? 1 : 0;
    const isIsNotOperatorUsed = query.filterByExcludedName || query.filterByExcludedProject || query.filterByExcludedEnvironment || query.filterByExcludedTags.length > 0 ? 1 : 0;
    const numUniqueOperators = isIsOperatorUsed + isIsNotOperatorUsed;
    const isFilterRuleUsed = (filterRule: string | undefined) => (filterRule ? 1 : 0);
    const isTagsFilterRuleUsed = (filterRule: string[]) => (filterRule.length > 0 ? 1 : 0);
    const numFilterRules = isFilterRuleUsed(query.filterByName) +
        isFilterRuleUsed(query.filterByExcludedName) +
        isFilterRuleUsed(query.filterByProject) +
        isFilterRuleUsed(query.filterByExcludedProject) +
        isFilterRuleUsed(query.filterByEnvironment) +
        isFilterRuleUsed(query.filterByExcludedEnvironment) +
        isTagsFilterRuleUsed(query.filterByTags) +
        isTagsFilterRuleUsed(query.filterByExcludedTags);
    return { numFilterRules, numUniqueOperators, resource: "Filters", action: Action.Apply };
}
const buildTenantsFilter = (filters: FilterRow[], tagSets: Map<string, TagSetResource>): TenantsFilterState => {
    const { includeTags, excludeTags } = filters.reduce((selected: {
        includeTags: string[];
        excludeTags: string[];
    }, filter: FilterRow) => {
        const set = tagSets.get(filter.groupId);
        if (set && filter.action.value === "include") {
            selected.includeTags.push(...filter.value);
        }
        if (set && filter.action.value === "exclude") {
            selected.excludeTags.push(...filter.value);
        }
        return selected;
    }, { includeTags: [], excludeTags: [] });
    return {
        filterByName: (filters.find((f) => f.key === fixedFilters.nameInclude.key)?.value || [])[0] || "",
        filterByExcludedName: (filters.find((f) => f.key === fixedFilters.nameExclude.key)?.value || [])[0] || "",
        filterByTags: includeTags,
        filterByExcludedTags: excludeTags,
        filterByProject: (filters.find((f) => f.key === fixedFilters.projectInclude.key)?.value || [])[0],
        filterByExcludedProject: (filters.find((f) => f.key === fixedFilters.projectExclude.key)?.value || [])[0],
        filterByEnvironment: (filters.find((f) => f.key === fixedFilters.environmentInclude.key)?.value || [])[0],
        filterByExcludedEnvironment: (filters.find((f) => f.key === fixedFilters.environmentExclude.key)?.value || [])[0],
    };
};
export const includeKey = (id: string) => `${id}-include`;
export const excludeKey = (id: string) => `${id}-exclude`;
interface FixedFilters {
    nameInclude: FilterRow;
    nameExclude: FilterRow;
    projectInclude: FilterRow;
    projectExclude: FilterRow;
    environmentInclude: FilterRow;
    environmentExclude: FilterRow;
}
export const fixedFilters: FixedFilters = {
    nameInclude: { groupId: "name", name: "Name", key: includeKey("name"), action: { value: "include", text: "contains" }, value: [] },
    nameExclude: { groupId: "name", name: "Name", key: excludeKey("name"), action: { value: "exclude", text: "does not contain" }, value: [] },
    projectInclude: { groupId: "project", name: "Project", key: includeKey("project"), action: { value: "include", text: "is" }, value: [] },
    projectExclude: { groupId: "project", name: "Project", key: excludeKey("project"), action: { value: "exclude", text: "is not" }, value: [] },
    environmentInclude: { groupId: "environment", name: "Environment", key: includeKey("environment"), action: { value: "include", text: "is" }, value: [] },
    environmentExclude: { groupId: "environment", name: "Environment", key: excludeKey("environment"), action: { value: "exclude", text: "is not" }, value: [] },
};
const emptyQuery: TenantsFilterState = {
    filterByName: ``,
    filterByExcludedName: ``,
    filterByTags: [],
    filterByExcludedTags: [],
    filterByProject: undefined,
    filterByExcludedProject: undefined,
    filterByEnvironment: undefined,
    filterByExcludedEnvironment: undefined,
};
const styles = {
    filterRowsContainer: css({
        display: "grid",
        gridTemplateColumns: "max-content minmax(auto, 15rem) minmax(auto, 11rem) minmax(auto, 32rem) auto",
        gridColumnGap: "1rem",
        gridTemplateRows: "auto",
        alignItems: "end",
        marginTop: "0.4rem",
        marginBottom: "1rem",
    }),
    actions: css({
        display: "flex",
        justifyContent: "flex-start",
        gap: "0.5rem",
    }),
};
