import { ActionButton, ActionButtonType } from "@octopusdeploy/design-system-components";
import * as React from "react";
import Card from "~/components/Card/Card";
import styles from "./style.module.less";
interface LoadMoreCardProps {
    onLoadMore: () => void;
    onLoadAll: () => void;
}
const LoadMoreCard: React.StatelessComponent<LoadMoreCardProps> = (props) => {
    return (<Card className={styles.hoverlessContainer} logo={null} header={<ActionButton type={ActionButtonType.Secondary} label="Load more" onClick={(e) => props.onLoadMore()}/>} content={<div className={styles.content}>
                    Use filters to narrow the results (or{" "}
                    <a href="#" onClick={(e) => {
                e.preventDefault();
                props.onLoadAll();
            }}>
                        load all
                    </a>
                    )
                </div>} link={<div />}/>);
};
LoadMoreCard.displayName = "LoadMoreCard"
export default LoadMoreCard;
