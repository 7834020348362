import type { ConnectTenantsToProjectTaskResource, ProjectTenant } from "@octopusdeploy/octopus-server-client";
import { useState } from "react";
import type { TenantsPageState } from "~/areas/tenants/Tenants/hooks/useTenantsState";
import { repository } from "~/clientInstance";
import type { DoBusyTask } from "~/components/DataBaseComponent/index";
import { useDoBusyTaskEffect } from "~/components/DataBaseComponent/index";
import useLocalStorage from "~/hooks/useLocalStorage";
const projectTenantsPagingSizeKey = "octoProjectTenantsPagingSize";
interface ProjectTenantsTableState {
    tenants: ProjectTenant[];
    totalTenantCount: number;
}
interface ProjectTenantsStateActions {
    setPageNumber: (newPageNumber: number) => void;
    setPageSize: (newPageSize: number) => void;
    refreshTable: () => void;
}
interface ProjectTenantsState {
    tableState: ProjectTenantsTableState | null;
    pageState: TenantsPageState;
    actions: ProjectTenantsStateActions;
}
export function useProjectTenantsState(projectId: string, doBusyTask: DoBusyTask, updateShowActionButtons: (show: boolean) => void, latestConnectionTask: ConnectTenantsToProjectTaskResource | null): ProjectTenantsState {
    const [pageSize, setPageSize] = useLocalStorage(projectTenantsPagingSizeKey, 30);
    const [pageNumber, setPageNumber] = useState(1);
    const [tableState, setTableState] = useState<ProjectTenantsTableState | null>(null);
    const [dataVersion, setDataVersion] = useState(1);
    useDoBusyTaskEffect(doBusyTask, async () => {
        const response = await repository.Projects.getTenants(projectId, pageNumber, pageSize);
        setTableState({ tenants: response.Tenants, totalTenantCount: response.TotalTenantCount });
        updateShowActionButtons(response.TotalTenantCount !== 0);
    }, [pageNumber, pageSize, latestConnectionTask?.ConnectionsCompleted, latestConnectionTask?.Id, dataVersion]);
    const actions: ProjectTenantsStateActions = {
        setPageNumber: (newPageNumber) => setPageNumber(newPageNumber),
        setPageSize: (newPageSize) => setPageSize(newPageSize),
        refreshTable: () => setDataVersion(dataVersion + 1),
    };
    return {
        tableState,
        pageState: {
            pageNumber,
            pageSize,
        },
        actions,
    };
}
