import type { TenantsOverviewTenant } from "@octopusdeploy/octopus-server-client";
import { useState } from "react";
import type { TenantsFilterState } from "~/areas/tenants/Tenants/hooks/useTenantsFilterState";
import { useTenantsFilterState } from "~/areas/tenants/Tenants/hooks/useTenantsFilterState";
import { repository } from "~/clientInstance";
import type { DoBusyTask } from "~/components/DataBaseComponent/index";
import { useDoBusyTaskEffect } from "~/components/DataBaseComponent/index";
import useLocalStorage from "~/hooks/useLocalStorage";
interface TenantsTableState {
    tenants: TenantsOverviewTenant[];
    totalTenantCount: number;
    filteredTenantCount: number | null;
}
export interface TenantsPageState {
    pageNumber: number;
    pageSize: number;
}
interface TenantsStateActions {
    setPageNumber: (newPageNumber: number) => void;
    setPageSize: (newPageSize: number) => void;
    setTenantsFilter: (newTenantsFilter: TenantsFilterState) => void;
}
interface TenantsState {
    tableState: TenantsTableState | null;
    pageState: TenantsPageState;
    filterState: TenantsFilterState;
    tenantsActions: TenantsStateActions;
}
const tenantsPagingSelectionKey = "octoTenantsPagingSelection";
export function useTenantsState(doBusyTask: DoBusyTask): TenantsState {
    const [tableState, setTableState] = useState<TenantsTableState | null>(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useLocalStorage(tenantsPagingSelectionKey, 30);
    const [filterState, setFilterState] = useTenantsFilterState();
    useDoBusyTaskEffect(doBusyTask, async () => {
        const response = await repository.Tenants.tenantsOverview(pageNumber, pageSize, filterState);
        setTableState({ tenants: response.Tenants, totalTenantCount: response.TotalTenantCount, filteredTenantCount: response.FilteredTenantCount });
    }, [pageNumber, pageSize, filterState]);
    const actions: TenantsStateActions = {
        setPageNumber: (newPageNumber) => setPageNumber(newPageNumber),
        setPageSize: (newPageSize) => setPageSize(newPageSize),
        setTenantsFilter: (newTenantsFilter) => setFilterState(newTenantsFilter),
    };
    return {
        tableState,
        pageState: {
            pageNumber,
            pageSize,
        },
        filterState,
        tenantsActions: actions,
    };
}
