import { IconButton } from "@octopusdeploy/design-system-components";
import React, { useEffect, useState } from "react";
import Tooltip from "~/primitiveComponents/dataDisplay/ToolTip";
import styles from "./style.module.less";
interface CopyToClipboardProps {
    value: string;
    showHoverTooltip?: boolean;
    children?: React.ReactNode;
}
const CopyToClipboardButton: React.FC<CopyToClipboardProps> = ({ value, children, showHoverTooltip = true }) => {
    const [event, setEvent] = useState<React.MouseEvent | null>(null);
    const [showConfirmation, setShowConfirmation] = useState(false);
    useEffect(() => {
        if (event) {
            setShowConfirmation(true);
            const timerId = window.setTimeout(() => setShowConfirmation(false), 3500);
            return () => {
                window.clearTimeout(timerId);
            };
        }
    }, [event]);
    const onClick = (e: React.MouseEvent) => {
        const copyText = async () => {
            await navigator.clipboard.writeText(value);
            setEvent(e);
        };
        copyText();
    };
    const outerTooltipTrigger = showConfirmation ? "manual" : undefined;
    const outerTooltipOpen = showConfirmation || !showHoverTooltip ? false : undefined;
    const innerTooltipOpen = showConfirmation ? true : false;
    return (<span>
            <Tooltip content="Copy to clipboard" trigger={outerTooltipTrigger} open={outerTooltipOpen}>
                <Tooltip content="Copied" trigger="manual" open={innerTooltipOpen}>
                    <span>{children ? <span onClick={onClick}>{children}</span> : <IconButton onClick={onClick} className={styles.defaultIconButton} icon="CopyToClipboard"/>}</span>
                </Tooltip>
            </Tooltip>
        </span>);
};
CopyToClipboardButton.displayName = "CopyToClipboardButton"
export default CopyToClipboardButton;
