import { css } from "@emotion/css";
import { ActionButton, ActionButtonType, Divider, IconButton, List, ListItem } from "@octopusdeploy/design-system-components";
import { colorScales, space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { TenantResource } from "@octopusdeploy/octopus-server-client";
import pluralize from "pluralize";
import { useState } from "react";
import * as React from "react";
import { Action, useAnalyticActionDispatch } from "~/analytics/Analytics";
import { defaultTenantWizardDialogPageSize, PagingSection } from "~/areas/projects/components/ProjectTenants/PagingSection";
import { ListItemContentWithButtons } from "~/components/BorderedListItem/index";
import Logo from "~/components/Logo/index";
import defaultBlankPageImgDark from "~/components/ProjectBasedActivation/assets/dark/darkimg-defaultblankspace.svg";
import defaultBlankPageImg from "~/components/ProjectBasedActivation/assets/img-defaultblankspace.svg";
import { useThemePaletteType } from "~/components/Theme/useThemePaletteType";
interface SelectTenantsProps {
    tenants: TenantResource[];
    selectedTenants: TenantResource[];
    updateSelectedTenants: (tenants: TenantResource[]) => void;
}
export default function SelectTenants({ tenants, selectedTenants, updateSelectedTenants }: SelectTenantsProps) {
    const dispatchAction = useAnalyticActionDispatch();
    const unselectedTenants = tenants.filter((t) => !selectedTenants.includes(t));
    const handleSelect = (tenant: TenantResource) => updateSelectedTenants([...selectedTenants, tenant]);
    const handleRemove = (tenant: TenantResource) => updateSelectedTenants(selectedTenants.filter((t) => t.Id !== tenant.Id));
    const handleSelectAll = () => {
        dispatchAction("Select All Tenants", { resource: "Tenant", action: Action.Add });
        updateSelectedTenants([...selectedTenants, ...unselectedTenants]);
        setUnselectedPageNumber(1);
    };
    const isDarkMode = useThemePaletteType() === "dark";
    const emptySelectedListImage = isDarkMode ? defaultBlankPageImgDark : defaultBlankPageImg;
    const [unselectedPageNumber, setUnselectedPageNumber] = useState(1);
    const [unselectedPageSize, setUnselectedPageSize] = useState(defaultTenantWizardDialogPageSize);
    const unselectedTenantsToDisplay = unselectedTenants.slice((unselectedPageNumber - 1) * unselectedPageSize, unselectedPageNumber * unselectedPageSize);
    const [selectedPageNumber, setSelectedPageNumber] = useState(1);
    const [selectedPageSize, setSelectedPageSize] = useState(defaultTenantWizardDialogPageSize);
    const selectedTenantsToDisplay = selectedTenants.slice((selectedPageNumber - 1) * selectedPageSize, selectedPageNumber * selectedPageSize);
    return (<div className={styles.panelContainer}>
            <div className={styles.listContainer}>
                <div className={styles.panelHeading}>{pluralize("tenant", unselectedTenants.length, true)}</div>
                <Divider />
                <div className={styles.tenantsList}>
                    <List items={unselectedTenantsToDisplay} rowKey={(x) => x.Id} renderRow={({ item: tenant, index }) => index === 0 ? (<React.Fragment>
                                    <SelectAllRow handleSelectAll={handleSelectAll}/>
                                    <Divider />
                                    <UnselectedTenantRow tenant={tenant} handleSelect={handleSelect}/>
                                </React.Fragment>) : (<UnselectedTenantRow tenant={tenant} handleSelect={handleSelect}/>)} empty={<div className={styles.emptyUnselectedList}>{tenants.length > 0 ? "All available tenants have been selected." : "All available tenants have already been connected."}</div>}/>
                </div>
                <PagingSection itemCount={unselectedTenants.length} pageNumber={unselectedPageNumber} pageSize={unselectedPageSize} onPagingSelectionChange={(newPageNumber, newPageSize) => {
            setUnselectedPageNumber(newPageNumber);
            setUnselectedPageSize(newPageSize);
        }}/>
            </div>
            <div className={styles.listContainer}>
                <div className={styles.panelHeading}>{pluralize("tenant", selectedTenants.length, true)} selected</div>
                <Divider />
                <div className={styles.tenantsList}>
                    <List items={selectedTenantsToDisplay} rowKey={(x) => x.Id} renderRow={({ item: tenant, index }) => <SelectedTenantRow tenant={tenant} handleRemove={handleRemove}/>} empty={<div className={styles.emptySelectedList}>
                                <img src={emptySelectedListImage} alt={"Empty selected tenants list"}/>
                                Looks like you haven't selected any tenants yet.
                            </div>}/>
                </div>
                <PagingSection itemCount={selectedTenants.length} pageNumber={selectedPageNumber} pageSize={selectedPageSize} onPagingSelectionChange={(newPageNumber, newPageSize) => {
            setSelectedPageNumber(newPageNumber);
            setSelectedPageSize(newPageSize);
        }}/>
            </div>
        </div>);
}
function SelectAllRow({ handleSelectAll }: {
    handleSelectAll: () => void;
}) {
    return (<React.Fragment>
            <ListItemContentWithButtons content={<div className={styles.listItem}>
                        <ActionButton label={"Select all"} onClick={handleSelectAll} type={ActionButtonType.Ternary}/>
                    </div>}/>
        </React.Fragment>);
}
function UnselectedTenantRow({ tenant, handleSelect }: {
    tenant: TenantResource;
    handleSelect: (tenant: TenantResource) => void;
}) {
    return (<ListItem>
            <ListItemContentWithButtons content={<div className={styles.listItem}>
                        <Logo url={tenant.Links.Logo} size={"2rem"} overflow={true}/>
                        {tenant.Name}
                    </div>} buttons={<IconButton customIcon={<BluePlusIcon />} onClick={() => handleSelect(tenant)}/>}/>
        </ListItem>);
}
function SelectedTenantRow({ tenant, handleRemove }: {
    tenant: TenantResource;
    handleRemove: (tenant: TenantResource) => void;
}) {
    return (<ListItem>
            <ListItemContentWithButtons content={<div className={styles.listItem}>
                        <Logo url={tenant.Links.Logo} size={"2rem"} overflow={true}/>
                        {tenant.Name}
                    </div>} buttons={<IconButton customIcon={<RedMinusIcon />} onClick={() => handleRemove(tenant)}/>}/>
        </ListItem>);
}
function RedMinusIcon() {
    return (<svg className={styles.icon} viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
            <path d="M26 19C26.5 19 27 19.5 27 20C27 20.5625 26.5 21 26 21H14C13.4375 21 13 20.5625 13 20C13 19.5 13.4375 19 14 19H26ZM36 20C36 28.875 28.8125 36 20 36C11.125 36 4 28.875 4 20C4 11.1875 11.125 4 20 4C28.8125 4 36 11.1875 36 20ZM20 6C12.25 6 6 12.3125 6 20C6 27.75 12.25 34 20 34C27.6875 34 34 27.75 34 20C34 12.3125 27.6875 6 20 6Z" fill={colorScales.red[500]}/>
        </svg>);
}
function BluePlusIcon() {
    return (<svg className={styles.icon} viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
            <path d="M19 26V21H14C13.4375 21 13 20.5625 13 20C13 19.5 13.4375 19 14 19H19V14C19 13.5 19.4375 13 20 13C20.5 13 21 13.5 21 14V19H26C26.5 19 27 19.5 27 20C27 20.5625 26.5 21 26 21H21V26C21 26.5625 20.5 27 20 27C19.4375 27 19 26.5625 19 26ZM36 20C36 28.875 28.8125 36 20 36C11.125 36 4 28.875 4 20C4 11.1875 11.125 4 20 4C28.8125 4 36 11.1875 36 20ZM20 6C12.25 6 6 12.3125 6 20C6 27.75 12.25 34 20 34C27.6875 34 34 27.75 34 20C34 12.3125 27.6875 6 20 6Z" fill={colorScales.blue[500]}/>
        </svg>);
}
const styles = {
    panelContainer: css({
        display: "grid",
        gridTemplateColumns: "1.5fr 1fr",
        gap: "1rem",
        width: "100%",
        height: "100%",
        padding: "1rem",
        backgroundColor: themeTokens.color.background.muted.default,
    }),
    emptyUnselectedList: css({
        paddingTop: space[8],
        color: themeTokens.color.text.subtle,
        font: text.interface.body.default.medium,
    }),
    emptySelectedList: css({
        color: themeTokens.color.text.subtle,
        font: text.interface.body.default.medium,
        height: "100%",
        display: "flex",
        gap: "1rem",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    }),
    listContainer: css({
        display: "flex",
        flexDirection: "column",
        border: `1px solid ${themeTokens.color.border.primary}`,
        borderRadius: "0.25rem",
        backgroundColor: themeTokens.color.background.primary.default,
        overflowY: "hidden",
    }),
    tenantsList: css({
        overflowY: "auto",
        padding: "0rem 1rem 0.5rem 1rem",
        height: "100%",
    }),
    listItem: css({
        display: "inline-flex",
        alignItems: "center",
        gap: "1rem",
        height: "2rem",
    }),
    panelHeading: css({
        font: text.interface.body.bold.base,
        color: themeTokens.color.text.primary,
        padding: "1rem 1rem",
    }),
    icon: css({
        width: "24px",
        height: "24px",
        fill: "currentcolor",
        display: "block",
    }),
};
