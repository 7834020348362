import { useMenuState, SimpleMenu, ActionButtonType, ActionButton } from "@octopusdeploy/design-system-components";
import type { History } from "history";
import * as React from "react";
import { DropDownIcon } from "~/components/Button/DropDownIcon/DropDownIcon";
import InternalLink from "~/components/Navigation/InternalLink/InternalLink";
import type { OverflowMenuNavLink } from "~/components/OverflowMenu/OverflowMenu";
import styles from "./style.module.less";
type LocationDescriptor = History.LocationDescriptor;
interface BreadcrumbProps {
    title?: string; // If specified, this text will display above the title.
    path?: LocationDescriptor; // If specified, this will link your breadcrumb title.
    overflow?: OverflowMenuNavLink[]; // If specified, this will create a Dropdown button with ternary theme and the specified overflow items
    isAreaLevelBreadcrumb?: boolean;
}
//TODO: @team-frontend-platform Our breadcrumb component has 3 possible states and they more than likely should be separated into multiple components
//The big problem however, is the path and overflow props are mutually exclusive and all of our pages including our SectionTitle makes use of these optional props
//The only real way to split these components and make things more effective is to also change every one of our pages, so leaving this here as something we know
//we need to come back to.
const Breadcrumb: React.StatelessComponent<BreadcrumbProps> = (props) => {
    return props.title ? (<div className={styles.breadcrumb}>
            <BreadcrumbContent {...props}/>
        </div>) : null;
};
Breadcrumb.displayName = "Breadcrumb"
const BreadcrumbContent = (props: BreadcrumbProps) => {
    if (props.overflow && props.title) {
        return <BreadcrumbContentMenu title={props.title} overflow={props.overflow}/>;
    }
    else if (props.path) {
        return (<InternalLink className={props.isAreaLevelBreadcrumb ? styles.breadcrumbLink : null} to={props.path}>
                {props.title}
            </InternalLink>);
    }
    return <div>{props.title}</div>;
};
interface BreadcrumbContentMenuProps {
    title: string;
    overflow: OverflowMenuNavLink[];
}
function BreadcrumbContentMenu({ overflow, title }: BreadcrumbContentMenuProps) {
    const [openMenu, menuState, buttonAriaAttributes] = useMenuState();
    return (<>
            <ActionButton type={ActionButtonType.Ternary} icon={<DropDownIcon />} iconPosition="right" label={title} onClick={openMenu} menuButtonAttributes={buttonAriaAttributes}/>
            <SimpleMenu compact={true} menuState={menuState} items={overflow.map((i) => ({ type: "internal-link", label: i.text, path: i.path, showAsActive: "never" }))} accessibleName={title}/>
        </>);
}
export default Breadcrumb;
