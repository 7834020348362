/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { css } from "@emotion/css";
import { space, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { ActionTemplateResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { connect } from "react-redux";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { repository } from "~/clientInstance";
import AreaTitle from "~/components/AreaTitle";
import BaseComponent from "~/components/BaseComponent";
import NavigationSidebarLayout, { Navigation } from "~/components/NavigationSidebarLayout";
import { withTheme } from "~/components/Theme";
import ToolTip from "~/primitiveComponents/dataDisplay/ToolTip/index";
import routeLinks from "~/routeLinks";
import { actionTemplateFetch, newActionTemplateSelected } from "../../../reducers/libraryArea";
interface ActionTemplateLayoutParams {
    templateId: string;
}
interface StateProps {
    actionTemplateId?: string;
    actionTemplateName?: string;
    actionTemplateLogo?: string;
    actionTemplatePendingUpdates?: number;
}
interface GlobalDispatchProps {
    onFetchActionTemplate(actionTemplate: ActionTemplateResource): void;
    onNewActionTemplateSelected(): void;
}
type Props = RouteComponentProps<ActionTemplateLayoutParams> & StateProps & GlobalDispatchProps;
class ActionTemplateLayoutInternal extends BaseComponent<Props, never> {
    private actionTemplateId: string;
    constructor(props: Props) {
        super(props);
        this.actionTemplateId = this.props.match && this.props.match.params ? this.props.match.params.templateId : null!;
    }
    async componentDidMount() {
        if (this.actionTemplateId) {
            const actionTemplate = await repository.ActionTemplates.get(this.actionTemplateId);
            this.props.onFetchActionTemplate(actionTemplate);
        }
        else {
            this.props.onNewActionTemplateSelected();
        }
    }
    render() {
        return withTheme((theme) => {
            const actionTemplateId = this.actionTemplateId;
            const navLinks = [];
            if (actionTemplateId) {
                navLinks.push(Navigation.navItem("Details", routeLinks.library.stepTemplate(this.actionTemplateId).root, true)!);
                navLinks.push(Navigation.navItem(<UsageNavbarItemLabel totalPendingUpdates={this.props.actionTemplatePendingUpdates}/>, routeLinks.library.stepTemplate(this.actionTemplateId).usage)!);
            }
            return (<main id="maincontent">
                    <AreaTitle link={routeLinks.library.stepTemplates.root} title="Step Templates" breadcrumbTitle={"Library"} breadcrumbPath={routeLinks.library.root}/>
                    <NavigationSidebarLayout name={this.props.actionTemplateName || "New step template"} logoUrl={this.props.actionTemplateLogo} navLinks={navLinks} content={this.props.children}/>
                </main>);
        });
    }
    static displayName = "ActionTemplateLayoutInternal";
}
interface UsageWithPendingUpdatesLabelProps {
    totalPendingUpdates: number | undefined;
}
function UsageNavbarItemLabel({ totalPendingUpdates = 0 }: UsageWithPendingUpdatesLabelProps) {
    return (<div className={usageWithPendingUpdatesLabelStyles}>
            Usage
            {totalPendingUpdates > 0 && <PendingUsages totalPendingUpdates={totalPendingUpdates}/>}
        </div>);
}
function PendingUsages({ totalPendingUpdates }: React.PropsWithChildren<{
    totalPendingUpdates: number;
}>) {
    const tooltipContent = `${totalPendingUpdates} action ${pluralize(totalPendingUpdates, "template usage has", "template usages have")} pending changes.`;
    return (<ToolTip content={tooltipContent}>
            <em className={"fa-solid fa-triangle-exclamation"} style={{ color: themeTokens.color.alert.text.warning }}/>
        </ToolTip>);
}
function pluralize(count: number, singular: string, plural: string) {
    return count > 1 ? plural : singular;
}
const usageWithPendingUpdatesLabelStyles = css({
    display: "flex",
    flexDirection: "row",
    gap: space[8],
    alignItems: "center",
});
const mapGlobalStateToProps = (state: GlobalState) => {
    return !state.libraryArea.currentActionTemplate
        ? {}
        : {
            actionTemplateId: state.libraryArea.currentActionTemplate.id,
            actionTemplateName: state.libraryArea.currentActionTemplate.name,
            actionTemplateLogo: state.libraryArea.currentActionTemplate.logo,
            actionTemplatePendingUpdates: state.libraryArea.currentActionTemplate.pendingUpdates,
        };
};
const mapGlobalActionDispatchersToProps = (dispatch: any) => {
    return {
        onFetchActionTemplate: (actionTemplate: ActionTemplateResource) => {
            dispatch(actionTemplateFetch(actionTemplate));
        },
        onNewActionTemplateSelected: () => {
            dispatch(newActionTemplateSelected());
        },
    };
};
const ActionTemplateLayout = connect<StateProps, GlobalDispatchProps, RouteComponentProps<ActionTemplateLayoutParams>, GlobalState>(mapGlobalStateToProps, mapGlobalActionDispatchersToProps)(ActionTemplateLayoutInternal);
export default withRouter(ActionTemplateLayout);
