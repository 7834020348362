/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionButton, ActionButtonType, NavigationButton } from "@octopusdeploy/design-system-components";
import type { TaskResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { TaskStatusIcon } from "~/areas/projects/components/TaskStatusIcon/TaskStatusIcon";
import TaskLayout from "~/areas/tasks/TaskLayout/TaskLayout";
import { repository } from "~/clientInstance";
import ActionList from "~/components/ActionList/ActionList";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import PaperLayout from "~/components/PaperLayout/PaperLayout";
import routeLinks from "../../../../routeLinks";
import styles from "./style.module.less";
interface RawLogState extends DataBaseComponentState {
    task?: TaskResource<any>;
    raw?: string;
}
export class RawLog extends DataBaseComponent<RouteComponentProps<any>, RawLogState> {
    taskId: string;
    constructor(props: RouteComponentProps<any>) {
        super(props);
        this.taskId = this.props.match.params.taskId;
        this.state = {};
    }
    componentDidMount() {
        return this.refresh();
    }
    refresh() {
        return this.doBusyTask(async () => {
            const task = await repository.Tasks.get(this.taskId);
            const raw = await repository.Tasks.getRaw(task);
            this.setState({ task, raw });
        });
    }
    render() {
        const task = this.state.task!;
        const actionList = task && (<ActionList actions={[
                <NavigationButton key="raw" href={task.Links["Raw"]} label="Download" external={true}/>,
                <ActionButton key="refresh" label="Refresh" type={ActionButtonType.Primary} disabled={this.state.busy} onClick={() => this.refresh()}/>,
            ]}/>);
        return (<TaskLayout>
                <PaperLayout title={"Raw Log"} titleLogo={task ? <TaskStatusIcon item={task}/> : undefined} breadcrumbTitle={task && task.Description} breadcrumbPath={routeLinks.task(task && task.Id).root} busy={this.state.busy} errors={this.errors} sectionControl={actionList} fullWidth={true}>
                    {task && <div className={styles.rawLogContent}>{this.state.raw}</div>}
                </PaperLayout>
            </TaskLayout>);
    }
    static displayName = "RawLog";
}
export default RawLog;
