import type { ConnectTenantsToProjectRequest, EnvironmentResource, ProjectResource, RunbookResource, TenantResource } from "@octopusdeploy/octopus-server-client";
import { Permission, Repository, TenantedDeploymentMode } from "@octopusdeploy/octopus-server-client";
import pluralize from "pluralize";
import * as React from "react";
import ConnectTenantWizardDialogLayout from "~/areas/projects/components/ProjectTenants/ConnectTenantWizardDialogLayout";
import SelectEnvironments from "~/areas/projects/components/ProjectTenants/SelectEnvironments";
import SelectTenants from "~/areas/projects/components/ProjectTenants/SelectTenants";
import { client, repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/index";
import { DataBaseComponent } from "~/components/DataBaseComponent/index";
import DataLoader from "~/components/DataLoader/index";
import SaveDialogLayout from "~/components/DialogLayout/SaveDialogLayout";
import type { DropdownMenuOption } from "~/primitiveComponents/form/Select/DropDownMenu";
export interface ConnectMultipleTenantDialogProps {
    project: ProjectResource;
    onConnected: (numberOfTenantsConnected: number) => void;
}
export default function ConnectMultipleTenantDialog(props: ConnectMultipleTenantDialogProps) {
    return (<InitialDataLoader load={loadInitialData.bind(null, props.project)} operationName="ConnectTenant" renderWhenLoaded={(data) => <ConnectMultipleTenants {...props} {...data}/>} renderAlternate={({ busy, errors }) => <SaveDialogLayout title={`Connect Tenants to ${props.project.Name}`} busy={busy} errors={errors} onSaveClick={() => Promise.resolve(true)}/>}/>);
}
interface InitialDataProps {
    tenants: AvailableTenants;
    environments: AvailableEnvironments;
    runBooks: RunbookResource[];
}
const InitialDataLoader = DataLoader<InitialDataProps>();
const loadInitialData = async (project: ProjectResource): Promise<InitialDataProps> => {
    const [tenants, availableEnvironmentsResponse, runBooks, connectedTenantsResponse] = await Promise.all([
        repository.Tenants.all(),
        repository.Projects.getAvailableEnvironmentsForProject(project.Id),
        repository.Projects.getRunbooks(project, { take: Repository.takeAll }),
        repository.Projects.getTenants(project.Id),
    ]);
    const connectedTenantIds = connectedTenantsResponse.Tenants.map((t) => t.Id);
    const unconnectedTenants = tenants.filter((t) => !connectedTenantIds.includes(t.Id));
    const availableTenants = {
        lookup: unconnectedTenants.reduce((map, tenant) => map.set(tenant.Id, tenant), new Map<string, TenantResource>()),
        options: unconnectedTenants.map((tenant) => ({ value: tenant.Id, text: tenant.Name })),
    };
    const environments = availableEnvironmentsResponse.Environments;
    const availableEnvironments = {
        lookup: environments.reduce((map, env) => map.set(env.Id, env), new Map<string, EnvironmentResource>()),
        all: environments,
    };
    return { tenants: availableTenants, environments: availableEnvironments, runBooks: runBooks.Items };
};
interface ConnectMultipleTenantsProps extends ConnectMultipleTenantDialogProps, InitialDataProps {
}
interface ConnectMultipleTenantsState extends DataBaseComponentState {
    selectedTenants: TenantResource[];
    selectedEnvironmentIds: string[];
}
class ConnectMultipleTenants extends DataBaseComponent<ConnectMultipleTenantsProps, ConnectMultipleTenantsState> {
    constructor(props: ConnectMultipleTenantsProps) {
        super(props);
        this.state = {
            selectedTenants: [],
            selectedEnvironmentIds: [],
        };
    }
    canConnect = () => this.state.selectedTenants.length > 0;
    connect = async () => this.doBusyTask(async () => {
        if (this.state.selectedTenants.length > 0) {
            const request: ConnectTenantsToProjectRequest = {
                TenantEnvironments: this.state.selectedTenants.map((t) => ({ TenantId: t.Id, Environments: this.state.selectedEnvironmentIds })),
            };
            await repository.Projects.connectTenants(request, this.props.project.Id);
            if (this.props.project.TenantedDeploymentMode === TenantedDeploymentMode.Untenanted) {
                const project = await repository.Projects.get(this.props.project.Id);
                client.dispatchEvent({ type: "ProjectModified", project });
            }
            setTimeout(() => this.props.onConnected(this.state.selectedTenants.length), 0);
        }
    });
    render() {
        const disabled = !this.canConnect();
        const onTenantsSelected = (tenants: TenantResource[]) => {
            this.setState({ selectedTenants: tenants });
        };
        const onEnvironmentsSelected = (environmentIds: string[]) => {
            this.setState({ selectedEnvironmentIds: environmentIds });
        };
        return (<ConnectTenantWizardDialogLayout title={`Connect Tenants to ${this.props.project.Name}`} onSaveClick={this.connect} saveButtonDisabled={disabled} busy={this.state.busy} errors={this.errors} savePermission={{ permission: Permission.TenantEdit }} saveButtonLabel={`Connect ${pluralize("Tenant", this.state.selectedTenants.length, true)}`} wizardStepNames={["Select tenants", "Select environments"]}>
                <SelectTenants tenants={Array.from(this.props.tenants.lookup.values())} selectedTenants={this.state.selectedTenants} updateSelectedTenants={onTenantsSelected}/>
                <SelectEnvironments selectedTenants={this.state.selectedTenants} availableEnvironments={this.props.environments} selectedEnvironmentIds={this.state.selectedEnvironmentIds} updateSelectedEnvironments={onEnvironmentsSelected} project={this.props.project}/>
            </ConnectTenantWizardDialogLayout>);
    }
    static displayName = "ConnectMultipleTenants";
}
interface AvailableTenants {
    lookup: Map<string, TenantResource>;
    options: DropdownMenuOption[];
}
export interface AvailableEnvironments {
    lookup: Map<string, EnvironmentResource>;
    all: EnvironmentResource[];
}
