import { css } from "@emotion/css";
import { space } from "@octopusdeploy/design-system-tokens";
import type { ReactElement } from "react";
import React from "react";
export interface TrailingSegmentProps {
    primaryAction?: ReactElement;
}
export function TrailingSegment({ primaryAction }: TrailingSegmentProps) {
    return <>{primaryAction && <div className={trailingSegmentStyles}>{primaryAction}</div>}</>;
}
const trailingSegmentStyles = css({
    display: "flex",
    alignItems: "center",
    gap: space["16"],
});
