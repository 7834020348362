/* eslint-disable @typescript-eslint/consistent-type-assertions */
import type { CloudConnectionType, MachineResource, StepPackageDeploymentTargetType, StepPackageDeploymentTargetTypeLinks, StepPackageEndpointResource, FeatureToggle } from "@octopusdeploy/octopus-server-client";
import { CommunicationStyle } from "@octopusdeploy/octopus-server-client";
import type { DeploymentTargetUI } from "@octopusdeploy/step-ui";
import { exhaustiveCheck } from "@octopusdeploy/type-utils";
import { Environment } from "@octopusdeploy/utilities";
import { values } from "lodash";
import * as React from "react";
import { createStepPackageCategoryDefinition } from "~/areas/infrastructure/components/MachineSettings/Endpoints/StepPackageCategoryDefinition";
import { client, repository } from "~/clientInstance";
import type { UnknownStepPackageDeploymentTarget } from "~/components/StepPackageDeploymentTargetEditor/StepPackageDeploymentTarget";
import { asUnknownStepPackageDeploymentTarget } from "~/components/StepPackageDeploymentTargetEditor/StepPackageDeploymentTarget";
import { stepPackageResolver } from "~/stepPackages/stepPackageResolver";
import { getMachineIconUrl } from "~/utils/MachineIconUrlFetchers/machineIconUrlFetchers";
import { hidePrereleaseStepPackages } from "../../../../../utils/FeatureFlags/hidePrereleaseStepPackages";
import type { MachineIconVariant } from "../../MachineIcon/MachineIcon";
import { MachineIcon } from "../../MachineIcon/MachineIcon";
import azureCategory from "./AzureCategoryDefinition";
import cloudRegionCategory from "./CloudRegionCategoryDefinition";
import EndpointCard from "./EndpointCard";
import kubernetesCategory from "./KubernetesCategoryDefinition";
import linuxCategory from "./LinuxCategoryDefinition";
import macCategory from "./MacCategoryDefinition";
import offlineDropCategory from "./OfflineDropCategoryDefinition";
import windowsCategory from "./WindowsCategoryDefinition";
import { createSampleDeploymentTargetType, sampleDeploymentTargetPackage } from "./sample/SampleDeploymentTargetPackage";
import styles from "./styles.module.less";
export { CommunicationStyle };
export interface DisplayOrder {
    displayOrder: number;
}
export interface CategoryDefinition extends DisplayOrder {
    category: string;
    title: React.ReactNode;
    help?: React.ReactNode;
}
export type TentacleType = CommunicationStyle.TentacleActive | CommunicationStyle.TentaclePassive;
export type MachineTypeRegistration = {
    communicationStyle: CommunicationStyle.Ssh | TentacleType;
    discoverable: boolean;
};
export type MachineRegistration = MachineTypeRegistration & CategorizedEndpointRegistration;
export type DeploymentTargetRegistration = CategorizedEndpointRegistration;
export enum EndpointSelectionScope {
    Worker = "Worker",
    DeploymentTarget = "Deployment Target"
}
export enum EndpointRegistrationKey {
    CloudRegion = "CloudRegion",
    TentaclePassive = "TentaclePassive",
    TentacleActive = "TentacleActive",
    Ssh = "Ssh",
    OfflineDrop = "OfflineDrop",
    AzureWebApp = "AzureWebApp",
    AzureCloudService = "AzureCloudService",
    AzureServiceFabricCluster = "AzureServiceFabricCluster",
    AzureVmExtension = "AzureVmExtension",
    Kubernetes = "Kubernetes",
    KubernetesWithArgoCD = "KubernetesWithArgoCD",
    KubernetesWithOctopusAgent = "KubernetesWithOctopusAgent",
    StepPackage = "StepPackage"
}
interface RenderRegistrationNavigationProps {
    onNavigate?: () => void;
}
export interface RenderRegistrationCardProps {
    category: CategoryDefinition;
    registration: CategorizedEndpointRegistration;
    onNavigate: () => void;
    scope: EndpointSelectionScope;
}
export type EndpointLogoProps = {
    machine?: MachineResource;
    variant?: MachineIconVariant;
};
export type EndpointLogo = React.ComponentType<EndpointLogoProps>;
export const BuiltInEndpointLogo: EndpointLogo = (props) => {
    if (props.machine === undefined)
        return <></>;
    const machineIcon = getMachineIconUrl(props.machine);
    return <MachineIcon imageUrl={machineIcon} variant={props.variant ?? "default"}/>;
};
export function createStepPackageEndpointLogo(endpoint: StepPackageEndpointResource) {
    const StepPackageEndpointLogo: EndpointLogo = (props) => {
        const stepPackageLogoUrl = client.resolve(endpoint.Links.Logo);
        return <MachineIcon imageUrl={stepPackageLogoUrl} variant={props.variant}/>;
    };
    return StepPackageEndpointLogo;
}
export interface SharedEndpointRegistration extends DisplayOrder {
    categories: CategoryDefinition[];
    renderCard: (props: RenderRegistrationCardProps) => React.ReactElement<{}>;
    key: string;
    name: string;
    communicationStyle: CommunicationStyle;
    renderDialogView?: (renderProps: {
        className: string;
    }) => React.ReactElement<{}>;
    visibilityFeatureToggle?: FeatureToggle;
}
export interface StepPackageEndpointRegistration extends SharedEndpointRegistration {
    communicationStyle: CommunicationStyle.StepPackage;
}
export function isStepPackageEndpointRegistration(registration: CategorizedEndpointRegistration): registration is StepPackageEndpointRegistration {
    return registration.communicationStyle === CommunicationStyle.StepPackage;
}
export function getEndpointRegistrationKey(communicationStyle: CommunicationStyle): EndpointRegistrationKey {
    switch (communicationStyle) {
        case CommunicationStyle.None:
            return EndpointRegistrationKey.CloudRegion;
        case CommunicationStyle.TentaclePassive:
            return EndpointRegistrationKey.TentaclePassive;
        case CommunicationStyle.TentacleActive:
            return EndpointRegistrationKey.TentacleActive;
        case CommunicationStyle.Ssh:
            return EndpointRegistrationKey.Ssh;
        case CommunicationStyle.OfflineDrop:
            return EndpointRegistrationKey.OfflineDrop;
        case CommunicationStyle.AzureWebApp:
            return EndpointRegistrationKey.AzureWebApp;
        case CommunicationStyle.Kubernetes:
            return EndpointRegistrationKey.Kubernetes;
        case CommunicationStyle.StepPackage:
            return EndpointRegistrationKey.StepPackage;
        case CommunicationStyle.AzureCloudService:
            return EndpointRegistrationKey.AzureCloudService;
        case CommunicationStyle.AzureServiceFabricCluster:
            return EndpointRegistrationKey.AzureServiceFabricCluster;
        default:
            exhaustiveCheck(communicationStyle, "Not all communication styles have been handled");
    }
}
export type BuiltInEndpointRegistration = SharedEndpointRegistration & {
    // Target Discovery properties are not set for StepPackage registrations
    // as that information is stored on the StepPackage itself.
    targetDiscoveryCloudConnectionTypes?: () => Array<CloudConnectionType>;
    customTargetDiscoveryLabel?: string;
};
export type InformationEndpointRegistration = SharedEndpointRegistration & {
    communicationStyle: CommunicationStyle.None;
};
export type CategorizedEndpointRegistration = BuiltInEndpointRegistration | StepPackageEndpointRegistration;
export type StepPackageDeploymentTargetRegistration = DeploymentTargetRegistration & {
    targetType: StepPackageDeploymentTargetType;
    links: StepPackageDeploymentTargetTypeLinks;
    version: string;
};
export interface CategorizedEndpointResult {
    category: CategoryDefinition;
    endpoints: CategorizedEndpointRegistration[];
}
export type EndpointRegistration = CategorizedEndpointRegistration;
const knownEndpointCategories = [azureCategory, linuxCategory, macCategory, windowsCategory, kubernetesCategory, cloudRegionCategory, offlineDropCategory];
const getStepPackageTargetCategories = (dt: StepPackageDeploymentTargetType): CategoryDefinition[] => {
    let packageCategories: CategoryDefinition[] = [];
    if (dt.Categories === undefined)
        return packageCategories;
    packageCategories = dt.Categories.map((category) => {
        const knownCategory = knownEndpointCategories.find((c) => c.category.toLowerCase() === category.toLowerCase());
        if (knownCategory !== undefined)
            return knownCategory;
        return createStepPackageCategoryDefinition(category);
    });
    return packageCategories;
};
const createStepPackageDeploymentTargetRegistration = (stepPackageDeploymentTargetType: StepPackageDeploymentTargetType): StepPackageDeploymentTargetRegistration => {
    const { Name, Id, Version, Links } = stepPackageDeploymentTargetType;
    const logoUrl = client.resolve(stepPackageDeploymentTargetType.Links.Logo);
    // const logoSize = "3.1rem";
    const logoCircleSize = "4.5rem";
    return {
        key: `${Id}`,
        displayOrder: 12,
        categories: getStepPackageTargetCategories(stepPackageDeploymentTargetType),
        name: Name,
        communicationStyle: CommunicationStyle.StepPackage,
        links: Links,
        version: Version,
        renderCard: ({ registration, category, onNavigate }) => (<EndpointCard logo={<div className={styles.centreThumbnail}>
                        <img src={logoUrl}/>
                    </div>} registrationName={registration.name} description={(registration as StepPackageDeploymentTargetRegistration).targetType.Description} onNavigate={onNavigate}/>),
        targetType: stepPackageDeploymentTargetType,
    };
};
class EndpointRegistry {
    private deploymentTargets: Record<string, DeploymentTargetRegistration> = {};
    private machines: Record<string, MachineRegistration> = {};
    getDeploymentTarget(key: EndpointRegistrationKey) {
        return this.deploymentTargets[key];
    }
    getMachine(key: EndpointRegistrationKey) {
        return this.machines[key];
    }
    getEndpoint(key: EndpointRegistrationKey) {
        return this.getDeploymentTarget(key) || this.getMachine(key);
    }
    registerEndpoint(registration: DeploymentTargetRegistration | MachineRegistration) {
        if (this.isMachineRegistration(registration)) {
            if (!this.machines.hasOwnProperty(registration.key)) {
                this.machines[registration.key] = registration;
            }
        }
        else {
            if (!this.deploymentTargets.hasOwnProperty(registration.key)) {
                this.deploymentTargets[registration.key] = registration;
            }
        }
    }
    async getAllRegistrations(): Promise<EndpointRegistration[]> {
        const stepPackageTargetTypes = this.getAllStepPackageTargetTypes();
        return [...this.getAllMachines(), ...this.getAllDeploymentTargets(), ...(await stepPackageTargetTypes)];
    }
    getAllMachines(): MachineRegistration[] {
        return values(this.machines);
    }
    getAllDeploymentTargets(): DeploymentTargetRegistration[] {
        return values(this.deploymentTargets);
    }
    async getStepPackageDeploymentTarget(deploymentTargetTypeId: string, version: string | undefined): Promise<UnknownStepPackageDeploymentTarget> {
        return getStepPackageDeploymentTargetImplementedInPortalInDevelopmentMode(deploymentTargetTypeId) ?? (await getRemoteStepPackageDeploymentTarget(deploymentTargetTypeId, version));
    }
    async getAllStepPackageTargetTypes(): Promise<StepPackageDeploymentTargetRegistration[]> {
        let targetsToRegister = await repository.StepPackageDeploymentTarget.getStepPackageDeploymentTargetTypes();
        if (Environment.isInDevelopmentMode()) {
            const inDevelopmentTargets = [createSampleDeploymentTargetType()];
            const targetTypeIds = new Set(targetsToRegister.map((t) => t.Id));
            const inDevelopmentTargetsThatArentReturnedByServer = inDevelopmentTargets.filter((t) => !targetTypeIds.has(t.Id));
            targetsToRegister = [...targetsToRegister, ...inDevelopmentTargetsThatArentReturnedByServer];
        }
        return targetsToRegister
            .filter(hidePrereleaseStepPackages) // feature toggle to prevent prerelease step packages appearing in production
            .map(createStepPackageDeploymentTargetRegistration);
    }
    isMachineRegistration(item: EndpointRegistration): item is MachineRegistration {
        switch (item.communicationStyle) {
            case CommunicationStyle.TentacleActive:
            case CommunicationStyle.TentaclePassive:
            case CommunicationStyle.Ssh:
                return true;
            default:
                return false;
        }
    }
    isCategorizedEndpoint(item: EndpointRegistration): item is CategorizedEndpointRegistration {
        const endpoint = item as CategorizedEndpointRegistration;
        return endpoint.categories !== undefined;
    }
    isBuiltInEndpoint(item: CategorizedEndpointRegistration): item is BuiltInEndpointRegistration {
        return item.communicationStyle !== CommunicationStyle.StepPackage;
    }
    categorizeEndpoints(endpoints: EndpointRegistration[]): Record<string, CategorizedEndpointResult> {
        const categorizedEndoints = endpoints.filter(this.isCategorizedEndpoint);
        return categorizedEndoints.reduce((prev: Record<string, CategorizedEndpointResult>, current: CategorizedEndpointRegistration) => {
            const result = { ...prev };
            current.categories.forEach((x) => (result[x.category] = {
                category: x,
                endpoints: [...((prev[x.category] && prev[x.category].endpoints) || []), ...[current]],
            }));
            return result;
        }, {});
    }
}
export async function getRemoteStepPackageDeploymentTarget(deploymentTargetTypeId: string, version: string | undefined): Promise<UnknownStepPackageDeploymentTarget> {
    const stepPackage = version === undefined ? await stepPackageResolver.getLatestStepPackageByDeploymentTargetType(deploymentTargetTypeId) : await stepPackageResolver.getStepPackageByDeploymentTargetType(deploymentTargetTypeId, version);
    return {
        deploymentTargetTypeId,
        version: stepPackage.version,
        name: stepPackage.name,
        ui: stepPackage.stepUI as DeploymentTargetUI<unknown>,
        inputJsonSchema: stepPackage.schema,
        targetDiscoveryCloudConnectionTypes: stepPackage.targetDiscoveryCloudConnectionTypes,
    };
}
function getStepPackageDeploymentTargetImplementedInPortalInDevelopmentMode(deploymentTargetTypeId: string): UnknownStepPackageDeploymentTarget | null {
    if (!Environment.isInDevelopmentMode()) {
        return null;
    }
    return getStepPackageDeploymentTargetImplementedInPortal(deploymentTargetTypeId);
}
function getStepPackageDeploymentTargetImplementedInPortal(deploymentTargetTypeId: string): UnknownStepPackageDeploymentTarget | null {
    switch (deploymentTargetTypeId) {
        case "sample-deployment-target":
            return asUnknownStepPackageDeploymentTarget(sampleDeploymentTargetPackage);
        default:
            return null;
    }
}
const registry = new EndpointRegistry();
export default registry;
