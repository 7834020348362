import { css, cx } from "@emotion/css";
import { space, text, themeTokens, colorScales, borderRadius } from "@octopusdeploy/design-system-tokens";
import type { ReactElement } from "react";
import React from "react";
import { useMediaQuery } from "react-responsive";
import InternalLink from "~/components/Navigation/InternalLink";
import { ThirdPartyIcon, ThirdPartyIconType } from "~/primitiveComponents/dataDisplay/Icon/index";
import type { HeaderPrimaryLogo } from "./HeaderPrimary";
export interface LeadingSegmentProps {
    backButtonHref?: string;
    logo?: HeaderPrimaryLogo;
    title: string;
    titleIcon?: ReactElement;
}
export function LeadingSegment({ backButtonHref, logo, title, titleIcon }: LeadingSegmentProps) {
    const isLargerThanIpad = useMediaQuery({ query: `(min-width: 811px)` });
    return (<div className={cx(leadingSegmentStyles, { [leadingSegmentMobileStyles]: !isLargerThanIpad })}>
            {backButtonHref && (<InternalLink to={backButtonHref} accessibleName={"Navigate Up"}>
                    <ThirdPartyIcon iconType={ThirdPartyIconType.ChevronLeft}/>
                </InternalLink>)}
            {logo && (<div className={logoContainerStyles}>
                    <img className={logoStyles} src={logo.href} alt={logo.accessibleName}/>
                </div>)}
            <h2 title={title} className={titleStyles}>
                {title}
            </h2>
            {isLargerThanIpad && titleIcon}
        </div>);
}
const leadingSegmentStyles = css({
    minWidth: 0,
    display: "flex",
    alignItems: "center",
    gap: space["12"],
});
const leadingSegmentMobileStyles = css({
    gap: space["16"],
});
const logoContainerStyles = css({
    width: "2.25rem",
    height: "2.25rem",
    borderRadius: borderRadius.medium,
    display: "flex",
    flexShrink: 0,
    justifyContent: "center",
    background: colorScales.white,
});
const logoStyles = css({
    borderRadius: borderRadius.small,
    objectFit: "scale-down",
    width: "100%",
    height: "100%",
});
const titleStyles = css({
    all: "unset",
    maxWidth: "35rem",
    font: text.interface.heading.small,
    color: themeTokens.color.text.primary,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
});
