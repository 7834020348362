import { css } from "@emotion/css";
import { fontSize, space } from "@octopusdeploy/design-system-tokens";
import { color } from "@octopusdeploy/design-system-tokens/src/generated/themeTokens";
import { Permission, Repository, type UserResource } from "@octopusdeploy/octopus-server-client";
import type { ServiceAccountOidcIdentityResource } from "@octopusdeploy/octopus-server-client/src/repositories/serviceAccountOidcIdentitiesRepository";
import * as React from "react";
import { useState } from "react";
import { repository } from "~/clientInstance";
import CopyValueToClipboard from "~/components/CopyToClipboardButton/CopyValueToClipboard";
import DataBaseComponent, { useDoBusyTaskEffect } from "~/components/DataBaseComponent";
import type { DoBusyTask } from "~/components/DataBaseComponent/DataBaseComponent";
import Dialog from "~/components/Dialog/Dialog";
import OpenDialogButton from "~/components/Dialog/OpenDialogButton";
import DeleteDialog from "~/components/DialogLayout/DeleteDialog";
import SaveDialogLayout from "~/components/DialogLayout/SaveDialogLayout";
import { useErrors } from "~/components/ErrorContext/ErrorContext";
import { useFieldErrors } from "~/components/FieldErrorContext/FieldErrorContext";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import { RemoveItemsList } from "~/components/RemoveItemsList/RemoveItemsList";
import { Note, Text, and, required, url } from "~/components/form";
interface ServiceAccountOidcIdentitiesProps {
    serviceAccount: UserResource;
    doBusyTask: DoBusyTask;
}
class ServiceAccountOidcIdentityList extends RemoveItemsList<ServiceAccountOidcIdentityResource> {
}
export default function ServiceAccountOidcIdentities({ serviceAccount, doBusyTask }: ServiceAccountOidcIdentitiesProps) {
    const [serviceAccountId, setServiceAccountId] = useState<string | undefined>(undefined);
    const [oidcIdentities, setOidcIdentities] = useState<ServiceAccountOidcIdentityResource[] | undefined>(undefined);
    const [oidcIdentityToEdit, setOidcIdentityToEdit] = useState<ServiceAccountOidcIdentityResource | undefined>(undefined);
    const [oidcIdentityToDelete, setOidcIdentityToDelete] = useState<ServiceAccountOidcIdentityResource | undefined>(undefined);
    const refresh = useDoBusyTaskEffect(doBusyTask, async () => {
        await refreshOidcIdentities();
    }, [serviceAccount]);
    async function refreshOidcIdentities() {
        const response = await repository.ServiceAccountOidcIdentities.getOidcIdentities({
            serviceAccountId: serviceAccount.Id,
            skip: 0,
            take: Repository.takeAll,
        });
        setServiceAccountId(response.ExternalId);
        setOidcIdentities(response.OidcIdentities);
    }
    async function onOidcIdentityDelete(identity: ServiceAccountOidcIdentityResource) {
        await repository.ServiceAccountOidcIdentities.delete({
            id: identity.Id,
            serviceAccountId: identity.ServiceAccountId,
        });
        setOidcIdentityToDelete(undefined);
        await refresh();
    }
    async function onOidcIdentityDeleteCanceled() {
        setOidcIdentityToDelete(undefined);
    }
    async function onOidcIdentityEdit(identity: ServiceAccountOidcIdentityResource) {
        await repository.ServiceAccountOidcIdentities.modify({
            id: identity.Id,
            serviceAccountId: identity.ServiceAccountId,
            name: identity.Name,
            issuer: identity.Issuer,
            subject: identity.Subject,
        });
        setOidcIdentityToEdit(undefined);
        await refresh();
    }
    function onOidcIdentityEditCancel() {
        setOidcIdentityToEdit(undefined);
    }
    async function onOidcIdentityCreate(identity: ServiceAccountOidcIdentityResource) {
        await repository.ServiceAccountOidcIdentities.create({
            serviceAccountId: identity.ServiceAccountId,
            name: identity.Name,
            issuer: identity.Issuer,
            subject: identity.Subject,
        });
        await refresh();
    }
    const styles = {
        noOidcIdentities: css({
            color: color.text.muted,
            fontSize: fontSize["medium"],
        }),
        serviceAccountIdContent: css({
            display: "flex",
            gap: space[1],
            alignItems: "center",
        }),
        identityContent: css({
            display: "flex",
            flexDirection: "column",
            gap: space[4],
        }),
        identityName: css({
            fontSize: "1rem",
            overflowWrap: "anywhere",
        }),
        identityProperty: css({
            display: "flex",
            gap: space[4],
            fontSize: fontSize["medium"],
        }),
        identityPropertyTitle: css({
            minWidth: space[56],
        }),
        identityPropertyValue: css({
            overflowWrap: "anywhere",
        }),
    };
    const listActions = [
        <OpenDialogButton key="newOidcIdentity" label="New OIDC Identity" title="New OIDC Identity" accessibleName="New OIDC Identity" permission={{ permission: Permission.UserEdit }} renderDialog={(renderProps) => <CreateServiceAccountOidcIdentityDialog open={renderProps.open} serviceAccount={serviceAccount} onSave={onOidcIdentityCreate} onCancel={renderProps.closeDialog}/>}/>,
    ];
    const canEditUser = isAllowed({ permission: Permission.UserEdit });
    return (<>
            {serviceAccountId && (<>
                    <div className={styles.serviceAccountIdContent}>
                        <div>Service Account Id:</div>
                        <CopyValueToClipboard value={serviceAccountId}/>
                    </div>
                    <Note>
                        Service Account Id must be set as the audience of the OIDC token, <ExternalLink href="ServiceAccountOidcIdentities">learn more about configuring OIDC identities.</ExternalLink>
                    </Note>
                </>)}
            {oidcIdentities && (<ServiceAccountOidcIdentityList removeButtonAccessibleName={(identity) => {
                return `removeOidcIdentity-${identity.Id}`;
            }} listActions={listActions} data={oidcIdentities} empty={<div className={styles.noOidcIdentities}>There aren't currently any OIDC identities configured.</div>} onRow={(identity) => (<div key={identity.Id} className={styles.identityContent}>
                            <div className={styles.identityName}>{identity.Name}</div>
                            <div className={styles.identityProperty}>
                                <span className={styles.identityPropertyTitle}>Issuer:</span>
                                <span className={styles.identityPropertyValue}>{identity.Issuer}</span>
                            </div>
                            <div className={styles.identityProperty}>
                                <span className={styles.identityPropertyTitle}>Subject:</span>
                                <span className={styles.identityPropertyValue}>{identity.Subject}</span>
                            </div>
                        </div>)} onRowTouch={canEditUser
                ? (identity) => {
                    setOidcIdentityToEdit(identity);
                }
                : undefined} onRemoveRow={canEditUser
                ? (identity) => {
                    setOidcIdentityToDelete(identity);
                }
                : undefined}/>)}

            {oidcIdentityToDelete && <DeleteServiceAccountOidcIdentityDialog oidcIdentity={oidcIdentityToDelete} onDelete={onOidcIdentityDelete} onCancel={onOidcIdentityDeleteCanceled}/>}
            {oidcIdentityToEdit && <EditServiceAccountOidcIdentityDialog oidcIdentity={oidcIdentityToEdit} onSave={onOidcIdentityEdit} onCancel={onOidcIdentityEditCancel}/>}
        </>);
}
type ConfigureServiceAccountOidcIdentityDialogLayoutInternalProps = {
    oidcIdentity: ServiceAccountOidcIdentityResource;
    onSave: (identity: ServiceAccountOidcIdentityResource) => Promise<unknown>;
    onCancel: () => void;
    doBusyTask: DoBusyTask;
    busy?: Promise<unknown> | boolean;
};
function ConfigureServiceAccountOidcIdentityDialogLayoutInternal({ oidcIdentity, onSave, onCancel, doBusyTask, busy }: ConfigureServiceAccountOidcIdentityDialogLayoutInternalProps) {
    const [oidcIdentityBeingConfigured, setOidcIdentityBeingConfigured] = useState<ServiceAccountOidcIdentityResource>(oidcIdentity);
    const { getFieldError } = useFieldErrors();
    const errors = useErrors();
    const isNew = oidcIdentity.Id === "";
    async function onSaveClick() {
        return await doBusyTask(async () => {
            await onSave(oidcIdentityBeingConfigured);
        });
    }
    return (<SaveDialogLayout title={`${isNew ? "New" : "Edit"} OIDC Identity`} busy={busy} errors={errors} onSaveClick={onSaveClick} onCancelClick={() => {
            onCancel();
            return true;
        }}>
            <Text key="Name" label="Name" value={oidcIdentityBeingConfigured.Name} onChange={(name) => setOidcIdentityBeingConfigured({
            ...oidcIdentityBeingConfigured,
            Name: name,
        })} validate={required("Name is required")} error={getFieldError("Name")} autoFocus helperText="A unique name for the OIDC identity within this service account."/>
            <Text key="Issuer" label="Issuer" value={oidcIdentityBeingConfigured.Issuer} onChange={(issuer) => setOidcIdentityBeingConfigured({
            ...oidcIdentityBeingConfigured,
            Issuer: issuer,
        })} validate={and([required("Issuer is required"), url("Issuer must be a valid url")])} error={getFieldError("Issuer")} helperText="The URL where OIDC tokens will be issued from. This must match exactly the issuer provided in the OIDC token."/>
            <Text key="Subject" label="Subject" value={oidcIdentityBeingConfigured.Subject} onChange={(subject) => setOidcIdentityBeingConfigured({
            ...oidcIdentityBeingConfigured,
            Subject: subject,
        })} validate={required("Subject is required")} error={getFieldError("Subject")} helperText="The subject of the OIDC identity. This must match exactly the subject provided in the OIDC token."/>
        </SaveDialogLayout>);
}
type ConfigureServiceAccountOidcIdentityDialogLayoutProps = {
    oidcIdentity: ServiceAccountOidcIdentityResource;
    onSave: (identity: ServiceAccountOidcIdentityResource) => Promise<unknown>;
    onCancel: () => void;
};
export class ConfigureServiceAccountOidcIdentityDialogLayout extends DataBaseComponent<ConfigureServiceAccountOidcIdentityDialogLayoutProps> {
    constructor(props: ConfigureServiceAccountOidcIdentityDialogLayoutProps) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        this.clearErrors();
    }
    render() {
        return <ConfigureServiceAccountOidcIdentityDialogLayoutInternal oidcIdentity={this.props.oidcIdentity} onSave={this.props.onSave} onCancel={this.props.onCancel} doBusyTask={this.doBusyTask} busy={this.state.busy}/>;
    }
    static displayName = "ConfigureServiceAccountOidcIdentityDialogLayout";
}
type CreateServiceAccountOidcIdentityDialogProps = {
    open: boolean;
    serviceAccount: UserResource;
    onSave: (identity: ServiceAccountOidcIdentityResource) => Promise<unknown>;
    onCancel: () => unknown;
};
function CreateServiceAccountOidcIdentityDialog({ open, serviceAccount, onSave, onCancel }: CreateServiceAccountOidcIdentityDialogProps) {
    return (<Dialog open={open} wide>
            <ConfigureServiceAccountOidcIdentityDialogLayout oidcIdentity={{
            Id: "",
            ServiceAccountId: serviceAccount.Id,
            Name: "",
            Issuer: "",
            Subject: "",
        }} onSave={onSave} onCancel={onCancel}/>
        </Dialog>);
}
type EditServiceAccountOidcIdentityDialogProps = {
    oidcIdentity: ServiceAccountOidcIdentityResource;
    onSave: (identity: ServiceAccountOidcIdentityResource) => Promise<unknown>;
    onCancel: () => unknown;
};
function EditServiceAccountOidcIdentityDialog({ oidcIdentity, onSave, onCancel }: EditServiceAccountOidcIdentityDialogProps) {
    return (<Dialog open wide>
            <ConfigureServiceAccountOidcIdentityDialogLayout oidcIdentity={oidcIdentity} onSave={onSave} onCancel={onCancel}/>
        </Dialog>);
}
type DeleteServiceAccountOidcIdentityDialogProps = {
    oidcIdentity: ServiceAccountOidcIdentityResource;
    onDelete: (identity: ServiceAccountOidcIdentityResource) => Promise<unknown>;
    onCancel: () => void;
};
export class DeleteServiceAccountOidcIdentityDialog extends DataBaseComponent<DeleteServiceAccountOidcIdentityDialogProps> {
    constructor(props: DeleteServiceAccountOidcIdentityDialogProps) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        this.clearErrors();
    }
    render() {
        const styles = {
            message: css({
                overflowWrap: "anywhere",
            }),
        };
        return (<DeleteDialog open={this.props.oidcIdentity != undefined} busy={this.state.busy} onClose={this.props.onCancel} errors={this.errors} onDeleteClick={() => this.doBusyTask(() => this.props.onDelete(this.props.oidcIdentity))} title="Delete OIDC Identity">
                <div className={styles.message}>
                    Are you sure you want to delete OIDC Identity <b>{this.props?.oidcIdentity.Name}</b>? This action cannot be undone.
                </div>
            </DeleteDialog>);
    }
    static displayName = "DeleteServiceAccountOidcIdentityDialog";
}
